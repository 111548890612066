import { axiosInstance } from "../../config";

//分页查询
export const getInventoryList = (params) => {
  return axiosInstance.post("/devicestock/page", params)
};

//新增设备库存
export const addDeviceInventory = (params) => {
  return axiosInstance.post("/devicestock/add", params)
};

//删除设备库存
export const deleteDeviceInventory = (id) => {
  return axiosInstance.post(`/devicestock/delete/${id}`)
};

//出库
export const outInventory = (params) => {
  return axiosInstance.post(`/devicestock/outStock`, params)
};

//修改配置
export const updateDeviceInventory = (params) => {
  return axiosInstance.post("/devicestock/update/configuration", params)
};

//通过id获取数据
export const getDeviceInventoryData = (id) => {
  return axiosInstance.post(`/devicestock/${id}`)
};
