/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-26 14:32:46
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-06-27 13:18:23
 * @FilePath: /pms-web/src/apis/gantt/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axiosInstance } from "../config";

//获取甘特图数据
export const getGanttData = (projectId) => {
  return axiosInstance.get(`/projectNode/queryGanttChart/${projectId}`)
};

//获取甘特图头部数据
export const getGanttHeaderData = (projectId) => {
  return axiosInstance.get(`/bulletinBoard/projectCountById?projectId=${projectId}`)
};