import eventBus from "@/utils/commonFunc/eventBus"
import React, { useState } from "react"
import ExamConfig from "./components/ExamConfig"
import ExercisesTable from "./components/ExercisesTable"
import SubJectTable from "./components/SubjectTable"
import "./index.scss"

function ExamBank () {
  const [showSwitch, setShowSwitch] = useState({
    subjectSwitch: true,
    exercisesSwitch: false,
    configSwitch: false,
    subjectName: undefined,
    subjectId: undefined
  })
  //切换组件及信息
  const pageChange = (type,subjectName,id) => {
    setShowSwitch({
      subjectSwitch: type === "subject",
      exercisesSwitch: type === "exercises",
      configSwitch: type === "config",
      subjectName: type === "exercises" ? subjectName : undefined,
      subjectId: type != "subject" ? id : undefined
    })
    const breadTitle = type === "subject" ? undefined : (type === "exercises" ? "科目题库" : "考试配置")
    eventBus.emit("setBreadcrumbData", ["系统管理", "考试题库", breadTitle ].filter(d => d))
  }
  return (
    <div className="exam-bank">
      {showSwitch.subjectSwitch && <SubJectTable pageChange={pageChange} />}
      {showSwitch.exercisesSwitch && <ExercisesTable pageChange={pageChange} subjectName={showSwitch.subjectName} subjectId={showSwitch.subjectId}/>}
      {showSwitch.configSwitch && <ExamConfig pageChange={pageChange} subjectId={showSwitch.subjectId}/>}
    </div>
  )
}
export default ExamBank