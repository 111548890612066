/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-08 10:49:03
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-06 13:14:43
 * @FilePath: /pms-web/src/containers/ProjectBoard/components/projectProgress.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useEffect, useState } from "react";
import ProjectProgressGrid from "./projectProgressGrid";

function ProjectProgress({ dataList }) {
  const [gridList, setGridList] = useState([]);
  useEffect(() => {
    const list = dataList.map((data, index) => {
      return {
        progressName: data.stageName,
        projectNum: data.number,
        stageId: data.stageId + "",
        projectType: data.sopRCode + "",
        showArrow: index !== dataList.length - 1,
      };
    });
    setGridList(list);
  }, [dataList]);
  return (
    <>
      <div className="project-progress-container">
        <div className="project-progress-desc">
          <div className="project-num">项目数</div>
          <div className="project-progress-tip">当前所处阶段</div>
        </div>
        <div className="grid-list">
          {gridList.map((grid, index) => (
            <ProjectProgressGrid
              key={index}
              progressName={grid.progressName}
              projectNum={grid.projectNum}
              showArrow={grid.showArrow}
              stageId={grid.stageId}
              projectType={grid.projectType}
            />
          ))}
        </div>
      </div>
    </>
  );
}
export default ProjectProgress;
