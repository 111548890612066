/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-08 10:52:15
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-04 13:36:21
 * @FilePath: /pms-web/src/containers/ProjectBoard/components/industryDistribution.js
 * @Description: 行业分布
 */
import { message } from "antd";
import EChartsReact from "echarts-for-react";
import { useEffect, useState } from "react";
import { getIndustryDistribution } from "../../../apis/operationAnalysis";
function IndustryDistribution() {
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    getIndustryDistribution()
      .then((result) => {
        const { dimensions, source } = result;
        setChartOptions({
          tooltip: {
            trigger: "item",
            formatter: function (params) {
              return (
                params.data.project +
                "：" +
                params.data.number +
                " (" +
                params.percent +
                ")%"
              );
            },
          },
          legend: {
            bottom: "6%",
          },
          grid: {
            // left: "13%",
            // right: "4%",
            bottom: "8%",
            // top: "5%",
            // containLabel: true,
          },
          textStyle: {
            // width: 20,
            // overflow: "break",
          },
          dataset: {
            source,
            dimensions,
          },
          series: [
            {
              color: [
                "#618FF7",
                "#31C48D",
                "#FFAE4E",
                "#3DB86D",
                "#16BDCA",
                "#A463FF",
              ],
              name: "",
              type: "pie",
              radius: ["40%", "60%"],
              avoidLabelOverlap: false,
              center: ["50%", "40%"],
              label: {
                show: true,
                formatter(param) {
                  return param.value.project + ":" + param.value.number;
                },
                fontFamily: "思源黑体 CN",
                fontSize: "12px",
                color: "#6B7280",
              },
            },
          ],
        });
      })
      .catch((e) => message.error(e));
  }, []);
  return (
    <>
      <div className="industry-distribution-container">
        <EChartsReact option={chartOptions}></EChartsReact>
      </div>
    </>
  );
}
export default IndustryDistribution;
