/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-08 10:43:08
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-06-16 15:34:05
 * @FilePath: /pms-web/src/containers/ProjectBoard/components/projectStatus.js
 * @Description: 项目状态统计
 */
import { useEffect } from "react";
import ProjectStatusGrid from "./projectStatusGrid";
function ProjectStatus({ dataList }) {
  useEffect(() => {
    console.log("ProjectStatus-card", dataList);
  }, [dataList]);
  return (
    <>
      <div className="project-status-container">
        {dataList.map((data, index) => (
          <ProjectStatusGrid
            status={data.status}
            name={data.name}
            image={data.image}
            num={data.num}
            backgroundColor={data.backgroundColor}
            key={index}
          />
        ))}
      </div>
    </>
  );
}
export default ProjectStatus;
