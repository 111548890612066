import { projectDetail } from "@/apis/projectCenter/projectManagement"
import {
  changeProjectNodeInfo,
  getProjectUserByNodeId,
  projectLogPage,
  queryNodesByStageId,
  queryProjectStageStatus,
  transferNode
} from "@/apis/projectOverview"
import eventBus from "@/utils/commonFunc/eventBus"
import readSession from "@/utils/commonFunc/readSession"
import removeSession from "@/utils/commonFunc/removeSession"
import writeSession from "@/utils/commonFunc/writeSession"
import {
  Button,
  DatePicker,
  Form,
  Modal,
  Progress,
  Select,
  Table,
  Tooltip,
  message
} from "antd"
import dayjs from "dayjs"
import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import Gantt from "../../components/Gantt"
import OperateButton from "../../components/OperateButton"
import ProjectDynamics from "./components/projectDynamics"
import ProjectScheduleEcharts from "./components/projectScheduleEcharts"
import Resume from "./components/resume"
import TaskDetails from "./components/taskDetails"
import TaskDetailsList from "./components/taskDetailsList"
import ResizableTable from "@/components/ResizableTable"
import "./index.scss"
const { Option } = Select
const { RangePicker } = DatePicker
function ProjectOverview() {
  const currentLoacation = useLocation()
  const timer = useRef()
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [projectDynamicsFlag, setProjectDynamicsFlag] = useState(false)
  const [taskDetailsFlag, setTaskDetailsFlag] = useState(false)
  const [taskDetailsListFlag, setTaskDetailsListFlag] = useState(false)
  const [ganttFlag, setGanttFlag] = useState(false)
  const [resumeFlag, setResumeFlag] = useState(false)
  const [isOther, setIsOther] = useState(false)
  const [projectMembers, setProjectMembers] = useState([])
  const [timelineData, setTimelineData] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [echartData, setEchartData] = useState([])
  const [projectNodeId, setProjectNodeId] = useState("")
  const [currentProjectId, setCurrentProjectId] = useState("")
  const [projectStatusFlag, setProjectStatusFlag] = useState(false)
  const [propsProjectNodeId, setPropsProjectNodeId] = useState("")
  const [allStage, setAllStage] = useState([])
  const dateFormat = "YYYY-MM-DD"
  const [isLeaderName, setIsLeaderName] = useState("")
  const echartDataColor = [
    {
      itemColor: "#FFAE4E",
      activeColor: "#EBF5FF"
    },
    {
      itemColor: "#7EDCE2",
      activeColor: "#EBF5FF"
    },
    {
      itemColor: "#31C48D",
      activeColor: "#EBF5FF"
    },
    {
      itemColor: "#3F83F8",
      activeColor: "#EBF5FF"
    },
    {
      itemColor: "#A463FF",
      activeColor: "#EBF5FF"
    },
    {
      itemColor: "#9CA3AF",
      activeColor: "#EBF5FF"
    }
  ]
  useEffect(() => {
    let sessionNodeData = readSession("sessionNodeData")
    if (sessionNodeData) {
      setTaskDetailsFlag(true)
      setDataSource(sessionNodeData)
      setPropsProjectNodeId(readSession("sessionProjectNodeId"))
      eventBus.emit("setBreadcrumbData", ["项目空间", "项目总览", "任务详情"])
    } else {
      let sessionProjectId = readSession("sessionProjectId")
      let sessionStatusFlag =
        readSession("sessionStatus") === 2 || readSession("sessionStatus") === 3
      setProjectStatusFlag(sessionStatusFlag)
      setCurrentProjectId(sessionProjectId)
      queryProjectStageStatusFn(sessionProjectId)
      projectLogPageFn(sessionProjectId)
    }
    handlerProjectDetail()
  }, [])

  /**
   * @description 点击查询按钮
   */
  const onFinish = (params) => {}
  const handlerProjectDetail = () => {
    projectDetail(readSession("sessionProjectId")).then((res) => {
      setIsLeaderName(res.isLeaderName)
    })
  }

  /**
   *
   * @description 阶段完成状态
   */
  const queryProjectStageStatusFn = (projectId) => {
    const queryParams = {
      projectId
    }
    queryProjectStageStatus(queryParams)
      .then((res) => {
        setAllStage(
          res.map((v) => {
            return {
              ...v,
              currentVersionId: v.nodeNumVos.at(-1).stageVersionId
            }
          })
        )
        let newData = []
        res.map((i, idx) => {
          if (
            i.stageId === readSession("sessionStageId") &&
            readSession("stageVersionId")
          ) {
            newData.push({
              ...i,
              ...i.nodeNumVos.filter(
                (d) => d.stageVersionId === readSession("stageVersionId")
              )[0],
              ...echartDataColor[idx]
            })
          } else {
            newData.push({
              ...i,
              ...i.nodeNumVos.at(-1),
              ...echartDataColor[idx]
            })
          }
        })
        setEchartData(newData)
        !readSession("sessionStageId") &&
          writeSession("sessionStageId", res[0].stageId)
        !readSession("stageVersionId") &&
          writeSession(
            "stageVersionId",
            res[0].nodeNumVos.at(-1).stageVersionId
          )
        queryNodesByStageIdFn(
          projectId,
          readSession("sessionStageId"),
          readSession("stageVersionId")
        )
      })
      .catch((e) => message.error(e))
  }

  /**
   *
   * @description 获取阶段下所有任务节点数据
   */
  const queryNodesByStageIdFn = (projectId, stageId, stageVersionId) => {
    const queryParams = {
      projectId,
      stageId,
      stageVersionId
    }
    queryNodesByStageId(queryParams)
      .then((res) => {
        setDataSource(res)
        if (readSession("from")) {
          setProjectStatusFlag(true)
          let enterItem = {
            ...res.filter(
              (d) => d.projectNodeId === readSession("taskProjectNodeId")
            )[0]
          }
          enterItem.nodeData = res
          handleEnter(enterItem)
          removeSession("from")
          removeSession("taskProjectNodeId")
        }
      })
      .catch((e) => message.error(e))
  }
  /**
   *
   * @description 转移任务
   */
  const transferNodeFn = (projectNodeId, projectUserId) => {
    const queryParams = {
      projectNodeId,
      projectUserId
    }
    transferNode(queryParams)
      .then((res) => {
        handleCancel()
        message.success("转移成功！")

        queryNodesByStageIdFn(
          currentProjectId,
          readSession("sessionStageId"),
          readSession("stageVersionId")
        )
      })
      .catch((e) => message.error(e))
  }
  /**
   *
   * @description 获取节点下可选用户列表
   */
  const getProjectUserByNodeIdFn = (projectNodeId) => {
    const queryParams = {
      projectNodeId
    }
    getProjectUserByNodeId(queryParams)
      .then((res) => {
        setProjectMembers(res)
      })
      .catch((e) => message.error(e))
  }
  /**
   *
   * @description 项目内-项目项目动态
   */
  const projectLogPageFn = (projectId) => {
    const queryParams = {
      data: {
        projectId,
        sortField: "create_time",
        sortType: "desc"
      },
      page: {
        pageIndex: 1,
        pageSize: 5
      }
    }
    projectLogPage(queryParams)
      .then((res) => {
        setTimelineData(res.list)
      })
      .catch((e) => message.error(e))
  }

  const handleReview = () => {}
  // 点击转移
  const handleTransfer = (projectNodeId) => {
    setProjectNodeId(projectNodeId)
    getProjectUserByNodeIdFn(projectNodeId)
    setIsModalVisible(true)
  }
  // 取消
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  // 转移确认
  const handleSave = () => {
    form.validateFields().then((values) => {
      transferNodeFn(projectNodeId, values.name)
    })
  }
  // 查看更多项目动态
  const seeMore = () => {
    clearInterval(timer.current)
    eventBus.emit("setBreadcrumbData", ["项目空间", "项目总览", "项目动态"])
    setProjectDynamicsFlag(true)
  }
  // 点击查看甘特图
  const changeGanttFlag = () => {
    clearInterval(timer.current)
    eventBus.emit("setBreadcrumbData", ["项目空间", "项目总览", "甘特图"])
    setGanttFlag(true)
  }
  //点击项目变更履历
  const changeResume = () => {
    clearInterval(timer.current)
    eventBus.emit("setBreadcrumbData", ["项目空间", "项目总览", "项目变更履历"])
    setResumeFlag(true)
  }
  // 进入任务详情
  const handleEnter = (row) => {
    setPropsProjectNodeId(row.projectNodeId)
    setIsOther(row.isMine)
    setDataSource(row.nodeData || dataSource)
    clearInterval(timer.current)
    writeSession("detailTaskData", {
      projectNodeId: row.projectNodeId,
      isMine: row.isMine,
      nodeData: row.nodeData || dataSource,
      stageId: row.stageId || readSession("sessionStageId"),
      isMyLiable: row.isMyLiable
    })
    eventBus.emit("setBreadcrumbData", ["项目空间", "项目总览", "任务详情"])
    setTaskDetailsFlag(true)
  }
  // 进入子任务详情的详情----传出的返回的方法
  const goDetailsPage = () => {
    setTaskDetailsListFlag(false)
    let newData = readSession("detailTaskData")
    handleEnter(newData)
  }
  // 进入子任务详情的详情
  const goTaskDetailsPage = (data) => {
    eventBus.emit("setBreadcrumbData", [
      "项目空间",
      "项目总览",
      "任务详情",
      readSession("taskProgressList").title
    ])
    setTaskDetailsFlag(false)
    setTaskDetailsListFlag(true)
  }

  // 点击不同阶段查询
  const selectEchart = (stageId, stageVersionId) => {
    writeSession("sessionStageId", stageId)
    writeSession("stageVersionId", stageVersionId)
    // let newData = []
    // allStage.map((i, idx) => {
    //   if(i.stageId === readSession("sessionStageId")) {
    //     newData.push({ ...i,...i.nodeNumVos.filter(d => d.stageVersionId === stageVersionId)[0], ...echartDataColor[idx] })
    //   } else {
    //     newData.push({ ...i,...i.nodeNumVos.at(-1), ...echartDataColor[idx] })
    //   }
    // })
    // setEchartData(newData)
    queryNodesByStageIdFn(currentProjectId, stageId, stageVersionId)
  }

  //选择不同阶段下的版本
  const stageVersionIdChange = (stageVersionId) => {
    let newData = []
    allStage.map((i, idx) => {
      if (i.stageId === readSession("sessionStageId")) {
        i.currentVersionId = stageVersionId
        newData.push({
          ...i,
          ...i.nodeNumVos.filter((d) => d.stageVersionId === stageVersionId)[0],
          ...echartDataColor[idx]
        })
      } else {
        newData.push({
          ...i,
          ...i.nodeNumVos.filter(
            (d) => d.stageVersionId === i.currentVersionId
          )[0],
          ...echartDataColor[idx]
        })
      }
    })
    setEchartData(newData)
    writeSession("stageVersionId", stageVersionId)
    queryNodesByStageIdFn(
      readSession("sessionProjectId"),
      readSession("sessionStageId"),
      readSession("stageVersionId")
    )
  }
  // 面包雪点击传来的值以及方法
  const breadcrumbItemClickEventsFn = (item) => {
    switch (item) {
      case "项目总览":
        writeSession("sessionNodeData", null)
        setGanttFlag(false)
        setResumeFlag(false)
        setProjectDynamicsFlag(false)
        setTaskDetailsFlag(false)
        setTaskDetailsListFlag(false)
        intervalTimes()
        queryProjectStageStatusFn(readSession("sessionProjectId"))
        projectLogPageFn(readSession("sessionProjectId"))
        let sessionStatusFlag =
          readSession("sessionStatus") === 2 ||
          readSession("sessionStatus") === 3
        setProjectStatusFlag(sessionStatusFlag)
        queryNodesByStageIdFn(
          readSession("sessionProjectId"),
          readSession("sessionStageId"),
          readSession("stageVersionId")
        )
        eventBus.emit("setBreadcrumbData", ["项目空间", "项目总览"])
        break
      default:
        return
    }
  }
  const intervalTimes = () => {
    timer.current && clearInterval(timer.current)
    timer.current = setInterval(() => {
      projectLogPageFn(readSession("sessionProjectId"))
    }, 10000)
  }

  const changePlanStartOrEndTime = (type, idx) => {
    let newData = dataSource
    type === "start"
      ? (newData[idx].isStart = true)
      : (newData[idx].isEnd = true)
    setDataSource(newData)
  }
  const sureStartOrEndTime = (record, index) => {
    if (dataSource[index].planStartTime && dataSource[index].planEndTime) {
      dataSource[index].openShow = false
      setDataSource([...dataSource])
      const params = [
        {
          nodeId: record.projectNodeId,
          planStartTime: record.planStartTime
            ? dayjs(record.planStartTime).format("YYYY-MM-DD HH:mm:ss")
            : null,
          planEndTime: record.planEndTime
            ? dayjs(record.planEndTime).format("YYYY-MM-DD 23:59:59")
            : null
        }
      ]
      changeProjectNodeInfo(params)
        .then((res) => {
          message.success("保存成功")

          queryNodesByStageIdFn(
            currentProjectId,
            record.stageId,
            readSession("stageVersionId")
          )
        })
        .catch((e) => message.error(e))
    } else {
      message.error("请填写完整时间")
    }
  }
  //切换时间
  const handleChangeValue = (date, dateString, record, index) => {
    dataSource[index].openShow = true
    dataSource[index].startChange = true
    dataSource[index].planStartTime = dateString[0]
    dataSource[index].planEndTime = dateString[1]
    setDataSource([...dataSource])
  }
  useEffect(() => {
    intervalTimes()
    return () => clearInterval(timer.current)
  }, [])
  // 表格表头
  const columns = [
    {
      title: "任务ID",
      dataIndex: "projectNodeId",
      ellipsis: true,
      width: 130,
      fixed: "left"
    },
    {
      title: "编号",
      dataIndex: "nodeCode",
      ellipsis: true,
      width: 90
    },
    {
      title: "任务名称",
      dataIndex: "nodeName",
      ellipsis: true,
      width: 200
    },
    {
      title: "责任描述",
      dataIndex: "nodeDesc",
      ellipsis: true,
      width: 200
    },
    {
      title: "相关责任人",
      dataIndex: "personLiable",
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        const name = record.personLiable
          .sort((a, b) => b.isMe - a.isMe)
          .map((i) => {
            return i.userName
          })
          .join("、")
        return (
          <div className="personLiable-box" title={name}>
            {record.personLiable
              .sort((a, b) => b.isMe - a.isMe)
              .map((i, idx) => {
                if (i.isMe) {
                  return (
                    <div className="is-me" key={idx}>
                      {i.userName}
                    </div>
                  )
                } else {
                  return (
                    <div className="not-me" key={idx}>
                      {idx === record.personLiable.length - 1
                        ? i.userName
                        : i.userName + "、"}
                    </div>
                  )
                }
              })}
          </div>
        )
      }
    },
    {
      title: "计划开始时间-计划结束时间",
      dataIndex: "planStartTime",
      width: 220,
      render: (text, record, index) => {
        return (!text && record.isMyLiable && !projectStatusFlag) ||
          record.startChange ? (
          <RangePicker
            value={
              text
                ? [
                    dayjs(text, dateFormat),
                    dayjs(record.planEndTime, dateFormat)
                  ]
                : []
            }
            bordered={false}
            allowClear={false}
            onFocus={() => {
              dataSource[index].openShow = true
              setDataSource([...dataSource])
            }}
            onBlur={() => {
              dataSource[index].openShow = false
              dataSource[index].planStartTime = null
              dataSource[index].planEndTime = null
              setDataSource([...dataSource])
            }}
            open={record.openShow}
            onChange={(date, dateString) =>
              handleChangeValue(date, dateString, record, index)
            }
            renderExtraFooter={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px"
                }}
              >
                <Button
                  type="primary"
                  onClick={() => sureStartOrEndTime(record, index)}
                >
                  确认
                </Button>
              </div>
            )}
          />
        ) : (
          <span className="light-text">
            {text
              ? dayjs(text).format("YYYY-MM-DD") +
                "  -  " +
                dayjs(record.planEndTime).format("YYYY-MM-DD")
              : "--"}
          </span>
        )
      }
    },
    {
      title: "交付物清单",
      dataIndex: "nodeOutput",
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        const nameList = record.nodeOutput.map((d) => d.fileName)
        return (
          <div className="personLiable-box" title={nameList.join("、")}>
            {record.nodeOutput.map((i, idx) => {
              return (
                <div className="nodeOutput-item" key={idx}>
                  {i.fileUrl && (
                    <a className="blue-text" href={i.fileUrl} target="_blank">
                      {idx === record.nodeOutput.length - 1
                        ? i.fileName
                        : i.fileName + "、"}
                    </a>
                  )}
                  {!i.fileUrl && (
                    <p className="gray-text">
                      {idx === record.nodeOutput.length - 1
                        ? i.fileName
                        : i.fileName + "、"}
                    </p>
                  )}
                </div>
              )
            })}
          </div>
        )
        // return nameList.join('、')
      }
    },
    {
      title: "任务状态",
      dataIndex: "nodeStatus",
      ellipsis: true,
      width: 100,
      render: (text, record) => (
        <div className="task-progress">
          {record.nodeStatus === 0 && !record.isDelay && (
            <div className="not-started common-status">未开始</div>
          )}
          {record.nodeStatus === 0 && record.isDelay && (
            <div className="not-started-delayed common-status">
              未开始(已延期)
            </div>
          )}
          {record.nodeStatus === 1 && !record.isDelay && (
            <div className="in-progress common-status">进行中</div>
          )}
          {record.nodeStatus === 1 && record.isDelay && (
            <div className="in-progress-delayed common-status">
              进行中(已延期)
            </div>
          )}
          {record.nodeStatus === 2 && (
            <div className="completed common-status">已完成</div>
          )}
        </div>
      )
    },
    {
      title: "任务进度",
      dataIndex: "accumulatedProjectHours",
      ellipsis: true,
      width: 100,
      render: (text, record) => (
        <div className="task-progress">
          <div className="percent">
            {record.nodeProgress ? record.nodeProgress + "%" : "--"}
          </div>
          {record.nodeProgress && (
            <Progress type="circle" percent={record.nodeProgress} size={20} />
          )}
        </div>
      )
    },
    {
      title: "操作",
      dataIndex: "estimatedTotalInvestment",
      width: 100,
      fixed: "right",
      render: (text, record) => (
        <div className="operation">
          {(record.isMyLiable || record.isMine) && !projectStatusFlag && (
            <OperateButton
              icon="icon-a-IconsSolid"
              text="进入"
              click={() => handleEnter(record)}
            />
          )}
          {record.isMyLiable && !projectStatusFlag && (
            <OperateButton
              icon="icon-switch-horizontal"
              text="转移"
              click={() => handleTransfer(record.projectNodeId)}
            />
          )}
          {((!record.isMyLiable && !record.isMine && !projectStatusFlag) ||
            projectStatusFlag) && (
            <OperateButton
              icon="icon-eye"
              text="查看"
              click={() => handleEnter(record)}
            />
          )}
        </div>
      )
    }
  ]

  useEffect(() => {
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    )
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      )
    }
  }, [breadcrumbItemClickEventsFn])

  const close = require("@/assets/img/projectoverview/close.png")
  return (
    <>
      <div className="projectOverview-page">
        {ganttFlag && (
          <Gantt
            projectId={currentProjectId}
            ganttToProjectOverview={breadcrumbItemClickEventsFn}
          />
        )}
        {resumeFlag && (
          <Resume resumeToProjectOverview={breadcrumbItemClickEventsFn} />
        )}
        {projectDynamicsFlag && (
          <ProjectDynamics projectId={currentProjectId}></ProjectDynamics>
        )}
        {taskDetailsFlag && (
          <TaskDetails
            projectId={currentProjectId}
            goTaskDetailsPage={goTaskDetailsPage}
            nodedata={dataSource}
            currentStageId={readSession("sessionStageId")}
            propsProjectNodeId={propsProjectNodeId}
            propsIsOther={isOther}
          ></TaskDetails>
        )}
        {taskDetailsListFlag && (
          <TaskDetailsList
            goIndexPage={breadcrumbItemClickEventsFn}
            goDetailsPage={goDetailsPage}
          ></TaskDetailsList>
        )}
        {!projectDynamicsFlag &&
          !taskDetailsFlag &&
          !taskDetailsListFlag &&
          !ganttFlag &&
          !resumeFlag && (
            <div>
              <div className="top-container">
                <div className="top-container-left">
                  <div className="container-left-item">
                    <div className="container-left-item-text">
                      <i className="iconfont icon-flag"></i>
                      项目进度(项目负责人:{isLeaderName})
                    </div>
                    <div className="container-right">
                      <div
                        className="gante"
                        onClick={() => {
                          changeResume()
                        }}
                      >
                        <i className="iconfont icon-xiangmubiangenglvli"></i>
                        项目变更履历
                      </div>
                      <div
                        className="gante"
                        onClick={() => {
                          changeGanttFlag()
                        }}
                      >
                        <i className="iconfont icon-eye"></i>查看甘特图
                      </div>
                    </div>
                  </div>
                  <div className="echart-box">
                    {echartData.map((grid, index) => (
                      <div
                        key={index}
                        className={
                          readSession("sessionStageId") === grid.stageId
                            ? "sanjiao-active"
                            : "sanjiao"
                        }
                        onClick={() => {
                          selectEchart(grid.stageId, grid.stageVersionId)
                        }}
                      >
                        <ProjectScheduleEcharts
                          title={grid.stageName}
                          color={grid.itemColor}
                          version={grid.version}
                          activeColor={grid.activeColor}
                          rightNum={grid.nodeTotalNum}
                          leftNum={grid.nodeFinishNum}
                          valueNum={Math.floor(
                            (grid.nodeFinishNum / grid.nodeTotalNum) * 100
                          )}
                        ></ProjectScheduleEcharts>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="top-container-right">
                  <div className="container-right-item">
                    <div className="container-right-item-text">
                      <i className="iconfont icon-rss"></i>项目动态
                    </div>
                    <div className="more" onClick={seeMore}>
                      <i className="iconfont icon-dots-vertical"></i>更多
                    </div>
                  </div>
                  <div className="time-line">
                    {!projectStatusFlag ? (
                      timelineData.map((item, index) => (
                        <div className="event-item" key={index}>
                          <div className="time">
                            {dayjs(item.createTime).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </div>
                          <div className="point-line">
                            <div className="point"></div>
                            {index !== timelineData.length - 1 && (
                              <div className="line"></div>
                            )}
                          </div>

                          <div className="man">{item.operator}</div>
                          <div className="eventName">{item.eventName}</div>
                          <Tooltip placement="top" title={item.eventTask}>
                            <div className="man over">{item.eventTask}</div>
                          </Tooltip>
                        </div>
                      ))
                    ) : (
                      <img src={close} className="empty-img"></img>
                    )}
                  </div>
                </div>
              </div>
              <div className="table-container">
                {allStage.length > 0 &&
                  allStage.filter(
                    (d) => d.stageId === readSession("sessionStageId")
                  )[0].nodeNumVos.length > 0 && (
                    <Select
                      value={readSession("stageVersionId")}
                      style={{
                        width: 160
                      }}
                      onChange={stageVersionIdChange}
                      options={allStage
                        .filter(
                          (d) => d.stageId === readSession("sessionStageId")
                        )[0]
                        .nodeNumVos.map((v) => {
                          return { label: v.version, value: v.stageVersionId }
                        })}
                    />
                  )}
                <div className="table-box">
                  <ResizableTable
                    style={{ margin: "24px 0" }}
                    size="small"
                    pagination={false}
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={(record) => record.projectNodeId}
                  />
                </div>
              </div>
              <Modal
                title="任务转移"
                centered
                width={500}
                wrapClassName="custom-modal"
                maskClosable={false}
                visible={isModalVisible}
                onOk={() => handleSave()}
                onCancel={() => handleCancel()}
                okText={
                  <span>
                    <i className="iconfont icon-check"></i>确认
                  </span>
                }
                cancelText={
                  <span>
                    <i className="iconfont icon-x"></i>返回
                  </span>
                }
              >
                <Form
                  layout="inline"
                  form={form}
                  onFinish={onFinish}
                  colon={false}
                >
                  <Form.Item
                    label="项目成员："
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{
                        width: 288,
                        height: 32
                      }}
                      allowClear
                      placeholder="请选择项目成员"
                      options={projectMembers.map((d) => ({
                        label: d.name,
                        value: d.projectUserId
                      }))}
                      onChange={onFinish}
                    ></Select>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          )}
      </div>
    </>
  )
}

export default ProjectOverview
