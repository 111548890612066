import { Button, Checkbox, Form, Input, InputNumber, message, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { deleteSubject, getSubject, updateSubject } from "../../../apis/examBank";
import CustomIcon from "../../../components/CustomIcon/customIcon";
import eventBus from "../../../utils/commonFunc/eventBus";

const { TextArea } = Input
const { confirm } = Modal

function ExamConfig ({pageChange, subjectId}) {
  const [dataSource, setDataSource] = useState([
    {
      questionType: 0,
      questionBankNumber: 0,
      questionNumber: 0,
      questionScore: 0,
    },
    {
      questionType: 1,
      questionBankNumber: 0,
      questionNumber: 0,
      questionScore: 0,
    }
  ])
  const [totalData, setTotalData] = useState({
    question: 0,
    score: 0
  })
  const [submitLoading, setSubmitLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  useEffect(() => {
    getSubject(subjectId).then(res => {
      let choiceList  = []
      if(res.singleChoice && res.singleChoice != "") {
        choiceList.push(JSON.parse(res.singleChoice))
      } else {
        choiceList.push({
          questionType: 0,
          questionBankNumber: 0,
          questionNumber: 0,
          questionScore: 0,
        })
      }
      if(res.multipleChoice && res.multipleChoice != "") {
        choiceList.push(JSON.parse(res.multipleChoice))
      } else {
        choiceList.push({
          questionType: 1,
          questionBankNumber: 0,
          questionNumber: 0,
          questionScore: 0,
        })
      }
      setDataSource(choiceList)
      subjectForm.setFieldsValue({
        ...res,
        examDuration: res.examDuration ? res.examDuration : 60,
        passLine: res.passLine ? res.passLine : 0,
        isRestart: (typeof res.isRestart != "boolean") ? true : (res.isRestart != false ? true : false),
        singleNum0: choiceList[0].questionNumber,
        singleSco0: choiceList[0].questionScore,
        multipleNum1: choiceList[1].questionNumber,
        multipleSco1: choiceList[1].questionScore,
      })
    })
    .catch(err => console.log(err))
  },[subjectId])

  useEffect(() => {
    setTotalData({
      question: dataSource.reduce((accumulator, current) => accumulator + current.questionNumber, 0),
      score: dataSource.reduce((accumulator, current) => accumulator + current.questionScore * current.questionNumber, 0),
    })
  }, [dataSource])

  const [subjectForm] = Form.useForm()

  const cancelSub = () => {
    pageChange("subject")
    subjectForm.resetFields()
  }
  //删除科目
  const deleteSub = () => {
    confirm({
      title: '确认删除该科目并清空科目题库？',
      centered: true,
      cancelText: <><i className="iconfont icon-x"/>取消</>,
      okText: <><i className="iconfont icon-check"/>确认</>,
      icon: <i className="iconfont icon-exclamation-circle" style={{color: "#F05252",fontSize: "20px"}}/>,
      onOk() {
        setDeleteLoading(true)
        deleteSubject(subjectId).then(() => {
          message.success("删除成功！")
          cancelSub()
          setDeleteLoading(false)
        })
        .catch(err => {
          console.log(err)
          setDeleteLoading(false)
        })
      }
    });
  }
  const contentChange = (row,attribute,val) => {
    if(!Number(val) && (Number(val) != 0 || val === "")) return
    if(attribute === "num" && Number(val) < 0) {
      val = 0
    }
    if(attribute === "num" && Number(val) > row.questionBankNumber) {
      val = row.questionBankNumber
    }
    if(attribute === "score" &&  Number(val) < 0) {
      val = 0
    }
    if(attribute === "score" &&  Number(val) > 100) {
      val = 100
    }
    const newData = [...dataSource]
    const index = newData.findIndex((d) => d.questionType === row.questionType)
    const item = newData[index]
    if(attribute === "num") {
      newData.splice(index,1, {
        ...item,
        questionNumber: Math.round(Number(val))
      })
    } else {
      newData.splice(index,1, {
        ...item,
        questionScore: Math.round(Number(val) * 10)/10
      })
    }
    setDataSource(newData)
  }
  //更新科目信息
  const saveSubject = () => {
    subjectForm.validateFields().then(values => {
      if(values.passLine > totalData.score) {
        message.warning("及格线不得高于考试总分！")
        return
      }
      const params = {
        ...values,
        singleChoice: JSON.stringify(dataSource[0]),
        multipleChoice: JSON.stringify(dataSource[1]),
        id: subjectId
      }
      setSubmitLoading(true)
      updateSubject(params).then(() => {
        message.success("修改成功！")
        cancelSub()
        setSubmitLoading(false)
      })
      .catch(err => {
        console.log(err)
        setSubmitLoading(false)
      })
    })
    .catch(err => console.log(err))
  }

   // 面包屑点击传来的值以及方法
   const breadcrumbItemClickEventsFn = useCallback(
    (item) => {
      switch (item) {
        case "考试题库":
          eventBus.emit("setBreadcrumbData", ["系统管理", "考试题库"])
          pageChange("subject")
          break
        default:
          return
      }
    },
    []
  )

  useEffect(() => {
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    )
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      )
    }
  }, [breadcrumbItemClickEventsFn])
  return (
    <>
      <div className="sub-header">
        <div>
          <CustomIcon name={"icon-kaoshishezhi"} width={"15px"} height={"16px"}/>
          <span className="sub-name">考试配置</span>
        </div>
      </div>
      <Form form={subjectForm} labelCol={{span: 2}} style={{width: 1000}} onFinish={saveSubject}>
        <Form.Item label="考试科目" name="subjectName" rules={[{required: true, message: "请填写"}]}>
          <Input placeholder="请输入" style={{width: 280}} maxLength={30}/>
        </Form.Item>
        <Form.Item label="考试说明" name="subjectExplain" rules={[{required: true, message: "请填写"}]}>
          <TextArea
            showCount
            maxLength={500}
            placeholder="请输入"
            style={{
              height: 110,
              width: 400,
              resize: 'none',
            }}
          />
        </Form.Item>
        <Form.Item label="考试时长" name="examDuration" rules={[{required: true, message: "请填写"}]}>
          <InputNumber
            style={{width: 150}}
            max={120}
            min={15}
            precision={0}
            addonAfter="分钟"
          />
        </Form.Item>
        <Form.Item label="考试内容" required>
          <p style={{margin: "6px 0 10px"}}>共 {totalData.question} 题，总分 {Number(totalData.score).toFixed(1)} 分</p>
          <table className="config-table">
            <thead>
              <tr>
                <th>题型</th>
                <th>题库数量</th>
                <th>出题数量</th>
                <th>单题得分</th>
              </tr>
            </thead>
            <tbody>
            {dataSource.map((d,index) => (
              <tr className="row">
                <td>{d.questionType === 0 ? "单选题" : "多选题"}</td>
                <td>{d.questionBankNumber}</td>
                <td>
                  <Form.Item name={d.questionType === 0 ? `singleNum${index}` : `multipleNum${index}`} rules={[{required: true, message: "请填写"}]}>
                    <InputNumber
                      max={d.questionBankNumber}
                      min={0}
                      precision={0}
                      onBlur={(event) => contentChange(d, "num", event.target.value)}
                    />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item name={d.questionType === 0 ? `singleSco${index}` : `multipleSco${index}`}  rules={[{required: true, message: "请填写"}]}>
                    <InputNumber
                      max={100}
                      min={0}
                      precision={1}
                      onBlur={(event) => contentChange(d, "score", event.target.value)}
                    />
                  </Form.Item>
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </Form.Item>
        <div style={{display: 'flex',justifyContent:'flex-start'}}>
          <Form.Item label="及格线" name="passLine" labelCol={{span: 10}} rules={[{required: true, message: "请输入及格线"}]}>
            <InputNumber
              style={{width: 130}}
              max={totalData.score}
              precision={1}
              min={0}
            />
          </Form.Item>
          <Form.Item name="isRestart" valuePropName="checked" style={{marginLeft: 30}}>
            <Checkbox>允许不及格重考</Checkbox>
          </Form.Item>
        </div>
        <div className="footer-button">
          <Button onClick={cancelSub}><i className="iconfont icon-x"/>取消</Button>
          <Button type="primary" danger onClick={deleteSub} loading={deleteLoading}><i className="iconfont icon-shanchu1"/>删除</Button>
          <Button type="primary" htmlType="submit" loading={submitLoading}><i className="iconfont icon-baocun"/>保存</Button>
        </div>
      </Form>
    </>
  )
}
export default ExamConfig