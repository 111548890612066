/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-10-09 14:51:19
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-07 10:50:52
 * @FilePath: /pms-web/src/containers/Management/components/ApproveWorkHour.js
 * @Description: 工时审批
 */
import {
  approvalCancel,
  approvalPass,
  approvePage,
  fillInDate
} from "@/apis/projectCenter/projectManagement"
import { queryNodesByStageId } from "@/apis/projectOverview"
import writeSession from "@/utils/commonFunc/writeSession"
import { Button, Modal, Popconfirm, Select, Space, Table, message } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { taskTotalHour } from "../../../apis/workHour"
import OperateButton from "../../../components/OperateButton"
import TabDate from "../../../components/TabDate"
function ApproveWorkHour({ projectData }) {
  let navigate = useNavigate()
  const id = projectData.id
  // 审批日期tab标题列表
  const [tabList, setTabList] = useState([])
  // tab选中的index
  const [taskList, setTaskList] = useState([])
  // 是否打开详情弹窗
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tableColumns, setTableColumns] = useState([])
  // 工时列表
  // const [workHourList, setWorkHourList] = useState([])
  // 任务类型列表
  const taskTypeOptions = [
    // { label: "全部任务", value: 1 },
    { label: "待审核的任务", value: 2 }
  ]
  // 当前选中的工时日期
  const [defaultIndex, setDefaultIndex] = useState(0)
  const [taskProgressList, setTaskProgressList] = useState([])
  // 表格搜索条件参数
  const [tableParams, setTableParams] = useState({
    taskStatus: 2,
    date: ""
  })
  // 今日工时-汇总
  const [totalWorkHours, setTotalWorkHours] = useState(0)
  // 任务总工时-汇总
  const [totalTaskWorkHours, setTotalTaskWorkHours] = useState(0)
  const [workHourDetailWidth, setWorkHourDetailWidth] = useState(413)
  // 表格（表头）
  const columns = [
    {
      title: () => {
        return (
          <div>
            <Select
              value={tableParams.taskStatus}
              options={taskTypeOptions}
              onChange={onTaskChange}
            />
            <div className="task-select-tip" style={{ textAlign: "left" }}>
              *包含今日完成的
            </div>
          </div>
        )
      },
      dataIndex: "nodeName",
      width: 220,
      ellipsis: true,
      render: (_, record) => {
        let str = ""
        if (record.nodeStatus === 0) {
          str = "(未开始)"
        } else if (record.nodeStatus === 1) {
          str = "(进行中)"
        } else {
          str = "(已完成)"
        }
        return (
          <Space direction="vertical">
            <div className="task" onClick={() => handlerClick(record)}>
              <div>
                {record.nodeName}
                {str}
              </div>
              <div>
                {record.startTime ?? ""}~{record.endTime ?? ""}
              </div>
            </div>
          </Space>
        )
      }
    },
    {
      width: 120,
      title: "今日工时",
      dataIndex: "workHour",
      ellipsis: true,
      render: (_, record) => {
        return record.nodeName !== "工时汇总" ? (
          <Space direction="vertical" className="today-work-hour">
            {record.workHour.length !== 0
              ? record.workHour.map((item, index) => {
                  return (
                    <div className="today-work-hour-content" key={index}>
                      <div
                        className="work-hour-and-name"
                        title={(item.userName ?? "--") + ":" + item.workHour}
                      >
                        {item.userName ?? "--"}:{item.workHour}
                      </div>
                      {index !== record.workHour.length - 1 && (
                        <div className="divider" />
                      )}
                    </div>
                  )
                })
              : "--"}
          </Space>
        ) : (
          <div style={{ textAlign: "center" }}>{record.workHours}</div>
        )
      }
    },
    {
      width: 120,
      title: "任务总工时",
      ellipsis: true,
      dataIndex: "allWorkHour",
      render: (_, record) => {
        return record.nodeName !== "工时汇总" ? (
          <Space direction="vertical" className="today-work-hour">
            {record.allWorkHour.length !== 0
              ? record.allWorkHour.map((item, index) => {
                  return (
                    <div className="today-work-hour-content" key={index}>
                      <div
                        className="work-hour-and-name"
                        title={(item.userName ?? "--") + ":" + item.workHour}
                      >
                        {item.userName ?? "--"}:{item.workHour}
                      </div>
                      {index !== record.allWorkHour.length - 1 && (
                        <div className="divider" />
                      )}
                    </div>
                  )
                })
              : "--"}
          </Space>
        ) : (
          <div style={{ textAlign: "center" }}>{record.taskWorkHours}</div>
        )
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      with: 50,
      // fixed: "right",
      render: (_, record) => {
        return (
          record.nodeName !== "工时汇总" &&
          (record.nodeStatus !== 0 ? (
            <div className="operation">
              <OperateButton
                text="工时详情"
                icon="icon-a-IconsSolid"
                data={record}
                click={() => showDetailDialog(record)}
              />
            </div>
          ) : (
            <div className="operation-grey">工时详情</div>
          ))
        )
      }
    }
  ]

  const totalColumns = [
    {
      title: "日期",
      dataIndex: "date",
      ellipsis: true,
      fixed: "left",
      width: "45%",
      render: (text, record) => {
        return `${text} ${
          record.allowModified
            ? record.approvel === 1
              ? "(已审批)"
              : ""
            : "(已锁定)"
        }`
      }
    },
    {
      title: "总工时",
      dataIndex: "workHourAll",
      ellipsis: true,
      fixed: "left",
      width: 90
    }
  ]

  // 任务类型变化
  const onTaskChange = (value) => {
    setTableParams({
      ...tableParams,
      taskStatus: value
    })
  }

  // 获取审核信息列表
  useEffect(() => {
    if (tableParams.date) {
      getTaskWorkHourList()
    }
  }, [tableParams])

  // 获取日期状态tab列表
  useEffect(() => {
    getDateTabList(true)
  }, [])

  // 显示工时详情弹框
  const showDetailDialog = (record) => {
    taskTotalHour(record.nodeId)
      .then((res) => {
        if (res.length > 0) {
          res[0].user.forEach((q, index) => {
            totalColumns.push({
              title: q.userName,
              dataIndex: q.userId,
              ellipsis: true,
              fixed: "",
              width: 90,
              key: index
            })
          })
          setTaskProgressList(
            res.map((d) => {
              let item = {}
              d.user.forEach((p) => {
                item[p.userId] = p.workHour
              })
              return {
                ...d,
                ...item
              }
            })
          )
        } else {
          setTaskProgressList([])
        }
        if (res.length > 0) {
          if (
            res[0].user.length * 90 + 200 + 100 + 48 >
            window.innerWidth * 0.8
          ) {
            setWorkHourDetailWidth(window.innerWidth * 0.8)
          } else {
            setWorkHourDetailWidth(res[0].user.length * 90 + 200 + 100 + 48)
          }
        }
        setTableColumns(totalColumns)
      })
      .catch((e) => {
        message.error(e)
      })
    setIsModalOpen(true)
  }

  // 获取任务工时信息列表
  const getTaskWorkHourList = () => {
    const { taskStatus, date } = tableParams
    const params = {
      date: date.date,
      taskStatus,
      projectId: id
    }
    approvePage(params)
      .then((res) => {
        if (res && res.taskWorkHourList) {
          const list = res.taskWorkHourList.map((element, index) => {
            return {
              ...element,
              key: index
            }
          })
          setTotalTaskWorkHours(res.taskWorkHourCount)
          setTotalWorkHours(res.workHourCount)
          setTaskList(list)
        }
      })
      .catch((e) => {
        message.error(e)
      })
    // }
  }

  // 更新表格数据
  const updateTableList = (dateItem, index) => {
    setTableParams({
      ...tableParams,
      date: dateItem
    })
    setDefaultIndex(index)
  }

  // 获取tab日期列表
  const getDateTabList = (isDefault = false) => {
    fillInDate({ projectId: id })
      .then((list) => {
        if (list && list.length) {
          const dateList = list.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          setTabList(dateList)
          const todayDateStr = dayjs().format("YYYY-MM-DD")
          for (let i = 0; i < dateList.length; i++) {
            if (isDefault) {
              if (todayDateStr === dateList[i].date) {
                setDefaultIndex(i)
                setTableParams({
                  ...tableParams,
                  date: dateList[i]
                })
              }
            } else {
              setTableParams({
                ...tableParams,
                date: dateList[defaultIndex]
              })
            }
          }
        }
      })
      .catch((e) => {
        message.error(e)
      })
  }

  // 审批通过
  const approveSuccess = () => {
    const { date } = tableParams
    approvalPass({ date: date.date, projectId: id })
      .then((res) => {
        getDateTabList()
        message.success("保存成功")
      })
      .catch((e) => {
        message.error(e)
      })
  }

  // 撤回审批
  const approveWithdraw = () => {
    const { date } = tableParams
    approvalCancel({ date: date.date, projectId: id })
      .then((res) => {
        getDateTabList()
        message.success("审批撤回成功")
      })
      .catch((e) => {
        message.error(e)
      })
  }

  //进入任务详情
  const [openedWindow, setOpenedWindow] = useState()
  const handlerClick = (row) => {
    const queryParams = {
      projectId: row.projectId,
      stageId: row.stageId,
      stageVersionId: row.stageVersionId
    }
    queryNodesByStageId(queryParams)
      .then((res) => {
       writeSession("sessionStatus", projectData.status)
       writeSession("sessionProjectId", row.projectId)
        writeSession("sessionStageId", row.stageId)
        writeSession("stageVersionId", row.stageVersionId)
        writeSession("detailTaskData", {
          projectNodeId: row.nodeId,
          isMine: row.isMine,
          nodeData: res,
          stageId: row.stageId,
          isMyLiable: row.isMyLiable
        })
        writeSession("sessionNodeData", res)
        writeSession("sessionProjectNodeId", row.nodeId)
        setOpenedWindow(
          window.open(`${location.origin}#/project-space/overview `, "_blank")
        )
        if (openedWindow) {
          openedWindow.close()
        }
        window.close()
      })
      .catch((e) => message.error(e))
  }

  // 滚动列表恢复到顶部
  const resetFormScrollY = () => {
    // 获取element table组件的根元素
    let parentDom = document.getElementById("all-work-hour-table")
    //- 获取table下ant-table-body的dom节点
    let childDom =
      parentDom && parentDom.getElementsByClassName("ant-table-body")
    //- 滚动条置顶
    childDom[0].scrollTop = 0
  }

  return (
    <>
      {/* 表格 */}
      <div className="work-hour-approve-container">
        <div className="approve-tip">
          *本周工时将于下周一前自动审批通过，通过后无法修改，请及时审批
        </div>
        {/* <div className="approve-table-container"> */}
        <div className="tab-date-container">
          <TabDate
            tabData={tabList}
            updateTable={updateTableList}
            tabIndex={defaultIndex}
          ></TabDate>
        </div>
        <div className="table-container">
          <Table
            className="work-hour-approve-table"
            bordered
            columns={columns}
            dataSource={taskList}
            pagination={false}
            width={600}
            sticky
            scroll={
              {
                // y: 300
              }
            }
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell>工时汇总</Table.Summary.Cell>
                  <Table.Summary.Cell>{totalWorkHours}</Table.Summary.Cell>
                  <Table.Summary.Cell>{totalTaskWorkHours}</Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
        {/* rowClassName={rowClassName} */}
        <div className="btn-container">
          {tableParams.date.approvel === 1 && (
            <Popconfirm
              description="请确认是否撤回当前工时审批？"
              onConfirm={approveWithdraw}
              okText="确定"
              cancelText="取消"
            >
              <Button className="btn-withdraw">
                <i className="iconfont icon-reply"></i>撤回审批
              </Button>
            </Popconfirm>
          )}
          {tableParams.date.approvel === 0 && tableParams.taskStatus === 2 && (
            <Popconfirm
              description="请确认是否通过当前工时审批"
              onConfirm={approveSuccess}
              okText="确定"
              cancelText="取消"
              disabled={taskList.length === 0}
            >
              <Button
                type="primary"
                className={
                  taskList.length === 0 ? "btn-success-grey" : "btn-succcess"
                }
              >
                <i className="iconfont icon-check"></i>审批通过
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>
      {/* 详情弹窗 */}
      <Modal
        wrapClassName="custom-modal"
        title="工时详情"
        open={isModalOpen}
        centered={true}
        onCancel={() => setIsModalOpen(false)}
        width={workHourDetailWidth}
        destroyOnClose={true}
        maskClosable={false}
        afterOpenChange={() => resetFormScrollY()}
        forceRender
        footer={null}
        cancelText={
          <span>
            <i className="iconfont icon-x"></i>关闭
          </span>
        }
      >
        <div className="approve-work-hour-detail">
          <Table
            size="small"
            className="all-work-hour-table"
            rowKey={(record) => record.id}
            dataSource={taskProgressList}
            columns={tableColumns}
            pagination={false}
            id="all-work-hour-table"
            sticky
            scroll={{
              y: 39 * 10,
              x: (tableColumns.length - 2) * 100 + 48,
              scrollToFirstRowOnChange: true
            }}
          />
        </div>
      </Modal>
    </>
  )
}
export default ApproveWorkHour
