/*
 * @Author: gn
 * @Date: 2023-06-09 14:38:22
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-19 17:10:31
 * @Description: file content
 */
import React, { useEffect, useState } from "react"
import "./index.scss"
import { Input, Space, Tree } from "antd"
import { DownOutlined } from "@ant-design/icons"
const { Search } = Input
const TransferTree = (props) => {
  const { data, onChange } = props
  const [checkedKeys, setCheckedKeys] = useState([]) //选择的key
  const [showKeys, setShowKeys] = useState([]) //右侧展示的key
  const [autoExpandParent, setAutoExpandParent] = useState(true) //是否自动展开
  const [treeData, setTreeData] = useState([]) //原始数据
  const [transferData, setTransferData] = useState([]) //中转数据
  const [expandedKeys, setExpandedKeys] = useState([]) //展开节点

  useEffect(() => {
    setTransferData(data)
    setTreeData(data)
  }, [])

  const onExpand = (keys) => {
    setExpandedKeys([keys[keys.length - 1]])
  }

  /**
   * @description: 选择
   * @param {*} checkedKeysValue
   * @param {*} e
   * @return {*}
   * @author: LINA.QI
   */
  const onCheck = (checkedKeysValue, e) => {
    let newList = [...showKeys]
    e.checkedNodes.forEach((item) => {
      //是否包含userDtoList人员列表
      if (!item.hasOwnProperty("userDtoList")) {
        newList.push(item)
      }
      item.disabled = true
    })
    const momentName = [] //暂时存储名字
    transferData.forEach((res) => {
      //人员的名字平铺
      let parent = res.userDtoList?.map((name) => name.userName)
      res.userDtoList?.forEach((child) => {
        //如果点击的里包含多个角色相同的人员
        if (checkedKeysValue.includes(child.userId)) {
          momentName.push(child.userName)
          child.disabled = true
        }
      })
      if (parent) {
        const commonCount = countCommonElements(momentName, parent)
        //选择的人员是否与角色里人员数组的数量相同
        if (parent.length > 0 && commonCount === parent.length) {
          res.disabled = true
          checkedKeysValue.push(res.userId)
        } else {
          res.disabled = false
        }
      }
    })
    newList = Array.from(new Set(newList))
    setShowKeys(newList) //右侧展示的人员
    setCheckedKeys(checkedKeysValue) //选择的人包含角色
    onChange(newList)
  }

  /**
   * @description: 删右侧人员
   * @return {*}
   * @author: LINA.QI
   */
  const handlerDel = (item) => {
    //去除右侧列表人员
    const newShowKeys = showKeys.filter(
      (element) => element.userId !== item.userId
    )
    setShowKeys(newShowKeys)
    //右侧还剩的人员名字
    const newShowTitle = newShowKeys.map((newTitle) => newTitle.userName)
    //右侧还剩人员的id
    const newCheckedKeys = newShowKeys.map((newKey) => newKey.userId)
    transferData.map((res) => {
      let parent = res.userDtoList?.map((name) => name.userName)
      if (parent) {
       const commonCount = countCommonElements(newShowTitle, parent)
        //选择的人员是否与角色里人员数组的数量相同
        if (parent.length > 0 && commonCount === parent.length) {
         res.disabled = true
         //相同 在选中列表里加上选中人员的角色id回显角色选中
          newCheckedKeys.push(res.userId)
        } else {
          res.disabled = newCheckedKeys.includes(res.userId)
        }
        res.userDtoList.forEach((resChild) => {
          resChild.disabled = newCheckedKeys.includes(resChild.userId)
        })
      } else {
        res.disabled = newCheckedKeys.includes(res.userId)
      }
    })
    setCheckedKeys(newCheckedKeys)
    onChange(newShowKeys)
  }

  //计算包含的数量
  function countCommonElements(arr1, arr2) {
    return arr2.filter((item) => arr1.includes(item)).length
  }

  /**
   * @description:查询人员
   * @return {*}
   * @author: LINA.QI
   */
  const onSearch = (data) => {
    if (data) {
      const searchList = [] //存储临时查询人员
      treeData.map((item) => {
        item.userDtoList?.map((child) => {
          if (child.userName.includes(data)) {
            searchList.push(child)
            return
          }
          if (
            child.userName
              .spell("low", "poly")
              .toLowerCase()
              .indexOf(data.toLowerCase()) >= 0
          ) {
            searchList.push(child)
            return
          }
        })
      })
     //过滤相同id的人员
      let unique = searchList.filter(
        (item, index, arr) =>
          arr.findIndex((t) => t.userId === item.userId) === index
      )
      setTransferData(unique)
    } else {
     //右侧还选中的id
     const checked = showKeys.map((keys) => keys.userId)
      //右侧还选中的userName
      const resizeTitle = showKeys.map((keys) => keys.userName)
      treeData.map((res) => {
        let parent = res.userDtoList?.map((name) => name.userName)
       if (parent) {
         //选择的人员是否与角色里人员数组的数量相同
          const commonCount = countCommonElements(resizeTitle, parent)
          if (parent.length > 0 && commonCount === parent.length) {
            res.disabled = true
            checked.push(res.userId)
          }
          res.userDtoList.forEach((resChild) => {
            resChild.disabled = checked.includes(resChild.userId)
          })
        }
      })
      setCheckedKeys(checked)
      setTransferData(treeData)
    }
  }

  return (
    <>
      <div className="transfer-tree">
        <div className="left-tree">
          <Space>
            <Search
              placeholder="请输入考试人姓名"
              onSearch={onSearch}
              allowClear
            />
          </Space>
          <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            treeData={transferData}
            switcherIcon={<DownOutlined />}
            fieldNames={{
              title: "userName",
              key: "userId",
              children: "userDtoList"
            }}
          />
        </div>
        <div className="right-choose">
          <p className="checked-num">已选：{showKeys.length}人</p>
          <div className="show-member">
            {showKeys.map((item, index) => (
              <div className="member" key={item.userId}>
                <span className="member-name">{item.userName}</span>
                <span className="icon-style" onClick={() => handlerDel(item)}>
                  <i className="iconfont icon-x"></i>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default TransferTree
