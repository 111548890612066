import {
  addRole,
  deleteRole,
  rolePage,
  updateRole,
  updateStatus
} from "@/apis/systemMgmt/roleManagement"
import OperateButton from "@/components/OperateButton"
import { NameNotSpecialChar } from "@/utils/commonFunc/validate"
import { Button, Form, Input, message, Modal, Pagination, Table } from "antd"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import "./style/index.scss"

function UserMgmt() {
  const [dataSource, setDataSource] = useState([])
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalObj, setModalObj] = useState({})

  const { containerHeight } = useSelector((state) => state.appReducer)

  const [form] = Form.useForm()
  const { TextArea } = Input

  useEffect(() => {
    getRolePage()
  }, [page, size]) // eslint-disable-line

  /**
   * @description: 请求角色分页接口
   * @return {*}
   * @Author: gn
   */
  const getRolePage = () => {
    const params = {
      data: {},
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    rolePage(params)
      .then((res) => {
        setDataSource(res.list)
        setTotal(res.total)
      })
      .catch((e) => message.error(e))
  }
  /**
   * @description: 修改角色状态接口
   * @return {*}
   * @Author: gn
   */
  function getUpdateStatus(id, state) {
    const params = {
      id: id,
      status: state
    }
    updateStatus(params)
      .then((res) => {
        getRolePage()
      })
      .catch((e) => message.error(e))
  }
  /**
   * @description: 新增角色的接口请求
   * @return {*}
   * @Author: gn
   */
  function getAddRole(data) {
    addRole(data)
      .then((res) => {
        setModalOpen(false)
        message.success("操作成功")
        getRolePage()
      })
      .catch((err) => message.error(err))
  }
  /**
   * @description: 修改角色的接口请求
   * @return {*}
   * @Author: gn
   */
  function getUpdateRole(data) {
    updateRole(data)
      .then((res) => {
        setModalOpen(false)
        message.success("操作成功")
        getRolePage()
      })
      .catch((err) => message.error(err))
  }

  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  const columns = [
    {
      title: "角色名称",
      dataIndex: "roleName",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>
    },
    {
      title: "角色描述",
      dataIndex: "roleDesc",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>
    },
    {
      title: "状态",
      dataIndex: "status",
      ellipsis: true,
      render: (text, record) => {
        switch (text) {
          case "0":
            return <span className="node-status teal">已发布</span>
          case "1":
            return <span className="node-status red">已停用</span>
          case "2":
            return <span className="node-status gray">草稿</span>
        }
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      render: (text, record) => (
        <>
          {record.roleType === 0 && (
            <div className="operation">
              {record.status === "0" && (
                <OperateButton
                  text="停用"
                  icon="icon-ban"
                  popType={true}
                  title={`确定停用角色${record.roleName}? 停用用后该角色将无法使用！`}
                  data={record}
                  confirm={() => handleStop(record)}
                />
              )}
              {/* {record.status === "1" && (
                <OperateButton
                  text="启用"
                  icon="icon-play"
                  data={record}
                  click={() => handleStart(record)}
                />
              )} */}
              {record.status === "2" && (
                <>
                  <OperateButton
                    text="编辑"
                    icon="icon-pencil"
                    data={record}
                    click={() => handleEdit(record)}
                  />
                  <OperateButton
                    text="发布"
                    icon="icon-paper-airplane"
                    popType={true}
                    title={`确定发布角色${record.roleName}? *注意发布后无法进行修改！`}
                    data={record}
                    confirm={() => handlePublish(record)}
                  />
                  <OperateButton
                    text="删除"
                    icon="icon-trash"
                    popType={true}
                    title={`确定删除角色${record.roleName}? `}
                    data={record}
                    confirm={handleDelete}
                  />
                </>
              )}
              {record.status === "1" && (
                <>
                  <OperateButton
                    text="编辑"
                    icon="icon-pencil"
                    data={record}
                    click={() => handleEdit(record)}
                  />
                  <OperateButton
                    text="发布"
                    icon="icon-paper-airplane"
                    popType={true}
                    title={`确定发布角色${record.roleName}? *注意发布后无法进行修改！`}
                    data={record}
                    confirm={() => handlePublish(record)}
                  />
                </>
              )}
            </div>
          )}
        </>
      )
    }
  ]
  /**
   * @description: 停用
   * @return {*}
   * @Author: gn
   */
  function handleStop({ id }) {
    getUpdateStatus(id, 1)
  }
  /**
   * @description: 启用
   * @return {*}
   * @Author: gn
   */
  function handleStart({ id }) {
    getUpdateStatus(id, 0)
  }
  /**
   * @description: 编辑
   * @return {*}
   * @Author: gn
   */
  function handleEdit(data) {
    setModalOpen(true)
    setModalObj({ ...data })
    form.setFieldsValue(data)
  }
  /**
   * @description: 发布
   * @return {*}
   * @Author: gn
   */
  function handlePublish({ id }) {
    getUpdateStatus(id, 0)
  }
  /**
   * @description: 删除
   * @return {*}
   * @Author: gn
   */
  const handleDelete = (id) => {
    deleteRole(id).then((res) => {
      message.success("删除成功")
      getRolePage()
    })
  }
  /**
   * @description: 点击新增
   * @return {*}
   * @Author: gn
   */
  function changeEdit() {
    setModalOpen(true)
    let json = {
      roleDesc: "",
      roleName: ""
    }
    setModalObj({ ...json })
    form.setFieldsValue(json)
  }
  /**
   * @description: 表单校验成功
   * @return {*}
   * @Author: gn
   */
  function onFinish(values) {
    if (modalObj.id) {
      const params = {
        ...modalObj,
        ...values
      }
      getUpdateRole(params)
    } else {
      getAddRole(values)
    }
  }
  /**
   * @description: 表单校验失败
   * @return {*}
   * @Author: gn
   */
  function onFinishFailed(error) {}
  return (
    <>
      <div className="roleManagement">
        <Button
          type="primary"
          className="role_add"
          onClick={() => changeEdit()}
        >
          创建角色<i className="add_icon iconfont icon-plus-sm"></i>
        </Button>
        <Table
          size="middle"
          className="custom-table role_table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          // scroll={{
          //     y: containerHeight - 56 - 24 * 2 - 32 - 56 * 2 - 24 * 2,
          // }}
        />
        {total !== 0 && (
          <div className="role_page">
            <Pagination
              className="custom-pagination"
              total={total}
              pageSize={size}
              current={page}
              pageSizeOptions={["10", "20", "30", "40", "50"]}
              showSizeChanger
              // showQuickJumper
              // showTotal={(total) => `共 ${total} 条`}
              onChange={handleChangePagination}
            />
          </div>
        )}
      </div>
      <Modal
        width="520px"
        className="custom-modal"
        maskClosable={false}
        title="创建/编辑角色"
        open={modalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <Form
          name="basic"
          initialValues={{
            remember: true
          }}
          labelCol={{
            span: 4
          }}
          // wrapperCol={{
          //     span: 16,
          // }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="roleName"
            rules={[...NameNotSpecialChar("角色名称", 15, true)]}
            label="角色名称"
          >
            <Input autoComplete="off" placeholder="请输入角色名称"></Input>
          </Form.Item>
          <Form.Item
            name="roleDesc"
            rules={[...NameNotSpecialChar("角色描述", 30)]}
            label="角色描述"
          >
            <TextArea rows={4} placeholder="请输入角色描述" />
          </Form.Item>
          <Form.Item
            style={{
              textAlign: "right",
              marginTop: "25px",
              marginBottom: "0px"
            }}
          >
            <Button
              style={{ marginRight: "8px" }}
              onClick={() => setModalOpen(false)}
            >
              <i
                className="iconfont icon-x"
                style={{ fontSize: "14px", marginRight: "2px" }}
              ></i>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              <i
                className="iconfont icon-baocun"
                style={{ fontSize: "14px", marginRight: "2px" }}
              ></i>
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default UserMgmt
