/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-07-26 09:07:39
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-09-12 11:14:47
 */
import {
  CHANGE_MENU,
  GET_CONTAINER_HEIGHT,
  UPDATE_EDITABLE_DOM,
  BOARD_PROJECT_LIST_FILTER
} from "../constant"

const defaultState = {
  currentMenu: "1-1",
  containerHeight: 0,
  currentEditableDomList: [],
  boardProjectListFilter: {}
}

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_MENU:
      return {
        ...state,
        currentMenu: action.payload
      }
    case GET_CONTAINER_HEIGHT:
      return {
        ...state,
        containerHeight: action.payload
      }
    case UPDATE_EDITABLE_DOM:
      return {
        ...state,
        currentEditableDomList: action.payload
      }
    case BOARD_PROJECT_LIST_FILTER:
      return {
        ...state,
        boardProjectListFilter: action.payload
      }
    default:
      return state
  }
}

export default appReducer
