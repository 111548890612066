/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-08-01 10:20:24
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-16 10:35:18
 */
import { Button, Form, Radio, Select, message } from "antd"
import { useEffect, useState } from "react"
import TransferTree from "@/components/TransferTree"
import {
  projectUserRoleTree,
  subjectListApi,
  addSubjectUser
} from "@/apis/projectCenter/projectManagement"
import "../index.scss"
const { Option } = Select

function SendOutExam(props) {
  const { data, isOpen, confirmClick, cancelClick } = props
  const [form] = Form.useForm() //表单
  const [subjectList, setSubjectList] = useState([]) //科目列表
  const [allData, setAllData] = useState([])
  const layout = {
    labelCol: {
      span: 4
    }
  }
  useEffect(() => {
    getSubjectList()
    getProjectUserRoleTree()
    form.setFieldValue("notice", 2)
    console.log(data)
  }, [isOpen])

  /**
   * @description: 获取科目下拉
   * @return {*}
   * @author: LINA.QI
   */
  const getSubjectList = () => {
    subjectListApi("",false)
      .then((res) => {
        setSubjectList(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 获取人员
   * @return {*}
   * @author: LINA.QI
   */
  const getProjectUserRoleTree = async () => {
    await projectUserRoleTree({ projectId: data.projectId })
      .then((res) => {
        res.forEach((item) => {
          item.userId = item.roleId
          item.userName = item.roleName
          // item.disabled = !item.userDtoList
        })
        setAllData([...res])
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 切换科目
   * @return {*}
   * @author: LINA.QI
   */
  const [subjectName, setSubjectName] = useState(null)
  const handleSubject = (data, option) => {
    setSubjectName(option.children)
    form.setFieldValue("subjectId", data)
  }

  /**
   * @description: 保存
   * @return {*}
   * @author: LINA.QI
   */
  const handleSubmit = () => {
    form
      .validateFields()
      .then((res) => {
        const params = {
          projectId: data.projectId,
          subjectName: subjectName,
          ...res
        }
        addSubjectUser(params)
          .then((resp) => {
            if (resp) {
              message.success("发卷子成功")
              confirmClick()
            }
          })
          .catch((errorInfo) => {
            console.log(errorInfo)
          })
      })
      .catch((errorInfo) => {
        console.log(errorInfo)
      })
  }

  return (
    <>
      <div className="sendOutExam">
        <Form form={form} {...layout}>
          <Form.Item
            name="subjectId"
            label="选择考试科目"
            rules={[{ required: true, message: `请选择科目` }]}
          >
            <Select
              style={{ width: "260px" }}
              placeholder="请选择考试科目"
              onChange={handleSubject}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {subjectList.map((item, index) => (
                <Option key={item.id} value={item.id}>
                  {item.subjectName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="userList"
            label="选择项目成员"
            rules={[{ required: true, message: `请选择项目成员` }]}
          >
            {allData.length > 0 && <TransferTree data={allData} />}
          </Form.Item>
          <Form.Item name="notice" label="">
            <Radio.Group style={{ paddingLeft: "110px" }}>
              <Radio value={2}>不接收通知</Radio>
              <Radio value={0}>接收交卷通知</Radio>
              <Radio value={1}>仅接收合格成绩通知</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </div>
      <div className="dialog-footer">
        <Button className="cancel" onClick={() => cancelClick()}>
          <i className="iconfont icon-x"></i>取消
        </Button>
        <Button className="confirm" type="primary" onClick={handleSubmit}>
          <i className="iconfont icon-baocun"></i>确认
        </Button>
      </div>
    </>
  )
}

export default SendOutExam
