import { memberList } from "@/apis/projectCenter/projectManagement";
import { addOrUpdateResume } from "@/apis/projectOverview";
import { DatePicker, Form, Input, Modal, Select, message } from 'antd';
import dayjs from "dayjs";
import React, { useEffect, useState } from 'react';
const { TextArea } = Input;

const ResumeModal = (props) => {
  const { modalOpen, modalData, modalType, modalTitle, projectId, cancelModal } = props
  const [refform] = Form.useForm();
  const [members, setMembers] = useState([])
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    refform.setFieldsValue({
      ...modalData,
      initiationTime: dayjs(modalData.initiationTime)
    })
    const params = {
      data:{projectId},
      page:{
        pageIndex:1,
        pageSize:999
      }
    }
    memberList(params).then(res => {
      setMembers(res.list)
    })
  },[modalOpen])
  const saveModal = () => {
    refform.validateFields().then(values => {
      const params = {
        ...values,
        id: modalData.id,
        projectId: projectId
      }
      addOrUpdateResume(params).then(res => {
        if(res) {
          message.success(`${modalData.id ? '编辑' : '新增'}成功！`)
          closeModal()
        }
      })
    })
    .catch(err => console.log(err))
  };
  const closeModal = () => {
    refform.resetFields()
    cancelModal();
  };
  return (
    <>
      <Modal
        className='custom-modal resume-modal'
        title={modalTitle}
        maskClosable={false}
        open={modalOpen}
        onOk={saveModal}
        onCancel={closeModal}
        okText={<span><i className="iconfont icon-check"></i>确认</span>}
        cancelText={<span><i className="iconfont icon-x"></i>取消</span>}
      >
        <Form form={refform} labelCol={{ span: 5  }} wrapperCol={{ span: 18 }} >
          <Form.Item name="initiationTime" rules={[{required: true}]} label="发起时间">
            <DatePicker 
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
          <Form.Item name="changeReason"rules={[{required: true}]}label="变更原因">
            <TextArea
              showCount
              maxLength={500}
              placeholder="请输入"
              autoSize={{ minRows: 4, maxRows: 8 }}
            />
          </Form.Item>
          <Form.Item name="costImpact"rules={[{required: true}]}label="成本影响">
            <TextArea
              showCount
              maxLength={500}
              placeholder="请输入"
              autoSize={{ minRows: 4, maxRows: 8 }}
            />
          </Form.Item>
          <Form.Item name="implementationMeasures" rules={[{required: true}]} label="执行措施">
            <TextArea
              showCount
              maxLength={500}
              placeholder="请输入"
              autoSize={{ minRows: 4, maxRows: 8 }}
            />
          </Form.Item>
          <Form.Item  name="projectUserId" rules={[{required: true}]} label="责任人">
            <Select
              style={{
                width: '100%',
              }}
              options={members.map(d => ({label: d.userName + (d.userDept ? `(${d.userDept})`: ""), value:d.id}))}
            />
          </Form.Item>
          <Form.Item name="remark"label="备注">
            <TextArea
              showCount
              maxLength={500}
              placeholder="请输入"
              autoSize={{ minRows: 4, maxRows: 8 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ResumeModal;