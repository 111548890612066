/*
 * @Description: 
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-20 10:11:48
 * @LastEditors: LINA.QI
 * @LastEditTime: 2023-06-30 15:12:46
 */
import qs from "qs";
import { axiosInstance } from '../config';


//项目管理-项目已发布sop下拉
export const selectAllSop = (params) => {
  return axiosInstance.post('/sopbasicinfo/selectPublishedSop', params);
};

//项目管理-查询仅发布和禁用的sop下拉框
export const disabledSop = (params) => {
 return axiosInstance.post('/sopbasicinfo/selectPublishedAndDisableSop', params);
};

//项目管理-查询仅发布和禁用的sop下拉框
export const currentDisabledSop = (params) => {
  return axiosInstance.post('/sopbasicinfo/selectCurrentPublishedAndDisableSop', params);
};

//我的项目-查询仅发布和禁用的sop下拉框
export const myProjectDisabledSop = () => {
  return axiosInstance.post('/sopbasicinfo/selectMyProjectPublishedAndDisableSop');
};

//项目成员-角色下拉
export const roleList = (params) => {
 return axiosInstance.get('/projectUser/getProjectRoleListBySource', {
   params,
   paramsSerializer: function (params) {
     return qs.stringify(params);
   }
 });
};