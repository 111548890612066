/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-13 16:42:09
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-09 17:11:32
 */
import { selectAllSop } from "@/apis/projectCenter/common"
import {
  addProject,
  getERPList,
  systemdict,
  updateProject
} from "@/apis/projectCenter/projectManagement"
import { Button, Form, Input, Select, message } from "antd"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { NameNotSpecialChar } from "../../../utils/commonFunc/validate"
import "../index.scss"
const { Option } = Select

function AddProjectDialog(props) {
  const { data, isOpen, confirmClick } = props
  const [form] = Form.useForm() //表单
  const [typeList, setTypeList] = useState([]) //项目类型列表
  const [erpList, setErpList] = useState([]) //项目类型列表
  const [industryList, setIndustryList] = useState([]) //行业列表
  const [type, setType] = useState(null) //项目类型前缀
  const [isLoading, setIsLoading] = useState(false) //保存loading
  const currentUser = useSelector((state) => state.userReducer)
  const layout = {
    labelCol: {
      span: 5
    }
  }

  useEffect(() => {
    if (data.type === "ADD") {
      getSelectAllSop() //项目类型
      getERPData() //ERP编号
    }
    getSystemdict() //所属行业
  }, [isOpen])

  useEffect(() => {
    if (data.type === "EDIT") {
      form.setFieldsValue(data.data)
    }
  }, [data.type])

  //获取ERP下拉
  const getERPData = () => {
    const params = {
      userId: currentUser.id
    }
    getERPList(params)
      .then((res) => {
        res.forEach((d) => {
          d.showName = d.erpNo + "(" + d.erpProjectName + ")"
        })
        setErpList(res)
      })
      .catch(() => {})
  }

  /**
   * @description: 项目类型
   * @return {*}
   * @author: LINA.QI
   */
  const getSelectAllSop = () => {
    selectAllSop()
      .then((res) => {
        setTypeList(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 所属行业
   * @return {*}
   * @author: LINA.QI
   */
  const getSystemdict = () => {
    systemdict({ categoryCode: "industry_code" })
      .then((res) => {
        setIndustryList(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 切换项目类型
   * @return {*}
   * @author: LINA.QI
   */
  const handleType = (data, option) => {
    setType(option.key)
  }

  /**
   * @description: 新增
   * @return {*}
   * @author: LINA.QI
   */
  const addProjectSave = (data) => {
    addProject(data)
      .then((resp) => {
        confirmClick("save")
        setIsLoading(false)
        message.success("新增成功")
      })
      .catch((errorInfo) => {
        setIsLoading(false)
        message.error(errorInfo)
      })
  }

  /**
   * @description: 修改项目
   * @return {*}
   * @author: LINA.QI
   */
  const updateProjectSave = (res) => {
    const params = {
      projectId: data.data.id,
      projectName: res.projectName,
      industryCode: res.industryCode
    }
    updateProject(params)
      .then((resp) => {
        confirmClick("save")
        setIsLoading(false)
        message.success("修改成功")
      })
      .catch((errorInfo) => {
        setIsLoading(false)
        message.error(errorInfo)
      })
  }

  /**
   * @description: 保存
   * @return {*}
   * @author: LINA.QI
   */
  const handleSubmit = () => {
    console.log(data.type)
    form
      .validateFields()
      .then((res) => {
        setIsLoading(true)
        if (data.type === "ADD") {
          res.projectCode = type + "_" + res.projectCode
          addProjectSave(res)
        } else {
          updateProjectSave(res)
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo)
      })
  }

  return (
    <>
      <div className="addProject">
        <Form form={form} {...layout}>
          <Form.Item
            name="projectName"
            label="项目名称："
            rules={[...NameNotSpecialChar("项目名称", 15, true)]}
          >
            <Input
              style={{ width: "295px" }}
              placeholder="请输入项目名称"
            ></Input>
          </Form.Item>
          <Form.Item
            name="sopId"
            label="项目类型："
            rules={[{ required: true, message: `请选择项目类型` }]}
            hidden={data.type === "ADD" ? false : true}
          >
            <Select
              style={{ width: "295px" }}
              placeholder="请选择项目类型"
              onChange={handleType}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {typeList.map((item, index) => (
                <Option key={item.sopCodePrefix} value={item.id}>
                  {item.sopName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="projectCode"
            label="项目编号："
            rules={[{ required: true, message: `请选择项目编号` }]}
            hidden={data.type === "ADD" ? false : true}
          >
            <div
              className="code"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "295px"
              }}
            >
              <span
                style={{ color: "#9CA3AF", fontSize: "14px" }}
                hidden={type ? false : true}
              >
                {type + "_"}
              </span>
              <Input style={{ flex: 1 }} placeholder="请输入项目编号"></Input>
            </div>
          </Form.Item>
          <Form.Item
            name="erpNo"
            label="ERP编号："
            hidden={data.type === "ADD" ? false : true}
            rules={[
              {
                required: data.type === "EDIT" ? false : true,
                message: `请输入ERP编号`
              }
            ]}
          >
            <Select
              placeholder="请输入ERP编号"
              style={{
                width: 295
              }}
              options={erpList}
              fieldNames={{ label: "showName", value: "erpNo" }}
            />
          </Form.Item>
          <Form.Item
            name="industryCode"
            label="所属行业："
            rules={
              data.type === "ADD"
                ? [{ required: true, message: `请选择所属行业` }]
                : []
            }
          >
            <Select style={{ width: "295px" }} placeholder="请选择行业">
              {industryList.map((item, index) => (
                <Option key={item.dictCode} value={item.dictCode}>
                  {item.dictDesc}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="dialog-footer">
        <Button
          className="cancel"
          onClick={() => confirmClick("cancel")}
          disabled={isLoading}
        >
          <i className="iconfont icon-x"></i>取消
        </Button>
        <Button
          className="confirm"
          type="primary"
          onClick={handleSubmit}
          loading={isLoading}
        >
          <i className="iconfont icon-baocun"></i>确认
        </Button>
      </div>
    </>
  )
}
export default AddProjectDialog
