/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-20 13:26:27
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-16 10:35:00
 */
import qs from "qs"
import { axiosInstance } from "../../config"

//项目管理-ERP编号
export const getERPList = (params) => {
  return axiosInstance.post("/projectErp/selectList", params)
}

//项目管理-项目状态tab
export const statusCount = (params) => {
  return axiosInstance.post("/project/manage/statusCount", params)
}

//项目管理-列表
export const projectPage = (params) => {
  return axiosInstance.post("/project/manage/page", params)
}

//项目管理-字典表
export const systemdict = (params) => {
  return axiosInstance.post("/systemdict/list", params)
}

//项目管理-新建项目
export const addProject = (params) => {
  return axiosInstance.post("/project/manage/add", params)
}

//项目管理-修改项目
export const updateProject = (params) => {
  return axiosInstance.post("/project/manage/update", params)
}

//项目管理-验证项目(开始start-结项done-关闭closed-删除delete)
export const manageCheck = (params) => {
  return axiosInstance.post(
    `/project/manage/check/${params.type}/${params.projectId}`
  )
}

//项目管理-操作项目(开始start-结项done-关闭closed-删除delete)
export const operateCheck = (params) => {
  return axiosInstance.post(
    `/project/manage/operate/${params.type}/${params.projectId}`
  )
}

//项目成员-列表页
export const memberList = (params) => {
  return axiosInstance.post("/projectUser/getProjectUserByProjectId", params)
}

//项目成员-选择成员下拉
export const userBySource = (params) => {
  return axiosInstance.get("/projectUser/getUserBySource", {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params)
    }
  })
}

//项目成员-添加成员
export const addMemberSave = (params) => {
  return axiosInstance.post("/projectUser/insertProjectUser", params)
}

//项目成员-不可取消角色列表
export const noCancelRole = (projectUserId) => {
  return axiosInstance.get(
    `/projectUser/getProjectUserNotCancelRoleList/${projectUserId}`
  )
}

//项目成员-编辑成员
export const editMemberSave = (params) => {
  return axiosInstance.post("/projectUser/editProjectUser", params)
}

//项目成员-移出成员
export const removeUser = (projectUserId) => {
  return axiosInstance.post(`/projectUser/removeProjectUser/${projectUserId}`)
}

//项目成员-转移项目经理列表
export const projectManager = (params) => {
  return axiosInstance.get(`/projectUser/getProjectManagerList`, {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params)
    }
  })
}

//项目成员-转移项目负责人
export const changeLeader = (params) => {
  return axiosInstance.post(`/projectUser/changeProjectLeader`, params)
}

//项目计划-获取项目下所有任务节点
export const projectNode = (projectId) => {
  return axiosInstance.get(`/projectNode/queryProjectAllNode/${projectId}`)
}

//项目计划-获取节点下可选用户列表
export const projectNodeUser = (nodeId) => {
  return axiosInstance.get(`/projectNode/getProjectUserByNodeId/${nodeId}`)
}

//项目计划-修改节点基本信息
export const changeProjectNodeInfo = (params) => {
  return axiosInstance.post(`/projectNode/changeProjectNodeInfo`, params)
}

//产线排班-获取列表
export const productionShift = (projectId) => {
  return axiosInstance.post(`/project/productionShift/${projectId}`)
}

//产线排班-新增班次
export const addSchedule = (params) => {
  return axiosInstance.post(`/project/productionShift/add`, params)
}

//基本信息
export const projectDetail = (params) => {
  return axiosInstance.post(`/project/detail/${params}`)
}

//批量修改项目计划
export const addPlanBatch = (params) => {
  return axiosInstance.post("/projectNode/addBatch", params)
}

//责任角色
export const projectRole = (params) => {
  return axiosInstance.get("/projectUser/queryProjectRole/" + params)
}

//责任人
export const userBySystem = (params) => {
  return axiosInstance.post("/projectUser/getSystemRole", params)
}

//节点输入输出
export const fileList = (params) => {
  return axiosInstance.post("/projectNode/fileListSelect", params)
}

//节点删除
export const deleteNode = (params) => {
  return axiosInstance.post("/projectNode/delete", params)
}

//节点编辑
export const updateNode = (params) => {
  return axiosInstance.post("/projectNode/updateNode", params)
}

//新增节点
export const addNode = (params) => {
  return axiosInstance.post("/projectNode/addNode", params)
}

/************************ 工时审批 ****************************/
// 审批日期 - tab显示
export const fillInDate = (params) => {
  return axiosInstance.post("/projectworkhours/approvalDate", params)
}

// 审批列表内容回显
export const approvePage = (params) => {
  return axiosInstance.post("/projectworkhours/approvalPage", params)
}

// 工时审批通过
export const approvalPass = (params) => {
  return axiosInstance.post("/projectworkhours/approvalPass", params)
}

// 工时审批撤回
export const approvalCancel = (params) => {
  return axiosInstance.post("/projectworkhours/approvalCancel", params)
}

// 项目计划-新增版本
export const addStageVersion = (params) => {
 return axiosInstance.post("/stageversion/addStageVersion", params)
}

// 项目计划-修改版本
export const updateStageVersion = (params) => {
 return axiosInstance.post("/stageversion/updateStageVersion", params)
}

// 项目计划-删除版本
export const deleteStageVersion = (params) => {
 return axiosInstance.post(`/stageversion/deleteStageVersion?stageVersionId=${params.stageVersionId}&projectId=${params.projectId}`,)
}


// 项目成员-获取用户在项目中角色级别
export const projectUserLevel = (projectId) => {
 return axiosInstance.get(`/projectUser/getProjectUserLevel/${projectId}`,)
}

// 工时统计-员工下拉
export const workHourMemberList = (projectId) => {
  return axiosInstance.get(`/projectUser/getProjectAllUser/${projectId}`,)
}
 
// 项目考试-科目下拉框
export const subjectListApi = (projectId,isMyExam) => {
 return axiosInstance.post(`/examsubject/subjectList?projectId=${projectId}&isMyExam=${isMyExam}`,)
}

// 项目考试-列表
export const subjectPage = (params) => {
 return axiosInstance.post(`/projectexamsubjectuser/page`,params)
}

// 项目考试-树状结构
export const projectUserRoleTree = (params) => {
 return axiosInstance.post(`/projectexamsubjectuser/projectUserRoleTree`,params)
}

// 项目考试-发卷子
export const addSubjectUser = (params) => {
 return axiosInstance.post(`/projectexamsubjectuser/addSubjectUser`,params)
}

// 项目考试-提醒、重考提醒
export const noticeApi = (params) => {
 return axiosInstance.post(`/projectexamsubjectuser/notice`,params)
}
