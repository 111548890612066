import { projectUserLevel } from "@/apis/projectCenter/projectManagement";
import { getResumeChangePage } from "@/apis/projectOverview";
import OperateButton from "@/components/OperateButton";
import eventBus from "@/utils/commonFunc/eventBus";
import { exportFile } from "@/utils/commonFunc/exportFile";
import readSession from "@/utils/commonFunc/readSession";
import { Button, DatePicker, Form, Input, Pagination, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import FinishModal from "./finishModal";
import ResumeModal from "./resumeModal";

const { RangePicker } = DatePicker;
const { Search } = Input;

function Resume(props) {
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = useState(false);
  const [finishModalOpen, setFinishModalOpen] = useState(false);
  const [modalData, setModalData] = useState({})
  const [modalType, setModalType] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [searchData, setSearchData] = useState({
    changeReason: undefined,
    finishEndTime: "",
    finishStartTime: "",
    finishTimeSort: undefined,
    initiationEndTime:"",
    initiationStartTime:"",
    initiationTimeSort: undefined,
    projectId: readSession("sessionProjectId")
  })
  const [isMyLiable, setIsMyLiable] = useState(false)
  const columns = [
    {
      title: "序号",
      dataIndex: "startTime",
      key: "startTime",
      width: 50,
      ellipsis: true,
      render:(text, record, index) => 
        `${(page - 1) * (size) + (index + 1)}`
    },
    {
      title: "发起时间",
      dataIndex: "initiationTime",
      sorter: true,
      width: 120,
      ellipsis: true,
      render: (text) => {
       return <span>{text ? dayjs(text).format("YYYY-MM-DD") : '--'}</span>
      }
    },
    {
      title: "变更发起人",
      dataIndex: "changeUserName",
      width: 100,
      ellipsis: true,
    },
    {
      title: "变更原因",
      dataIndex: "changeReason",
      ellipsis: true,
      width: 170
    },
    {
      title: "项目成本影响",
      dataIndex: "costImpact",
      ellipsis: true,
      width: 170
    },
    {
      title: "执行措施",
      dataIndex: "implementationMeasures",
      ellipsis: true,
    },
    {
      title: "执行责任人",
      dataIndex: "projectUserName",
      width: 100,
      ellipsis: true,
    },
    {
      title: "完成情况",
      dataIndex: "finishInfo",
      ellipsis: true,
    },
    {
      title: "完成时间",
      width: 120,
      dataIndex: "finishTime",
      sorter: true,
      ellipsis: true,
      render: (text) => {
        return <span>{text ? dayjs(text).format("YYYY-MM-DD") : '--'}</span>
       }
    },
    {
      title: "备注",
      width: 170,
      dataIndex: "remark",
      ellipsis: true,
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 150,
      render: (text, record) => (
        <div className="operation">
          {record.finishStatus != 1 && isMyLiable && <OperateButton icon="icon-eye" text="编辑" click={() => editResume(record)}/>}
          {record.finishStatus != 1 && isMyLiable && <OperateButton icon="icon-pencil" text="完成" click={() => finishResume(record)}/>}
          {record.finishStatus === 1 && <span className="finish-text">已完成</span>}
        </div>
      ),
    },
  ];

  const tableSorterChange = (electedRowKeys, selectedRows, info) => {
    const { field, order } = info
    if (field === "initiationTime") {
      setSearchData({
        ...searchData,
        initiationTimeSort: order ? (order === "descend" ? 1 : 0) : undefined
      })
    } else {
      setSearchData({
        ...searchData,
        finishTimeSort: order ? (order === "descend" ? 1 : 0) : undefined
      })
    }
  }
  useEffect(() => {
    projectUserLevel(readSession("sessionProjectId"))
      .then((res) => {
        setIsMyLiable([1,2].includes(res))
      })
      .catch((e) => console.log(e))
  }, [])
  useEffect(() => {
    const breadcrumbItemClickEventsFn = (item) => {
      switch (item) {
        case "项目总览":
          props.resumeToProjectOverview(item);
          eventBus.emit("setBreadcrumbData", ["项目空间", "项目总览"]);
          break;
        default:
          return;
      }
    };
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    );
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      );
    };
  }, [props]);
  useEffect(() => {
    query()
  },[page, size, searchData])
  const query = () => {
    const params = {
      data: searchData,
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    getResumeChangePage(params).then(res => {
      setDataSource(res.list)
      setTotal(res.total)
    })
    .catch(err => console.log(err))
  }
  const initiationTimeChange = (date) => {
    setPage(1)
    setSearchData({
      ...searchData,
      initiationStartTime: date && date[0] && dayjs(date[0]).format("YYYY-MM-DD"),
      initiationEndTime: date && date[1] && dayjs(date[1]).format("YYYY-MM-DD")
    })
  }
  const finishTimeChange = (date) => {
    setPage(1)
    setSearchData({
      ...searchData,
      finishStartTime: date && date[0] && dayjs(date[0]).format("YYYY-MM-DD"),
      finishEndTime: date && date[1] && dayjs(date[1]).format("YYYY-MM-DD")
    })

  }
  const onFinish = (val) => {
    if(typeof(val) === "string") {
      setPage(1)
      setSearchData({
        ...searchData,
        changeReason: val
      })
    }
  }
  const explainResult = () => {
    exportFile("/projectchangehistory/exportProjectChangeHistory", "post", searchData);
  }
  const handleAdd = () => {
    setModalOpen(true)
    setModalTitle("添加记录")
    setModalType('add')
  }
  const editResume = (row) => {
    setModalOpen(true)
    setModalTitle("编辑记录")
    setModalType('edit')
    setModalData(row)
  }
  const finishResume = (row) => {
    setFinishModalOpen(true)
    setModalData(row)
  }
  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };
  const closeModal = () => {
    setModalOpen(false)
    setFinishModalOpen(false)
    setModalData({})
    query()
  }
  return (
    <>
      <div className="project-resume">
        <div className="form-container">
          <div className="operate-part">
            {isMyLiable && <Button
              onClick={handleAdd}
              type="primary"
              style={{
                background: '#1C64F2',
                color: "#fff",
                display: "flex",
                marginRight: "10px"
              }}
            >
              添加记录<i className="iconfont icon-plus"></i>
            </Button>}
            <Button
              onClick={explainResult}
              type="primary"
              style={{
                background: '#fff',
                color: '#374151',
                display: "flex",
                border: '1px solid #E5E7EB'
              }}
            >
              导出查询结果<i className="iconfont icon-external-link"></i>
            </Button>
          </div>
          <Form form={form} layout="inline" colon={false} >
            <Form.Item name="initiationTime" label="发起时间">
              <RangePicker onChange={initiationTimeChange} />
            </Form.Item>
            <Form.Item name="typeCode" label="完成时间" style={{marginLeft: "20px"}}>
              <RangePicker onChange={finishTimeChange}/>
            </Form.Item>
            <Form.Item name="deviceNo" style={{marginLeft: "20px"}}>
              <Search
                style={{
                  width: 270,
                }}
                autoComplete="off"
                allowClear
                onSearch={onFinish}
                onKeyDown={(event) => {
                  if(event.code === "Enter"){
                    onFinish()
                  }
                }}
                placeholder="请输入变更原因或备注"
              />
            </Form.Item>
          </Form>
        </div>
        <div style={{ marginTop: 16 }}>
          <Table
            size="middle"
            className="custom-table"
            rowKey={(record) => record.id}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            onChange={tableSorterChange}
          />
          {total === 0 ? undefined : <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "40", "50"]}
            showSizeChanger
            className="custom-pagination"
            onChange={handleChangePagination}
          />}
        </div>
      </div>
      <ResumeModal
        modalTitle={modalTitle}
        modalType={modalType}
        modalData={modalData}
        modalOpen={modalOpen}
        projectId={readSession("sessionProjectId")}
        cancelModal={closeModal}
      />
      <FinishModal
        modalOpen={finishModalOpen}
        modalData={modalData}
        cancelModal={closeModal}
      />
    </>
  )
}
export default Resume