/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-07-22 06:20:33
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-06 14:57:49
 */
import { axiosInstance } from "../config"

export const test = () => {
  return axiosInstance.get("/test")
}
// 阶段完成状态
export const queryProjectStageStatus = (params) => {
  return axiosInstance.post(
    `/projectNode/queryProjectStageStatus/${params.projectId}`
  )
}
// 获取阶段下所有任务节点数据
export const queryNodesByStageId = (params) => {
  return axiosInstance.get(
    `/projectNode/queryNodesByStageId/${params.stageId}/${params.projectId}/${params.stageVersionId}`
  )
}
// 任务转移
export const transferNode = (params) => {
  return axiosInstance.post(
    `/projectNode/transferNode
    `,
    params
  )
}
// 获取节点下可选用户列表
export const getProjectUserByNodeId = (params) => {
  return axiosInstance.get(
    `/projectNode/getProjectUserByNodeId/${params.projectNodeId}`
  )
}
// 获取任务详情
export const queryNodeDetail = (projectNodeId) => {
  return axiosInstance.get(`/projectNode/queryNodeDetail/${projectNodeId}`)
}

// 开始任务
export const startNode = (projectNodeId) => {
  return axiosInstance.post(`/projectNode/startNode/${projectNodeId}`)
}
// 更新任务进度
export const addNodeProgress = (params) => {
  return axiosInstance.post(`/projectNode/addNodeProgress`, params)
}
// 更新子任务进度
export const addTaskProgress = (params) => {
  return axiosInstance.post(`/projectNode/addTaskProgress`, params)
}

// 获取总工时
export const queryAllWorkHour = (params) => {
  return axiosInstance.post(`/projectworkhours/allWorkHour`, params)
}

// 完成任务
export const completeNode = (params) => {
  return axiosInstance.post(`/projectNode/completeNode`, params)
}
// 子完成任务
export const completeNodeTask = (params) => {
  return axiosInstance.post(`/projectNode/completeNodeTask`, params)
}
// 获取任务进度列表
export const queryNodeProgressList = (params) => {
  return axiosInstance.post(`/projectNode/queryNodeProgressList`, params)
}
// 删除子任务前确认工时
export const queryNodeTaskWorkHourExist = (params) => {
  return axiosInstance.post(`/projectNode/queryNodeTaskWorkHourExist`,params)
}
// 删除子任务
export const deleteNodeTask = (projectNodeTaskId) => {
 return axiosInstance.post(`/projectNode/deleteNodeTask/${projectNodeTaskId}`)
}
// 新增子任务
export const addNodeTask = (params) => {
  return axiosInstance.post(`/projectNode/addNodeTask`, params)
}
// 项目内-项目项目动态
export const projectLogPage = (params) => {
  return axiosInstance.post(`/project/log/page`, params)
}
// 项目内-修改节点基本信息
export const changeProjectNodeInfo = (params) => {
  return axiosInstance.post(`/projectNode/changeProjectNodeInfo`, params)
}
// 项目内-修改节点实际执行时间
export const changeProjectNodeTime = (params) => {
  return axiosInstance.post(`/projectNode/updateStartTime`, params)
}
// 项目内-提交节点文件
export const commitNodeFile = (params) => {
  return axiosInstance.post(`/projectFile/commitNodeFile`, params)
}

// 任务重启
export const restartNode = (projectNodeId) => {
  return axiosInstance.post(`/projectNode/restartNode/${projectNodeId}`)
}
// 子任务重启
export const restartNodeTask = (projectNodeTaskId) => {
  return axiosInstance.get(`/projectNode/restartNodeTask/${projectNodeTaskId}`)
}

// 子任务名称修改
export const updateNodeTaskName = (params) => {
  return axiosInstance.post(`/projectNode/updateNodeTask`, params)
}
// =================================项目产品==================================

// 项目内-项目项目动态
export const projectProductPage = (params) => {
  return axiosInstance.post(`/project/product/page`, params)
}
// 项目内-新增项目产品
export const addProjectProduct = (params) => {
  return axiosInstance.post(`/project/product/add`, params)
}
// 查询各种下拉框的数据
export const allSelsetData = (params) => {
  return axiosInstance.post(`/systemdict/list`, params)
}
// 设备-分页查询
export const productDevicePage = (params) => {
  return axiosInstance.post(`/project/product/device/page`, params)
}
// 模型-分页查询
export const productModelPage = (params) => {
  return axiosInstance.post(`/project/product/model/page`, params)
}
// 光学方案-分页查询
export const productOpticsSolutionPage = (params) => {
  return axiosInstance.post(`/project/product/opticsSolution/page`, params, {
    responseType: "blob"
  })
}
// 产品详情-摆放位姿
export const getPlacementPosture = (params) => {
  return axiosInstance.post(`/projectposition/getDetail`, params)
}
// 产品详情-增加位姿
export const addPlacementPosture = (params) => {
  return axiosInstance.post(`/projectposition/add`, params)
}
// 产品详情-产品组件
export const getProductComponent = (id) => {
  return axiosInstance.post(`/project/device/configuration/${id}`)
}
// 产品详情-增加组件
export const addProductComponent = (params) => {
  return axiosInstance.post(`/project/device/configuration`, params)
}

// =================================项目设备==================================

// 项目设备-分页查询
export const projectDevicePage = (params) => {
  return axiosInstance.post(`/project/device/page`, params)
}
// 项目设备-下载token
export const downloadToken = (params) => {
  return axiosInstance.post(`/project/device/download/token`, params, {
    responseType: "blob"
  })
}
// 项目设备-获取软件版本
export const getSoftVersion = (params) => {
  return axiosInstance.post(`/devicestocklog/getSoftVersion`, params)
}
// 项目设备-设备配置
export const deviceonline = (id) => {
  return axiosInstance.post(`/deviceonline/${id}`)
}

// 工时
export const queryWorkHour = (params) => {
  return axiosInstance.post(`/projectworkhours/getWorkHour`, params)
}

// 任务详情中交付物新增
export const addDeliverables = (params) => {
  return axiosInstance.post(`/projectFile/addProjectFile`, params)
}

// 任务详情中交付物删除
export const deleteDeliverables = (params) => {
  return axiosInstance.post(`/projectFile/deleteProjectFile`, params)
}

// =================================履历变更==================================

export const getResumeChangePage = (params) => {
  return axiosInstance.post(`/projectchangehistory/page`, params)
}

export const addOrUpdateResume = (params) => {
  return axiosInstance.post(`/projectchangehistory/addOrUpdate`, params)
}

export const finishResume = (params) => {
  return axiosInstance.post(`/projectchangehistory/update`, params)
}

//履历责任人列表
export const resumeMemberList = (projectId) => {
  return axiosInstance.post(`/projectUser/getProjectUserByProjectIdInManager?projectId=${projectId}`)
}