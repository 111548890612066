/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-08-01 10:09:14
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-21 11:13:43
 */
import { projectPage } from "@/apis/projectCenter/myProject"
import { subjectListApi } from "@/apis/projectCenter/projectManagement"
import OperateButton from "@/components/OperateButton"
import { Input, Modal, Pagination, Select, Table, Tooltip, message } from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { myExam, myExamProjectList } from "../../apis/projectCenter/myExam"
import AnswerDetails from "../Management/components/AnswerDetails"
import "./index.scss"
const { Search } = Input
const { Option } = Select

function MyExam() {
  const [filterParams, setFilterParams] = useState({
    projectId: null, //项目
    subjectId: null, //科目
    sortType: null,
    sortField: null
  }) // eslint-disable-line
  const [projectList, setProjectList] = useState([]) //项目下拉框数据
  const [subjectList, setSubjectList] = useState([]) //科目下拉数据
  const [subject, setSubject] = useState(null) //科目选中
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState([]) //表格数据
  const [dialogData, setDialogData] = useState({
    title: "选择考试人员",
    data: {},
    type: "ADD",
    projectId: undefined
  }) //弹窗数据
  const [isModalOpen, setIsModalOpen] = useState(false) //弹窗展示
  const columns = [
    {
      title: "项目",
      dataIndex: "projectName",
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "科目",
      dataIndex: "subjectName",
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return <span>{text}</span>
      }
    },
    {
      title: "发卷时间",
      dataIndex: "createTime",
      width: "200px",
      sorter: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--"
      }
    },
    {
      title: "最后交卷时间",
      dataIndex: "finalSubmissionTime",
      width: "200px",
      sorter: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--"
      }
    },
    {
      title: "答题次数",
      dataIndex: "answersNumber",
      ellipsis: {
        showTitle: false
      },
      render: (text, record) => {
        return text > 0 ? (
          <span
            style={{ color: "#3F83F8", cursor: "pointer" }}
            onClick={() => handlerView(record)}
          >
            {text}
          </span>
        ) : (
          text
        )
      }
    },

    {
      title: "最终得分",
      dataIndex: "finalScore",
      ellipsis: {
        showTitle: false
      },
      render: (text, record) => {
        return text !== null && text >= 0 ? (
          <span
            style={{
              color:
                record.finalScore >= record.passLine ? "#27AE60" : "#F5222D"
            }}
          >
            {record.finalScore >= record.passLine
              ? text + " (合格)"
              : text + " (不合格)"}
          </span>
        ) : (
          "--"
        )
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 100,
      render: (text, record) => (
        // eslint-disable-next-line
        <div className="operation">
          {record.answersNumber === 0 && (
            <OperateButton
              text="去考试"
              icon="icon-pencil"
              data={record}
              click={() => handleAgain(record)}
            />
          )}
          {record.answersNumber !== 0 &&
            record.finalScore < record.passLine &&
            record.isRestart && (
              <OperateButton
                text="重考"
                data={record}
                icon="icon-switch-horizontal"
                click={() => handleAgain(record)}
              />
            )}
        </div>
      )
    }
  ]

  useEffect(() => {
    getProjectPage()
    getSubjectList("")
  }, [])

  useEffect(() => {
    getMyExam()
  }, [page, size])

  /**
   * @description: 获取所有项目
   * @return {*}
   * @author: LINA.QI
   */
  const getProjectPage = () => {
    myExamProjectList()
      .then((res) => {
        setProjectList(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 获取科目下拉
   * @return {*}
   * @author: LINA.QI
   */
  const getSubjectList = (id) => {
    subjectListApi(id, true)
      .then((res) => {
        setSubjectList(res)
      })
      .catch((e) => message.error(e))
  }

  const getMyExam = () => {
    const params = {
      data: filterParams,
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    myExam(params)
      .then((res) => {
        setDataSource(res.list)
        setTotal(res.total)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description:切换项目
   * @param {*} params
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeProject = (params) => {
    filterParams.projectId = params
    filterParams.subjectId = null
    setSubject(null)
    setPage(1)
    getSubjectList(params ? params : "")
    getMyExam()
  }

  /**
   * @description: 切换科目
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeSubject = (value, option) => {
    filterParams.subjectId = value ? value : null
    setSubject(option ? option.children : null)
    setPage(1)
    getMyExam()
  }

  /**
   * @description: 排序
   * @return {*}
   * @author: LINA.QI
   */
  const handleSort = (electedRowKeys, selectedRows, info) => {
    console.log(info)
    filterParams.sortType =
      info.order === "ascend" ? "asc" : info.order === "descend" ? "desc" : null
    filterParams.sortField =
      info.field === "createTime"
        ? "create_time"
        : !info.order
          ? null
          : "final_submission_time"
    setPage(1)
    getMyExam()
  }

  /**
   * @description: 查看答题明细
   * @param {*} record
   * @return {*}
   * @author: LINA.QI
   */
  const handlerView = (record) => {
    setDialogData({
      title: "答题明细",
      data: record,
      type: "ADD"
    })
    setIsModalOpen(true)
  }
  /**
   * @description:关闭弹窗
   * @return {*}
   * @author: LINA.QI
   */
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  /**
   * @description: 重考/去考试
   * @return {*}
   * @author: LINA.QI
   */
  let navigate = useNavigate()
  const handleAgain = (data) => {
    navigate(`/examination?pageId=${data.id}&subjectId=${data.subjectId}`, {
      state: {
        pageId: data.id,
        subjectId: data.subjectId
      }
    })
  }

  return (
    <>
      <div className="my-exam">
        <div className="exam-title">
          <i className="iconfont icon-kaoshijilu"></i>
          考试记录
        </div>
        <div className="form-container">
          <Select
            showSearch
            allowClear
            placeholder="请选择项目"
            optionFilterProp="children"
            onChange={handleChangeProject}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {projectList.map((item, index) => (
              <Option key={item.id} value={item.id}>
                {item.projectName}
              </Option>
            ))}
          </Select>
          <Select
            showSearch
            allowClear
            placeholder="请选择科目"
            optionFilterProp="children"
            onChange={handleChangeSubject}
            value={subject}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {subjectList.map((item, index) => (
              <Option key={item.id} value={item.id}>
                {item.subjectName}
              </Option>
            ))}
          </Select>
        </div>
        <Table
          style={{ margin: "15px 0 10px 0" }}
          size="middle"
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          onChange={handleSort}
        />
        <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />
        <Modal
          title={dialogData.title}
          wrapClassName="custom-modal"
          open={isModalOpen}
          maskClosable={false}
          footer={null}
          onCancel={handleCloseModal}
          width={700}
          destroyOnClose={true}
        >
          <AnswerDetails
            data={dialogData}
            isOpen={isModalOpen}
            cancelClick={handleCloseModal}
          />
        </Modal>
      </div>
    </>
  )
}
export default MyExam
