import { addStage, dictionariesList, fileAllSelect, selectRoleList, stageNode } from "@/apis/systemMgmt/SOPManagement";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Button,
  Form, message, Modal, Table
} from "antd";
import React, { useEffect, useRef, useState } from 'react';
import SopFirstChildren from './SopFirstChildren';
import SopSecondChildren from "./SopSecondChildren";



const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 999,
      }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <i
                className="iconfont icon-menu-alt-2"
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};
const SopTabs = (props) => {
  //mesStatus当前数据的状态，2草稿，1已停用
  const { SOPId, activeKey , tabsStatus, mesStatus, handleEditStatus, handleTab } = props;
  
  const [dataSource, setDataSource] = useState([]);
  const dataRef = useRef();
  const [ nodeIds , setNodeIds] = useState([]);
  const nodeIdsRef = useRef();
  const [ stageIds , setStageIds] = useState([]);
  const stageIdsRef = useRef();
  const [modelTitle, setModalTitle] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modelComName, setModalComName] = useState('');
  const [modelObj, setModalObj] = useState({});
  const [secondStatus, setSecondStatus] = useState('');
  const [loading , setLoading] = useState(false);
  const [tableLoading , setTableLoading] = useState(false);
  const [autoStatus , setAutoStatus] = useState(false);

  const [roleList, setRoleList] = useState([]);
  const [busList, setBusList] = useState([]);
  const [nodeList , setNodeList] = useState([]);
  /**
     * @description: 获取角色list
     * @return {*}
     * @Author: gn
     */
  function getSelectRoleList() {
    selectRoleList().then(res => {
      setRoleList(res);
    }).catch(err => message.error(err));
  };
  /**
   * @description: 获取业务系统list
   * @return {*}
   * @Author: gn
   */
  function getBusinessList() {
    const params = {
      categoryCode: 'business_system',
    };
    dictionariesList(params).then(res => {
      setBusList(res);
    }).catch(err => message.error(err));
  };
  /**
   * @description: 获取所有节点输入/输出下拉框
   * @return {*}
   * @Author: gn
   */  
  function getFileAllSelect(){
    const params = {
      id : SOPId,
    };
    fileAllSelect(params).then(res=>{
      setNodeList(res);
    }).catch(err=>message.error(err));
  };
  /**
   * @description: 获取sop节点详情
   * @return {*}
   * @Author: gn
   */
  function getStageNode() {
    setDataSource([]);
    setTableLoading(true);
    stageNode(SOPId).then(res => {
      setDataSource(res);
      setTableLoading(false);
    }).catch(err => {message.error(err);setTableLoading(false)});
  };
  useEffect(() => {
    getStageNode();
    getSelectRoleList();
    getBusinessList();
  }, [SOPId])
  
  useEffect(()=>{
    getFileAllSelect();
  } , [modalOpen])

  useEffect(()=>{
    dataRef.current = dataSource;
  } , [dataSource]);

  useEffect(()=>{
    nodeIdsRef.current = nodeIds;
  } , [nodeIds]);

  useEffect(()=>{
    stageIdsRef.current = stageIds;
  } , [stageIds]);

  useEffect(()=>{
    if(activeKey === '3'){
      setAutoStatus(true);
      getStageNode();
    }
    if(activeKey !== '3' && tabsStatus !== 'view' && autoStatus){
      setAutoStatus(false);
      AutoSub();
    }
  },[activeKey]);

  useEffect(()=>{
    if(tabsStatus !== 'view'){
      const timer = setInterval(()=>{
        AutoSub();
      } , 300 * 1000);
      return ()=>{
        clearInterval(timer);
        AutoSub();
      }
    }
  } , [])
  /**
   * @description: 一级拖拽
   * @param {*} active
   * @param {*} over
   * @return {*}
   * @Author: gn
   */
  const onDragEnd = ({ active, over }) => {
    console.log(active, over);
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };
  /**
   * @description: 二级的拖拽
   * @param {*} active
   * @param {*} over
   * @return {*}
   * @Author: gn
   */
  const onDragEndChildren = ({ active, over }) => {
    // console.log(active , over);
    // console.log(arrayMove);
    if (active.id !== over?.id) {
      let arrData = [...dataSource];
      for (let n = 0; n < arrData.length; n++) {
        let sopNodeAddBos = arrData[n].sopNodeAddBos;
        if (sopNodeAddBos.findIndex((i) => i.id === active.id) !== -1) {
          const activeIndex = sopNodeAddBos.findIndex((i) => i.id === active.id);
          const overIndex = sopNodeAddBos.findIndex((i) => i.id === over?.id);
          arrData[n].sopNodeAddBos = arrayMove(sopNodeAddBos, activeIndex, overIndex);
        }
      };
      setDataSource(arrData);
    }
  };
  /**
   * @description: 子表格
   * @param {*} record
   * @return {*}
   * @Author: gn
   */
  const expandedRowRender = (record) => {
    // console.log(record);
    // console.log(dataSource);
    const columns = tabsStatus === 'view' ? [
      { title: '节点编号', width : 180 , dataIndex: "nodeCode", key: "nodeCode" , render:(text , record)=><span onClick={()=>handleViewSecond(record)} className="mouse" style={{color : '#3F83F8'}}>{text}</span> },
      { title: '节点名称', width : 180 , dataIndex: 'nodeName', key: "nodeName" , render:(text , record)=><span onClick={()=>handleViewSecond(record)} className="mouse" style={{color : '#3F83F8'}}>{text}</span> },
      {
        title: '节点输入', width : 240 , dataIndex: 'nodeInput', key: "nodeInput", render: (text) => <span>{text.map((e , i) => {
          return <div>{i+1}、{ nodeList.filter(v=>v.id === e).length > 0 ? nodeList.filter(v=>v.id === e)[0].fileName : e}</div>
        })}</span>,
      },
      {
        title: '节点输出', width : 240 , dataIndex: 'nodeOutput', key: "nodeOutput", render: (text) => <span>{text.map((e , i) => {
          return <div>{i+1}、{ nodeList.filter(v=>v.id === e).length > 0 ? nodeList.filter(v=>v.id === e)[0].fileName : e}</div>
        })}</span>,
      },
      {
        title: '责任角色', dataIndex: 'systemRoleId', key: "systemRoleId", render: (text, second) => <span>{text.split(",").map(p => {
          let role = roleList.filter(v => v.id === p).length > 0 ? roleList.filter(v => v.id === p)[0] : undefined
          return role && role.roleName
        }).join(",")}</span>,
      },] : [
      {
        key: 'sort',
        width: 50,
        sortIcon : ()=>{
          return 'nihao'
        }
      },
      { title: '节点编号', width : 180 , dataIndex: "nodeCode", key: "nodeCode" },
      { title: '节点名称', width : 180 , dataIndex: 'nodeName', key: "nodeName" },
      {
        title: '节点输入', width : 240 , dataIndex: 'nodeInput', key: "nodeInput", render: (text) => <span>{text.map((e , i) => {
          return <div>{i+1}、{ nodeList.filter(v=>v.id === e).length > 0 ? nodeList.filter(v=>v.id === e)[0].fileName : e}</div>
        })}</span>,
      },
      {
        title: '节点输出', width : 240 , dataIndex: 'nodeOutput', key: "nodeOutput", render: (text) => <span>{text.map((e , i) => {
          return <div>{i+1}、{ nodeList.filter(v=>v.id === e).length > 0 ? nodeList.filter(v=>v.id === e)[0].fileName : e}</div>
        })}</span>,
      },
      {
        title: '责任角色', dataIndex: 'systemRoleId', key: "systemRoleId", render: (text, second) => <span>{text.split(",").map(p => {
          let role = roleList.filter(v => v.id === p).length > 0 ? roleList.filter(v => v.id === p)[0] : undefined
          return role && role.roleName
        }).join(",")}</span>,
      },
      {
        title: '操作', dataIndex: 'operation',width:'92px', render: (text, second) => (
          <>
            <i className='iconfont mouse icon-pencil' onClick={() => handleEditSecond(second)} style={{ color: '#3F83F8', marginRight: '15px' }}></i>
            <i className='iconfont mouse icon-minus-circle' onClick={() => handleDeleteSecond(second)} style={{ color: '#3F83F8' }}></i>
            {/* <OperateButton
                text="编辑"
                icon="icon-pencil"
                data={record}
                click={()=>handleAddChildren(record)}
            />
            <OperateButton
                text="添加"
                icon="icon-plus-circle"
                data={record}
                click={()=>handleDelete(record)}
            /> */}
          </>
        ),
      }
    ];

    return (
      <>
        {tabsStatus === 'view' ?
          <Table
            size="middle"
            // className="custom-table" 
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={record.sopNodeAddBos}
            pagination={false} />
          :
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEndChildren}>
            <SortableContext
              // rowKey array
              items={record.sopNodeAddBos.map((i) => i.id)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                size="middle"
                // className="custom-table" 
                components={{ body: { row: Row, }, }}
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={record.sopNodeAddBos}
                pagination={false} />
            </SortableContext>
          </DndContext>}
      </>

    )

  };
  /**
   * @description: 子表格展开icon
   * @return {*}
   * @Author: gn
   */  
  const expandIcon = ({ expanded, onExpand, record })=>{
    console.log(111 , expanded , onExpand , record)
    expanded ? (
      // <i className="iconfont mouse icon-pencil" onClick={e => onExpand(record, e)}></i>
      // <span>111</span>
      <PlusCircleTwoTone/>
    ) : (
      // <i className="iconfont icon-chevron-up" onClick={e => onExpand(record, e)}></i>
      <MinusCircleTwoTone/>
    )
  }
  /**
   * @description: 新增一级tables
   * @return {*}
   * @Author: gn
   */
  function handleAddFirst() {
    const json = {
      id: '',
      sopBasicInfoId: SOPId,
      stageDesc: '',
      stageName: '',
      updateOrAdd: 1,
      sopNodeAddBos: [],
    };
    setModalObj(json);
    setModalOpen(true);
    setModalTitle('添加/编辑阶段');
    setModalComName('SopFirstChildren');
  };
  /**
   * @description: 编辑一级
   * @return {*}
   * @Author: gn
   */
  function handleEditFirst(record) {
    setModalObj(record);
    setModalOpen(true);
    setModalTitle('添加/编辑阶段');
    setModalComName('SopFirstChildren');
  };
  /**
   * @description: 新增二级tables
   * @return {*}
   * @Author: gn
   */
  function handleAddChildren(record) {
    const json = {
      id: null,
      sopStageId: record.id,
      nodeName: '',
      nodeCode: '',
      nodeDesc: '',
      systemRoleId: undefined,
      nodeInput: [],
      nodeOutput: [],
      sopBasicInfoId: SOPId,
      businessSystems: null,
    };
    setModalObj(json);
    setModalOpen(true);
    setModalTitle('添加/编辑SOP节点');
    setModalComName('SopSecondChildren');
    setSecondStatus('add');
  };
  /**
   * @description: 删除一级
   * @return {*}
   * @Author: gn
   */
  function handleDeleteFirst(record) {
    let json = [...dataSource];
    let arr = [...stageIds];
    let nodeArr = [...nodeIds];
    json.forEach((e, i) => {
      if (e.id === record.id) {
        arr.push(e.id);
        json.splice(i, 1);
        if(e.sopNodeAddBos && e.sopNodeAddBos.length > 0){
          e.sopNodeAddBos.forEach(no=>{
            nodeArr.push(no.id);
          })
        }
      };
    });
    setDataSource(json);
    setStageIds(arr);
    setNodeIds(nodeArr);
  };
  /**
   * @description: first阶段的新增数据
   * @return {*}
   * @Author: gn
   */
  function handlePushFirst(value) {
    let json = [...dataSource];
    json.push(value);
    setDataSource(json);
  };
  /**
   * @description: 编辑first阶段的数据
   * @return {*}
   * @Author: gn
   */
  function handleReplaceFirst(value) {
    // console.log(value);
    let json = [...dataSource];
    json.forEach((e, i) => {
      if (e.id === value.id) {
        json.splice(i, 1, value);
      };
    });
    setDataSource(json);
  };
  /**
   * @description: 唤醒二级弹窗
   * @return {*}
   * @Author: gn
   */
  function handleEditSecond(record) {
    // console.log(record);
    let data = {
      ...record,
      systemRoleId: record.systemRoleId.split(",")
    }
    setModalObj(data);
    setModalOpen(true);
    setModalTitle('添加/编辑SOP节点');
    setModalComName('SopSecondChildren');
    setSecondStatus('edit');
  };
  /**
   * @description: 查看二级
   * @return {*}
   * @Author: gn
   */  
  function handleViewSecond(record){
    setModalObj(record);
    setModalOpen(true);
    setModalTitle('查看SOP节点');
    setModalComName('SopSecondChildren');
    setSecondStatus('view');
  };
  /**
   * @description: 删除二级
   * @return {*}
   * @Author: gn
   */
  function handleDeleteSecond(val) {
    let arrData = [...dataSource];
    let arr = [...nodeIds];
    for (let i = 0; i < arrData.length; i++) {
      if (arrData[i].id === val.sopStageId) {
        const newArr = [...arrData[i].sopNodeAddBos];
        for (let v = 0; v < newArr.length; v++) {
          if (newArr[v].id === val.id) {
            arr.push(val.id);
            newArr.splice(v, 1);
            break
          }
        }
        arrData[i].sopNodeAddBos = newArr;
        break
      };
    };
    setDataSource(arrData);
    setNodeIds(arr);
  };
  /**
   * @description: 新增二级表单
   * @return {*}
   * @Author: gn
   */
  function handlePushSecond(val) {
    // console.log(val);
    let arrData = [...dataSource];
    for (let i = 0; i < arrData.length; i++) {
      if (arrData[i].id === val.sopStageId) {
        const newArr = [...arrData[i].sopNodeAddBos];
        newArr.push(val);
        arrData[i].sopNodeAddBos = newArr;
      };
    };
    setDataSource(arrData);
  };
  /**
   * @description: 编辑二级表格
   * @return {*}
   * @Author: gn
   */
  function handleReplaceSecond(val) {
    console.log(val);
    let arrData = [...dataSource];
    for (let i = 0; i < arrData.length; i++) {
      if (arrData[i].id === val.sopStageId) {
        const newArr = [...arrData[i].sopNodeAddBos];
        for (let v = 0; v < newArr.length; v++) {
          if (newArr[v].id === val.id) {
            newArr.splice(v, 1, val);
            break
          }
        }
        arrData[i].sopNodeAddBos = newArr;
        break
      };
    };
    setDataSource(arrData);
  };
  /**
   * @description: 关闭弹窗
   * @return {*}
   * @Author: gn
   */
  function hanleCloseModel() {
    setModalOpen(false);
  };
  /**
   * @description: 提交数据
   * @return {*}
   * @Author: gn
   */
  function handleSub() {
    let list = [...dataSource];
    list.forEach((e, k) => {
      e.updateOrAdd = tabsStatus === 'add' ? 1 : 0;
      e.stageSort = k;
      if (e.sopNodeAddBos.length > 0) {
        let newArr = [...e.sopNodeAddBos];
        newArr.forEach((v, i) => {
          v.nodeSort = i;
        });
      }
    });
    setLoading(true);
    const params = {
      list : list ,
      nodeIds : nodeIds,
      stageIds : stageIds,
    }
    addStage(params).then(res => {
      setLoading(false);
      message.success('保存成功');
      getStageNode();
    }).catch(err => { console.log(err) ; setLoading(false) });
  };
  /**
   * @description: 自动保存
   * @return {*}
   * @Author: gn
   */  
  function AutoSub(){
    // console.log(dataRef.current)
    let list = [...dataRef.current];
    list.forEach((e, k) => {
      e.updateOrAdd = tabsStatus === 'add' ? 1 : 0;
      e.stageSort = k;
      if (e.sopNodeAddBos.length > 0) {
        let newArr = [...e.sopNodeAddBos];
        newArr.forEach((v, i) => {
          v.nodeSort = i;
        });
      }
    });
    const params = {
      list : list ,
      nodeIds : nodeIdsRef.current,
      stageIds : stageIdsRef.current,
    }
    addStage(params).then(res => {
      getStageNode();
    }).catch(err => {});
  };
  /**
   * @description: 切换tabs
   * @return {*}
   * @Author: gn
   */  
  // const handleTab = (e)=>{

  // };

  const columns = tabsStatus === 'view' ? [
    {
      title: '阶段名称',
      width:'180px',
      dataIndex: 'stageName',
    },
    {
      title: '阶段描述',
      dataIndex: 'stageDesc',
    },
  ] : [
    {
      key: 'sort',
      width: 50,
    },
    {
      title: '阶段名称',
      width:'180px',
      dataIndex: 'stageName',
    },
    {
      title: '阶段描述',
      dataIndex: 'stageDesc',
    },
    {
      title: <i onClick={handleAddFirst} className='mouse iconfont icon-plus-circle'></i>,
      dataIndex: "operation",
      align: 'center',
      width:'100px',
      render: (text, record) => (
        <>
          <i className='iconfont mouse icon-pencil' onClick={() => handleEditFirst(record)} style={{ color: '#3F83F8', marginRight: '15px' }}></i>
          <i className='iconfont mouse icon-plus-circle' onClick={() => handleAddChildren(record)} style={{ color: '#3F83F8', marginRight: '15px' }}></i>
          <i className='iconfont mouse icon-minus-circle' onClick={() => handleDeleteFirst(record)} style={{ color: '#3F83F8' }}></i>
          {/* <OperateButton
                text="编辑"
                icon="icon-pencil"
                data={record}
                click={()=>handleAddChildren(record)}
            />
            <OperateButton
                text="添加"
                icon="icon-plus-circle"
                data={record}
                click={()=>handleDelete(record)}
            /> */}
        </>
      ),
    },
  ];
  return (
    <>
      {tabsStatus === 'view' ?
        <Table
          key={dataSource}
          loading={tableLoading}
          expandable={{
            // defaultExpandedRowKeys: [ (dataSource.length > 0 && dataSource[0].id) ,],
            defaultExpandAllRows: true,
            expandedRowRender,
            expandIcon : ({ expanded, onExpand, record })=>
              expanded ? (
                <i className="iconfont mouse icon-chevron-up" onClick={e => onExpand(record, e)}></i>
              ) : (
                <i className="iconfont icon-chevron-down" onClick={e => onExpand(record, e)}></i>
              )
          }}
          rowClassName={'dnd_first_row'}
          style={{ marginBottom: "10px" }}
          size="small"
          // className="custom-table"
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        /> :
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={dataSource.map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              key={dataSource}
              loading={tableLoading}
              components={{
                body: {
                  row: Row,
                },
              }}
              expandable={{
                defaultExpandAllRows: true,
                expandedRowRender,
                expandIcon : ({ expanded, onExpand, record })=>
                  expanded ? (
                    <i className="iconfont mouse icon-chevron-up" onClick={e => onExpand(record, e)}></i>
                  ) : (
                    <i className="iconfont icon-chevron-down" onClick={e => onExpand(record, e)}></i>
                  )
              }}
              rowClassName={'dnd_first_row'}
              style={{ marginBottom: "10px" }}
              size="small"
              // className="custom-table"
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </SortableContext>
        </DndContext>
      }
      {
        tabsStatus === 'view' ?
          <Form.Item style={{marginTop : '25px',marginBottom:'0px'}}>
            <Button style={{ marginRight: '8px' }} onClick={handleEditStatus}>
              <i className="iconfont icon-reply" style={{ fontSize: '14px', marginRight: '2px' }}></i>返回
            </Button>
          </Form.Item>
          :
          <Form.Item style={{marginTop : '25px',marginBottom:'0px'}}>
            <Button style={{ marginRight: '8px' }} onClick={handleEditStatus}>
              <i className="iconfont icon-x" style={{ fontSize: '14px', marginRight: '2px' }}></i>取消
            </Button>
            <Button type="primary" loading={loading} onClick={handleSub}>
              <i className="iconfont icon-baocun" style={{ fontSize: '14px', marginRight: '2px' }}></i>保存
            </Button>
          </Form.Item>
      }
      <Modal
        width="520px"
        destroyOnClose={true}
        className='custom-modal'
        maskClosable={false}
        title={modelTitle}
        open={modalOpen}
        footer={null}
        onCancel={hanleCloseModel}
      >
        {
          modelComName === 'SopFirstChildren' ?
            <SopFirstChildren modelObj={modelObj} handlePushFirst={handlePushFirst} handleReplaceFirst={handleReplaceFirst} hanleCloseModel={hanleCloseModel} />
            :
            <SopSecondChildren SOPId={SOPId} roleList={roleList} busList={busList} secondStatus={secondStatus} modelObj={modelObj} handlePushSecond={handlePushSecond} handleReplaceSecond={handleReplaceSecond} hanleCloseModel={hanleCloseModel} />
        }
      </Modal>
    </>
  );
};
export default SopTabs;