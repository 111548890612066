/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-09 17:30:17
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-01 10:10:35
 * @FilePath: /pms-web/src/containers/ProjectBoard/projectList.js
 * @Description: 项目看板-项目列表页
 */
import { BOARD_PROJECT_LIST_FILTER } from "@/store/constant"
import eventBus from "@/utils/commonFunc/eventBus"
import readSession from "@/utils/commonFunc/readSession"
import removeSession from '@/utils/commonFunc/removeSession'
import writeSession from '@/utils/commonFunc/writeSession'
import { Form, Input, message, Pagination, Select, Table } from "antd"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import {
  getAllSop,
  getProjectList,
  getStageById,
  getStatusSelectList
} from "../../apis/operationAnalysis"
import OperateButton from "../../components/OperateButton"
import ResizableTable from "@/components/ResizableTable"

const { Option } = Select
const { Search } = Input

function ProjectList() {
  const { boardProjectListFilter } = useSelector((state) => state.appReducer)
  const [dataSource, setDataSource] = useState([])
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  // const { isGantt } = useParams()

  // 项目状态选择列表
  const [projectStatusList, setProjectStatusList] = useState([])
  // 项目类型选择列表
  const [projectTypeList, setProjectTypeList] = useState([])
  // 项目进度选择列表
  const [projectProgressList, setProjectProgressList] = useState([])
  // 是否首次执行
  const [isFirstRun, setIsFirstRun] = useState(true)
  const navigate = useNavigate()
  const currentLoacation = useLocation()
  const formRef = useRef()
  const [filterParams, setFilterParams] = useState({
    nameOrCode: null,
    progress: null,
    status: null,
    rcode: null
  })
  /**
   *
   * @description 查询列表方法
   */
  const queryList = () => {
    let { status, rcode, progress, nameOrCode } = filterParams
    if (status === 999) {
      status = null
    }
    const params = {
      data: {
        nameOrCode: nameOrCode || null,
        progress: progress || null,
        status: status || null,
        rcode: rcode || null
      },
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    getProjectList(params)
      .then((res) => {
        setDataSource(res.list)
        setTotal(res.total)
      })
      .catch((e) => message.error(e))
  }

  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  useEffect(() => {
    let { status, projectType, stageId, isGantt } =
      (currentLoacation?.state || readSession("boardParams") || {})
    const promise1 = getStatusSelectList({
      categoryCode: "project_status_code"
    })
    const promise2 = getAllSop()
    const promiseArr = [promise1, promise2]
    if (projectType) {
      console.log("projectType", projectType)
      const promise3 = getStageById({ id: projectType })
      promiseArr.push(promise3)
    }
    Promise.all(promiseArr).then((results) => {
      if (results.at(0)) {
        const statusList = results[0]
          ?.map((item, index) => {
            const { dictDesc, dictCode } = item
            return {
              name: dictDesc,
              value: dictCode
            }
          })
          .filter((item) => {
            return item.value !== 999
          })
        setProjectStatusList(statusList)
      }

      if (results.at(1)) {
        const typeList = results[1]?.map((item, index) => {
          const { rCode, sopName } = item
          return {
            name: sopName,
            value: rCode
          }
        })
        setProjectTypeList(typeList)
      }
      if (results.at(2)) {
        const stagelist = results[2].map((item) => {
          return {
            name: item.stageName,
            value: item.id
          }
        })
        setProjectProgressList(stagelist)
      }
      if (status === 999) {
        status = null
      }
      // 甘特图页面面包屑跳转
      if (isGantt) {
        form.setFieldsValue({
          ...boardProjectListFilter[0]
        })
        setFilterParams({
          ...boardProjectListFilter[0]
        })
      } else {
        // 项目面板跳转-条件回显
        console.log("rcode", projectType)
        form.setFieldsValue({
          status: !status && status !== 0 ? null : status + "",
          rcode: projectType || null,
          progress: stageId || null
        })
        setFilterParams({
          ...filterParams,
          status: !status && status !== 0 ? null : status + "",
          rcode: projectType || null,
          progress: stageId || null
        })
      }
      setIsFirstRun(false)
    })
  }, [currentLoacation])

  useEffect(() => {
    if (!isFirstRun) {
      queryList()
    }
  }, [filterParams, page, size])

  // 面包屑点击传来的值以及方法
  const breadcrumbItemClickEventsFn = useCallback(
    (item) => {
      switch (item) {
        case "项目看板":
          eventBus.emit("setBreadcrumbData", ["运营分析", "项目看板"])
          navigate("/operations-analysis/board")
          break
        default:
          return
      }
    },
    [navigate]
  )

  useEffect(() => {
    console.log("breadcrumbItemClickEvents")
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    )
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      )
    }
  }, [breadcrumbItemClickEventsFn])

  // 选择状态
  const handleStatusChange = (status) => {
    writeSession('boardParams', {
      ...readSession('boardParams'),
      status
    })
    setPage(1)
    setFilterParams({
      ...filterParams,
      status
    })
  }

  // 选择项目类型
  const handleTypeChange = async (rcode) => {
    console.log(rcode, filterParams);
    writeSession('boardParams', {
      ...readSession('boardParams'),
      projectType: rcode
    })
    if (rcode) {
      getStageDataById(rcode)
    }
    if (rcode === null || rcode === undefined) {
      form.setFieldsValue({
        progress: null,
        rcode: null
      })
      setPage(1)
      setFilterParams({
        ...filterParams,
        progress: null,
        rcode: null
      })
      return
    }
    if (rcode && filterParams.rcode && rcode != filterParams.rcode) {
      form.setFieldsValue({
        progress: null
      })
      setPage(1)
      setFilterParams({
        ...filterParams,
        rcode,
        progress: null
      })
      return
    }
    setPage(1)
    setFilterParams({
      ...filterParams,
      rcode
    })
  }

  // 根据项目类型获取阶段列表数据
  const getStageDataById = async (id) => {
    const result = await getStageById({ id })
    const stageList = result.map((item) => {
      return {
        name: item.stageName,
        value: item.id
      }
    })
    setProjectProgressList(stageList)
  }

  // 选择进度
  const onProgressChange = (progress) => {
    writeSession('boardParams', {
      ...readSession('boardParams'),
      stageId: progress
    })
    setPage(1)
    setFilterParams({
      ...filterParams,
      progress
    })
  }

  const columns = [
    {
      title: "项目编号",
      dataIndex: "projectCode",
      width: 180,
      ellipsis: true,
      fixed: "left"
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      width: 180,
      ellipsis: true
    },
    {
      title: "项目类型",
      dataIndex: "sopName",
      width: 180,
      ellipsis: true
    },
    {
      title: "项目状态",
      dataIndex: "status",
      width: 180,
      ellipsis: true,
      render: (_, record) => {
        let customStatus
        switch (record.statusName) {
          case "进行中已延期":
            customStatus = 2
            break
          case "进行中":
            customStatus = 1
            break
          case "未开始":
            customStatus = 0
            break
          case "已结项":
            customStatus = 4
            break
          case "已关闭":
            customStatus = 5
            break
          default:
            customStatus = 3
        }
        const styleName = "projectStatus" + customStatus
        return (
          <div
            className={styleName}
            style={{
              display: "inline-block",
              paddingLeft: 8,
              paddingRight: 8,
              height: 20
            }}
          >
            {record.statusName}
          </div>
        )
      }
    },
    {
      title: "项目进度",
      dataIndex: "projectProgress",
      width: 180,
      ellipsis: true,
      render: (text) => {
        return <div>{text ? text : "--"}</div>
      }
    },
    {
      title: "项目负责人",
      dataIndex: "managerName",
      width: 180,
      ellipsis: true,
      render: (text) => {
        return <div>{text ? text : "--"}</div>
      }
    },
    {
      title: "操作",
      width: 104,
      dataIndex: "operation",
      fixed: "right",
      render: (text, record) => (
        <div className="operation">
          <OperateButton
            text="进入"
            icon="icon-pencil"
            click={() => goDetail(record)}
            data={record}
          />
        </div>
      )
    }
  ]

  useEffect(() => {
    setTimeout(() => {
      eventBus.emit("setBreadcrumbData", ["运营分析", "项目看板", "项目列表"])
    }, 200)
  }, [])

  const goDetail = (record) => {
    // let projectType = currentLoacation?.state && currentLoacation?.state.projectType && currentLoacation?.state.projectType != "undefined" ? currentLoacation?.state.projectType : filterParams.rcode
    setTimeout(() => {
      eventBus.emit("setBreadcrumbData", ["项目空间", "项目总览"])
    }, 100)
    writeSession("sessionProjectId", record.id)
    writeSession("sessionProjectName", record.projectName)
    writeSession("sessionStatus", record.status)
    removeSession("stageVersionId")
    writeSession("sessionNodeData", null)
    writeSession("beforeRouter", "/operations-analysis/board/project-list")
    eventBus.emit("setMenuBoolean", true)
    removeSession("sessionStageId")
    dispatch({
      type: BOARD_PROJECT_LIST_FILTER,
      payload: [form.getFieldsValue()]
    })
    navigate("/project-space/overview")
    // navigate(
    //   `/operations-analysis/board/project-list/project-detail?projectId=${record.id}&projectType=${projectType}`
    // )
  }
  // 输入搜索e
  const onSearch = (value) => {
    writeSession('boardParams', {
      ...readSession('boardParams'),
      nameOrCode: value
    })
    setPage(1)
    setFilterParams({
      ...filterParams,
      nameOrCode: value
    })
  }

  return (
    <>
      <div className="page-container">
        <div className="form-container">
          <Form
            form={form}
            layout="inline"
            colon={false}
            ref={formRef}
            className="custom-form project-board-list-form"
          >
            <Form.Item
              name="status"
              label="项目状态:"
              style={{ marginRight: 0 }}
            >
              <Select
                onChange={handleStatusChange}
                style={{ width: 108 }}
                placeholder="选择状态"
                onClear={handleStatusChange}
                allowClear
              >
                {projectStatusList.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="rcode" label="项目类型:">
              <Select
                showSearch
                filterOption={false}
                allowClear
                style={{ width: 108 }}
                placeholder="选择类型"
                onChange={handleTypeChange}
              >
                {projectTypeList.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="progress"
              rules={[
                {
                  max: 30
                }
              ]}
              label="项目进度"
            >
              <Select
                style={{
                  width: 108
                }}
                showSearch
                allowClear
                placeholder="选择进度"
                onChange={onProgressChange}
              >
                {projectProgressList.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="nameOrCode">
              <Search
                placeholder="请输入项目编号或项目名称"
                onSearch={onSearch}
                allowClear
                className="custom-channel-search"
              />
            </Form.Item>
          </Form>
        </div>
        <div className="table-container">
          <ResizableTable
            size="small"
            pagination={false}
            columns={columns}
            dataSource={dataSource}
            rowKey={(record) => record.id}
          />
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "40", "50"]}
            showSizeChanger
            showQuickJumper
            className="custom-pagination"
            showTotal={(total) => `共 ${total} 条`}
            onChange={handleChangePagination}
          />
        </div>
      </div>
    </>
  )
}

export default ProjectList
