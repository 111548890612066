import React, { useState } from "react";
import "../index.scss";
import BaseInfo from "./baseInfo";
import PlacementPosture from "./placementPosture";
import ProductComponent from "./productComponent";


const ProductDetail = (props) => {
  const [activeTab, setActiveTab] = useState('baseInfo')
  const tabList = [
    {
      key: "baseInfo",
      label: `基本信息`
    },
    {
      key: "placementPosture",
      label: `摆放位姿`
    },
    {
      key: "productComponent",
      label: `产品组件`
    }
  ]

  const handleChangeTab = (key) => {
    setActiveTab(key)
  }
  
  const switchComponent = () => {
    switch (activeTab) {
      case "baseInfo":
        return <BaseInfo baseData={props.detailData}/>
      case "placementPosture":
        return <PlacementPosture baseData={props.detailData} goBack={props.goBack}/>
      default:
        return <ProductComponent baseData={props.detailData} goBack={props.goBack}/>
    }
  }
  return (
    <>
      <div className="product-detail">
        <div className="schedule">
          <div className="tab-box">
            {tabList.map((item) => (
              <span
                key={item.key}
                className={[
                  "tab-list",
                  activeTab === item.key ? "tab-active" : null,
                ].join(" ")}
                onClick={() => handleChangeTab(item.key)}
              >
                {item.label}
              </span>
            ))}
          </div>
        </div>
        {switchComponent()}
      </div>
    </>
  )
}
export default ProductDetail