import { queryProjectFilePage } from "@/apis/projectFile";
import readSession from "@/utils/commonFunc/readSession";
import { SearchOutlined } from "@ant-design/icons";
import { Form, Input, message, Pagination, Select, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { invalidInput } from "../../utils/commonFunc/invalidInput";
import "./index.scss";
function ProjectOverview() {
  const [dataSource, setDataSource] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const [sorterValue, setSorterValue] = useState(1);
  const { containerHeight } = useSelector((state) => state.appReducer);
  /**
   * @description 点击查询按钮
   */
  const onFinish = (params) => {
    form.validateFields().then((values) => {
      let newData = {
        projectId: readSession("sessionProjectId"),
        ...values,
      };
      setFilterParams(newData);
      setPage(1);
      queryList(newData);
    });
  };
  const fileTypeArr = [
    {
      value: 0,
      label: "执行标准",
    },
    {
      value: 1,
      label: "交付物",
    },
  ];

  /**
   *
   * @description 查询列表方法
   */
  const queryList = (params) => {
    const queryParams = {
      data: { ...params, sortType: sorterValue },
      page: {
        pageIndex: page,
        pageSize: size,
      },
    };
    queryProjectFilePage(queryParams)
      .then((res) => {
        let list = res.list.slice();
        list.map((item, index) => (item.index = index + 1));
        setDataSource(list);
        setTotal(res.total);
      })
      .catch((e) => message.error(e));
  };

  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };

  const tableChange = (selectedRowKeys, selectedRows, info) => {
    setSorterValue(info.order === "ascend" ? 0 : 1);
  };
  useEffect(() => {
    queryList({ ...filterParams, projectId: readSession("sessionProjectId") });
  }, [page, size, sorterValue]);
  const columns = [
    {
      title: "文件编号",
      dataIndex: "fileCode",
      ellipsis: true,
    },
    {
      title: "文件类型",
      dataIndex: "fileType",
      ellipsis: true,
      render: (text, record) => {
        return <div>{record.fileType === "1" ? "交付物" : "执行标准"}</div>;
      },
    },
    {
      title: "文件名",
      dataIndex: "fileName",
      ellipsis: true,
    },

    {
      title: "文件模版",
      dataIndex: "fileTemplate",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            {record.fileTemplate && (
              <a
                target="_blank"
                href={record.fileTemplate}
                className="blue-text"
              >
                {record.fileTemplate}
              </a>
            )}
            {!record.fileTemplate && <div>{"--"}</div>}
          </div>
        );
      },
    },
    {
      title: "交付文件地址",
      dataIndex: "outputUrl",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            {record.outputUrl && (
              <a target="_blank" href={record.outputUrl} className="blue-text">
                {record.outputUrl}
              </a>
            )}
            {!record.outputUrl && <div>{"--"}</div>}
          </div>
        );
      },
    },
    {
      title: "交付人",
      dataIndex: "commitUser",
      ellipsis: true,
    },
    {
      title: "交付时间",
      dataIndex: "commitTime",
      ellipsis: true,
      sorter: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--";
      },
    },
  ];

  return (
    <>
      <div className="project-filemain-page">
        <div className="form-container">
          <Form form={form} layout="inline" onFinish={onFinish} colon={false}>
            <Form.Item name="fileType" label="文件类型：">
              <Select
                style={{
                  width: 109,
                  height: 32,
                }}
                allowClear
                placeholder="请选择项目成员"
                options={fileTypeArr.map((d) => ({
                  label: d.label,
                  value: d.value,
                }))}
                onChange={onFinish}
              ></Select>
            </Form.Item>

            <Form.Item name="codeOrName" label="">
              <Input
                style={{
                  width: 354,
                  height: 32,
                }}
                autoComplete="off"
                placeholder="请输入编号/名称"
                onChange={(e) => {
                  if (invalidInput(e.nativeEvent.data)) {
                    form.setFieldsValue({
                      codeOrName: e.target.value.slice(0, -1),
                    });
                  }
                }}
                suffix={<SearchOutlined onClick={() => onFinish()} />}
              ></Input>
            </Form.Item>
          </Form>
        </div>
        <div className="table-container" style={{ marginTop: 16 }}>
          <Table
            size="middle"
            className="custom-table"
            rowKey={(record) => record.index}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            onChange={tableChange}
          />
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "40", "50"]}
            showSizeChanger
            // showQuickJumper
            className="custom-pagination"
            // showTotal={(total) => `共 ${total} 条`}
            onChange={handleChangePagination}
          />
        </div>
      </div>
    </>
  );
}

export default ProjectOverview;
