/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-08-10 14:56:09
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-08-10 14:58:49
 */
function NotFoundPage() {
  return <div>Not Found</div>;
}

export default NotFoundPage;
