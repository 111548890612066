import { axiosInstance } from "../../config";

//查询设备类目
export const getDeviceCategoryList = () => {
  return axiosInstance.post("/devicecategory/list")
};

//新增设备类目
export const addDeviceCategory = (params) => {
  return axiosInstance.post("/devicecategory/add", params)
};

//删除设备类目
export const deleteDeviceCategory = (id) => {
  return axiosInstance.post(`/devicecategory/delete/${id}`)
};

//设备类型分页查询
export const getDeviceTypeList = (params) => {
  return axiosInstance.post("/devicetype/page", params)
};

//新增设备类型
export const addDeviceType = (params) => {
  return axiosInstance.post("/devicetype/add", params)
};

//修改设备类型
export const updateDeviceType = (params) => {
  return axiosInstance.post("/devicetype/update", params)
};

//配置
export const updateConfig = (params) => {
  return axiosInstance.post("/devicetype/configuration", params)
};

//查看设备配置
export const checkConfig = (id) => {
  return axiosInstance.post(`/devicetype/${id}`)
};

//删除设备类型
export const deleteDeviceType = (id) => {
  return axiosInstance.post(`/devicetype/delete/${id}`)
};


//组件下二级拉树
export const getComponentSecondaryTree = () => {
  return axiosInstance.post("/devicetype/componentTree")
};

//设备类型下拉树
export const getDeviceTypeTree = () => {
  return axiosInstance.post("/devicetype/deviceCategoryList")
};

//设备类型二级下拉树
export const getDeviceTypeSecondaryTree = () => {
  return axiosInstance.post("/devicetype/deviceTree")
};

//设备型号下拉树
export const getDeviceModelTree = (params) => {
  return axiosInstance.post("/devicetype/deviceTypeList",params)
};