/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-07-26 09:07:39
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-08-19 10:30:54
 */
import { Navigate, Route, Routes } from "react-router-dom";
import GuardRouter from "./GuardRouter";

function AppRouter() {
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|avantgo|blackberry|iemobile|ipad|iphone|ipod|opera mini|opera mobi|palm|pocket|psp|series(4|6)0|symbian|windows ce|windows phone|xda|xiino/i.test(userAgent)
  }
  let location = window.location.hash.slice(1)
  // const renderRoutes = (routes) =>
  //   routes.map((route, index) => {
  //     if (route.children && route.children.length) {
  //       const redirectRoute = (
  //         <Route
  //           key={route.path}
  //           path={route.path}
  //           element={<Navigate to={route.redirect} />}
  //         />
  //       );
  //       return [redirectRoute, ...renderRoutes(route.children)];
  //     }
  //     console.log(route);
  //     return (
  //       <Route
  //         key={route.path || index}
  //         path={route.path}
  //         element={
  //           <RouteProtected renderPath={route.path}>
  //             {route.component}
  //           </RouteProtected>
  //         }
  //       />
  //     );
  //   });

  return (
    <Routes>
      {GuardRouter()}
      {!isMobile() && <Route path={location != "my-exam" ? location : undefined} element={<Navigate to={"/my-task"} />} />}
      {!isMobile() && <Route path="my-exam" element={<Navigate to={"/my-exam"} />} />}
      {isMobile() && <Route path="/mobile-workhour" element={<Navigate to={"/mobile-workhour"} />} />}
      {isMobile() && <Route path="/mobile-exam" element={<Navigate to={"/mobile-exam"} />} />}
    </Routes>
  );
  // return <Routes>{renderRoutes([...constantMenu, ...constMenu])}</Routes>;
}

export default AppRouter;
