/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-18 10:05:13
 * @LastEditors: LINA.QI
 * @LastEditTime: 2023-07-04 14:50:10
 */
import {
  changeLeader, projectManager
} from '@/apis/projectCenter/projectManagement';
import { Button, Form, Select, message } from 'antd';
import { useEffect, useState } from 'react';
const { Option } = Select;

function Transfer(props) {
  const { data, confirmClick } = props;
  const [form] = Form.useForm(); //表单
  const layout = {
    labelCol: {
      span: 6,
    },
  };
  const [pmList, setPmList] = useState([]); //项目经理列表
  const [isLoading, setIsLoading] = useState(false);

  const [pmState, setPmState] = useState(null);

  useEffect(() => {
    getProjectManager();
  },[]);// eslint-disable-line

  /**
   * @description: 获取项目经理列表
   * @return {*}
   * @author: LINA.QI
   */
  const getProjectManager = () => {
    projectManager({ username: '' })
      .then((res) => {
        setPmList(res);
      })
      .catch((e) => console.log(e));
  };
  /**
   * @description: 切换项目经理
   * @return {*}
   * @author: LINA.QI
   */
  const handleChange = (data, option) => {
    setPmState(pmList[option.key]);
  };
  /**
   * @description: 保存
   * @return {*}
   * @author: LINA.QI
   */
  const handleSubmit = () => {
    form
      .validateFields()
      .then((res) => {
        setIsLoading(true);
        const params = {
          departmentName: pmState.department,
          projectId: data.projectId,
          userId: pmState.id,
          userName: pmState.name,
        };
        changeLeader(params)
          .then((resp) => {
           message.success('转移成功')
           setIsLoading(false);
           confirmClick();
           props.goBack()
          })
          .catch((e) => {
            setIsLoading(false);
            console.log(e)
          });
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  return (
    <>
      <div className="addProject">
        <Form form={form} {...layout}>
          <Form.Item
            name="userId"
            label="项目经理"
            rules={[{ required: true, message: `请选择项目经理` }]}
          >
            <Select
              style={{ width: '280px' }}
              placeholder="请选择项目经理"
              onChange={handleChange}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {pmList.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name + '(' + item.department + ')'}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="dialog-footer">
        <Button
          className="cancel"
          disabled={isLoading}
          onClick={() => confirmClick()}
        >
          <i className="iconfont icon-x"></i>取消
        </Button>
        <Button
          className="confirm"
          loading={isLoading}
          type="primary"
          onClick={handleSubmit}
        >
          <i className="iconfont icon-baocun"></i>确认
        </Button>
      </div>
    </>
  );
}

export default Transfer;
