/*
 * @Author: gn
 * @Date: 2023-06-15 20:24:22
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-17 17:20:54
 * @Description: file content
 */
import { Button, Form, Input, message, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  addChannel,
  queryDetailById,
  updateChannelDetail,
} from "../../../apis/channelManage";
import { RuleEmail } from "../../../utils/commonFunc/validate";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title}不能为空`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

function BasicTabs({ channelId, handleEditStatus, handelCancel }) {
  const [dataSource, setDataSource] = useState([]);
  const [cellKey, setCellKey] = useState(0);
  const [formBasic] = Form.useForm();

  // 保存
  function onFinish() {
    const params = {
      ...formBasic.getFieldsValue(),
      channelContactList: dataSource,
      id: channelId,
    };
    if (!channelId) {
      addNewChannel(params);
      return;
    }
    let illegality = false;
    for (let dataItem of dataSource) {
      const regPhone = new RegExp(/^1[3456789]\d{9}$/);
      const regName = new RegExp(/^[\u4e00-\u9fa5_0-9a-zA-Z_-]{1,}$/);
      if (!regPhone.test(dataItem.phone)) {
        message.error("联系人手机号格式不合法");
        illegality = true;
        break;
      }
      if (dataItem.name.length > 15) {
        message.error("联系人姓名长度不能超过15个字符");
        illegality = true;
        break;
      }
      if (!regName.test(dataItem.name)) {
        message.error("联系人姓名仅支持数字、大小写字母和下划线");
        illegality = true;
        break;
      }
    }
    if (illegality) {
      return;
    }
    updateChannelDetail(params).then((res) => {
      message.success("编辑成功");
      queryDetail();
    });
  }

  const addNewChannel = (params) => {
    addChannel(params)
      .then((res) => {
        if (res) {
          handleEditStatus(res);
          message.success("新增成功");
          queryDetail();
        }
      })
      .catch((err) => message.error(err));
  };
  const defaultColumns = [
    {
      title: "姓名",
      dataIndex: "name",
      width: "30%",
      editable: true,
    },
    {
      title: "联系电话",
      dataIndex: "phone",
      //   width : '45%',
      editable: true,
    },
    {
      title: (
        <i
          className="iconfont icon-plus-circle mouse"
          onClick={() => onAddTableCell()}
        ></i>
      ),
      dataIndex: "operation",
      width: "10%",
      render: (_, record) => (
        <i
          className="iconfont icon-minus-circle mouse"
          onClick={() => onDeleteTableCell(record)}
          style={{ color: "#3F83F8" }}
        ></i>
      ),
    },
  ];

  useEffect(() => {
    queryDetail();
    // eslint-disable-next-line
  }, []);

  // 查询详情
  const queryDetail = () => {
    if (!channelId) {
      return;
    }
    queryDetailById(channelId).then((result) => {
      const {
        channelContactList,
        channelCode,
        channelName,
        channelContactAddress,
        channelMail,
        channelInformation,
      } = result;
      const dataList = channelContactList?.map((item, index) => {
        const { name, phone } = item;
        return {
          name,
          phone,
          // key: index + name,
        };
      });
      setDataSource(dataList || []);
      formBasic.setFieldsValue({
        channelCode,
        channelName,
        channelMail,
        channelInformation,
        channelContactAddress,
      });
    });
  };

  // 编辑联系人行cell
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  // 新增联系人行
  const onAddTableCell = () => {
    const tableData = [
      ...dataSource,
      {
        name: "",
        phone: "",
        key: cellKey,
      },
    ];
    const nextCellKey = cellKey + 1;
    setCellKey(nextCellKey);
    setDataSource(tableData);
  };

  // 删除联系人行
  const onDeleteTableCell = (record) => {
    const newDataSource = dataSource.filter(
      (item, index) => item.phone !== record.phone
    );
    setDataSource(newDataSource);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div className="basic_tabs">
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        labelCol={{
          span: 4,
        }}
        // wrapperCol={{
        //     span: 16,
        // }}
        form={formBasic}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="channelCode"
          rules={[
            {
              required: true,
              max: 30,
            },
          ]}
          label="渠道代码"
        >
          <Input
            autoComplete="off"
            placeholder="请输入渠道代码"
            disabled={channelId}
          ></Input>
        </Form.Item>
        <Form.Item
          name="channelName"
          rules={[
            {
              required: true,
              max: 30,
            },
          ]}
          label="渠道名称"
        >
          <Input autoComplete="off" placeholder="请输入渠道名称"></Input>
        </Form.Item>
        <Form.Item
          name="channelContactList"
          rules={[
            {
              required: false,
              max: 30,
            },
          ]}
          label="联系人"
        >
          <Table
            className="custom-table"
            components={components}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </Form.Item>
        <Form.Item
          name="channelContactAddress"
          rules={[
            {
              required: false,
              max: 200,
            },
          ]}
          label="联系地址"
        >
          <Input autoComplete="off" placeholder="请输入联系地址"></Input>
        </Form.Item>
        <Form.Item name="channelMail" rules={RuleEmail} label="邮箱">
          <Input autoComplete="off" placeholder="请输入邮箱"></Input>
        </Form.Item>
        <Form.Item
          name="channelInformation"
          rules={[
            {
              required: false,
              // max: 30,
            },
          ]}
          label="资料"
        >
          <Input autoComplete="off" placeholder="请输入资料"></Input>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button style={{ marginRight: "8px" }} onClick={handelCancel}>
            <i
              className="iconfont icon-x"
              style={{ fontSize: "14px", marginRight: "2px" }}
            ></i>
            取消
          </Button>
          <Button type="primary" htmlType="submit">
            <i
              className="iconfont icon-baocun"
              style={{ fontSize: "14px", marginRight: "2px" }}
            ></i>
            保存
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default BasicTabs;
