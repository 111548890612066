/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-08-04 17:47:47
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-08-09 11:20:23
 */

// 需要隐藏的菜单项
const routerHidden = ["/message", "/resource/preview"];
// menuType = 1 带/,menuType = 2 不带/
export function routerFilter(routers, pre = "") {
  pre = pre === "/" ? "" : pre;
  if (routers && routers.length > 0) {
    return routers.map((route) => {
      let fullPath = pre;
      let newRoute = { path: route.path, name: route.name };
      newRoute.meta = { title: route.name, icon: route.icon };
      if (route.path) {
        if (route.menuType === 1) {
          fullPath = `${pre}${route.path}`;
          newRoute.component = "";
          newRoute.route = fullPath;
        } else if (route.menuType === 2) {
          fullPath = `containers${pre}/${route.path}/index`;
          newRoute.component = (resolve) => require([`@/${fullPath}`], resolve);
          newRoute.route = `${pre}/${route.path}`;
          // 处理permissions
          let permissions = [];
          if (route.childList) {
            permissions = route.childList;
          }
          newRoute.meta.permission = permissions;
        }
        if (routerHidden.includes(newRoute.route)) {
          newRoute.hidden = true;
        }
      }
      if (
        route.childList &&
        route.menuType === 1 &&
        route.childList.length > 0
      ) {
        newRoute.children = routerFilter(route.childList, fullPath);
      }
      return newRoute;
    });
  }
  return [];
}

const constMenu = [];

// 扁平化菜单列表
export function flatMenu(source) {
  const fn = (source) => {
    let res = [];
    if (source && source.length) {
      source.forEach((el) => {
        res.push(el);
        el.children && el.children.length > 0 && res.push(...fn(el.children));
      });
    }
    return res;
  };
  return [...fn(source), ...constMenu];
}

export function filterMenu(menuList, source) {
  let finalMenus = menuList.filter((item) => source.includes(item.path));
  finalMenus = finalMenus.map((item) => {
    let newChildren = item.children.filter((child) =>
      source.includes(child.path)
    );
    return { ...item, children: newChildren };
  });
  return finalMenus;
}
