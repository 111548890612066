import { axiosInstance } from "../../config";

//分页查询
export const getOnLineList = (params) => {
  return axiosInstance.post("/deviceonline/page", params)
};

//返库
export const backWarehouse = (id) => {
  return axiosInstance.post(`/deviceonline/inStock/${id}`);
};

//通过id查询
export const getDeviceData = (id) => {
  return axiosInstance.post(`/deviceonline/${id}`);
};

//修改配置
export const updateOnlineDevice = (params) => {
  return axiosInstance.post("/deviceonline/update/configuration", params)
};