import { axiosInstance } from "../config";

// 获取科目列表
export const getSubjectList = (params) => {
  return axiosInstance.post(`/examsubject/page`, params);
};

// 新增科目
export const addSubject = (params) => {
  return axiosInstance.post(`/examsubject/addSubject`,params);
};

// 修改科目
export const updateSubject = (params) => {
  return axiosInstance.post(`/examsubject/updateSubject`, params);
};

// 查询科目
export const getSubject = (id) => {
  return axiosInstance.post(`/examsubject/getById/${id}`);
};

// 删除科目
export const deleteSubject = (id) => {
  return axiosInstance.post(`/examsubject/delete/${id}`);
};

// =================================题目管理==================================

// 获取题目列表
export const getQuestionList = (params) => {
  return axiosInstance.post(`/examsubjectquestions/page`, params);
};

// 新增修改题目
export const addAndUpdateSubject = (params) => {
  return axiosInstance.post(`/examsubjectquestions/addOrUpdateSubjectQuestion`,params);
};

// 查询题目
export const getQuestion = (id) => {
  return axiosInstance.post(`/examsubjectquestions/byId/${id}`);
};

// 删除题目
export const deleteQuestion = (id) => {
  return axiosInstance.post(`/examsubjectquestions/delete/${id}`);
};