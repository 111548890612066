/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-07-30 10:51:27
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-06 14:16:45
 */
import React, { useEffect, useState } from "react"
import { Resizable } from "react-resizable"
import { Tooltip, Table } from "antd"
import "./index.scss"
const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(event) => {
            event.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}

/**
 * ant-table 可伸缩列
 * @param props
 * @returns
 */
const ResizableTable = (props) => {
  const { dataSource } = props
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if (props.columns) {
      setColumns(props.columns)
    }
  }, [dataSource])

  const handleResize =
    (index) =>
    (_, { size }) => {
      const newColumns = [...columns]
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      }
      setColumns([...newColumns])
    }

  const mergeColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width, //?? 80,
      onResize: handleResize(index)
    })
  }))

  return (
    <div className="resizeTable">
      <Table
        {...props}
        // bordered
        components={{
          header: {
            cell: ResizableTitle
          }
        }}
        scroll={{ x: 900 }}
        columns={mergeColumns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default ResizableTable
