/*
 * @Author: pan.aiyan
 * @Date: 2023-06-08 10:18:44
 * @LastEditors: pan.aiyan
 * @LastEditTime: 2023-07-19 17:32:21
 * @Description: file content
 */
import { projectUserLevel } from "@/apis/projectCenter/projectManagement";
import {
  allSelsetData,
  deviceonline,
  downloadToken,
  getSoftVersion,
  projectDevicePage
} from "@/apis/projectOverview";
import ArgTable from "@/containers/EquipmentAssembly/components/ArgTable";
import readSession from "@/utils/commonFunc/readSession";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Pagination,
  Select,
  Table,
  message
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDeviceTypeTree } from "../../apis/deviceManage/type/index";
import OperateButton from "../../components/OperateButton";
import { invalidInput } from "../../utils/commonFunc/invalidInput";
import "./index.scss";

const { Option } = Select;

function ProjectOverview() {
  const [modalTitle, setModalTitle] = useState("");
  const [width, setWidth] = useState(0);
  const [deviceTypeSource, setDeviceTypeSource] = useState([]); //设备类型
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [columnsDetail, setColumnsDetail] = useState([]);
  const [deviceStatusOption, setDeviceStatusOption] = useState([]);
  const [versions, setVersions] = useState({
    softwareVersion: undefined,
    softwareTime: undefined,
    algorithmVersion: undefined,
    algorithmTime: undefined
  });
  const [dataSourceDetail, setDataSourceDetail] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const { containerHeight } = useSelector((state) => state.appReducer);
  const [parameterTitle, setParameterTitle] = useState('')
  const [parameterOpen, setParameterOpen] = useState(false)
  const [parameterData, setParameterData] = useState([])
  const [isMyLiable, setIsMyLiable] = useState(false)
  useEffect(() => {
    queryDeviceTypeList();
    allSelsetDataFn();
    projectUserLevel(readSession("sessionProjectId"))
      .then((res) => {
        setIsMyLiable([1,2].includes(res))
      })
      .catch((e) => console.log(e))
  }, []);

  /**
   *
   * @description 获取设备类型
   */
  const queryDeviceTypeList = () => {
    getDeviceTypeTree()
      .then((res) => {
        setDeviceTypeSource(res);
      })
      .catch((e) => message.error(e));
  };
  /**
   * @description 查看项目类型
   */
  const handleReview = (value, name) => {
    console.log(value,name);
    switch (name) {
      case "中控软件版本历史":
        setWidth(600);
        getSoftVersionFn(value);
        setColumnsDetail(softwareDetailsData);
        break;
      case "轨迹算法版本历史":
        setWidth(600);
        getSoftVersionFn(value);
        setColumnsDetail(softwareDetailsData);
        break;
      case "设备配置":
        setWidth(900);
        deviceonlineFn(value);
        setColumnsDetail(configurationData);
        break;
      default:
        return;
    }
    setModalTitle(name);
    setIsModalVisible(true);
  };

  /**
   * @description 新增项目类型
   */
  const allSelsetDataFn = () => {
    allSelsetData({ categoryCode: "project_device_status" })
      .then((res) => {
        setDeviceStatusOption(res);
      })
      .catch((e) => message.error(e));
  };
  /**
   *
   * @description 查询列表方法
   */
  const queryList = () => {
    form.validateFields().then((values) => {
      const queryParams = {
        data: { ...values, projectId: readSession("sessionProjectId") },
        page: {
          pageIndex: page,
          pageSize: size,
        },
      };
      projectDevicePage(queryParams)
        .then((res) => {
          let list = res.list.slice();
          list.map((item, index) => (item.index = index + 1));
          setDataSource(list);
          setTotal(res.total);
        })
        .catch((e) => message.error(e));
    });
  };

  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };

  const handleDownloadToken = (row) => {
    let sessionProjectName = readSession("sessionProjectName");
    let deviceNo = row.deviceNo;
    let params = {
      id: row.id,
      projectId: readSession("sessionProjectId"),
    };
    downloadToken(params)
      .then((res) => {
        let blob = new Blob([res], {
          type: "text/plain;charset=utf-8",
        });
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function (e) {
          let a = document.createElement("a");
          a.download = sessionProjectName + "-" + deviceNo + "-TOKEN";
          a.href = e.target.result;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };
      })
      .catch((e) => message.error(e));
  };
  const getSoftVersionFn = (deviceStockId) => {
    let params = {
      deviceStockId,
      projectId: readSession("sessionProjectId"),
    };
    getSoftVersion(params)
      .then((res) => {
        setDataSourceDetail(res);
      })
      .catch((e) => message.error(e));
  };
  const deviceonlineFn = (id) => {
    deviceonline(id)
      .then((res) => {
        setDataSourceDetail(res.deviceStockVo.components);
        setVersions(res.deviceStockVo);
      })
      .catch((e) => message.error(e));
  };

  useEffect(() => {
    queryList();
  }, [page, size]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "云端设备号",
      dataIndex: "cloudDeviceNo",
      ellipsis: true,
    },
    {
      title: "设备序列号",
      dataIndex: "deviceNo",
      ellipsis: true,
    },
    {
      title: "设备类型",
      dataIndex: "deviceTypeName",
      ellipsis: true,
    },
    {
      title: "状态",
      dataIndex: "templateProjectName",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div className="center-status">
            <div
              className={
                record.status === 0
                  ? "common-status green-status"
                  : "common-status red-status"
              }
            >
              {record.status === 0 ? "正常" : "禁用"}
            </div>
          </div>
        );
      },
    },
    {
      title: "软件版本（中控）",
      dataIndex: "softwareVersion",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div
            className="blue-text"
            onClick={() =>
              handleReview(record.deviceStockId, "中控软件版本历史")
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "算法版本（轨迹）",
      dataIndex: "algorithmVersion",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div
            className="blue-text"
            onClick={() =>
              handleReview(record.deviceStockId, "轨迹算法版本历史")
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 200,
      render: (text, record) => (
        <div className="operation">
          {isMyLiable && <OperateButton
            icon="icon-eye"
            text="查看配置"
            click={() => handleReview(record.deviceStockId, "设备配置")}
          />}
          {isMyLiable && <OperateButton
            icon="icon-download"
            text="下载Token"
            click={() => handleDownloadToken(record)}
          />}
        </div>
      ),
    },
  ];
  
  // 软件版本表格表头数据
  const softwareDetailsData = [
    {
      title: "版本",
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <div>
            {record.algorithmVersion || record.softwareVersion}
            {index === 0 ? "(当前版本)" : ""}
          </div>
        );
      },
    },
    {
      title: "更新时间",
      dataIndex: "updateTime",
      ellipsis: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--";
      },
    },
  ];
  // 设备配置表格表头数据
  const configurationData = [
    {
      title: "组件",
      dataIndex: "id",
      ellipsis: true,
    },
    {
      title: "品牌",
      dataIndex: "componentBrand",
      ellipsis: true,
    },
    {
      title: "型号",
      dataIndex: "componentModel",
      ellipsis: true,
    },
    {
      title: "操作",
      dataIndex: "operation",
      ellipsis: true,
      render: (_, record) => {
        return <span className="checkParameter" onClick={() => checkParameter(record)}>查看参数</span>
      }
    },
  ];
  const checkParameter = (data) => {
    setParameterTitle(data.componentBrand + " - " + data.componentModel)
    let parameterlist = JSON.parse(data.componentParameter)
    setParameterData(parameterlist.map((d,idx) => {
      return {...d,key: idx}
    }))
    setParameterOpen(true)
  }
  const closeParameter = () => {
    setParameterOpen(false)
  }
  return (
    <>
      <div className="main-page-equipment">
        <div className="form-container">
          <Form form={form} layout="inline" colon={false}>
            <Form.Item
              name="deviceStatus"
              rules={[
                {
                  max: 30,
                },
              ]}
              label="设备状态："
            >
              <Select
                style={{
                  width: 160,
                }}
                showSearch
                allowClear
                placeholder="请选择设备状态"
                options={
                  deviceStatusOption &&
                  deviceStatusOption.map((d) => ({
                    label: d.dictDesc,
                    value: d.dictCode,
                  }))
                }
                onChange={queryList}
              />
            </Form.Item>
            <Form.Item
              name="deviceCategoryNo"
              rules={[
                {
                  max: 30,
                },
              ]}
              label="设备类型："
            >
              <Select
                style={{
                  width: 160,
                }}
                showSearch
                allowClear
                placeholder="请选择设备类型"
                options={
                  deviceTypeSource &&
                  deviceTypeSource.map((d) => ({
                    label: d.categoryName,
                    value: d.categoryNo,
                  }))
                }
                onChange={queryList}
              />
            </Form.Item>
            <Form.Item name="keyword" label="">
              <Input
                style={{
                  width: 354,
                  height: 32,
                }}
                autoComplete="off"
                placeholder="请输入云端设备号/设备序列号"
                onChange={(e) => {
                  if (invalidInput(e.nativeEvent.data)) {
                    form.setFieldsValue({
                      keyword: e.target.value.slice(0, -1),
                    });
                  }
                }}
                suffix={<SearchOutlined onClick={() => queryList()} />}
              ></Input>
            </Form.Item>
          </Form>
        </div>
        <div className="table-container" style={{ marginTop: 16 }}>
          <Table
            size="middle"
            className="custom-table"
            rowKey={(record) => record.index}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
          {dataSource && dataSource.length > 0 && (
            <Pagination
              total={total}
              pageSize={size}
              current={page}
              pageSizeOptions={["10", "20", "30", "40", "50"]}
              showSizeChanger
              // showQuickJumper
              className="custom-pagination"
              // showTotal={(total) => `共 ${total} 条`}
              onChange={handleChangePagination}
            />
          )}
        </div>
        <Modal
          title={modalTitle}
          centered
          width={width}
          wrapClassName="custom-modal"
          visible={isModalVisible}
          maskClosable={false}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              <i className="iconfont icon-reply"></i>
              返回
            </Button>,
          ]}
        >
          {width === 900 && (
            <div>
              <div className="version">
                软件版本（中控）： {versions.softwareVersion}
                {versions.softwareTime
                  ? dayjs(versions.softwareTime).format("YYYY-MM-DD HH:mm:ss")
                  : "--"}
              </div>
              <div>
                算法版本（轨迹）： {versions.algorithmVersion}
                {versions.algorithmTime
                  ? dayjs(versions.algorithmTime).format("YYYY-MM-DD HH:mm:ss")
                  : "--"}
              </div>
            </div>
          )}
          <Table
            style={{ margin: "20px 0" }}
            className="custom-table"
            rowKey={(record) => record.id}
            size="middle"
            dataSource={dataSourceDetail}
            columns={columnsDetail}
            pagination={false}
            scroll={{
              y: containerHeight - 56 - 24 * 2 - 32 - 24 - 32 - 32 - 60,
            }}
          />
        </Modal>
        <Modal
          width="40%"
          centered
          className='custom-modal'
          maskClosable={false}
          okButtonProps={{ style: { display: 'none'} }}
          title={parameterTitle}
          open={parameterOpen}
          onCancel={closeParameter}
          cancelText={<span><i className="iconfont icon-reply"></i>返回</span>}
        >
          <ArgTable dataSource={parameterData} getParameter={() => {}} type={"update"} columnType={true}/>
        </Modal>
      </div>
    </>
  );
}

export default ProjectOverview;
