/*
 * @Author: gn
 * @Date: 2023-06-19 10:23:47
 * @LastEditors: gn
 * @LastEditTime: 2023-06-19 15:19:34
 * @Description: file content
 */
import { axiosInstance } from "../config";

//角色列表
export function rolePage(data){
    return axiosInstance.post('/systemrole/page' , data)
}

//修改角色状态
export function updateStatus(data){
    return axiosInstance.post('/systemrole/updateStatus' , data)
}

//新增系统角色
export function addRole( data ){
    return axiosInstance.post('/systemrole/add' , data);
}

//修改角色
export function updateRole( data ) {
    return axiosInstance.post('/systemrole/update' , data);
}

//删除接口
export function deleteRole(data){
    return axiosInstance.post('/systemrole/delete/'+data)
}