import OperateButton from "@/components/OperateButton";
import { Button, Form, Input, message, Modal, Pagination, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { addSubject, getSubjectList } from "../../../apis/examBank";

const { TextArea, Search } = Input;

function SubjectTable({ pageChange }) {
  const [dataSource, setDataSource] = useState([])
  const [total, setTotal] = useState(0)
  const [searchData, setSearchData] = useState({
    subjectName: undefined
  })
  const [page, setPage] = useState({
    pageSize: 10,
    pageIndex: 1
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [subjectForm] = Form.useForm()
  const columns = [
    {
      title: "考试科目",
      dataIndex: "subjectName",
      ellipsis: true,
      width: 200,
      render: (text,record) => (
        <span
          className="subject-name"
          title={text}
          onClick={() => pageChange("exercises", text, record.id)}
        >
          {text}
        </span>
      )
    },
    {
      title: "科目描述",
      dataIndex: "subjectDesc",
      ellipsis: true
    },
    {
      title: "题库数量",
      dataIndex: "bankQuestionNum",
      width: 180
    },
    {
      title: "更新时间",
      dataIndex: "updateTime",
      width: 250,
      render: (text) => (
        <span>{dayjs(text).format("YYYY-MM-DD HH:mm:ss")}</span>
      ),
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 180,
      render: (text, record) => (
        <>
          <OperateButton
            text="题库管理"
            data={record}
            click={() => pageChange("exercises", record.subjectName, record.id)}
          />
          <OperateButton
            text="考试配置"
            data={record}
            click={() => pageChange("config", record.subjectName, record.id)}
          />
        </>
      )
    }
  ]
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    queryList()
  },[page,searchData])
  //筛选列表数据
  const queryList = () => {
    const params = {
      data: searchData,
      page
    }
    getSubjectList(params).then(res => {
      setDataSource(res.list)
      setTotal(res.total)
    })
    .catch(err => console.log(err))
  }
  //更新筛选信息
  const onFinish = (val) => {
    setSearchData({
      subjectName: val
    })
    setPage({
      ...page,
      pageIndex: 1
    })
  }
  //切换分页信息
  const handleChangePagination = (pageIndex, pageSize) => {
    setPage({
      pageIndex,
      pageSize
    })
  }
  //打开新增弹框
  const openDialog = () => {
    setIsModalOpen(true)
  }
  //关闭弹框，并清空数据
  const cancelDialog = () => {
    setIsModalOpen(false)
    subjectForm.resetFields()
  }
  //新增科目
  const submitSubject = () => {
    subjectForm.validateFields().then((values) => {
      setConfirmLoading(true)
      addSubject(values).then(() => {
        message.success("新增成功")
        cancelDialog()
        queryList()
        setConfirmLoading(false)
      })
      .catch(err => {
        console.log(err)
        setConfirmLoading(false)
      })
    })
  }

  return (
    <>
      <div className="search-line">
        <Button type="primary" className="add-subject" onClick={openDialog}>
          添加科目
          <i className="add_icon iconfont icon-plus"></i>
        </Button>
        <Search
          style={{
            width: 270,
          }}
          autoComplete="off"
          allowClear
          onSearch={onFinish}
          onKeyDown={(event) => {
            if(event.code === "Enter"){
              onFinish(event.target.value)
            }
          }}
          placeholder="请输入考试科目"
        />
      </div>
      <Table
        className="custom-table"
        rowKey={(record) => record.examId}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      {total != 0 && (
        <Pagination
          total={total}
          pageSize={page.pageSize}
          current={page.pageIndex}
          pageSizeOptions={["10", "20", "30", "40", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />
      )}
      <Modal
        title="添加科目"
        wrapClassName="custom-modal"
        open={isModalOpen}
        centered={true}
        maskClosable={false}
        onCancel={cancelDialog}
        width={450}
        footer={null}
        destroyOnClose={true}
      >
        <Form form={subjectForm} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={submitSubject}>
          <Form.Item label="科目名称" name="subjectName" rules={[{required: true, message: "请填写科目名称"}]}>
            <Input placeholder="请输入科目名称，最多30个字符" maxLength={30}/>
          </Form.Item>
          <Form.Item label="科目描述" name="subjectDesc">
            <TextArea
              showCount
              maxLength={500}
              placeholder="请输入"
              style={{
                height: 120,
                resize: 'none',
              }}
            />
          </Form.Item>
          <div className="modal-footer">
            <Button onClick={cancelDialog} style={{marginRight: "12px"}}>取消</Button>
            <Button type="primary" htmlType="submit" loading={confirmLoading}>保存</Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}
export default SubjectTable
