import { changeProjectNodeInfo, transferNode } from "@/apis/projectOverview";
import eventBus from "@/utils/commonFunc/eventBus";
import { DatePicker, Form, Modal, Select, message } from "antd";
import dayjs from "dayjs";
import Gantt from "frappe-gantt";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CollapsedContext } from "../../App";
import { getGanttData, getGanttHeaderData } from "../../apis/gantt/index";
import "./index.scss";
const { RangePicker } = DatePicker;

const GanttComponent = (props) => {
  const collaged = useContext(CollapsedContext)
  const [form] = Form.useForm();
  const { projectId, isHeaderShow } = props;
  const [projectSta, setProjectSta] = useState(undefined);
  const [projectData, setProjectData] = useState(undefined);
  const [tasks, setTasks] = useState([]);
  const [stages, setStages] = useState([])
  const [projectInfo, setProjectInfo] = useState(undefined);
  const ganttRef = useRef(null)
  const [ganttTitleWidth, setGanttTitleWidth] = useState(260)
  const [stagesWidth, setStagesWidth] = useState(64)
  const [temporaryWidth, setTemporaryWidth] = useState([ganttTitleWidth,stagesWidth])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [projectMembers, setProjectMembers] = useState([])
  const [projectNodeId, setProjectNodeId] = useState(null)
  const [dialogType, setDialogType] = useState(null)
  const nodeTitleMaxWidth = ganttTitleWidth - stagesWidth

  useEffect(() => {
    getProjectGanttData()
    isHeaderShow &&
      getGanttHeaderData(projectId).then((res) => {
        setProjectInfo(res);
      })
      .catch((e) => message.error(e));;
  }, [projectId, isHeaderShow]);

  const getProjectGanttData = () => {
    getGanttData(projectId).then((res) => {
      setProjectData(res);
    })
    .catch((e) => message.error(e));;
  }

  const projectStatus = (status) => {
    let textList = {
      0: {
        text: "未开始",
        textColor: "#16BDCA",
        background: "#E6FFFB",
      },
      1: {
        text: "进行中",
        textColor: "#3DB86D",
        background: "#EDFFF8",
      },
      2: {
        text: "已结项",
        textColor: "#722ED1",
        background: "#F9F0FF",
      },
      3: {
        text: "已关闭",
        textColor: "#4B5563",
        background: "#EFEFEF",
      },
    };
    return textList[status];
  };
  const nodeStatus = (status, delay) => {
    let textList = {
      0: {
        text: delay ? "未开始-延期" : "未开始",
        textColor: delay ? "#F3911A" : "#16BDCA",
        background: delay ? "#FFF7E6" : "#E6FFFB",
      },
      1: {
        text: delay ? "进行中-延期" : "进行中",
        textColor: delay ? "#F5222D" : "#3DB86D",
        background: delay ? "#FFF1F0" : "#EDFFF8",
      },
      2: {
        text: "已完成",
        textColor: "#1C64F2",
        background: "#F0F5FF",
      },
      10: {
        text: "未开始", //计划时间都还没有的任务
        textColor: "#4B5563",
        background: "#EFEFEF",
      },
    };
    return textList[status];
  };
  const taskStatus = (status, delay, isPlan) => {
    let textList = {
      0: {
        text: delay ? "未开始-延期" : "未开始",
        textColor: delay ? "#F3911A" : "#16BDCA",
        background: delay ? "#FFF7E6" : "#E6FFFB",
        customClass: isPlan
          ? delay
            ? "planStartDelay"
            : "planStart"
          : delay
          ? "startDelay"
          : "start",
      },
      1: {
        text: delay ? "进行中-延期" : "进行中",
        textColor: delay ? "#F5222D" : "#3DB86D",
        background: delay ? "#FFF1F0" : "#EDFFF8",
        customClass: isPlan
          ? delay
            ? "planProgressDelay"
            : "planProgress"
          : delay
          ? "progressDelay"
          : "progress",
      },
      2: {
        text: "已完成",
        textColor: "#1C64F2",
        background: "#F0F5FF",
        customClass: "finish",
      },
      10: {
        text: "未开始", //计划时间都还没有的任务
        textColor: "#4B5563",
        background: "#EFEFEF",
        customClass: "noPlan",
      },
    };
    return textList[status];
  };

  useEffect(() => {
    let stageTasks = [];
    let stages = []
    projectData &&
      projectData.stages.map((d) => {
        stages.push({
          stageName: d.stageName,
          nodesNum: d.nodes.length,
          stageId: d.stageId
        })
        d.nodes = d.nodes.map((p) => {
          let colorObj = nodeStatus(
            p.planStartTime ? p.nodeStatus : 10,
            p.isDelay
          );
          return {
            ...p,
            colorObj,
          };
        });
        stageTasks.push(
          ...d.nodes.map((p) => {
            let isPlan = p.startTime ? false : true;
            let distance = p.planStartTime
              ? dayjs(p.planEndTime).diff(p.planStartTime, "days") 
              : 0;
            return {
              id: p.nodeId,
              name: p.planStartTime ? '' : "暂无计划",
              start: p.startTime ? dayjs(p.startTime).format("YYYY-MM-DD") : null || p.planStartTime ? dayjs(p.planStartTime).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
              end:
              p.endTime ? dayjs(p.endTime).format("YYYY-MM-DD") : null ||
                (p.startTime
                  ? dayjs(p.startTime).add(distance, "days").isBefore(dayjs()) ? dayjs().format("YYYY-MM-DD") : dayjs(p.startTime).add(distance, "days").format("YYYY-MM-DD") 
                  : p.planEndTime ? dayjs(p.planEndTime).format("YYYY-MM-DD") : dayjs().add(1, "days").format("YYYY-MM-DD")),
              progress: 0,
              data: p,
              dependencies: "",
              custom_class: taskStatus(
                p.planStartTime ? p.nodeStatus : 10,
                p.isDelay,
                isPlan
              ).customClass,
            };
          })
        );
        return d;
      });
    setProjectData(projectData);
    setTasks(stageTasks);
    setStages(stages)
    projectData && setProjectSta(projectStatus(projectData.projectStatus));
  }, [projectData]);
  // window.setPlanTime = (data) => {
  //   console.log('设置计划时间',data.nodeId);
  //   setIsModalOpen(true)
  //   setDialogType('planTime')
  //   setProjectNodeId(data.nodeId)
  // }

  // const getProjectUserByNodeIdFn = (projectNodeId) => {
  //   getProjectUserByNodeId({projectNodeId})
  //     .then((res) => {
  //       setProjectMembers(res);
  //     })
  //     .catch((e) => message.error(e));
  // };
  // window.transferTask = (data) => {
  //   setIsModalOpen(true)
  //   setDialogType('transfer')
  //   setProjectNodeId(data.nodeId)
  //   getProjectUserByNodeIdFn(data.nodeId)
  // }
  const handleSave = () => {
    form.validateFields().then((values) => {
      if(dialogType === 'transfer'){
        const queryParams = {
          projectNodeId,
          projectUserId: values.name,
        };
        transferNode(queryParams)
          .then((res) => {
            handleCancel();
            message.success("转移成功！");
            getProjectGanttData()
            form.resetFields()
          })
          .catch((e) => message.error(e));
      } else if(dialogType === "planTime") {
        const queryParams = {
          planStartTime: dayjs(values.planTime[0])
            .startOf("date")
            .format("YYYY-MM-DD HH:mm:ss"),
          planEndTime: dayjs(values.planTime[1])
            .endOf("date")
            .format("YYYY-MM-DD HH:mm:ss"),
          nodeId: projectNodeId,
        };
        changeProjectNodeInfo([queryParams])
          .then((res) => {
            handleCancel();
            message.success("设置成功");
            getProjectGanttData()
            form.resetFields()
          })
          .catch((e) => message.error(e));
      }
    });
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  useEffect(() => {
    tasks.length > 0 &&
      new Gantt("#gantt", tasks, {
        header_height: 50,
        column_width: 30,
        // step: 24,
        view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
        bar_height: 12,
        bar_corner_radius: 7,
        arrow_curve: 5,
        padding: 30,
        view_mode: "Day",
        date_format: "YYYY-MM-DD",
        language: "zh", // or 'es', 'it', 'ru', 'ptBr', 'fr', 'tr', 'zh', 'de', 'hu'
        on_click: function (task) {
          return;
        },
        on_date_change: function (e, start, end) {
          return;
        },
        on_progress_change: function () {
          return;
        },
        on_view_change: function () {
          return;
        },
        custom_popup_html: function (task) {
          const { data } = task
          return `
          <div class="details-container" style="width:300px">
            <div>
              <h5>${data.nodeName}</h5>
              <p>负责人：${data.userName ? data.userName : ''}</p>
              <p>完成情况：${data.progress ? data.progress : 0}%</p>
              <p>计划时间：${data.planStartTime ? dayjs(data.planStartTime).format("YYYY-MM-DD") + " ~ " + dayjs(data.planEndTime).format("YYYY-MM-DD") : ''}</p>
              <p>完成时间：${data.startTime ? dayjs(data.startTime).format("YYYY-MM-DD") + " ~ " : ''}${data.endTime ? dayjs(data.endTime).format("YYYY-MM-DD") : ''}</p>
            </div>
          </div>
          `;
        },
      });
  }, [tasks]);
  // ${isHeaderShow ? '' :
  //   `<div>
  //     ${!data.startTime ? `<button onclick='setPlanTime(${JSON.stringify(data)})'>设置计划时间</button>` : ''}
  //     <button onclick='transferTask(${JSON.stringify(data)})'>转移</button>
  //   <div>`
  // }
  useEffect(() => {
    const breadcrumbItemClickEventsFn = (item) => {
      switch (item) {
        case "项目总览":
          props.ganttToProjectOverview(item);
          eventBus.emit("setBreadcrumbData", ["项目空间", "项目总览"]);
          break;
        default:
          return;
      }
    };
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    );
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      );
    };
  }, [props]);
  const clickGantt = (e) => {
    // console.log(e,ganttRef.current.contains(e.target));
    const popDialog = document.getElementsByClassName('popup-wrapper')[0]
    if(ganttRef.current && !popDialog.contains(e.target) && !["rect", "text"].includes(e.target.localName)) {
      popDialog.style.opacity = 0;
      popDialog.style.left = 0;
    }
  }

  const dragLine = () => {
    let menuWidth = collaged ? 62 : 200
    document.onmousemove = (e) => {
      if(e.clientX > menuWidth + stagesWidth + 196) {
        setGanttTitleWidth(e.clientX - menuWidth)
      }
    }
    document.onmouseup = () => {
      document.onmousemove = null;
      document.onmouseup = null;
    }
  }

  const dragLine1 = () => {
    setTemporaryWidth([ganttTitleWidth, stagesWidth])
    let menuWidth = collaged ? 62 : 200
    document.onmousemove = (e) => {
      if(e.clientX > menuWidth + 64 ) {
        setStagesWidth(e.clientX - menuWidth)
      }
    }
    document.onmouseup = () => {
      document.onmousemove = null;
      document.onmouseup = null;
    }
  }
  useEffect(() => {
    setGanttTitleWidth(temporaryWidth[0] + (stagesWidth - temporaryWidth[1]) )
  },[stagesWidth])
  return (
    <div className="gantt-page" onClick={clickGantt}>
      {isHeaderShow && projectInfo && (
        <div className="project-header">
          <div className="project-left">
            <p className="code">项目编号：{projectInfo.code}</p>
            <p className="type">项目类型：{projectInfo.typeDesc}</p>
          </div>
          <div className="project-right">
            <div className="project-content member">
              <p className="num">{projectInfo.projectUserCount}</p>
              <p className="title">项目成员</p>
            </div>
            <div className="project-content file">
              <p className="num">{projectInfo.projectFileCount}</p>
              <p className="title">交付物</p>
            </div>
            <div className="project-content device">
              <p className="num">{projectInfo.projectDeviceCount}</p>
              <p className="title">项目设备</p>
            </div>
            <div className="project-content product">
              <p className="num">{projectInfo.projectProductCount}</p>
              <p className="title">项目产品</p>
            </div>
          </div>
        </div>
      )}
      <div className="gantt-content" >
        <div className="gantt-title" style={{width: ganttTitleWidth + 'px', flexShrink: 0}} >
          {projectSta && (
            <div className="header">
              <div className="project-name" style={{maxWidth:`${projectData.isProjectDelay ? `${ganttTitleWidth - 150}px` : `${ganttTitleWidth - 97}px`}`}}>
                {projectData && projectData.projectName}
              </div>
              <div className="project-statuses">
                <span
                  className="status"
                  style={{
                    color: projectSta.textColor,
                    background: projectSta.background,
                  }}
                >
                  {projectSta.text}
                </span>
                {projectData && projectData.isProjectDelay && (
                  <span className="project-delay">已延期</span>
                )}
              </div>
            </div>
          )}
          <div className="stages" >  
            <div className="stageName" style={{width: stagesWidth + 'px', flexShrink: 0}}>
              {stages.map(d => {
                return (
                  <div className="stage" style={{height: d.nodesNum * 42 +"px"}} title={d.stageName} key={d.stageId}>
                    <span className="stage-title">{d.stageName}</span>
                  </div>
                )
              })}
            </div>
            <div className="stages-line" style={{height: tasks.length * 42 + "px"}} onMouseDown={dragLine1}></div>
            <div className="tasks">
              {tasks.map(({data}) => {
                return (
                  <div className="node-item" key={data.nodeId} style={{width: nodeTitleMaxWidth + "px"}}>
                    <span className="node-title" title={data.nodeName} style={{maxWidth:`${data.isDelay ? `${nodeTitleMaxWidth - 82}px` : `${nodeTitleMaxWidth - 52}px`}`}}>{data.nodeName}</span>
                    {data.colorObj && (
                      <span
                        className="status"
                        style={{
                          color: data.colorObj.textColor,
                          background: data.colorObj.background,
                        }}
                      >
                        {data.colorObj.text}
                      </span>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="empty"></div>
        </div>
        <div className="gantt-line" onMouseDown={dragLine}></div>
        <div className="gantt-part" >
          <div id="gantt" ref={ganttRef} style={{ background: "#fff"}}></div>
        </div>
      </div>
      <Modal
        title={dialogType === "transfer" ? "任务转移" : "更改任务计划"}
        centered
        width={500}
        wrapClassName="custom-modal"
        maskClosable={false}
        visible={isModalOpen}
        onOk={() => handleSave()}
        onCancel={() => handleCancel()}
        okText={
          <span>
            <i className="iconfont icon-check"></i>确认
          </span>
        }
        cancelText={
          <span>
            <i className="iconfont icon-x"></i>返回
          </span>
        }
      >
        <Form
          layout="inline"
          form={form}
          colon={false}
        >
          {dialogType === "planTime" && <Form.Item
            label="计划时间"
            name="planTime"
            rules={[{ required: true }]}
          >
            <RangePicker />
          </Form.Item>}
          {dialogType === "transfer" && <Form.Item
            label="项目成员："
            name="name"
            rules={[{ required: true }]}
          >
            <Select
              style={{
                width: 288,
                height: 32,
              }}
              allowClear
              placeholder="请选择项目成员"
              options={projectMembers.map((d) => ({
                label: d.name,
                value: d.projectUserId,
              }))}
            ></Select>
          </Form.Item>}
        </Form>
      </Modal>
    </div>
  );
};

export default GanttComponent;