/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-12 11:07:42
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-10-25 11:47:43
 * @FilePath: /pms-web/src/containers/ProjectBoard/projectDetail.js
 * @Description: 项目详情-甘特图页面
 */
import Gantt from "../../components/Gantt"
import eventBus from "@/utils/commonFunc/eventBus"
import { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useSearchParams } from "react-router-dom"

function ProjectDetail() {
  const [searchParams, setSearchParams] = useSearchParams()
  const projectId = searchParams.get("projectId")
  const projectType = searchParams.get("projectType")
  const navigate = useNavigate()
  // 面包屑点击传来的值以及方法
  const breadcrumbItemClickEventsFn = useCallback((item) => {
    console.log("item", item)
    switch (item) {
      case "项目看板":
        eventBus.emit("setBreadcrumbData", ["运营分析", "项目看板"])
        navigate("/operations-analysis/board")
        break
      case "项目列表":
        setTimeout(() => {
          eventBus.emit("setBreadcrumbData", [
            "运营分析",
            "项目看板",
            "项目列表"
          ])
        }, 100)
        navigate("/operations-analysis/board/project-list", {
          state: { isGantt: true, projectType }
        })
        break
      default:
        return
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      eventBus.emit("setBreadcrumbData", [
        "运营分析",
        "项目看板",
        "项目列表",
        "甘特图"
      ])
    }, 200)
  }, [])

  useEffect(() => {
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    )
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      )
    }
  }, [breadcrumbItemClickEventsFn])
  return (
    <>
      <Gantt projectId={projectId} isHeaderShow={true} />
    </>
  )
}

export default ProjectDetail
