/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-07-28 09:23:20
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-09-01 10:10:12
 */

import {
  UPDATE_PROJECT_NODE,
  ADD_PROJECT_NODE,
  DELETE_PROJECT_NODE,
  DISPLAY_PROJECT_NODE,
} from "../constant";
const initialState = [];

const projectTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROJECT_NODE:
      return updateProjectNode(state, action.payload);
    case ADD_PROJECT_NODE:
      return addProjectNode(state, action.payload);
    case DELETE_PROJECT_NODE:
      return deleteProjectNode(state, action.payload);
    case DISPLAY_PROJECT_NODE:
      return action.payload;
    default:
      return state;
  }
};

//计算节点编号
const calculateNodeCode = (nodeList) => {
  let startMilestoneNum = Number(nodeList[0].nodeCode.slice(-1));
  let currentMilestoneNum = startMilestoneNum;
  let mileStoneCount = 0;
  let taskCount = 0;
  return nodeList.map((item, index) => {
    if (item.nodeType === 0) {
      currentMilestoneNum = startMilestoneNum + mileStoneCount;
      mileStoneCount++;
      taskCount = 0;
      return {
        ...item,
        nodeCode:
          currentMilestoneNum || currentMilestoneNum === 0
            ? `M${currentMilestoneNum}`
            : "",
      };
    } else if (item.nodeType === 1) {
      taskCount++;
      return { ...item, nodeCode: `S${currentMilestoneNum}.${taskCount}` };
    } else {
      return item;
    }
  });
};

//清除当前节点之后的所有节点的前置引用
const clearPreNodeRef = (nodeList, index) => {
  return nodeList.map((item, idx) => {
    if (idx > index) {
      return { ...item, projectPreNodeCodes: [] };
    } else {
      return item;
    }
  });
};

const updateProjectNode = (state, row) => {
  let projectTypeList = [...state];
  const index = projectTypeList.findIndex((item) => row.id === item.id);
  const item = projectTypeList[index];
  //判断第一行的节点编号是否有变化
  let typeChange = index === 0 && item.nodeCode !== row.nodeCode;
  const newData = { ...item, ...row };
  projectTypeList.splice(index, 1, newData);
  //1.重新计算所有的节点编号
  projectTypeList = calculateNodeCode(projectTypeList);
  //2.清空所有后续节点的前置节点引用（第一行的节点编号跟非第一行的节点类型变化才清除对应后续行的前置节点）
  projectTypeList =
    row.typeChange || typeChange
      ? clearPreNodeRef(projectTypeList, index)
      : projectTypeList;
  return projectTypeList;
};

const addProjectNode = (state, index) => {
  const newData = [...state];
  const newRow = {
    id: Date.now(),
    nodeType: null,
    nodeCode: "",
    projectNodeName: "",
    inputFileCode: [],
    inputFileName: [],
    inputFileIds: [],
    deliverFileCode: [],
    deliverFileName: [],
    deliverablesFileIds: [],
    productSystemName: "",
    projectPreNodeCodes: [],
  };
  newData.splice(index + 1, 0, newRow);
  return newData;
};

const deleteProjectNode = (state, record) => {
  let newData = [...state];
  const index = newData.findIndex((item) => record.id === item.id);
  //删除的时候，如果当前行有nodeType，则需要清除后续行的前置节点
  newData = record.nodeType ? clearPreNodeRef(newData, index) : newData;
  newData = newData.filter((item) => item.id !== record.id);
  return newData;
};

export default projectTypeReducer;
