/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-13 16:42:09
 * @LastEditors: pan.aiyan
 * @LastEditTime: 2023-06-28 13:14:43
 */
import { getResidueTimeList, saveTimeList, taskTotalHour } from "@/apis/workHour";
import WorkStatistics from "@/components/WorkStatistics";
import eventBus from "@/utils/commonFunc/eventBus";
import readSession from "@/utils/commonFunc/readSession";
import { Button, Progress, Table, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../index.scss";

function TaskDetailsList(props) {
  const [taskProgressList, setTaskProgressList] = useState([]);
  const [tabList, setTabList] = useState([])
  const [tableColumns, setTableColumns] = useState([])
  const [activeTab, setActiveTab] = useState('')
  const [isCanSave, setIsCanSave] = useState(true)
  const workHourId = readSession("taskProgressList").id
  const currentUser = useSelector((state) => state.userReducer);
  const [isLoading, setIsLoading] = useState(false)
  const breadcrumbItemClickEventsFn = (item) => {
    switch (item) {
      case "项目总览":
        eventBus.emit("setBreadcrumbData", ["项目空间", "项目总览"]);
        props.goIndexPage(item);
        break;
      case "任务详情":
        eventBus.emit("setBreadcrumbData", [
          "项目空间",
          "项目总览",
          "任务详情",
        ]);
        props.goDetailsPage();
        break;
      default:
        return;
    }
  };
  let totalColumns = [
    {
      title: "日期",
      dataIndex: "date",
      ellipsis: true,
      fixed: "left",
      width: 170,
      render: (text, record) => {
        return `${text} ${record.allowModified
          ? record.approvel === 1
            ? "(已审批)"
            : ""
          : "(已锁定)"}`
      }
    },
    {
      title: "总工时",
      dataIndex: "workHourAll",
      ellipsis: true,
      fixed: "left",
      width: 100
    },
  ]
  const getWorkHourList = (userId) => {
    const params = {
      taskId: workHourId,
      userId
    }
    getResidueTimeList(params).then(res => {
      setTaskProgressList(res)
    })
    .catch(() => {})
  }
  const handleChangeTab = (key) => {
    setActiveTab(key)
    switch (key) {
      case 'progressDetail':
        setTaskProgressList(readSession("taskProgressList").taskProgressList);
        setTableColumns(columns1)
        break;
      case 'workingHour':
        getWorkHourList(readSession("detailTaskData").taskUserId)
        setIsCanSave(readSession("detailTaskData").isMyLiable)
        break;
      case 'totalWorkingHour':
        taskTotalHour(workHourId).then(res => {
          if(res.length > 0 ) {
            res[0].user.forEach(q => {
              totalColumns.push({
                title: q.userName,
                dataIndex: q.userId,
                ellipsis: true,
                fixed: '',
                width: 90
              })
            })
            setTaskProgressList(res.map(d => {
              let item = {}
              d.user.forEach(p => {
                item[p.userId] = p.workHour
              })
              return{
                ...d,
                ...item
              }
            }))
          } else {
            setTaskProgressList([])
          }
          setTableColumns(totalColumns)
        })
        .catch(() => {})
        break;
      case 'subtaskProgressDetail':
        setTaskProgressList(readSession("taskProgressList").taskProgressList);
        setTableColumns(columns)
        break;
      case 'subtaskWorkingHour':
        getWorkHourList(readSession("taskProgressList").userId)
        let boolean = readSession("detailTaskData").isMyLiable ? readSession("detailTaskData").isMyLiable : readSession("taskProgressList").userId === currentUser.id
        setIsCanSave(boolean)
        break;
      default:
        break;
    }
  }
  const columns = [
    {
      title: "时间",
      dataIndex: "progressTime",
      ellipsis: true,
      sorter: {
        compare: (a, b) => {
          return new Date(a.progressTime).getTime() - new Date(b.progressTime).getTime()
        },
        multiple: 10,
      },
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--";
      },
    },
    {
      title: "操作",
      dataIndex: "progressOperateType",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            {record.progressOperateType === 1
              ? "开始任务"
              : record.progressOperateType === 2
              ? "更新任务"
              : record.progressOperateType === 3
              ? "完成任务"
              : "重启任务"}
          </div>
        );
      },
    },
    {
      title: "进度",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div className="jindu-box">
            <p className="jindu-box-text">{record.progress}</p>
            <Progress type="circle" percent={record.progress} size={20} />
          </div>
        );
      },
    },
    {
      title: "进度说明",
      dataIndex: "progressDesc",
      ellipsis: true,
    },
  ];
  const columns1 = [
    {
      title: "时间",
      dataIndex: "reportTime",
      ellipsis: true,
      sorter: {
        compare: (a, b) => {
          return new Date(a.reportTime).getTime() - new Date(b.reportTime).getTime()
        },
        multiple: 10,
      },
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--";
      },
    },
    {
      title: "操作",
      dataIndex: "operateType",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            {record.operateType === 1
              ? "开始任务"
              : record.operateType === 2
              ? "更新任务"
              : record.operateType === 3
              ? "完成任务"
              : "重启任务"}
          </div>
        );
      },
    },
    {
      title: "进度",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div className="jindu-box">
            <p className="jindu-box-text">{record.progress}%</p>
            <Progress type="circle" percent={record.progress} size={20} />
          </div>
        );
      },
    },
    {
      title: "进度说明",
      dataIndex: "progressDescribe",
      ellipsis: true,
    },
  ];
  useEffect(() => {
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    );
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      );
    };
  }, [breadcrumbItemClickEventsFn]);
  useEffect(() => {
    setTaskProgressList(readSession("taskProgressList").taskProgressList);
    setTabList(readSession("taskProgressList").titleList);
    if(readSession("taskProgressList").title === "进度详情") {
      setTableColumns(columns1)
      setActiveTab("progressDetail")
    } else {
      setTableColumns(columns)
      setActiveTab("subtaskProgressDetail")
    }
  }, []);
  const getData = (data) => {
    setTaskProgressList(data)
  }
  const submit = () => {
    setIsLoading(true)
    let params = {
      boList: taskProgressList,
      userId: activeTab === "subtaskWorkingHour" ? readSession("taskProgressList").userId : undefined
    }
    saveTimeList(params).then(res => {
      if(res) {
        message.success("保存成功")
        setIsLoading(false)
        getWorkHourList(activeTab === "subtaskWorkingHour" ? readSession("taskProgressList").userId : readSession("detailTaskData").taskUserId)
      }
    })
    .catch(() => {setIsLoading(false)})
  }
  return (
    <>
      <div className="task-details-list">
        <div className="schedule">
          <div className="tab-box">
            {tabList.map((item) => (
              <span
                key={item.key}
                className={[
                  "tab-list",
                  activeTab === item.key ? "tab-active" : null,
                ].join(" ")}
                onClick={() => handleChangeTab(item.key)}
              >
                {item.label}
              </span>
            ))}
          </div>
        </div>

        {["workingHour", "subtaskWorkingHour"].includes(activeTab) ? 
        <>
          <p className="tip"><span className="tip-icon iconfont icon-xingxing"></span> 本周工时将于下周一前锁定，锁定后无法修改，请及时填写</p>
          <WorkStatistics dataSource={taskProgressList} getData={getData}/>
          {isCanSave && <Button type="primary" loading={isLoading} onClick={submit}><i className="iconfont icon-baocun"></i>保存</Button> }
        </> : 
        <Table
          style={{ margin: "10px 0" }}
          size="middle"
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={taskProgressList}
          columns={tableColumns}
          pagination={false}
          scroll={{
            x: activeTab === "totalWorkingHour" ? (tableColumns.length - 2) * 100 : null,
          }}
        />}
      </div>
    </>
  );
}
export default TaskDetailsList;

