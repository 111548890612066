/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-08-04 10:12:09
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-08-04 10:13:03
 */
/***
 *  移除sessionStorage
 * @param key
 * @param value
 */
const removeSession = function (key) {
  key && sessionStorage.removeItem(key);
};

export default removeSession;
