/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-07-26 09:07:39
 * @LastEditors: gn
 * @LastEditTime: 2023-07-20 14:45:05
 */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import AuthConfirm from "./AuthConfirm";
import { ConfigProvider } from "antd";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import zhCN from "antd/es/locale/zh_CN";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import "./assets/iconfont/iconfont.css";
import {message,} from "antd";

ConfigProvider.config({
  theme: {
    primaryColor: "#1fa6d7",
  },
});

message.config({
  top: 80,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <ReactKeycloakProvider authClient={keycloak}>
    <HashRouter>
      <Provider store={store}>
        <ConfigProvider locale={zhCN}>
          <AuthConfirm>
            <App />
          </AuthConfirm>
        </ConfigProvider>
      </Provider>
    </HashRouter>
  </ReactKeycloakProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
