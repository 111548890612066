/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-09 11:44:59
 * @LastEditors: pan.aiyan
 * @LastEditTime: 2023-06-30 16:21:10
 * @FilePath: /pms-web/src/containers/ProjectBoard/components/ProjectStatusGrid.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { Progress, Tooltip } from "antd";
import { useState } from "react";
function ProjectScheduleEcharts({
  color,
  title,
  valueNum,
  activeColor,
  rightNum,
  leftNum,
  version,
}) {
  const [optionOne, setOptionsOne] = useState({});
  return (
    <>
      <div className="echart-item-box-one">
        <div className="echart-item-box-two">
          <Progress
            type="circle"
            percent={valueNum}
            showInfo={false}
            size={83}
            strokeWidth={7}
            trailColor={"rgba(0,0,0,0)"}
            strokeColor={color}
          />
          <div className="echart-item-box-three">
            {/* <Tooltip placement="top" title={title + `(${version})`}>
              <p className="three-title">{title + `(${version})`}</p>
            </Tooltip> */}
            <Tooltip placement="top" title={title}>
              <p className="three-title">{title}</p>
            </Tooltip>
            <p className="three-num">{leftNum + "/" + rightNum}</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProjectScheduleEcharts;
