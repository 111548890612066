/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-06 13:22:19
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-01-26 11:05:21
 */
import { Table, TimePicker, Input, Button, message } from 'antd';
import {
  productionShift,
  addSchedule,
} from '@/apis/projectCenter/projectManagement';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/zh-cn';

import '../index.scss';
function Schedule(props) {
  dayjs.extend(isBetween);
  const { projectData } = props;
  const [dataSource, setDataSource] = useState([]); //表格数据
  const oneData = [
    {
      name: '全班',
      startTime: '00:00',
      startTimeType: 0,
      endTime: '00:00',
      endTimeType: 1,
      key: Math.random().toString(16).slice(2),
    },
  ]; //一条数据
  const [isLoading, setIsLoading] = useState(false);
  const format = 'HH:mm';
  const columns = [
    {
      title: '生产班次',
      dataIndex: 'name',
      ellipsis: true,
      width: 220,
      render: (text, record, index) => {
        return projectData.status !== 2 && projectData.status !== 3 ? (
          <Input
            placeholder="班次名"
            defaultValue={text}
            style={{ width: '136px' }}
            disabled={dataSource.length === 1}
            value={record.name}
            onChange={(e) => inputChange(e, index)}
          />
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: '时间',
      dataIndex: 'startTime',
      ellipsis: true,
      render: (text, record, index) => {
        return projectData.status !== 2 && projectData.status !== 3 ? (
          <div className="time">
            <div>
              {record.startTimeType === 0 ? '当日 ' : '次日 '}

              <TimePicker
                defaultValue={text ? dayjs(record.startTime, format) : ''}
                format={format}
                allowClear={false}
                showNow={false}
                value={dayjs(record.startTime, format)}
                disabled={dataSource.length === 1 || index !== 0}
                onChange={(time, timeString) =>
                  startChange(time, timeString, index)
                }
              />
            </div>
            -
            <div>
              {record.endTimeType === 0 ? '当日 ' : '次日 '}
              <TimePicker
                defaultValue={
                  record.endTime ? dayjs(record.endTime, format) : ''
                }
                showNow={false}
                allowClear={false}
                format={format}
                value={dayjs(record.endTime, format)}
                disabled={dataSource.length === 1}
                onChange={(time, timeString) =>
                  endChange(time, timeString, index)
                }
              />
            </div>
          </div>
        ) : (
          <div className="time">
            <div>
              {record.startTimeType === 0 ? '当日' : '次日'}
              <span>{text ? text : '--'}</span>
            </div>
            -
            <div>
              {record.endTimeType === 0 ? '当日' : '次日'}
              <span>{record.endTime ? record.endTime : '--'}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <i
          className="iconfont icon-plus-circle mouse"
          onClick={() => handelAdd()}
        ></i>
      ),
      dataIndex: 'operation',
      width: '40%',
      render: (text, record, index) => (
        <i
          hidden={index === 0}
          className="iconfont icon-minus-circle"
          style={{
            color: '#3F83F8',
            cursor: 'pointer',
          }}
          onClick={() => reduceClass(index)}
        ></i>
      ),
    },
  ];

  useEffect(() => {
    getProductionShift(); //获取列表数据
  }, []); // eslint-disable-line

  /**
   * @description: 获取列表数据
   * @return {*}
   * @author: LINA.QI
   */
  const getProductionShift = () => {
    productionShift(projectData.id)
      .then((res) => {
        res.forEach((item) => {
          item.key = Math.random().toString(16).slice(2);
        });
        setDataSource(res);
      })
      .catch((e) => message.error(e));
  };

  /**
   * @description: 添加班次
   * @return {*}
   * @author: LINA.QI
   */
  const handelAdd = () => {
    if (dataSource[dataSource.length - 1].endTime) {
      let startTime = dayjs(
        `2023-06-19 ${dataSource[dataSource.length - 1].endTime}`
      ).format('HH:mm');
      let startTimeType =
        dataSource[dataSource.length - 1].endTimeType === 1 ? 1 : 0;
      let endTime = dayjs(`2023-06-19 ${dataSource[0].startTime}`)
        .add(1, 'days')
        .format('HH:mm');
      let endTimeType = 1;
      let newData = {
        name: undefined,
        startTime: startTime,
        startTimeType: startTimeType,
        endTime: endTime,
        endTimeType: endTimeType,
        key: Math.random().toString(16).slice(2),
      };
      setDataSource((oldArray) => [...oldArray, newData]);
    } else {
      message.error('请填写结束时间');
    }
  };

  /**
   * @description: 修改开始时间
   * @return {*}
   * @author: LINA.QI
   */
  const startChange = (time, timeString, index) => {
    if (index === 0) {
      dataSource[0].startTimeType = 0;
    } else {
      dataSource[index].startTimeType =
        dataSource[index - 1].endTimeType === 1 ? 1 : 0;
    }
    dataSource[index].startTime = timeString;
    setDataSource([...dataSource]);
  };

  /**
   * @description: 修改结束时间
   * @return {*}
   * @author: LINA.QI
   */
  const endChange = (time, timeString, index) => {
    let timeValue = null;
    timeValue =
      dataSource[index].startTimeType === 1
        ? dayjs(`2023-06-20 ${timeString}`)
        : dayjs(`2023-06-19 ${timeString}`);
    if (dataSource[index].startTimeType === 1) {
      dataSource[index].endTimeType = 1;
    } else {
      dataSource[index].endTimeType = timeValue.isAfter(
        `2023-06-19 ${dataSource[0].startTime}`
      )
        ? 0
        : 1;
    }
    if (index < dataSource.length - 1) {
      startChange(time, timeString, index + 1);
    }
    dataSource[index].endTime = timeString;
    setDataSource([...dataSource]);
  };

  /**
   * @description: 删除条数
   * @return {*}
   * @author: LINA.QI
   */
  const reduceClass = (index) => {
    let item = dataSource.splice(index, 1);
    setDataSource((current) =>
      current.filter((employee, i) => {
        return i !== index;
      })
    );
    if (index < dataSource.length) {
      dataSource[index].startTimeType = item[0].startTimeType;
      dataSource[index].startTime = item[0].startTime;
      setDataSource([...dataSource]);
    }
    if (dataSource.length === 1) {
      setDataSource([...oneData]);
    }
  };
  /**
   * @description: 修改班次名
   * @return {*}
   * @author: LINA.QI
   */
  const inputChange = (e, index) => {
    dataSource[index].name = e.target.value;
    setDataSource([...dataSource]);
  };
  /**
   * @description: 保存
   * @return {*}
   * @author: LINA.QI
   */
  const handleConfirm = () => {
    let startTimeType = dayjs(`2023-06-19 ${dataSource[0].startTime}`);
    let endTimeFlag =
      dataSource[dataSource.length - 1].endTimeType === 0
        ? dayjs(`2023-06-19 ${dataSource[dataSource.length - 1].endTime}`)
        : dayjs(`2023-06-20 ${dataSource[dataSource.length - 1].endTime}`);
    let diffTime = Math.abs(
      dayjs(startTimeType).diff(dayjs(endTimeFlag), 'minutes')
    );
    let nameList = [];
    for (let i = 0; i < dataSource.length; i++) {
      const element = dataSource[i];
      if (element.startTime === element.endTime && i != 0) {
        message.warning(`第${i + 1}行两时间不可相同`);
        return;
      }
      if (nameList.includes(element.name)) {
        message.warning('班次名称不可相同');
        return;
      }
      if (!element.name) {
        message.warning('请填写班次名称');
        return;
      }
      if (!element.startTime || !element.endTime) {
        message.warning(`请完善第${i + 1}行的班次时间`);
        return;
      }
      let startTime =
        element.startTimeType === 0
          ? dayjs(`2023-06-19 ${element.startTime}`)
          : dayjs(`2023-06-20 ${element.startTime}`);
      let endTime =
        element.endTimeType === 0
          ? dayjs(`2023-06-19 ${element.endTime}`)
          : dayjs(`2023-06-20 ${element.endTime}`);
      let isPass = false;
      dataSource.forEach((d, index) => {
        let start =
          d.startTimeType === 0
            ? dayjs(`2023-06-19 ${d.startTime}`)
            : dayjs(`2023-06-20 ${d.startTime}`);
        let end =
          d.endTimeType === 0
            ? dayjs(`2023-06-19 ${d.endTime}`)
            : dayjs(`2023-06-20 ${d.endTime}`);
        if (
          index < i &&
          (startTime.isBetween(start, end, null, '()') ||
            endTime.isBetween(start, end, null, '()'))
        ) {
          isPass = true;
        }
      });
      if (isPass) {
        message.warning(`第${i + 1}行时间超出24小时范围，请修改`);
        return;
      }
      nameList.push(element.name);
    }
    if (diffTime > 1440) {
      message.warning('排班时间超出24小时');
      return;
    } else if (diffTime < 1440) {
      message.warning('排班时间不到24小时！');
      return;
    }
    setIsLoading(true);
    const params = {
      projectId: projectData.id,
      shiftList: dataSource,
    };
    addSchedule(params)
      .then((res) => {
        message.success('班次设置成功！');
        setIsLoading(false);
      })
      .catch((e) => {
        message.error(e);
        setIsLoading(false);
      });
  };

  /**
   * @description: 取消
   * @return {*}
   * @author: LINA.QI
   */
  const handleCancel = () => {
    getProductionShift();
  };

  return (
    <>
      <div className="schedule">
        <Table
          style={{ margin: '10px 0'}}
          size="middle"
          className="custom-table"
          rowKey={(record) => record.key}
          dataSource={dataSource}
          columns={
            projectData.status === 2 || projectData.status === 3
              ? columns.filter((item) => item.dataIndex !== 'operation')
              : columns
          }
          pagination={false}
        />
        <div
          className="operation-box"
          hidden={projectData.status === 2 || projectData.status === 3}
        >
          <Button hidden={dataSource.length === 1} disabled={isLoading} onClick={handleCancel}>
            <i className="iconfont icon-x" />
            取消
          </Button>
          <Button hidden={dataSource.length === 1} loading={isLoading} type="primary" onClick={handleConfirm}>
            <i className="iconfont icon-baocun" />
            保存
          </Button>
        </div>
      </div>
    </>
  );
}

export default Schedule;
