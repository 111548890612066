
import { Cascader, Form, Input, message, Modal } from 'antd';
import dayjs from "dayjs";
import React, { useEffect, useState } from 'react';
import { checkConfig, getDeviceTypeSecondaryTree } from "../../../apis/deviceManage/type/index";
import { NameAddressSpecialChar } from "../../../utils/commonFunc/validate";
import "../index.scss";
import ParameterTable from './ParameterTable';

const EquipmentDetail = (props) => {
  const { modalData, modalType, modalOpen, saveModal, cancelModal, modalTitle, isVersion = false } = props
  const [dataSource, setDataSource] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [deviceTypeSource, setDeviceTypeSource] = useState([])


  useEffect(() => {
    if(modalData.data){
      setDataSource(modalData.data.map((d,idx) => ({...d,key:idx})))
    } else {
      setDataSource([])
    }
  },[modalData.data])
  
  useEffect(() => {
    getDeviceTypeSource()
  }, [])

  const getDeviceTypeSource = () => {
    getDeviceTypeSecondaryTree().then(res => {
      setDeviceTypeSource(res.map(d => {
        let item = {}
        item.label = d.categoryName
        item.value = d.id
        item.children = d.deviceTypeChilds.map(p => {
          return {
            label: p.typeName,
            value: p.id
          }
        })
        return item
      }))
    })
    .catch((e) => message.error(e));
  }
 
  const deviceTypeChange = (value) => {
    if(value && value[1]) {
      checkConfig(value[1]).then(res => {
        if(res.detailVos){
          setDataSource(res.detailVos.map((d,idx) => ({...d,key:idx})))
        }
      })
    }
  }

  const { softwareVersion, algorithmVersion, softwareTime, algorithmTime } = modalData
  const [refform] = Form.useForm();
  const confirmModal = () => {
    if(modalTitle === '创建设备(入库)'){
      refform.validateFields().then(values => {
        if(dataSource.length === 0) {
          messageApi.warning(`请添加设备配置！`);
          return
        }
        for (let i = 0; i < dataSource.length; i++) {
          const item = dataSource[i];
          if(!item.id) {
            messageApi.warning(`请补充第${i + 1}行信息`);
            return
          }
        }
        saveModal({
          ...values,
          components: dataSource.map(d => ({id: d.id, componentParameter: d.componentParameter}))
        },clostModal)
      })
      .catch(err => console.log(err))
    } else {
      for (let i = 0; i < dataSource.length; i++) {
        const item = dataSource[i];
        if(!item.id) {
          messageApi.open({
            type: 'warning',
            content: `请补充第${i + 1}行信息`,
          });
          return
        }
      }
      saveModal({
        components: dataSource.map(d => ({id: d.id, componentParameter: d.componentParameter})),
        id: modalData.id
      },clostModal)
    }
  }
  const clostModal = () => {
    refform.resetFields()
    setDataSource([])
    cancelModal()
  }
  const getSource = (data) => {
    setDataSource(data)
  }
  return (
    <>
      {contextHolder}
      <Modal
        width="40%"
        className='custom-modal'
        destroyOnClose={true}
        maskClosable={false}
        okButtonProps={{ style: { display: modalType === "check" ? 'none' : 'inline-block' } }}
        title={modalTitle}
        open={modalOpen}
        onOk={confirmModal}
        onCancel={clostModal}
        okText={<span><i className="iconfont icon-baocun"></i>保存</span>}
        cancelText={modalType === "check" ? <span><i className="iconfont icon-reply"></i>返回</span> : <span><i className="iconfont icon-x"></i>取消</span>}
      >
        {modalTitle === '创建设备(入库)' && <Form form={refform} labelCol={{ span: 4  }} wrapperCol={{ span: 15 }} >
          <Form.Item
            name="deviceNo"
            rules={NameAddressSpecialChar('设备序列号', 30)}
            label="设备序列号"
          >
            <Input
              style={{
                width: 200,
              }}
              autoComplete="off"
              placeholder="请输入设备序列号"
            ></Input>
          </Form.Item>
          <Form.Item
            name="deviceTypeId"
            rules={[
              {
                required: true,
              },
            ]}
            label="设备型号"
          >
            <Cascader 
              style={{
                width: 200,
              }}
              options={deviceTypeSource}
              onChange={deviceTypeChange}
              changeOnSelect
              placeholder="请选择设备类型" />
          </Form.Item>
        </Form>}
        {isVersion && <p><span>软件版本：</span>{softwareVersion ? softwareVersion + " " + dayjs(softwareTime).format("YYYY-MM-DD HH:mm:ss") : '--'}</p>}
        {isVersion && <p className='algorithm'><span>算法版本：</span>{algorithmVersion ? algorithmVersion + " "  + dayjs(algorithmTime).format("YYYY-MM-DD HH:mm:ss") : '--'}</p>}
        <ParameterTable parameterSource={dataSource} modalType={modalType} getSource={getSource}/>
      </Modal>
      
    </>
  );
};

export default EquipmentDetail;