/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-13 13:42:22
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-18 11:02:43
 * @FilePath: /pms-web/src/containers/CapacityEfficiency/components/efficiencyAnalysis.js
 * @Description:  产能人效-详情-产能分析
 */
import { Form, DatePicker, Button } from "antd";
import { useCallback, useEffect, useState } from "react";
import { exportFile } from "../../../utils/commonFunc/exportFile";
import EChartsReact from "echarts-for-react";
import * as echart from "echarts";
import {
  efficiencyListPie,
  efficiencyYieldTrend,
} from "../../../apis/efficiency";
import dayjs from "dayjs";
import "./index.scss";
function EfficiencyAnalysis({ productCode, productName }) {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [modeName, setModeName] = useState("人机");
  // 饼图数据
  const [pieDataList, setPieDataList] = useState([]);
  // 趋势图数据
  const [trendDataList, setTrendDataList] = useState({});
  const getQueryParams = (isList = false) => {
    const { time } = form.getFieldsValue();
    const queryParams = {
      // channel: selectedChannel,
      endTime: null,
      // productCode: "",
      // productName: projectCodeOrName,
      // projectId: selectProjectType,
      startTime: null,
    };
    if (time) {
      queryParams.startTime = dayjs(time[0])
        .startOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      queryParams.endTime = dayjs(time[1])
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
    }
    // eslint-disable-next-line
    return queryParams;
  };

  // 获取产能趋势图表数据
  const getTrendData = () => {
    const params = getQueryParams();
    efficiencyYieldTrend(params).then((res) => {
      setTrendData(res);
    });
  };

  /**
   * @description 设置饼图数据
   */
  const setPieData = (result) => {
    let dataList = [];
    if (result) {
      dataList = result?.map((item, index) => {
        const { title, subTitle, dimensions, source, dataType } = item;
        return {
          projectName: title,
          dataType,
          options: {
            title: {
              text: subTitle,
              x: "center",
              // y: "center",
              top: 55,
              textStyle: {
                fontWeight: 500,
                fontSize: 18,
                fontFamily: "Roboto",
                color: "#374151",
              },
            },
            dataset: {
              source,
              dimensions,
            },
            series: [
              {
                labelLine: {
                  show: true,
                  length: 10,
                },
                type: item.chartType.toLowerCase(),
                color: ["#3884F5", "#FFAE4E"],
                radius: ["60%", "80%"],
                label: {
                  show: true,
                  formatter(param) {
                    return param.value.project + "：" + param.value.number;
                  },
                  fontFamily: "思源黑体 CN",
                  fontSize: "12px",
                  color: "#6B7280",
                },
              },
            ],
          },
        };
      });
    }
    setPieDataList(dataList);
  };

  /**
   * @description 设置趋势图数据
   */
  const setTrendData = (result) => {
    let series = [];
    let dateList = [];
    if (result) {
      series = result?.map((data) => {
        const { title, chartType, source } = data;
        if (chartType.toLowerCase() === "line") {
          return {
            name: title,
            type: chartType.toLowerCase(),
            // stack: "Total",
            data: source.map((sourceItem) => {
              dateList.push(sourceItem.day);
              return sourceItem.number;
            }),
            symbolSize: 8,
            symbol: "circle",
            yAxisIndex: 2,
          };
        }
        return {
          name: title,
          type: chartType.toLowerCase(),
          stack: "Total",
          data: source.map((sourceItem) => {
            return sourceItem.number;
          }),
        };
      });
      const chartOptions = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          x: "center",
          data: ["良品", "缺陷品", "良品率"],
          bottom: 20,
          icon: "roundRect",
        },
        color: ["#3F83F8", "#FFAE4E", "#31C48D"],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          top: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true,
          },
          data: dateList,
        },
        yAxis: [
          {
            type: "value",
            name: "良品",
            position: "left",
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#FFAE4E",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "缺陷品",
            position: "left",
            alignTicks: true,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#FFAE4E",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "良品率",
            position: "right",
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#31C48D",
              },
            },
            axisLabel: {
              interval: "auto",
              formatter: function (value) {
                return value * 100 + "%";
              },
            },
          },
        ],
        series,
      };
      console.log("chartOptions", chartOptions);
      setTrendDataList(chartOptions);
    }
  };

  /**
   * @description 查询列表饼图
   */
  const queryListPie = useCallback(() => {
    const queryParams = getQueryParams();
    efficiencyListPie(queryParams).then((res) => {
      console.log("res====", res);
      setPieData(res);
    });
  }, []);

  useEffect(() => {
    queryListPie();
  }, [queryListPie]);

  useEffect(() => {
    getTrendData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const chart = document.getElementsByClassName("custom-trend-chart")[0];
      echart.init(chart).resize();
    }, 1);
  }, []);

  // 日期选择改变
  const onRangPicker = () => {
    queryListPie();
    getTrendData();
  };

  // 产能分析-导出
  const exportList = () => {
    const params = {};
    const { time } = form.getFieldsValue();
    // params.userName = projectCodeOrName;
    let searchTime = "";
    if (time && time.length) {
      params.startTime = dayjs(time[0])
        .startOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      params.endTime = dayjs(time[1])
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      searchTime =
        "(" +
        dayjs(time[0]).startOf("date").format("YYYY-MM-DD") +
        "——" +
        dayjs(time[1]).startOf("date").format("YYYY-MM-DD") +
        ")";
    }
    params.productCode = productCode;
    params.productName = " ";
    exportFile(
      `/capacityEfficiency/analyticalExport`,
      "post",
      params,
      `${productName}${searchTime}.xlsx`
    );
    // }
  };
  return (
    <>
      <div className="efficiency-analysis-container">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: 32,
          }}
        >
          <Form form={form}>
            <Form.Item name="time" label="选择日期">
              <RangePicker
                onChange={onRangPicker}
                className="staff-range-picker"
              />
            </Form.Item>
          </Form>
        </div>
        <div className="title-container ">
          <div className="rectangle"></div>
          <div className="product-name">
            <span>产品名：{productName}</span>
          </div>
          <div className="cooperate-mode">
            <span>协同模式：{modeName}</span>
          </div>
        </div>
        <div className="chart-container">
          {pieDataList.map((data, index) => (
            <>
              <div className="project-type-circle-container">
                <EChartsReact
                  style={{
                    width: "100%",
                    height: "100%",
                    // borderRight: index !== 2 ? "1px solid #e3e3e3" : "none",
                  }}
                  key={index}
                  option={data.options}
                />
                {data.projectName && (
                  <div className="project-name">{data.projectName}</div>
                )}
              </div>
            </>
          ))}
        </div>
        <div className="title-container">
          <div className="rectangle"></div>
          <div style={{ marginLeft: 7 }}>产能良率趋势</div>
          <Button
            type="link"
            className="export-botton"
            onClick={() => exportList()}
          >
            导出明细
          </Button>
        </div>
        {/* 趋势图 */}
        <div
          className="project-trend-container"
          style={{ width: "100%", marginTop: 20 }}
        >
          <EChartsReact
            option={trendDataList}
            style={{ width: "100%" }}
            autoResize={true}
            className="custom-trend-chart"
          ></EChartsReact>
        </div>
      </div>
    </>
  );
}
export default EfficiencyAnalysis;
