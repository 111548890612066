import { projectDetail } from "@/apis/projectCenter/projectManagement"
import { constantMenu } from "@/menu"
import MyRouter from "@/router"
import eventBus from "@/utils/commonFunc/eventBus"
import { filterMenu, flatMenu } from "@/utils/commonFunc/handleRoute"
import readSession from "@/utils/commonFunc/readSession"
import removeSession from "@/utils/commonFunc/removeSession"
import writeSession from "@/utils/commonFunc/writeSession"
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import { useKeycloak } from "@react-keycloak/web"
import { Layout, Menu } from "antd"
import _ from "lodash"
import React, { createContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import "./App.scss"
import { GET_CONTAINER_HEIGHT } from "./store/constant"

const { SubMenu } = Menu

const { Header, Content, Sider } = Layout

export const CollapsedContext = createContext(null)

const App = () => {
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(false)
  const [openKeys, setOpenKeys] = useState([])
  const [breadcrumb, setBreadcrumb] = useState([])
  const [menuBoolean, setMenuBoolean] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState([])
  const [showProjectName, setShowProjectName] = useState(false)
  const [projectName, setProjectName] = useState("")
  const [erpNo, setErpNo] = useState("")

  let navigate = useNavigate()
  let location = window.location.hash.slice(1)

  const currentUser = useSelector((state) => state.userReducer)

  const { keycloak } = useKeycloak()

  useEffect(() => {
    if (location.includes("/operations-analysis/board")) {
      setSelectedKeys(["/operations-analysis/board"])
    } else if (location.includes("/operations-analysis/efficiency")) {
      setSelectedKeys(["/operations-analysis/efficiency"])
    } else {
      setSelectedKeys([location])
    }
    if (location.includes("/project-space")) {
      setMenuFn(true)
    } else {
      setMenuFn(false)
    }
    const splitLoaction = location?.split("/")
    const openKey = splitLoaction.length > 2 ? `/${splitLoaction[1]}` : "/"
    setOpenKeys([openKey])
    handleUpdateBreadcrumb(location)
    isShowProjectName(
      [
        "/project-space/file",
        "/project-space/overview",
        "/project-space/equipment",
        "/project-space/product"
      ].includes(location)
    )
    // eslint-disable-next-line
  }, [location])

  const isShowProjectName = (condition) => {
    if (condition) {
      setShowProjectName(true)
      projectDetail(readSession("sessionProjectId"))
        .then((res) => {
          setProjectName(res.projectName)
          setErpNo(res.erpNo)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      setShowProjectName(false)
    }
  }

  const userMenus = readSession("user-menu")
  const flatedMenu = flatMenu(userMenus)
  const hasPermission = flatedMenu.map((r) => r.route).filter((e) => e)
  const renderMenus = filterMenu(constantMenu, hasPermission).filter(
    (d) => !["/mobile-workhour","mobile-exam"].includes(d.path)
  )
  const getIcon = (path) => {
    switch (path) {
      case "/":
        // 因为首页路径为 / 会匹配所有故特殊处理
        if (selectedKeys[0]?.split("/").length === 2) {
          return <i className="iconfont icon-view-grid"></i>
        }
        return (
          <img
            src={require("@/assets/img/iconFill/a-xiangmuguanlimoren.png")}
            style={{ width: "20px", height: "20px" }}
            alt=""
          />
        )
      case "/operations-analysis":
        if (selectedKeys[0]?.startsWith(path)) {
          return <i className="iconfont icon-a-yunyingfenximoren"></i>
        }
        return (
          <img
            src={require("@/assets/img/iconFill/a-yunyingfenximoren.png")}
            style={{ width: "20px", height: "20px" }}
            alt=""
          />
        )
      case "/collaborating-centres":
        if (selectedKeys[0]?.startsWith(path)) {
          return <i className="iconfont icon-a-hezuozhongxinmoren"></i>
        }
        return (
          <img
            src={require("@/assets/img/iconFill/a-hezuozhongxinmoren.png")}
            style={{ width: "20px", height: "20px" }}
            alt=""
          />
        )
      case "/equipment-management":
        if (selectedKeys[0]?.startsWith(path)) {
          return <i className="iconfont icon-a-shebeiguanlimoren"></i>
        }
        return (
          <img
            src={require("@/assets/img/iconFill/a-shebeiguanlimoren.png")}
            style={{ width: "20px", height: "20px" }}
            alt=""
          />
        )
      case "/system-management":
        if (selectedKeys[0]?.startsWith(path)) {
          return <i className="iconfont icon-cog"></i>
        }
        return <i className="iconfont icon-cog" style={{ color: "#fff" }}></i>
      case "/project-space":
        if (selectedKeys[0]?.startsWith(path)) {
          return (
            <i
              className="iconfont icon-left-icon"
              style={{ fontSize: "14px" }}
              onClick={goBackMenu}
            ></i>
          )
        }
        return (
          <img
            src={require("@/assets/img/iconFill/a-tuichumoren.png")}
            style={{ width: "20px", height: "20px" }}
            alt=""
          />
        )
      default:
        break
    }
  }
  //  事件监听面包雪传值
  const setBreadcrumbDataFn = (data) => {
    isShowProjectName(
      (data[1] && data[1] === "项目管理" && data[2] && data[2] === "管理") ||
        (data[1] && data[1] === "项目总览")
    )
    setBreadcrumb(data)
  }

  // 面包屑点击事件
  const breadcrumbItemClick = (item) => {
    eventBus.emit("breadcrumbItemClickEvents", item)
  }

  //  事件监听菜单切换
  const setMenuFn = (boolean) => {
    setMenuBoolean(boolean)
  }

  const goBackMenu = () => {
    removeSession("sessionStageId")
    setMenuFn(false)
    let backRouter = readSession("beforeRouter")
    navigate(backRouter)
  }

  const renderMenu = (menuList) => {
    return menuList.map((item) => {
      if (item.children && item.children.length) {
        const iconDom = getIcon(item.path)
        return (
          <SubMenu key={item.path} title={item.name} icon={iconDom}>
            {renderMenu(item.children)}
          </SubMenu>
        )
      }
      return (
        <Menu.Item
          key={item.path}
          onClick={() => {
            handleMenu(item.path)
          }}
        >
          {item.name}
        </Menu.Item>
      )
    })
  }
  const handleMenu = (path) => {
    if (path === location) {
      window.location.reload()
    }
    if (readSession("sessionSave")) {
      eventBus.emit("clickPath", { name: "router", data: path })
    } else {
      navigate(path)
    }
  }

  let containerRef = useRef()
  useEffect(() => {
    dispatch({
      type: GET_CONTAINER_HEIGHT,
      payload: containerRef.current.offsetHeight
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.addEventListener("resize", onWindowResize)
    return () => window.removeEventListener("resize", onWindowResize)
    // eslint-disable-next-line
  }, [])

  let onWindowResize = (e) => {
    dispatch({
      type: GET_CONTAINER_HEIGHT,
      payload: containerRef.current.offsetHeight
    })
  }

  onWindowResize = _.debounce(onWindowResize, 100)

  /**
   * @param keys 父级菜单的key
   * @description 切换主菜单时折叠其余主菜单
   */
  const onOpenChange = (keys) => {
    setOpenKeys([keys[1]])
  }

  /**
   *
   * @param key 子级菜单的key
   * @description 更新面包屑导航
   */
  const handleUpdateBreadcrumb = (location) => {
    let breadcrumbArr = []
    const splitLoaction = location.split("/")
    const parentKey = splitLoaction.length > 2 ? `/${splitLoaction[1]}` : "/"
    const parent = constantMenu.find((item) => item.path === parentKey)
    const child = parent.children.find((item) => item.path === location)
    breadcrumbArr.push(parent?.name, child?.name)
    writeSession("breadcrumbData", breadcrumbArr)
    setBreadcrumb(breadcrumbArr)
  }

  const logOut = () => {
    removeSession("kc-token")
    removeSession("user-menu")
    removeSession("sessionSave")
    keycloak.logout()
  }

  useEffect(() => {
    eventBus.addListener("setBreadcrumbData", setBreadcrumbDataFn)
    return () => {
      eventBus.removeListener("setBreadcrumbData", setBreadcrumbDataFn)
    }
  }, [setBreadcrumbDataFn])

  useEffect(() => {
    eventBus.addListener("setMenuBoolean", setMenuFn)
    return () => {
      eventBus.removeListener("setMenuBoolean", setMenuFn)
    }
  }, [setMenuFn])

  return (
    <Layout className="app" style={{ height: "100vh" }}>
      {!["/mobile-workhour", "/examination", "/mobile-exam", "/mobile-exam/page"].includes(location.split('?')[0]) && (
        <>
          <Sider
            trigger={null}
            collapsible
            className="silder-box"
            collapsed={collapsed}
          >
            <div className="logo">
              {!collapsed ? (
                <img src={require("@/assets/img/logo.png")} alt=""></img>
              ) : (
                <img src={require("@/assets/img/small-logo.png")} alt=""></img>
              )}
            </div>
            <div className="scrollbar">
              <Menu
                mode="inline"
                openKeys={openKeys}
                selectedKeys={selectedKeys}
                className="system-menu"
                onOpenChange={onOpenChange}
              >
                {renderMenu(
                  menuBoolean
                    ? renderMenus.filter((d) => d.path === "/project-space")
                    : renderMenus.filter((d) => d.path !== "/project-space")
                )}
              </Menu>
            </div>
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                background: "#fff",
                height: "58px",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div className="head-left-container">
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    style: { color: "#828282", fontSize: "24px" },
                    onClick: () => setCollapsed(!collapsed)
                  }
                )}
                {/* <Breadcrumb
                className="breadcrumb-style"
                separator="-"
                style={{
                  margin: "14px 36px",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {breadcrumb.map((item, index) => (
                  <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                ))}
              </Breadcrumb> */}
                <div className="my-breadcrumb">
                  {breadcrumb.map((item, index) => (
                    <p key={index}>
                      <span
                        className="breadcrumb-name"
                        onClick={() => {
                          breadcrumbItemClick(item)
                        }}
                      >
                        {item}
                      </span>
                      {index !== breadcrumb.length - 1 && (
                        <span className="gang">/</span>
                      )}
                    </p>
                  ))}
                  {showProjectName && (
                    <div className="project-name">
                      ( {erpNo}：{projectName})
                    </div>
                  )}
                </div>
              </div>
              <span className="user-msg">
                <div className="user-content">
                  <img
                    alt=""
                    style={{ width: 32, height: 32 }}
                    src={require("@/assets/img/headImage.png")}
                  />
                  <span className="user-name">{currentUser.name}</span>
                  <span className="user-logout" onClick={logOut}>
                    退出登录 <i className="iconfont icon-logout1"></i>
                  </span>
                </div>
              </span>
            </Header>
            <CollapsedContext.Provider value={collapsed}>
              <Content
                style={{
                  margin: "20px",
                  overflow: "initial"
                }}
              >
                <div
                  className="site-layout-background content-container"
                  ref={containerRef}
                >
                  <MyRouter></MyRouter>
                </div>
              </Content>
            </CollapsedContext.Provider>
          </Layout>
        </>
      )}
      {location.split("?")[0] === "/examination" && (
        <>
          <Layout className="white-site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                background: "#fff",
                height: "58px",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <img src={require("@/assets/img/white-logo.png")} className="logo" alt=""></img>
              <span className="user-msg">
                <div className="user-content">
                  <img
                    alt=""
                    style={{ width: 32, height: 32 }}
                    src={require("@/assets/img/headImage.png")}
                  />
                  <span className="user-name">{currentUser.name}</span>
                  <span className="user-logout" onClick={logOut}>
                    退出登录 <i className="iconfont icon-logout1"></i>
                  </span>
                </div>
              </span>
            </Header>
            <CollapsedContext.Provider value={collapsed}>
              <Content
                style={{
                  margin: "20px",
                  overflow: "initial"
                }}
              >
                <div
                  className="site-layout-background content-container"
                  ref={containerRef}
                >
                  <MyRouter></MyRouter>
                </div>
              </Content>
            </CollapsedContext.Provider>
          </Layout>
        </>
      )}
      {["/mobile-workhour", "/mobile-exam", "/mobile-exam/page"].includes(String(location.split('?')[0])) && (
        <div
          className="site-layout-background content-container"
          ref={containerRef}
        >
          <MyRouter></MyRouter>
        </div>
      )}
    </Layout>
  )
}

export default App
