/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-09-05 09:13:36
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-09-21 19:14:15
 * @FilePath: /pms-web/src/apis/workHour/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axiosInstance } from "../config"

export const getResidueTime = (params) => {
  return axiosInstance.post("/projectworkhours/lastHour", params)
}

export const getResidueTimeList = (params) => {
  return axiosInstance.post(`/projectworkhours/lastHourList`, params)
}

export const saveTimeList = (params) => {
  return axiosInstance.post("/projectworkhours/myList", params)
}

export const taskTotalHour = (taskId) => {
  return axiosInstance.post(`/projectworkhours/taskCount/?taskId=${taskId}`)
}
// 工时看板分页数据-按照用户查询
export const workHourPageByStaff = (params) => {
  return axiosInstance.post(`/projectworkhours/userPage`, params)
}

// 工时看板分页数据-按照用户查询
export const workHourPageByProject = (params) => {
  return axiosInstance.post(`/projectworkhours/projectPage`, params)
}

// 工时看板导出
export const workHourExport = () => {
  return axiosInstance.post(`/projectworkhours/export`)
}

// 工时看板-查询明细
export const workHourDetail = (params) => {
  return axiosInstance.post(`/projectworkhours/detail`, params)
}

// 组织结构下拉列表
export const userDepListApi = () => {
  return axiosInstance.post(`/project/userDeptTree`)
}

// 部门下拉列表
export const departmentListApi = (companyId) => {
  return axiosInstance.post(`/project/deptList/${companyId}`)
}

// 项目下拉
export const projectListApi = () => {
  return axiosInstance.post(`/project/projectList`)
}

// 用户下拉
export const userListApi = (deptId) => {
  return axiosInstance.post(`/project/userList/${deptId}`)
}

// 回显补填工时
export const getWorkhourApi = () => {
  return axiosInstance.get(`/projectworkhours/getWorkHourReplenishConfig`)
}

// 用户下拉
export const fillWorkhourApi = (params) => {
  return axiosInstance.post(`/projectworkhours/setWorkHourReplenishConfig`, params)
}

// 项目工时统计总和
export const sumWorkhourApi = (params) => {
  return axiosInstance.post(`/projectworkhours/getProjectWorkHourSum`, params)
}
