/*
 * @Description: 
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-04-24 09:34:54
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-12 17:44:58
 */
import { axiosInstance } from "../config";

// 交接人员下拉框
export const handoverPersonnelList = (params) => {
 return axiosInstance.get(`projectUser/getHandoverPersonnelList?source=${params.source}&companyFlag=${params.companyFlag}`); 
};

// 交接列表
export const handoverPersonnelProjectPage = (params) => {
 return axiosInstance.post(`/projectUser/getHandoverPersonnelProjectPage`,params); 
};

// 交接项目
export const handoverProject = (params) => {
 return axiosInstance.post(`/projectUser/handoverProject`,params); 
};