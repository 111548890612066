/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-07-31 16:36:58
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-07-31 16:57:13
 */
import { Button, message } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { getExamPage, submitExamPage } from "../../apis/projectCenter/myExam"
import ExamList from "../../components/ExamList"
import Submit from "../../components/ExamModal"
import "./index.scss"

function Examination() {
  const [examData, setExamData] = useState({})
  const [isDisabled, setIsDisabled] = useState(false)
  const [totalTime, setTotalTime] = useState(undefined);
  const [modalData, setModalData] = useState({
    isOpen: false,
    modalType: "",
    modalWidth: 320,
    score: 0,
    isRestart: false
  })
  const timer = useRef()
  const navigate = useNavigate();
  const currentLoacation = useLocation()
  const [searchParams] = useSearchParams();
  const [submitLoading, setSubmitLoading] = useState(false)

  const getData = (data) => {
    setExamData({
      ...examData,
      examSubjectQuestions: data
    })
  }

  const submit = () => {
    if(examData.examSubjectQuestions.filter(d => !d.commitAnswer || d.commitAnswer === "").length > 0 ) {
      message.warning("请完成试卷后再提交！")
      return
    }
    setModalData({
      ...modalData,
      isOpen: true,
      modalType: "submit",
    })
  }
  
  const submitPage = () => {
    const params = examData.examSubjectQuestions.map(d => {
      return {
        examId: examData.examId,
        subjectId: (currentLoacation.state && currentLoacation.state.subjectId) || searchParams.get("subjectId"),
        commitAnswer: d.commitAnswer && d.commitAnswer != "" ? JSON.stringify(Array.isArray(d.commitAnswer) ? d.commitAnswer : [d.commitAnswer]) : null,
        questionType: d.questionType,
        questionName: d.questionName,
        standardAnswer: JSON.stringify(d.standardAnswer),
        id: d.id,
        subjectName: examData.subjectName
      }
    })
    setSubmitLoading(true)
    submitExamPage(params).then((res) => {
      message.success("提交成功")
      cancelModal()
      setSubmitLoading(false)
      clearInterval(timer.current);
      if(res.isQualified) {
        setModalData({
          ...modalData,
          modalWidth: 600,
          isOpen: true,
          modalType: "pass",
          score: res.score,
          isRestart: res.isRestart
        })
      } else {
        setModalData({
          ...modalData,
          modalWidth: 600,
          isOpen: true,
          modalType: "unqualified",
          score: res.score,
          isRestart: res.isRestart
        })
      }
    })
    .catch(err => {
      console.log(err)
      setSubmitLoading(false)
      navigate("/my-exam")
    })
  }

  const submitOk = () => {
    if(["submit"].includes(modalData.modalType)) {
      submitPage()
    } else if (modalData.modalType === "pass") {
      navigate("/my-exam")
    } else if (modalData.modalType === "unqualified") {
      location.reload();
    }
  }

  const cancelModal = () => {
    setModalData({
      modalWidth: 320,
      isOpen: false,
      modalType: "",
      score: 0,
      isRestart: false
    })
    if(["pass", "unqualified","no-exam-info"].includes(modalData.modalType)) {
      navigate("/my-exam")
    }
  }

  useEffect(() => {
    getExamPage((currentLoacation.state && currentLoacation.state.pageId) || searchParams.get("pageId")).then(res => {
      if(res.sendSuccess) {
        setExamData({
          ...res,
          examSubjectQuestions: res.examSubjectQuestions.map(d => {
            return {
              ...d,
              questionChoose: JSON.parse(d.questionChoose),
              standardAnswer: JSON.parse(d.standardAnswer),
              commitAnswer: d.commitAnswer ? JSON.parse(d.commitAnswer): []
            }
          })
        })
        setTotalTime(res.examDuration * 60)
      } else {
        setModalData({
          ...modalData,
          modalWidth: 360,
          isOpen: true,
          modalType: "no-exam-info",
          score: 0
        })
      }
    })
    .catch(err => console.log(err))
  }, [])

  useEffect(()=>{
    timer.current = setInterval(()=>{
      setTotalTime(currentTime => currentTime - 1);
    } , 1000);
    return ()=>{
      clearInterval(timer.current);
    }
  },[totalTime])

  useEffect(() => {
    if (totalTime === 0) {
      setModalData({
        ...modalData,
        modalWidth: 360,
        isOpen: true,
        modalType: "timeOver",
      })
      setTimeout(() => {
        submitPage()
      }, 3000)
      clearInterval(timer.current);
    }
  }, [totalTime]);

  const countTime = () => {
    let hour = `0${Math.floor((totalTime ? totalTime : 0) / 3600)}`
    let minute = `${Math.floor(((totalTime ? totalTime : 0) % 3600)/ 60)}`
    let second = `${(totalTime ? totalTime : 0) - Math.floor((totalTime ? totalTime : 0) / 3600)* 3600 - Math.floor(((totalTime ? totalTime : 0) % 3600)/ 60) * 60}`
    if (minute.length < 2) {
      minute = `0${minute}`
    }
    if (second.length < 2) {
      second = `0${second}`
    }
    return `${hour}:${minute}:${second}`
  }

  return (
    <div className="exam-page">
      <div className="exam-content">
        <div className="exam-paper">
          <p className="exam-title">{examData.subjectName}</p>
          <p className="exam-explain">{examData.subjectExplain}考试时间为{examData.examDuration}分钟，满分为{examData.fullMarks}分。</p>
          {examData.examSubjectQuestions && <ExamList type={isDisabled} questionList={examData.examSubjectQuestions} getData={getData}/>}
          <p className="submit">
            {!isDisabled && <Button type="primary" className="submit-button" onClick={submit} loading={submitLoading}>提交试卷</Button>}
          </p>
        </div>
        <div className="count-down">
          <p className="count-title">剩余时间</p>
          <p className="count-time">
            {countTime()}
          </p>
        </div>
      </div>
      <Submit
        isModalOpen={modalData.isOpen}
        modalWidth={modalData.modalWidth}
        modalType={modalData.modalType}
        score={modalData.score}
        isRestart={modalData.isRestart}
        submitOk={submitOk}
        cancelModal={cancelModal}
      />
    </div>
  )
}
export default Examination
