import { addPlacementPosture, getPlacementPosture } from "@/apis/projectOverview";
import readSession from "@/utils/commonFunc/readSession";
import UpLoad from "@/components/Upload";
import { Button, Form, Input, message, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { projectUserLevel } from "@/apis/projectCenter/projectManagement";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  productCode,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      if(record.positionName === '' && dataIndex === "stlFile") {
        message.warning("请先填写摆放方式！")
      }
      if(dataIndex != "stlFile") {
        inputRef.current.focus();
      }
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if(dataIndex === "positionName" && values.positionName != record.positionName) {
        handleSave({
          ...record,
          ...values,
          fileData: {},
          stlFile: ''
        });
      } else {
        handleSave({
          ...record,
          ...values,
        });
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  const getFileUrl = (data) => {
    toggleEdit();
    handleSave({
      ...record,
      stlFile: `Product_${productCode}_${record.positionName + "." + data.fileName.split('.')[1]}`,
      fileData: {
        ...data,
        fileName: `Product_${productCode}_${record.positionName + "." + data.fileName.split('.')[1]}`
      }
    });
  }
  const removeUrl = () => {
    toggleEdit();
    handleSave({
      ...record,
      stlFile: '',
      fileData: {},
    });
  }
  const childrenElement = () => {
    switch (dataIndex) {
      case "positionName":
        return <Form.Item
          style={{
            margin: 0,
          }}
          name="positionName"
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} maxLength={30}/>
        </Form.Item>
      case "stlFile":
        return <Form.Item
          style={{
            margin: 0,
          }}
          name="stlFile"
        >
          <UpLoad
            name="file"
            urlPath="/file/minio/upload"
            maxCount={1}
            getFileUrl={getFileUrl}
            removeUrl={removeUrl}
            fileData={record.fileData}
            isIcon={true}
          />
        </Form.Item>
      default:
        break;
    }
  }
  let childNode = children;
  if (editable) {
    let isEdit = record.positionName === '' && dataIndex === "stlFile" ? false : true
    childNode = editing && isEdit  ? (
      childrenElement()
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          whiteSpace: 'pre-wrap',
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const PlacementPosture  = (props) => {
  const { baseData } = props
  const [dataSource, setDataSource] = useState([])
  const [count, setCount] = useState(0);
  const [isMyLiable, setIsMyLiable] = useState(false)
  const defaultColumns= [
    {
      title: '摆放方式',
      dataIndex: 'positionName',
      width: "30%",
      ellipsis: true,
      editable: true,
    },
    {
      title: 'STL',
      dataIndex: 'stlFile',
      width: "70%",
      editable: true,
    },
    {
      dataIndex: 'operation',
      width: 80,
      className: "special-operation",
      filterDropdown: true,
      filterIcon: () => {return (isMyLiable && <i onClick={addrow} className='iconfont icon-plus-circle iconplus'></i>)},
      render: (_, record) =>
      dataSource.length >= 1 ? (
          <i className='iconfont icon-minus-circle iconminus' onClick={() =>  handleDelete(record.key)}></i>
        ) : null,
    },
  ];
  useEffect(() => {
    let params = {
      productCode: baseData.productCode,
      projectId: baseData.projectId
    }
    getPlacementPosture(params).then(res => {
      setDataSource((res.positionList || []).map((d, idx) => {
        return {
          ...d,
          key: idx
        }
      }))
      setCount(res.positionList.length || 0)
    })
    projectUserLevel(readSession("sessionProjectId"))
      .then((res) => {
        setIsMyLiable([1,2].includes(res))
      })
      .catch((e) => console.log(e))
  },[props])
  const addrow = () => {
    const newData = {
      key: count,
      positionName: '',
      stlFile: '',
      productCode: baseData.productCode,
      projectId: baseData.projectId
    };
    setDataSource([...dataSource,newData]);
    setCount(count + 1);
  }
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  }
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const argColumns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        ellipsis: col.ellipsis,
        dataIndex: col.dataIndex,
        title: col.title,
        productCode: baseData.productCode,
        handleSave,
      }),
    };
  });
  const confirm = () => {
    for (let i = 0; i < dataSource.length; i++) {
      const element = dataSource[i];
      if(element.positionName === "" || element.stlFile === '') {
        message.warning(`请完善第${i + 1}行的信息`)
        return
      }
    }
    const params = {
      positionList: dataSource,
      productCode: baseData.productCode,
      projectId: baseData.projectId
    }
    addPlacementPosture(params).then(res => {
      if(res) {
        message.success("保存成功")
      }
    })
    .catch(() => {})
  }
  const goBack = () => {
    props.goBack()
  }
  return(
    <div className="placement-posture">
      <Table
        components={components}
        style={{border:"1px solid #D9DCE1",width: "500px"}}
        className="custom-table "
        rowClassName={() => 'editable-row'}
        dataSource={dataSource}
        columns={props.type ? argColumns.filter(d => d.dataIndex != "operation" && d.dataIndex != "argType") : argColumns}
        pagination={false}
      />
      {isMyLiable && <div className="foot">
        <Button onClick={goBack}><i className="iconfont icon-x"></i>取消</Button>
        <Button type="primary" onClick={confirm}><i className="iconfont icon-baocun"></i>保存</Button>
      </div>}
    </div>
  )
}

export default PlacementPosture