import ArgTable from "@/containers/EquipmentAssembly/components/ArgTable";
import { Cascader, Form, message, Modal, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getComponentSecondaryTree } from "../../../apis/deviceManage/type/index";
import { projectUserLevel } from "@/apis/projectCenter/projectManagement";
import readSession from "@/utils/commonFunc/readSession";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleChange,
  modalType,
  ...restProps
}) => {
const [editing, setEditing] = useState(false);
const [options, setOptions] = useState([])
const cascaderRef = useRef(null)
const form = useContext(EditableContext);
useEffect(() => {
  if (editing) {
    if(title === "组件" && modalType != "check") {
      cascaderRef.current.focus();
    } 
  }
}, [editing,title]);
const toggleEdit = () => {
  setEditing(!editing);
  form.setFieldsValue({
    [dataIndex]: record[dataIndex],
  });
};

const change = async (value) => {
  try {
    let data = {
      id: '',
      componentParameter: ''
    }
    if(value.length > 1) {
      toggleEdit();
      options.forEach(d => {
        if(d.value === value[0]){
          d.children.forEach(p => {
            if(p.value === value[1]){
              data.id = p.id
              data.componentParameter = p.componentParameter
            }
          });
        }
      })
    }
    handleChange({
      ...record,
      value,
      data
    });
  } catch (errInfo) {
    // console.log('Save failed:', errInfo);
  }
};
useEffect(() => {
  getOptions()
},[])

const getOptions = () => {
  getComponentSecondaryTree().then(res => {
    let source = res.map(d => {
      let item = {}
      item.label = d.componentType
      item.value = d.componentType
      item.children = d.componentChilds.map(p => {
        return{
          id: p.id,
          value: p.componentBrand + '-' + p.componentModel,
          label: p.componentBrand + '-' + p.componentModel,
          componentParameter: p.componentParameter
        }
      })
      return item
    })
    setOptions(source)
  })
  .catch((e) => message.error(e));
}

let childNode = children;
  if (editable) {
    childNode = editing && modalType != "check" ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title}必填`,
          },
        ]}
      >
        <Cascader ref={cascaderRef} options={options} onChange={change} changeOnSelect placeholder="请选择组件" />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {[undefined, options.filter(d => d.value === children[1])[0] ? options.filter(d => d.value === children[1])[0].label : undefined] }
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const ParameterTable = (props) => {
  const { parameterSource, modalType } = props
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(0);
  const [parameterOpen,setParameterOpen] = useState(false)
  const [parameterTitle, setParameterTitle] = useState('')
  const [parameterData, setParameterData] = useState([])
  const [parameterId, setParameterId] = useState(null)
  const [tempParameter, setTempParameter] = useState([])
  const [isMyLiable, setIsMyLiable] = useState(false)

  useEffect(() => {
    if(parameterSource){
      setDataSource(parameterSource)
      setCount(parameterSource.length)
    } else {
      setDataSource([])
      setCount(0)
    }
    projectUserLevel(readSession("sessionProjectId"))
      .then((res) => {
        setIsMyLiable([1,2].includes(res))
      })
      .catch((e) => console.log(e))
  },[parameterSource])

  const defaultColumns = [
    {
      title: '组件',
      dataIndex: 'componentType',
      width: '30%',
      editable: true,
      ellipsis: true,
    },
    {
      title: '品牌',
      dataIndex: 'componentBrand',
      ellipsis: true,
    },
    {
      title: '型号',
      dataIndex: 'componentModel',
      ellipsis: true,
    },
    {
      title: "操作",
      width: 90,
      dataIndex: "parameterOperation",
      render: (_, record) => 
        modalType === "check" ? (
          <span className='parameter' onClick={() => checkParameter(record)}>查看参数</span>) 
        : (
          <span className='parameter' onClick={() => updateParameter(record)}>更新参数</span>
        ) 
    },
    {
      dataIndex: 'operation',
      width: 80,
      className: "special-operation",
      filterDropdown: true,
      filterIcon: () => {return (isMyLiable && <i onClick={handleAdd} className='iconfont icon-plus-circle iconplus'></i>)},
      render: (_, record) =>
        dataSource.length >= 1 ? (
            <span onClick={() => handleDelete(record.key)}><i className='iconfont icon-minus-circle iconminus'></i></span>
        ) : null,
    },
  ];
  const handleAdd = () => {
    if(modalType === "check") return
    const newData = {
      key: count,
      componentType: '',
      componentBrand: '',
      componentModel: '',
    };
    updateSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    updateSource(newData);
  };
  const checkParameter = (item) => {
    setParameterTitle(item.componentBrand + " - " + item.componentModel)
    let parameterlist = JSON.parse(item.componentParameter)
    setParameterData(parameterlist)
    setTempParameter(parameterlist.map((d,idx) => {
      return {...d,key:idx}
    }))
    setParameterOpen(true)
  }
  const updateParameter = (item) => {
    if(!item.id || item.id === "") {
      message.warning("请选择组件")
      return
    }
    let index = dataSource.findIndex(d => d === item)
    setParameterId(index)
    setParameterTitle(item.componentBrand + " - " + item.componentModel)
    let parameterlist = JSON.parse(item.componentParameter)
    setParameterData(parameterlist)
    setTempParameter(parameterlist.map((d,idx) => {
      return {...d,key:idx}
    }))
    setParameterOpen(true)
  }
  const getParameter = (data) => {
    setTempParameter(data)
  }
  const comfirmParameter = () => {
    for (let i = 0; i < tempParameter.length; i++) {
      const element = tempParameter[i];
      if(!element.argName || !element.argValue || !element.argType || !element.argKey) {
        message.warning(`请完善第${i + 1}行信息`);
        return
      }
    }
    const newData = [...dataSource];
    const item = newData[parameterId];
    newData.splice(parameterId, 1, {
      ...item,
      componentParameter: JSON.stringify(tempParameter)
    });
    updateSource(newData);
    setParameterOpen(false)
  }
  const closeParameter = () => {
    setTempParameter(parameterData)
    setParameterOpen(false)
  }
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const handleChange = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    const brandModel = row.value[1] ? row.value[1].split('-') : null
    item.componentType = row.value[0]
    if(brandModel){
      item.componentBrand = brandModel[0]
      item.componentModel = brandModel[1]
      item.id = row.data.id
      item.componentParameter = row.data.componentParameter
    }
    newData.splice(index, 1, item);
    updateSource(newData);
  };
  const updateSource = (data) => {
    setDataSource(data);
    props.getSource(data)
  }
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        modalType,
        handleSave,
        handleChange
      }),
    };
  });

  return (
    <>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        style={{width: props.width ? props.width + 'px' : "100%"}}
        bordered
        dataSource={dataSource}
        columns={modalType === "check" ? columns.filter(d => d.dataIndex != "operation") : columns}
        pagination={false}
      />
      <Modal
        width="40%"
        className='custom-modal'
        maskClosable={false}
        okButtonProps={{ style: { display: modalType === "check" ? 'none' : 'inline-block' } }}
        title={parameterTitle}
        open={parameterOpen}
        onOk={comfirmParameter}
        onCancel={closeParameter}
        okText={<span><i className="iconfont icon-baocun"></i>确认</span>}
        cancelText={modalType === "check" ? <span><i className="iconfont icon-reply"></i>返回</span> : <span><i className="iconfont icon-x"></i>取消</span>}
      >
        <ArgTable dataSource={tempParameter} getParameter={getParameter} type={modalType === "check" ? "check" : "update"} columnType={true}/>
      </Modal>
    </>
  )
}

export default ParameterTable