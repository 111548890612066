/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-16 16:03:26
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-12 17:49:16
 */
import { roleList } from "@/apis/projectCenter/common"
import { Button, Form, Input, message, Radio, Select, Switch } from "antd"
import { useEffect, useState } from "react"
import {
  LengthRange,
  NameNotSpecialChar,
  RulesTelphone
} from "../../../utils/commonFunc/validate"
import { handoverProject, handoverPersonnelList } from "@/apis/connectPersonnel"
import "../style/index.scss"
import { type } from "@testing-library/user-event/dist/type"

const { Option } = Select

function AddMember(props) {
  const { data, isOpen, confirmClick } = props
  const [form] = Form.useForm() //表单
  const [radioValue, setRadioValue] = useState(0)
  const layout = {
    labelCol: {
      span: 6
    }
  }
  const [memberList, setMemberList] = useState([]) //成员列表
  const [isLoading, setIsLoading] = useState(false) //保存loading

  useEffect(() => {
    form.setFieldValue("userSource", 0)
    console.log(data)
    getHandoverPersonnelList(0)
  }, [isOpen]) // eslint-disable-line

  /**
   * @description: 获取选择成员下拉
   * @return {*}
   * @author: LINA.QI
   */
  const getHandoverPersonnelList = (params) => {
    handoverPersonnelList({ source: params, companyFlag: 9 })
      .then((res) => {
        if (params === 0) {
          res.uuamInCompanyUser.forEach((item) => {
            item.disabled = false
            if (item.userId === data.personnel) {
              item.disabled = true
            }
          })
          setMemberList(res.uuamInCompanyUser)
        } else {
          res.channelUser.forEach((item) => {
            item.disabled = false
            if (item.userId === data.personnel) {
              item.disabled = true
            }
          })
          setMemberList(res.channelUser)
        }
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 切换成员
   * @return {*}
   * @author: LINA.QI
   */
  const [newUserInfo, setNewUserInfo] = useState({})
  const handleType = (data, option) => {
    form.setFieldValue("userName", option.userName)
    setNewUserInfo(
      memberList.find((item) => {
        return item.userId === option.value
      })
    )
  }

  /**
   * @description: 切换radio
   * @return {*}
   * @author: LINA.QI
   */
  const handChangeRadio = (data) => {
    form.resetFields()
    form.setFieldValue("userSource", data.target.value)
    getHandoverPersonnelList(data.target.value)
  }

  /**
   * @description: 保存
   * @return {*}
   * @author: LINA.QI
   */
  const handleSubmit = () => {
    form
      .validateFields()
      .then((res) => {
        console.log(newUserInfo)
        setIsLoading(true)
        const params = {
          flag: data.type === "alone" ? false : true,
          newUserId: res.userId,
          type: res.userSource,
          userName: form.getFieldValue().userName,
          ...data.data,
          newUserInfo: newUserInfo
        }
        console.log(params)

        handoverProject(params)
          .then((res) => {
            console.log(res)
            if (res) {
              confirmClick()
              setIsLoading(false)
              message.success("提交成功")
            }
          })
          .catch((e) => {
            setIsLoading(false)
            message.error(e)
          })
      })
      .catch((errorInfo) => {
        console.log(errorInfo)
      })
  }

  return (
    <>
      <div className="addProject">
        <Form form={form} {...layout}>
          <Form.Item
            name="userSource"
            label="选择来源："
            rules={[{ required: true, message: `请选择来源` }]}
          >
            {data.source === 1 ? (
              <Radio.Group onChange={handChangeRadio}>
                <Radio value={0}>UUAM</Radio>
                <Radio value={1}>合作渠道</Radio>
              </Radio.Group>
            ) : (
              <Radio.Group onChange={handChangeRadio}>
                <Radio value={0}>UUAM</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item
            name="userId"
            label="交接人员："
            rules={[{ required: true, message: `请选择成员` }]}
          >
            <Select
              style={{ width: "260px" }}
              placeholder="请选择成员"
              allowClear
              onChange={handleType}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={memberList.map((d) => ({
                label: d.userName + (d.userDept ? `(${d.userDept})` : ""),
                value: d.userId,
                userName: d.userName,
                disabled: d.disabled
              }))}
            ></Select>
          </Form.Item>
        </Form>
      </div>
      <div className="dialog-footer">
        <Button
          className="cancel"
          disabled={isLoading}
          onClick={() => confirmClick()}
        >
          <i className="iconfont icon-x"></i>取消
        </Button>
        <Button
          className="confirm"
          loading={isLoading}
          type="primary"
          onClick={handleSubmit}
        >
          <i className="iconfont icon-baocun"></i>确认
        </Button>
      </div>
    </>
  )
}
export default AddMember
