import { Button, Upload } from "antd";
import { useEffect, useState } from "react";
import readSession from "../../utils/commonFunc/readSession";
import "./index.scss";

const UpLoad = (props) => {
  const { getFileUrl, removeUrl, fileData, isIcon, disabled, type } = props
  const uploadUrl = process.env.REACT_APP_BASE_URL
  const [fileList, setFileList] = useState([])

  useEffect(()=> {
    let list = []
    if(fileData && fileData.url) {
      list = [{
        name: fileData.fileName,
        status: 'done',
        url: fileData.url,
      }]
    }
    setFileList(list)
  },[fileData])
  const onChange = ({file,fileList}) => {
    setFileList(fileList)
    if(fileList[0] && fileList[0].status === "done") {
      getFileUrl(fileList[0].response.data)
    }
  }

  const onRemove = (file) => {
    if(type === "check") {
      return false
    }
    if(["removed", "done"].includes(file.status) ) {
      removeUrl()
    }
  }

  const onPreview = (data) => {
    const url = data.url
    if (url) {
      let fileUrl = process.env.REACT_APP_BASE_URL + "/file/minio/download" + url
      let xhr = new XMLHttpRequest();
      xhr.open("get", fileUrl, true);
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xhr.responseType = "blob";
      xhr.onload = function () {
        let fileHeaderName = xhr.getResponseHeader("Content-disposition");
        let down = document.createElement("a");
        let blobUrl = "";
        if (xhr.status === 200) {
          let blob = new Blob([this.response], { type: "application/zip" });
          blobUrl = window.URL.createObjectURL(blob);
          down.href = blobUrl;
          let fileName = data.name.split(".");
          down.download = window.decodeURIComponent(
            fileName[0]
          );
        }
        down.click();
        window.URL.revokeObjectURL(blobUrl);
      };
      xhr.onerror = function () {
      };
      xhr.send();
    }
  }

  const uploadProps = {
    ...props,
    headers: {
      authorization:
        readSession("kc-token") && "Bearer " + readSession("kc-token"),
    },
    action: uploadUrl + props.urlPath,
    disabled: disabled,
    onChange: onChange,
    onRemove: onRemove,
    onPreview: onPreview,
  }
  return (
    <Upload
      {...uploadProps}
      fileList={fileList}
      showUploadList={true}
    >
      {fileList.length === 0 && (isIcon ?  <i className='iconfont icon-upload upload'></i> : <Button >上传文件</Button>)}
    </Upload>
  )
}
export default UpLoad