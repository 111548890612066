/*
 * @Author: gn
 * @Date: 2023-06-15 20:25:34
 * @LastEditors: gn
 * @LastEditTime: 2023-07-20 15:13:11
 * @Description: file content
 */
import OperateButton from "@/components/OperateButton";
import {
  Button,
  DatePicker,
  Form,
  message,
  Modal,
  Pagination,
  Select,
  Table,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  addSaleTool,
  deleteToolById,
  disableTool,
  enableTool,
  getToolsPage,
  queryDemoProjects,
  queryToolById,
  updateSaleTool,
} from "../../../apis/channelManage";
import { exportFile } from "../../../utils/commonFunc/exportFile";

function MarketTabs({ channelId }) {
  const [dataSource, setDataSource] = useState([]);
  const { containerHeight } = useSelector((state) => state.appReducer);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  // 0-关闭 1-新增 2-修改
  const [modalMode, setModalMode] = useState(0);
  const [editToolData, setEditToolData] = useState({});
  // 工具启用 提示文字
  const [enableTip, setEnableTip] = useState("");
  const [toolTypeList, setToolTypeList] = useState([]);
  const [projectList, setProjectList] = useState([
    { name: "项目1", value: 0 },
    { name: "项目2", value: 1 },
  ]);
  const columns = [
    {
      title: "云端设备号（虚拟）",
      dataIndex: "cloudDeviceNumber",
      ellipsis: true,
    },
    {
      title: "设备序列号（虚拟）",
      dataIndex: "deviceNumber",
      ellipsis: true,
    },
    {
      title: "类型",
      dataIndex: "typeName",
      ellipsis: true,
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      ellipsis: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--";
      },
    },
    {
      title: "有效期",
      dataIndex: "periodValidity",
      ellipsis: true,
    },
    {
      title: "状态",
      dataIndex: "status",
      ellipsis: true,
      render: (_, record) => {
        return (
          <div className={record.status === 1 ? "status1" : "status2"}>
            {record.status === 1 ? "正常" : "已停用"}
          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "220px",
      render: (text, record) => (
        <div className="operation">
          <OperateButton
            text="修改"
            icon="icon-pencil"
            data={record}
            click={() => handleEdit(record)}
          />
          {record.status === 1 && (
            <OperateButton
              text="停用"
              icon="icon-ban"
              popType={true}
              title={`你确定要停用该销售工具吗？`}
              data={record}
              confirm={() => handleDisable(record.id)}
            />
          )}
          {record.status === 4 && (
            <OperateButton
              text="启用"
              icon="icon-play"
              popType={true}
              title={enableTip}
              data={record}
              click={() => handleEnableTip(record)}
              confirm={() => handleEnable(record.id)}
            />
          )}
          <OperateButton
            text="下载token"
            icon="icon-download"
            click={() => handleDownload(record)}
          />
          <OperateButton
            text="删除"
            icon="icon-trash"
            popType={true}
            title={"你确定要删除该销售工具吗？注意删除后无法恢复！"}
            data={record}
            confirm={() => handleDelete(record.id)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    queryList();
    // eslint-disable-next-line
  }, [page, size]);

  useEffect(() => {
    if (modalMode === 1) {
      getDemoProjectList();
      const params = {
        categoryCode: "sales_tool_type",
      };
      getToolList(params);
    }
  }, [modalMode]);

  // 查询工具类型列表
  const getToolList = (id) => {
    queryToolById(id).then((res) => {
      const list = res?.map((item) => {
        const { dictCode, dictDesc } = item;
        return {
          dictCode,
          dictDesc,
        };
      });
      setToolTypeList(list);
    });
  };

  // 查询演示项目
  const getDemoProjectList = () => {
    const params = {
      data: {
        canItBeOperated: false,
        status: 1,
      },
      page: {
        pageIndex: 1,
        pageSize: 99999,
      },
    };
    queryDemoProjects(params).then((res) => {
      const list = res?.list.map((item) => {
        const { id, projectName } = item;
        return {
          id,
          projectName,
        };
      });
      setProjectList(list);
    });
  };

  // 销售工具分页列表
  const queryList = () => {
    const params = {
      data: {
        channelId,
      },
      page: {
        pageIndex: page,
        pageSize: size,
      },
    };
    getToolsPage(params).then((result) => {
      setTotal(result?.total);
      setDataSource(result?.list);
    });
  };

  // 修改
  const handleEdit = (record) => {
    setEditToolData(record);
    setModalMode(2);
    form.setFieldsValue({
      validityDate: dayjs(record.periodValidity),
    });
  };

  // 停用
  const handleDisable = (id) => {
    disableTool(id).then((res) => {
      if (res) {
        message.success("停用成功");
        queryList();
      }
    });
  };

  // 设置启用提示文字-根据是否已过有效期
  const handleEnableTip = (record) => {
    const validTime = new Date(record.periodValidity).getTime();
    const nowTime = new Date().getTime();
    if (validTime < nowTime) {
      setEnableTip("无法启用，请先修改工具有效期！");
    } else {
      setEnableTip("你确定要启用该销售工具吗？”");
    }
  };

  // 启用
  const handleEnable = (id) => {
    enableTool(id)
      .then((res) => {
        if (res) {
          message.success("启用成功");
          queryList();
        }
      })
      .catch((e) => {
        message.error(e);
      });
  };

  // 下载token
  const handleDownload = (record) => {
    if (record.statusName === "已停用") {
      message.error("销售工具已禁用，无法下载token！");
      return;
    }
    const { id, typeName, deviceNumber } = record;
    exportFile(
      `/channelsalestool/downloadToken/${id}`,
      "post",
      {},
      `${typeName}-${deviceNumber}-token.txt`
    );
  };

  // 下载token
  const handleDelete = (id) => {
    deleteToolById(id)
      .then((res) => {
        message.success("删除成功");
        queryList();
      })
      .catch((e) => {
        message.error(e);
      });
  };

  // 新增渠道点击事件
  const handleAddClick = () => {
    setModalMode(1);
  };

  const handleOk = () => {
    const values = form.getFieldsValue();
    const { selectedProjectType, selectedToolType, validityDate } = values;
    const formatTime = dayjs(validityDate).format("YYYY-MM-DD HH:mm:ss");
    form
      .validateFields()
      .then((values) => {
        if (modalMode === 1) {
          addSaleTool({
            channelId,
            periodValidity: formatTime,
            projectId: selectedProjectType,
            type: selectedToolType,
          }).then((res) => {
            setModalMode(0);
            if (res) {
              message.success("新增销售工具成功！");
              queryList();
            }
          });
        } else {
          updateSaleTool({
            id: editToolData.id,
            periodValidity: formatTime,
          }).then((res) => {
            queryList();
            setModalMode(0);
          });
        }
      })
      .catch((e) => {
        // throw e;
      });
  };

  const handleCancel = () => {
    setModalMode(0);
  };

  const handleModalClose = () => {
    form.resetFields();
  };
  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };
  return (
    <div className="MarketTabs">
      <Button
        type="primary"
        style={{ marginBottom: "15px" }}
        onClick={() => handleAddClick()}
      >
        添加
        <i className="iconfont icon-plus-sm" style={{ fontSize: "14px" }}></i>
      </Button>
      <Table
        size="middle"
        className="custom-table market-table"
        rowKey={(record) => record.id}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={
          {
            // y: containerHeight - 56 - 24 * 2 - 32 - 56 * 2 - 24 * 2,
          }
        }
      />
      <Pagination
        total={total}
        pageSize={size}
        current={page}
        pageSizeOptions={["10", "20", "30", "40", "50"]}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `共 ${total} 条`}
        onChange={handleChangePagination}
        className="custom-pagination"
      />

      <Modal
        width="413px"
        className="custom-modal add-tool-modal"
        title={modalMode === 1 ? "添加销售工具" : "修改有效期"} //"添加销售工具"
        maskClosable={false}
        open={modalMode}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={handleModalClose}
        okText={
          <span>
            <i className="iconfont icon-baocun"></i>保存
          </span>
        }
        cancelText={
          <span>
            <i className="iconfont icon-x"></i>取消
          </span>
        }
      >
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 19 }}>
          {modalMode === 1 && (
            <Form.Item
              name="selectedToolType"
              rules={[{ required: true }]}
              label="工具类型"
            >
              <Select
                style={{
                  width: 300,
                }}
                //   disabled
                placeholder="选择工具类型"
                options={toolTypeList.map((d) => ({
                  label: d.dictDesc,
                  value: d.dictCode,
                }))}
              ></Select>
            </Form.Item>
          )}
          {modalMode === 1 && (
            <Form.Item
              name="selectedProjectType"
              rules={[{ required: true }]}
              label="演示项目"
            >
              <Select
                style={{
                  width: 300,
                }}
                placeholder="选择演示项目类型"
                options={projectList.map((d) => ({
                  label: d.projectName,
                  value: d.id,
                }))}
              ></Select>
            </Form.Item>
          )}
          <Form.Item
            name="validityDate"
            rules={[{ required: true }]}
            label="有效期至"
          >
            <DatePicker
              format="YYYY-MM-DD"
              placeholder="选择日期"
              style={{ width: 300 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default MarketTabs;
