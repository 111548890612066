/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-27 13:18:12
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-06-29 14:39:10
 * @FilePath: /pms-web/src/apis/efficiency/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axiosInstance } from "../config";
// 产能良率趋势
export const efficiencyYieldTrend = (params) => {
  return axiosInstance.post(
    `/capacityEfficiency/productivity/yieldTrend`,
    params
  );
};

// 产能能效列表
export const efficiencyList = (params) => {
  return axiosInstance.post(`/capacityEfficiency/list`, params);
};

// 通过产品code查询产品详情
export const productByCode = (productCode) => {
  return axiosInstance.post(
    `/capacityEfficiency/getProductByCode?productCode=${productCode}`
  );
};

// 产能能效列表饼图
export const efficiencyListPie = (params) => {
  return axiosInstance.post(`/capacityEfficiency/listPie`, params);
};

// 登录明细
export const efficiencyLoginDetail = (params) => {
  return axiosInstance.post(`/capacityEfficiency/loginDetail`, params);
};

// 按人员设备列表
export const efficiencyStatisticsByDevice = (params) => {
  return axiosInstance.post(`/capacityEfficiency/statisticsByDevice`, params);
};

// 按人员统计列表
export const efficiencyStatisticsByPersonnel = (params) => {
  return axiosInstance.post(
    `/capacityEfficiency/statisticsByPersonnel`,
    params
  );
};

// 按人员统计列表
export const efficiencyStatisticsByPersonnelExport = (params) => {
  return axiosInstance.post(
    `/capacityEfficiency/statisticsByPersonnelExport`,
    params
  );
};
