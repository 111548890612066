/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-05 13:17:05
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-07-31 13:31:08
 */
import eventBus from "@/utils/commonFunc/eventBus"
import readSession from "@/utils/commonFunc/readSession"
import writeSession from "@/utils/commonFunc/writeSession"
import { Modal } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ApproveWorkHour from "./components/ApproveWorkHour"
import BasicInformation from "./components/BasicInformation"
import ProjectMember from "./components/ProjectMember"
import ProjectPlan from "./components/ProjectPlan"
import Schedule from "./components/Schedule"
import ProjectExam from "./components/ProjectExam"
import StatisticsWorkhour from "./components/StatisticsWorkhour"
import "./index.scss"

function Management(props) {
  const { projectData, changeComponents } = props
  const tabList = [
    "基本信息",
    "项目成员",
    "项目计划",
    "工时审批",
    "产线排班",
   "工时统计",
    "项目考试"
  ] // tab列表
  const [activeIndex, setActiveIndex] = useState(0) // 点击索引
  const [open, setOpen] = useState(false)
  const [clickData, setClickData] = useState(null) //卡控的来源
  let navigate = useNavigate()

  /**
   * @description: 切换tab
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeTab = (index) => {
    if (readSession("sessionSave") && index !== 2) {
      eventBus.emit("clickPath", { name: "tab", data: index })
      return
    } else {
      setActiveIndex(index)
    }
  }

  //卡控更改弹窗
  const saveEventsFn = (data) => {
    setOpen(true)
    setClickData(data)
  }

  //监听未保存点击事件
  useEffect(() => {
    eventBus.addListener("clickPath", saveEventsFn)
    return () => {
      eventBus.removeListener("clickPath", saveEventsFn)
    }
  }, [saveEventsFn])

  //弹窗确认
  const handleOk = () => {
    writeSession("sessionSave", false)
    switch (clickData.name) {
      case "bread":
        breadcrumbItemClickEventsFn(clickData.data)
        setOpen(false)
        break
      case "tab":
        handleChangeTab(clickData.data)
        setOpen(false)
        break
      case "router":
        if (clickData.data === "/project-management") {
          changeComponents()
        } else {
          navigate(clickData.data)
        }
        setOpen(false)
        break
      default:
        return
    }
  }

  //弹窗取消
  const handleCancel = () => {
    setOpen(false)
  }

  // 面包雪点击传来的值以及方法
  const breadcrumbItemClickEventsFn = (item) => {
    if (readSession("sessionSave")) {
      eventBus.emit("clickPath", { name: "bread", data: "项目管理" })
    } else {
      switch (item) {
        case "项目管理":
          eventBus.emit("setBreadcrumbData", ["项目中心", "项目管理"])
          changeComponents()
          break
        default:
          return
      }
    }
  }

  //面包屑
  useEffect(() => {
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    )
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      )
    }
  }, [breadcrumbItemClickEventsFn])

  //后退事件
  useEffect(() => {
    window.history.pushState(null, null, document.URL)
    window.onpopstate = function (e) {
      window.history.pushState(null, null, document.URL)
      if (readSession("sessionSave")) {
        eventBus.emit("clickPath", { name: "bread", data: "项目管理" })
      }
    }
    return () => {
      // 回退事件只用于当前组件，则需要在组件销毁时把回退事件销毁
      window.onpopstate = null
    }
  }, [])

  return (
    <>
      <div className="management">
        <div className="tab-box">
          {tabList.map((item, index) => (
            <span
              key={index}
              className={[
                "tab-list",
                activeIndex === index ? "tab-active" : null
              ].join(" ")}
              onClick={() => handleChangeTab(index)}
            >
              {item}
            </span>
          ))}
        </div>
        {activeIndex === 0 ? (
          <BasicInformation projectData={projectData} />
        ) : activeIndex === 1 ? (
          <ProjectMember
            projectData={projectData}
            goBack={breadcrumbItemClickEventsFn}
          />
        ) : activeIndex === 2 ? (
          <ProjectPlan projectData={projectData} />
        ) : activeIndex === 3 ? (
          <ApproveWorkHour projectData={projectData} />
        ) : activeIndex === 4 ? (
          <Schedule projectData={projectData} />
        ) : activeIndex === 5 ? (
          <StatisticsWorkhour projectData={projectData} />
        ) : (
          <ProjectExam projectData={projectData} />
        )}

        <Modal
          title=""
          centered={true}
          open={open}
          maskClosable={false}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>当前页面有未保存的内容，是否确认退出？</p>
        </Modal>
      </div>
    </>
  )
}

export default Management
