/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-07-21 05:34:52
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-07-17 16:44:22
 */
import { myProjectDisabledSop } from "@/apis/projectCenter/common"
import {
  addOrCancelStar,
  projectPage,
  statusCount
} from "@/apis/projectCenter/myProject"
import eventBus from "@/utils/commonFunc/eventBus"
import readSession from "@/utils/commonFunc/readSession"
import removeSession from "@/utils/commonFunc/removeSession"
import writeSession from "@/utils/commonFunc/writeSession"
import { Card, Form, Input, message, Pagination, Progress, Select } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import StateTab from "../../components/StateTab"
import "./index.scss"

import React from "react"
const { Search } = Input
const { Option } = Select

function MyExamine() {
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [filterParams, setFilterParams] = useState({
    status: 999,
    keyword: "",
    rcode: null
  }) // eslint-disable-line
  const [stateTabList, setStateTabList] = useState([]) // 项目状态列表
  const [checkStatus, setCheckStatus] = useState(999) //当前项目状态
  const [projectOptions, setProjectOptions] = useState([]) //项目类型下拉框数据
  const [cardList, setCardList] = useState([]) //列表内容
  const [form] = Form.useForm() 
  let navigate = useNavigate()

  useEffect(() => {
    getStatusCount() //获取状态列表
    getSelectAllSop() //项目类型下拉
    if(readSession("MyProjectParams")) {
      setFilterParams(readSession("MyProjectParams").filterParams)
      form.setFieldsValue(readSession("MyProjectParams").filterParams)
      setCheckStatus(readSession("MyProjectParams").filterParams.status)
      setPage(readSession("MyProjectParams").page)
      setSize(readSession("MyProjectParams").size)
    }
  }, [])

  useEffect(() => {
    queryList(readSession("MyProjectParams"))
  }, [page, size])

  /**
   * @description: 获取状态列表
   * @return {*}
   * @author: LINA.QI
   */
  const getStatusCount = () => {
    statusCount()
      .then((res) => {
        setStateTabList(res)
      })
      .catch((e) => message.error(e))
  }
  /**
   * @description: 获取项目类型下拉
   * @return {*}
   * @author: LINA.QI
   */
  const getSelectAllSop = () => {
    myProjectDisabledSop()
      .then((res) => {
        setProjectOptions(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description:切换项目状态
   * @return {*}
   * @author: LINA.QI
   */
  const handleCheck = (data) => {
    console.log(data)
    filterParams.status = data
    writeSession("MyProjectParams", {
      filterParams,
      page,
      size
    })
    setCheckStatus(data)
    setPage(1)
    queryList()
  }

  /**
   * @description: 切换项目类型
   * @return {*}
   * @author: LINA.QI
   */
  const handleOptionChange = (value) => {
    filterParams.rcode = value ? value : null
    writeSession("MyProjectParams", {
      filterParams,
      page,
      size
    })
    setPage(1)
    queryList()
  }

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (pageIndex, pageSize) => {
    writeSession("MyProjectParams", {
      filterParams,
      page: pageIndex,
      size: pageSize
    })
    setPage(pageIndex)
    setSize(pageSize)
  }

  /**
   * @description 输入编号查询
   */
  const onSearch = (params) => {
    filterParams.keyword = params
    writeSession("MyProjectParams", {
      filterParams,
      page,
      size
    })
    setPage(1)
    queryList()
  }

  /**
   * @description: 星标更改
   * @return {*}
   * @author: LINA.QI
   */
  const handleStartClick = (event, projectId) => {
    event.stopPropagation()
    addOrCancelStar(projectId)
      .then((res) => {
        queryList()
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 进入项目总览
   * @return {*}
   * @author: LINA.QI
   */
  const handleIn = (data) => {
    writeSession("sessionProjectId", data.id)
    writeSession("sessionProjectName", data.projectName)
    writeSession("sessionStatus", data.status)
    removeSession("sessionStageId")
    removeSession("stageVersionId")
    navigate("/project-space/overview")
    writeSession("beforeRouter", "/my-project")
    writeSession("MyProjectParams", {
      filterParams,
      page,
      size
    })
    eventBus.emit("setMenuBoolean", true)
  }
  /**
   *
   * @description 查询列表方法
   */
  const queryList = (params) => {
    const queryParams = {
      data: params ? params.filterParams : filterParams,
      page: {
        pageIndex: params ? params.page : page,
        pageSize: params ? params.size : size
      }
    }
    projectPage(queryParams)
      .then((res) => {
        setCardList(res.list)
        setTotal(res.total)
      })
      .catch((e) => message.error(e))
  }

  return (
    <>
      <div className="my-project">
        <StateTab
          tabList={stateTabList}
          title={"我参与的项目"}
          changeClick={handleCheck}
          checkStatus={checkStatus}
        />
        <div className="form-box">
          <Form form={form} layout="inline">
            <Form.Item name="rcode" label="项目类型:">
              <Select
                showSearch
                allowClear
                placeholder="请选择"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={handleOptionChange}
              >
                {projectOptions.map((item, index) => (
                  <Option title={item.sopName} key={item.rCode} value={item.rCode}>
                    {item.sopName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="keyword">
              <Search
                placeholder="请输入项目编号或项目名称"
                onSearch={onSearch}
                allowClear
              />
            </Form.Item>
          </Form>
          

            
        </div>
        {cardList.length > 0 && (
          <div className="main-box">
            {cardList.map((item, index) => (
              <Card
                title={
                  <React.Fragment>
                    <i
                      className={
                        "iconfont " +
                        (item.startFlag === 0
                          ? "icon-weixuanzhong"
                          : "icon-xuanzhong")
                      }
                      style={{
                        color: item.startFlag === 0 ? "#9CA3AF" : "#F3911A",
                        marginRight: "3px"
                      }}
                      onClick={(event) => handleStartClick(event, item.id)}
                    ></i>

                    {item.sopName}
                  </React.Fragment>
                }
                extra={
                  <p>
                    <span
                      className={
                        "node-status " +
                        (item.status === 1
                          ? "green"
                          : item.status === 2
                            ? "purple"
                            : "gray")
                      }
                    >
                      {item.status === 1
                        ? "进行中"
                        : item.status === 2
                          ? "已结项"
                          : "已关闭"}
                    </span>
                  </p>
                }
                key={index}
                onClick={() => handleIn(item)}
              >
                <p
                  className="project-name"
                  title={
                    item.projectName + (item.erpNo ? `(${item.erpNo})` : "")
                  }
                >
                  {item.projectName + (item.erpNo ? `(${item.erpNo})` : "")}
                </p>
                <div className="status-box" hidden={item.status !== 1}>
                  <p
                    hidden={!item.taskStatus}
                    className="star-status"
                    style={{
                      color:
                        item.taskStatus === 0
                          ? "#16BDCA"
                          : item.taskStatus === 1
                            ? "#31C48D"
                            : item.taskStatus === 4
                              ? "#F05252"
                              : "#3F83F8"
                    }}
                  >
                    {item.taskStatus === 0
                      ? "任务未开始"
                      : item.taskStatus === 1
                        ? "任务进行中..."
                        : item.taskStatus === 4
                          ? "有延期任务"
                          : "任务已完成"}
                  </p>
                  <p hidden={item.taskStatus} className="star-status"></p>
                  <Progress
                    percent={item.projectProgress ? item.projectProgress : 0}
                    strokeColor={"#3F83F8"}
                    showInfo={false}
                    strokeWidth={6}
                  />
                  <div className="progress-status">
                    <span>我的进度：</span>
                    <span>
                      {item.completeNodeNum ? item.completeNodeNum : 0}/
                      {item.allNodeNum ? item.allNodeNum : 0}
                    </span>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}

        {cardList.length === 0 && (
          <div className="no-roject">
            <img
              src={require("@/assets/img/projectcenter/no-project.png")}
              alt=""
            />
            <p>暂无项目，如有疑问请联系项目负责人。</p>
          </div>
        )}

        <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["20", "50", "100"]}
          showSizeChanger
          showQuickJumper
          className="custom-pagination"
          onChange={handleChangePagination}
        />
      </div>
    </>
  )
}

export default MyExamine
