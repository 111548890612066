import UpLoad from "@/components/Upload";
import { Form, Input, message, Select, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      if(dataIndex === "argValue" && record.argType === "附件" && record.argKey === '') {
        message.warning("请先填写Key再上传文件！")
      }
      if(dataIndex === "argValue" && record.argType === "") {
        message.warning("请先选择参数类型！")
        setEditing(!editing);
      }
      if(record.argType != "附件") {
        inputRef.current && inputRef.current.focus();
      } 
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if(dataIndex === "argKey" && record.argKey != values.argKey && record.argType === "附件" ) {
        handleSave({
          ...record,
          ...values,
          fileData: {},
          argValue: ''
        });
      } else {
        handleSave({
          ...record,
          ...values,
        });
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  const getFileUrl = (data) => {
    toggleEdit();
    handleSave({
      ...record,
      argValue: record.argKey + "." + data.fileName.split('.')[1],
      fileData: {
        ...data,
        fileName: record.argKey + "." + data.fileName.split('.')[1]
      }
    });
  }
  const removeUrl = () => {
    if(type) {
      return
    }
    toggleEdit();
    handleSave({
      ...record,
      stlFile: '',
      fileData: {},
      argValue: ''
    });
  }
  const selectChange = (e) => {
    if(e === "值"){
      delete record.fileData
    }
    record.argValue = ''
    save()
  }
  const childrenElement = () => {
    switch (dataIndex) {
      case "argName":
        return <Form.Item
          style={{
            margin: 0,
          }}
          name="argName"
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} maxLength={30}/>
        </Form.Item>
      case "argType":
        return <Form.Item
          style={{
            margin: 0,
          }}
          name="argType"
        >
          <Select
            style={{
              margin: 0,
              width:"70px"
            }}
            ref={inputRef}
            options={[
              { value: '值', label: '值'},
              { value: '附件', label: '附件'},
            ]}
            onChange={selectChange}
            onBlur={save}
          />
        </Form.Item>
      case "argKey":
        return <Form.Item
          style={{
            margin: 0,
          }}
          name="argKey"
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} maxLength={30}/>
        </Form.Item>
      case "argValue":
        return record.argType != "附件" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name="argValue"
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} maxLength={30}/>
        </Form.Item>) : (
        <Form.Item
          style={{
            margin: 0,
          }}
          name="argValue"
        >
           <UpLoad
            name="file"
            urlPath="/file/minio/upload"
            maxCount={1}
            getFileUrl={getFileUrl}
            removeUrl={removeUrl}
            disabled={record.argKey === ''}
            fileData={record.fileData}
            isIcon={true}
            type={type}
          />
        </Form.Item>)
      default:
        break;
    }
  }
  let childNode = children;
  if (editable) {
    let isEdit = dataIndex === "argValue" && record.argType === '' ? false : true
    console.log(type);
    let isType = true
    if(type === 'check') {
      if(record.argType === "附件" && dataIndex === "argValue") {
        isType = true
      } else {
        isType = false
      }
    }
    childNode = editing && isEdit && isType ? (
      childrenElement()
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          whiteSpace: 'pre-wrap',
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

function ArgTable (props) {
  const [parameter, setParameter] = useState([])
  const [count, setCount] = useState(0);

  const defaultColumns= [
    {
      title: '参数名',
      dataIndex: 'argName',
      width: 110,
      ellipsis: true,
      editable: true,
    },
    {
      title: '参数类型',
      dataIndex: 'argType',
      width: 100,
      ellipsis: true,
      editable: true,
    },
    {
      title: 'Key',
      dataIndex: 'argKey',
      width: 110,
      ellipsis: true,
      editable: true,
    },
    {
      title: 'Value',
      dataIndex: 'argValue',
      ellipsis: true,
      editable: true,
    },
    {
      dataIndex: 'operation',
      width: 60,
      className: "special-operation",
      filterDropdown: true,
      filterIcon: () => {return (<i onClick={addrow} className='iconfont icon-plus-circle iconplus'></i>)},
      render: (_, record) =>
      props.dataSource.length >= 1 ? (
          <i className='iconfont icon-minus-circle iconminus' onClick={() =>  handleDelete(record.key)}></i>
        ) : null,
    },
  ];
  
  useEffect(() => {
    setParameter(props.dataSource || [])
    setCount((props.dataSource && props.dataSource.length) || 0)
  },[props])

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const handleDelete = (key) => {
    const newData = parameter.filter((item) => item.key !== key);
    updateParameter(newData);
  };
  const addrow = () => {
    const newData = {
      key: count,
      argName: '',
      argType: '值',
      argKey: '',
      argValue: '',
    };
    updateParameter([...parameter,newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...parameter];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    updateParameter(newData);
  };
  const updateParameter = (data) => {
    setParameter(data);
    props.getParameter(data)
  }
  const argColumns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        type: props.type 
      }),
    };
  });
  return <Table
          components={components}
          style={{border:"1px solid #D9DCE1"}}
          className="custom-table "
          rowClassName={() => 'editable-row'}
          dataSource={parameter}
          columns={props.columnType ? argColumns.filter(d => d.dataIndex != "operation" && d.dataIndex != "argType") : argColumns}
          pagination={false}
        />
}

export default ArgTable;