/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-08-02 13:33:43
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-21 13:57:02
 */
import { examDetail } from "@/apis/projectCenter/myExam"
import ExamList from "@/components/ExamList"
import { Button, Form } from "antd"
import dayjs from "dayjs"
import html2pdf from "html2pdf.js"
import { useEffect, useState } from "react"
import "../index.scss"

function AnswerDetails(props) {
  const { data, isOpen, cancelClick } = props
  const [form] = Form.useForm() //表单
  const layout = {
    labelCol: {
      span: 4
    }
  }
  const [activeIndex, setActiveIndex] = useState() // 点击索引
  const [isDisabled, setIsDisabled] = useState(true)
  const [examObj, setExamObj] = useState({})
  const [allExam, setAllExam] = useState()

  useEffect(() => {
    getExamDetail()
    console.log(data)
  }, [isOpen])

  /**
   * @description: 获取答题详情
   * @return {*}
   * @author: LINA.QI
   */
  const getExamDetail = () => {
    examDetail(data.data.id).then((res) => {
      res.map((tab) => {
        tab.createTime = dayjs(tab.createTime).format("YYYY-MM-DD")
      })
      setAllExam(addNumberToDuplicateIds(res).reverse())
      setActiveIndex(res[0])
      setExamObj({
        ...res[0],
        questionDetail:
          res[0] && res[0].questionDetail
            ? res[0].questionDetail.map((d) => {
                return {
                  ...d,
                  questionChoose: JSON.parse(d.questionChoose),
                  standardAnswer: JSON.parse(d.standardAnswer),
                  commitAnswer: d.commitAnswer ? JSON.parse(d.commitAnswer) : []
                }
              })
            : []
      })
    })
  }

  //计算日期重复
  function addNumberToDuplicateIds(arr) {
    arr.reverse()
    let counter = {} // 计数器对象
    for (let i = 0; i < arr.length; i++) {
      let date = arr[i].createTime
      if (!counter[date]) {
        counter[date] = 1
      } else {
        counter[date]++
      }
      if (counter[date] > 1) {
        arr[i].createTime = date + "(" + counter[date] + ")"
      }
    }
    return arr
  }

  /**
   * @description: 切换tab
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeTab = (item) => {
    console.log(item)
    setActiveIndex(item)
    setExamObj({
      ...item,
      questionDetail: item.questionDetail
        ? item.questionDetail.map((d) => {
            return {
              ...d,
              questionChoose: JSON.parse(d.questionChoose),
              standardAnswer: JSON.parse(d.standardAnswer),
              commitAnswer: d.commitAnswer ? JSON.parse(d.commitAnswer) : []
            }
          })
        : []
    })
  }

  const getData = (data) => {
    console.log(data)
    // setExamObj({
    //   ...examObj,
    //   questionDetail: data
    // })
  }

  /**
   * @description: 导出
   * @return {*}
   * @author: LINA.QI
   */
  const handleDerive = () => {
    let opt = {
      margin: [5, 5, 0, 5],
      pagebreak: { mode: ["css", "legacy"] },
      filename: `${data.data.subjectName}-${activeIndex.createTime}-${data.data.userName}.pdf`
    }
    const element = document.getElementById("derive")
    html2pdf().set(opt).from(element).save()
  }

  return (
    <>
      <div className="answer-detail">
        {allExam && (
          <div className="tab-box">
            {allExam.map((item, index) => (
              <span
                key={item.scoreId}
                className={[
                  "tab-list",
                  activeIndex.scoreId === item.scoreId ? "tab-active" : null
                ].join(" ")}
                onClick={() => handleChangeTab(item)}
              >
                {item.createTime}
              </span>
            ))}
          </div>
        )}

        <div id="derive" className="pad-derive">
          <div className="answer-score">
            <p>
              考试人：
              <span>{examObj.userName}</span>
            </p>
            <p>
              考试得分：<span className="score">{examObj.score}</span>
              <span style={{ color: "#1F2A37" }}>分</span>
              <span
                style={{ color: examObj.isQualified ? "#27AE60" : "#F5222D" }}
              >
                {examObj.isQualified ? "(合格)" : "(不合格)"}
              </span>
            </p>
          </div>
          <div className="subject-name">{data.data.subjectName}</div>
          <div className="answer-content">
            {examObj.questionDetail && (
              <ExamList
                type={isDisabled}
                questionList={examObj.questionDetail}
                getData={getData}
              />
            )}
          </div>
        </div>

        <div className="dialog-footer">
          <Button className="cancel" onClick={() => cancelClick()}>
            <i className="iconfont icon-reply"></i>返回
          </Button>
          <Button className="confirm" type="primary" onClick={handleDerive}>
            导出 <i className="iconfont icon-external-link"></i>
          </Button>
        </div>
      </div>
    </>
  )
}

export default AnswerDetails
