import { Checkbox, Radio } from "antd";
import React, { useEffect, useState } from "react";
import "./index.scss";

function ExamList({ type, questionList, getData }) {
  useEffect(() => {
    if(questionList) {
      setQuestionData(questionList)
    }
  },[questionList])
  const [questionData, setQuestionData] = useState([])

  const radioChange = (index,event) => {
    const newData = [...questionData];
    const item = {...questionData[index],commitAnswer: event.target.value};
    newData.splice(index, 1, {
      ...item,
    });
    setQuestionData(newData);
    getData(newData)
  }

  const checkboxChange = (index, event) => {
    const newData = [...questionData];
    const item = {...questionData[index],commitAnswer: event};
    newData.splice(index, 1, {
      ...item,
    });
    setQuestionData(newData);
    getData(newData)
  }
  
  const checkRadio = (question, ansower) => {
    if((!question.commitAnswer.includes(ansower) && question.standardAnswer.includes(ansower)) || (question.commitAnswer.includes(ansower) && question.standardAnswer.includes(ansower))) {
      return "green"
    } else if(question.commitAnswer.includes(ansower) && !question.standardAnswer.includes(ansower)) {
      return "red"
    }
  }

  const checkCheckBox = (question,ansower) => {
    if(question.standardAnswer.includes(ansower)) {
      return "green"
    } else if(question.commitAnswer.includes(ansower) && !question.standardAnswer.includes(ansower)) {
      return "red"
    }
  }

  return (
    <div className="question-page">
      <div className="mask" style={{display: type ? 'block': 'none'}}></div>
      {questionData.length > 0 && questionData.map((d, index) => (
        <div className="question" key={index}>
          <div className="question-title"><div className="order">{index + 1}.</div><div className="content" style={{paddingLeft: 3}}>{d.questionName}</div></div>
          <div className="question-content">
            {d.questionType === 0 ? 
            (type ? 
              <div className="ant-radio-group">
                {d.questionChoose && d.questionChoose.map(v => (
                  <Radio
                    checked={[...d.commitAnswer,...d.standardAnswer].includes(v)}
                    value={v}
                    key={v}
                    className={checkRadio(d, v)}
                  >
                    {v}
                  </Radio>
                ))}
              </div>
            :
              <Radio.Group onChange={(event) => radioChange(index,event)} value={type ? d.standardAnswer : d.commitAnswer}>
                {d.questionChoose && d.questionChoose.map(v => (
                  <Radio value={v} key={v}>{v}</Radio>
                ))}
              </Radio.Group>
            )
            :
            (type ?
              <div className="ant-checkbox-group">
                {d.questionChoose && d.questionChoose.map(v => (
                <Checkbox
                  checked={d.commitAnswer.includes(v) || d.standardAnswer.includes(v)}
                  value={v}
                  key={v}
                  className={checkCheckBox(d, v)}
                >
                  {v}
                </Checkbox>
              ))}
              </div>
            :
              <Checkbox.Group value={type ? d.standardAnswer : d.commitAnswer} onChange={(event) => checkboxChange(index,event)} >
                {d.questionChoose && d.questionChoose.map(v => (
                  <Checkbox value={v} key={v}>{v}</Checkbox>
                ))}
              </Checkbox.Group>
            )
            }
          </div>
        </div>
      ))}
    </div>
    
  )
}
export default ExamList