import { NameAddressSpecialChar, NameNotSpecialChar } from "@/utils/commonFunc/validate";
import { Button, Form, Input, message, Modal, Pagination, Select, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { addDeviceCategory, addDeviceType, checkConfig, deleteDeviceCategory, deleteDeviceType, getDeviceCategoryList, getDeviceTypeList, updateConfig, updateDeviceType } from "../../apis/deviceManage/type/index";
import OperateButton from "../../components/OperateButton";
import TypeHearder from "../EquipmentAssembly/components/TypeHeader";
import EquipmentDetail from "../OnLine/components/EquipmentDetail";
import "./index.scss";

function EquipmentType() {
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const [deviceId, setDeviceId] = useState(undefined)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalData, setModalData] = useState({})
  const [deviceSource, setDeviceSource] = useState([])
  const [updateId, setUpdateId] = useState('')

   /**
   *
   * @description 查询列表方法
   */
    const queryList = useCallback(() => {
      let params = {
        data: {
          deviceCategoryId: deviceId
        },
        page:{
          pageIndex: page,
          pageSize: size
        }
      }
      getDeviceTypeList(params)
        .then((res) => {
          setTotal(res.total);
          setDataSource(res.list);
        })
        .catch((e) => message.error(e));
    },[deviceId,page,size]);

  /**
   * @description 加载组件时先进行一次查询
   */
  // eslint-disable-next-line
  useEffect(() => queryDeviceCategoryeList(),[])
  useEffect(() => queryList(), [queryList]);

   /**
   *
   * @description 查询组件类型
   */
    const queryDeviceCategoryeList = () => {
      getDeviceCategoryList()
        .then((res) => {
          let source = res.map(d => {
            return {
              id: d.id,
              code: d.categoryNo,
              name: d.categoryName,
              number: d.number
            }
          })
          if(!deviceId) {
            setDeviceId(res[0].id)
          }
          setDeviceSource(source);
        })
        .catch((e) => message.error(e));
    };

  /**
   * @description 新建记录（此时数据只是在本地）
   */
  const handleAdd = () => {
    setModalType("add")
    form.setFieldsValue({deviceCategoryId: deviceId})
    setIsModalOpen(true)
  };

  /**
   * @description 删除二次弹框确认逻辑
   */
  const confirm = (id) => {
    deleteDeviceType(id)
      .then((res) => {
        if(res) {
          message.success("删除成功");
          queryList();
          queryDeviceCategoryeList()
        }
        
      })
      .catch((e) => message.error(e));
  };

  const defaultColumns = [
    {
      title: "型号代码",
      dataIndex: "typeCode",
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "型号名称",
      dataIndex: "typeName",
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "设备类型",
      dataIndex: "categoryName",
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "操作",
      width: 180,
      dataIndex: "operation",
      render: (text, record) => (
        <div className="operation">
          <OperateButton text="查看" icon="icon-eye" click={() => checkModal(record, "check")} data={record}/>
          <OperateButton text="编辑" icon="icon-pencil" click={() => updateModal(record)} data={record}/>
          <OperateButton text="配置" icon="icon-adjustments" click={() => configure(record,"update")} data={record}/>
          <OperateButton text="删除" popType={true} title={`确认删除设备型号“${record.typeName}”？`} description="删除后将无法添加该型号的设备！" icon="icon-trash" confirm={confirm} data={record}/>
        </div>
      ),
    },
  ];

  const checkModal = (data,type) => {
    checkConfig(data.id).then(res => {
      setModalTitle('设备详情')
      setModalType(type)
      setModalData({
        ...res,
        data: res.detailVos
      })
      setModalOpen(true)
    })
    .catch((e) => message.error(e));
  }

  const updateModal = (data) => {
    setUpdateId(data.id)
    setModalType("update")
    form.setFieldsValue(data)
    setIsModalOpen(true)
  }

  const configure = (data,type) => {
    checkConfig(data.id).then(res => {
      setModalTitle('设备详情')
      setModalType(type)
      setModalData({
        ...res,
        data: res.detailVos
      })
      setModalOpen(true)
    })
    .catch((e) => message.error(e));
  }

  const saveModalData = (data,callback) => {
    let params ={
      ...data,
    }
    updateConfig(params).then(res => {
      if(res) {
        message.success("配置成功");
        callback()
      }
    })
    .catch((e) => message.error(e));
  }
  const closeModal = () => {
    form.resetFields()
    setIsModalOpen(false)
    setModalOpen(false)
  }
  const deleteType = (id) => {
    deleteDeviceCategory(id).then(res => {
      if(res) {
        message.success("删除成功");
        queryDeviceCategoryeList()
      }
    })
    .catch((e) => message.error(e));
  }
  const getComponentTypeId = (id) => {
    setDeviceId(id)
    if(deviceId !== id) {
      setPage(1)
    } 
  }
  const handleOk = () => {
    form.validateFields().then(values => {
      if(modalType === 'add') {
        addDeviceType(values).then(res => {
          if(res) {
            message.success("添加成功");
            queryList()
            queryDeviceCategoryeList()
            form.resetFields()
            setIsModalOpen(false);
          }
        })
        .catch((e) => message.error(e));
      } else {
        let params = {
          ...values,
          id: updateId
        }
        updateDeviceType(params).then(res => {
          if(res) {
            message.success("更新成功");
            form.resetFields()
            setIsModalOpen(false);
            queryList()
          }
        })
        .catch((e) => message.error(e));
      }
    })
  };
  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };
  return (
    <div className="type-content">
      <TypeHearder
        typeSource={deviceSource}
        headerType="equipment"
        deleteItem={deleteType}
        getComponentTypeId={getComponentTypeId}
        api={addDeviceCategory}
        refresh={queryDeviceCategoryeList}
      />
      <div className="table-container">
        <Button
          onClick={handleAdd}
          type="primary"
          style={{
            marginBottom: "15px",
            display: "flex"
          }}
        >
          添加设备型号<i className="iconfont icon-plus"></i>
        </Button>
        <Table
          size="middle"
          rowClassName={() => "editable-row"}
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={defaultColumns}
          pagination={false}
        />
        {total === 0 ? undefined : <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "40", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />}
      </div>
      <EquipmentDetail
        modalTitle={modalTitle}
        modalType={modalType}
        modalData={modalData}
        modalOpen={modalOpen}
        saveModal={saveModalData}
        cancelModal={closeModal}
      />
      <Modal
        width="420px"
        className='custom-modal'
        title={modalType === "add" ? '添加设备型号' : '编辑设备型号'}
        maskClosable={false}
        destroyOnClose={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={closeModal}
      >
        <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
          <Form.Item
            name="typeCode"
            rules={NameAddressSpecialChar('型号代码', 15)}
            label="型号代码"
          >
            <Input
              style={{width: 300}}
              autoComplete="off"
              placeholder="支持输入英文、数字、_、-，最多15字符"
            ></Input>
          </Form.Item>
          <Form.Item
            name="typeName"
            rules={NameNotSpecialChar('型号名称',15,true)}
            label="型号名称"
          >
            <Input
              style={{width: 300}}
              autoComplete="off"
              placeholder="支持输入中英文、数字、_、-，最多15字符"
            ></Input>
          </Form.Item>
          <Form.Item
            name="deviceCategoryId"
            label="设备类型"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              style={{
                width: 300,
              }}
              disabled={modalType !== "add"}
              placeholder="选择设备类型"
              options={deviceSource.map(d => ({label: d.name,value:d.id}))}
            >
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default EquipmentType;
