import { Button, Modal } from "antd";
import React, { useMemo } from "react";
import "./index.scss";

function Submit({ isModalOpen ,submitOk, cancelModal, modalWidth = 320, modalType, score, isRestart}) {
  const style = useMemo(()=>{
    switch (modalType) {
      case 'timeOver'://pc端超时
        return {
          image: {
            width: "209.15px",
            height: "96.91px"
          },
          tip: {
            fontSize: "16px"
          }
        };
      case 'mobile-timeOver'://手机端超时
        return {
          image: {
            width: "172.65px",
            height: "80px"
          },
          tip: {
            fontSize: "15px"
          }
        };
      case 'no-exam-info'://pc端没有试卷信息
        return {
          image: {
            width: "209.15px",
            height: "120px"
          },
          tip: {
            fontSize: "16px"
          },
          button: {
            width: "90px"
          }
        };
      case 'mobile-no-exam-info'://移动端没有试卷信息
        return {
          image: {
            width: "174.29px",
            height: "100px"
          },
          tip: {
            fontSize: "15px"
          },
          button: {
            width: "120px"
          }
        };
      case 'mobile-pass'://移动端合格
        return {
          image: {
            width: "93.33px",
            height: "120px"
          },
        };
      case "mobile-unqualified"://移动端不合格
        return {
          image: {
            width: "93.33px",
            height: "120px"
          },
        };
      case 'pass'://pc端合格
        return {
          image: {
            width: "108.89px",
            height: "140px"
          },
        };
      case "unqualified"://pc端不合格
        return {
          image: {
            width: "108.89px",
            height: "140px"
          },
        };
      default:
        return {};
    }
  },[modalType])

  return (
    <>
      {modalType === "submit" && <Modal
        centered
        width={modalWidth}
        closable={false}
        open={isModalOpen}
        footer={null}
        className="submit-modal"
      >
        <p className="title">确定要交卷吗？</p>
        <div className="footer">
          <div className="cancel" onClick={cancelModal}>取消</div>
          <div className="confirm" onClick={submitOk}>确定</div>
        </div>
      </Modal>}
      {["timeOver", "mobile-timeOver"].includes(modalType) && <Modal
        centered
        width={modalWidth}
        closable={false}
        open={isModalOpen}
        footer={null}
        className="timeOver-modal"
      >
        <img src={require("@/assets/img/submit-exam.png")} style={style.image}/>
        <p className="tip" style={style.tip}>考试时间结束，自动交卷中...</p>
      </Modal>}
      {["no-exam-info", "mobile-no-exam-info"].includes(modalType) && <Modal
        centered
        width={modalWidth}
        closable={false}
        open={isModalOpen}
        footer={null}
        className="noExamInfo-modal"
      >
        <img src={require("@/assets/img/no-exam-info.png")} style={style.image}/>
        <p className="tip" style={style.tip}>考试信息不存在，请咨询项目负责人</p>
        <Button type="primary" style={style.button} onClick={cancelModal}>确定</Button>
      </Modal>}
      {["pass", "unqualified", "mobile-pass", "mobile-unqualified"].includes(modalType) && <Modal
        centered
        width={modalWidth}
        closable={false}
        open={isModalOpen}
        footer={null}
        className="score"
      >
        <div className="header">
          <div className="content">
            <div className="circle"></div>
            <div className="title">考试得分</div>
            <div className="score">
              <span className="num">{score}</span>
              <span className="unit">分</span>
            </div>
          </div>
        </div>
        <img src={["pass", "mobile-pass"].includes(modalType) ? require("@/assets/img/pass.png") : require("@/assets/img/unqualified.png")} className="image" style={style.image}/>
        {modalType === "mobile-pass" && <Button onClick={cancelModal} className="pass-operate">返回</Button>}
        {modalType === "pass" && <Button onClick={cancelModal} className="pass-operate">查看考试记录</Button>}
        {modalType === "unqualified" && ( isRestart ?
        <div className="unqualified-operate">
          <Button onClick={cancelModal}>查看考试记录</Button>
          <Button type="primary" onClick={submitOk}>再考一次</Button>
        </div>
        : 
        <Button onClick={cancelModal} className="pass-operate">查看考试记录</Button>)}
        {modalType === "mobile-unqualified" &&( isRestart ?
        <div className="mobile-unqualified-operate">
          <Button onClick={cancelModal}>返回</Button>
          <Button type="primary" onClick={submitOk}>再考一次</Button>
        </div>
        :
        <Button onClick={cancelModal} className="pass-operate">返回</Button>
        )}
      </Modal>}
    </>
  )
}

export default Submit
