/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-20 13:57:36
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-06 11:45:06
 * @FilePath: /pms-web/src/apis/operationAnalysis/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axiosInstance } from "../config";
// 查询所有sop
export const getAllSop = () => {
  return axiosInstance.post(`/sopbasicinfo/selectAllSop`);
};

// 根据sop项目id查询阶段进度
export const getStageById = (params) => {
  return axiosInstance.post(
    `sopbasicinfo/selectStageBySopRCode
  `,
    params
  );
};

// 项目看板-状态统计
export const getStatusStatistics = () => {
  return axiosInstance.get(`/bulletinBoard/statusStatistics`);
};

// 项目看板-状态统计下拉
export const getStatusSelectList = (params) => {
  return axiosInstance.post(`/systemdict/list`, params);
};

// 项目看板-类型统计
export const getTypeStatistics = (typeCode) => {
  if (typeCode || typeCode === 0) {
    return axiosInstance.get(`/bulletinBoard/typeStat?typeCode=${typeCode}`);
  }
  return axiosInstance.get(`/bulletinBoard/typeStat`);
};

// 项目看板-项目进度统计
export const getProgressStatistics = (typeCode) => {
  return axiosInstance.get(
    `/bulletinBoard/progressStatistics?typeCode=${typeCode}`
  );
};

// 近12个月项目趋势
export const getTrendsStatistics = (typeCode) => {
  if (typeCode || typeCode === 0) {
    return axiosInstance.get(
      `/bulletinBoard/productTrends?typeCode=${typeCode}`
    );
  }
  return axiosInstance.get(`/bulletinBoard/productTrends`);
};

// 项目看板-行业分布
export const getIndustryDistribution = (params) => {
  return axiosInstance.get(`/bulletinBoard/industryDistribution`, params);
};

// 项目看板-项目列表-列表查询
export const getProjectList = (params) => {
  return axiosInstance.post(`/bulletinBoard/page`, params);
};
