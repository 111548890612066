import { Form, Input, Select } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
// import { invalidInput } from "./invalidInput";

const EditableContext = React.createContext(null);
const { Option } = Select;

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  setPreNodeOptions,
  selectedOptions,
  domObj,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [opDis , setOpDis] = useState(false);
  const [ openStatus , setOpenStatus] = useState(true);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    // setOpenStatus(!openStatus);
    // console.log(2222 , openStatus)
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    // setOpenStatus(false)
    try {
      const values = await form.validateFields();
      console.log(values)
      toggleEdit();
      for (let i in values) {
        if (i + "Err" in record) {
          delete record[i + "Err"];
        }
      }
      handleSave({ ...record, ...values });

      setOpenStatus(true)
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
      // 给每一个保存报错的key加上err后缀
      const errorValue = Object.entries(errInfo.values);
      for (let i = 0; i < errorValue.length; i++) {
        errorValue[i][0] += "Err";
      }
      console.log({ ...record, ...Object.fromEntries(errorValue) });
      handleSave({ ...record, ...Object.fromEntries(errorValue) });
    }
  };

  const handleClear = (value, option, dataIndex) => {
    let newRow = { ...record };
    if (dataIndex === "deptName") {
      let companyId = "";
      let deptId = "";
      newRow = { ...newRow, companyId, deptId, deptName: "" };
      handleSave(newRow);
      return;
    }
  };

  const handleChange = (value, option, dataIndex) => {
    console.log(value , option , dataIndex);
    // let newRow = { ...record };
    if(dataIndex === 'fileRight'){
      if(value.indexOf('all') !== -1){
        form.setFieldsValue({
          [dataIndex]: ['all'],
        });
        setOpDis(true);
      }else{
        setOpDis(false)
      }
    }
  };

  const onDropdownVisibleChange = (value) => {
    console.log(1111,value);
    setOpenStatus(value);
    if (value && dataIndex === "fileRight") {
      
    }
  };

  /**
   * @description 根据传入数据动态渲染dom
   * @params input输入框类型
   */
  const renderEditableDom = (domObj) => {
    const { type, rules, placeholder, options, mode, onSearchNow } = domObj;
    switch (type) {
      case "input":
        return (
          <Form.Item name={dataIndex} rules={rules}>
            <Input
              placeholder={placeholder}
              ref={inputRef}
              autoComplete="off"
              size="small"
              onBlur={save}
              onChange={(e) => {
                // if (invalidInput(e.nativeEvent.data)) {
                //   form.setFieldsValue({
                //     [dataIndex]: e.target.value.slice(0, -1),
                //   });
                // }
              }}
            />
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item name={dataIndex} rules={rules}>
            <Select
              showSearch
              allowClear
              size="small"
              filterOption={(input, option) =>
                option.children?.toLowerCase().includes(input?.toLowerCase())
              }
              placeholder={placeholder}
              ref={inputRef}
              open={openStatus}
              onBlur={save}
              mode={mode}
              onChange={(value, option) =>
                handleChange(value, option, dataIndex)
              }
              onSearch={!!onSearchNow ? (val) => onSearchNow(val) : null}
              onDropdownVisibleChange={onDropdownVisibleChange}
              virtual={false}
              onClear={(value, option) => handleClear(value, option, dataIndex)}
              //getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {options.map((item) => {
                //console.log("item", options);
                return (
                  <Option
                    key={`${item.value}-${item.label}`}
                    value={item.value}
                    disabled={ (item.value !== 'all' && opDis) || item.disableStatus}
                  >
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        );
      default:
        return null;
    }
  };

  let childNode = children;

  /**
   * @description 读取当前redux中currentEditableDom数据动态添加
   */
  // const { currentEditableDomList } = useSelector((state) => state.appReducer);
  if (editable) {
    childNode = editing ? (
      renderEditableDom(
        domObj.find((item) => item.name === dataIndex)
      )
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
