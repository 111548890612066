/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-09 11:44:59
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-17 10:20:54
 * @FilePath: /pms-web/src/containers/ProjectBoard/components/ProjectStatusGrid.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import EChartsReact from "echarts-for-react";
import { useNavigate } from "react-router-dom";
import eventBus from "@/utils/commonFunc/eventBus";
import writeSession from '@/utils/commonFunc/writeSession'
function ProjectTypeGrid({
  chartOptions,
  projectName,
  projectType,
  statusCode,
}) {
  const navigate = useNavigate();
  const goProjectList = () => {
    setTimeout(() => {
      eventBus.emit("setBreadcrumbData", ["运营分析", "项目看板", "项目列表"]);
    }, 100);
    navigate("/operations-analysis/board/project-list", {
      state: { projectType, status: statusCode },
    });
    writeSession("boardParams", {
      projectType,
      status: statusCode 
    })
  };
  return (
    <>
      <div
        className="project-type-circle-container"
        onClick={() => goProjectList()}
      >
        <EChartsReact
          style={{ width: "105px", height: "105px", cursor: "pointer" }}
          option={chartOptions}
          // onEvents={onEvents}
          // notMerge={true}
          // lazyUpdate={true}
          // style={{ height: "230px", left: "12px", top: "-8px" }}
        />
        <div className="project-name" title={projectName}>
          {projectName}
        </div>
      </div>
    </>
  );
}
export default ProjectTypeGrid;
