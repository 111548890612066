/*
 * @Author: gn
 * @Date: 2023-06-15 20:11:57
 * @LastEditors: gn
 * @LastEditTime: 2023-07-17 16:03:27
 * @Description: file content
 */
import { Tabs } from 'antd';

import React, { useEffect, useState } from "react";
import eventBus from "@/utils/commonFunc/eventBus";

import SopTabs from "./SopTabs";
import BasicTabs from './BasicTabs';
import FileTabs from "./FileTabs";

import "../index.scss";

function TabsChildren(props){

    const { mesStatus , tabsStatus , handleEditStatus } = props;

    const [SOPId , setSOPId] = useState(props.SOPId);
    const [ activeKey , setActiveKey ] = useState('1');

    useEffect(()=>{
        if(tabsStatus === 'view'){
            eventBus.emit("setBreadcrumbData", ["系统管理", "SOP管理","查看SOP"]);
        }else{
            eventBus.emit("setBreadcrumbData", ["系统管理", "SOP管理","编辑SOP"]);
        }
        
    },[])

    function breadcrumbItemClickEventsFn(item){
        if(item === 'SOP管理'){
          handleEditStatus()
        }
      };
    
      useEffect(() => {
        eventBus.addListener(
          "breadcrumbItemClickEvents",
          breadcrumbItemClickEventsFn
        );
        return () => {
          eventBus.removeListener(
            "breadcrumbItemClickEvents",
            breadcrumbItemClickEventsFn
          );
        };
      }, [breadcrumbItemClickEventsFn]);
    /**
     * @description: 获取sopId
     * @return {*}
     * @Author: gn
     */    
    function handleSopId(val){
        setSOPId(val);
    };

    const onTabClick = (key) => {
        console.log(key);
        setActiveKey(key);
    };
    const items = [
        {
            key: '1',
            label: `基本信息`,
            children: <BasicTabs SOPId={SOPId} activeKey={activeKey} tabsStatus={tabsStatus} mesStatus={mesStatus} handleEditStatus={handleEditStatus} handleSopId={handleSopId} />,
        },
        {
            key: '2',
            label: `文件列表`,
            children: <FileTabs SOPId={SOPId} activeKey={activeKey} tabsStatus={tabsStatus} mesStatus={mesStatus} handleEditStatus={handleEditStatus} handleSopId={handleSopId} ></FileTabs>,
            disabled: !SOPId,
        },
        {
            key: '3',
            label: `SOP节点`,
            children: <SopTabs SOPId={SOPId} activeKey={activeKey} tabsStatus={tabsStatus} mesStatus={mesStatus} handleEditStatus={handleEditStatus} handleSopId={handleSopId} />,
            disabled: !SOPId,
        },
    ];
    return (
        <>
            <Tabs activeKey={activeKey} items={items} onTabClick={onTabClick} />
        </>
    )
};

export default TabsChildren;