/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-04-19 09:54:07
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-12 17:45:29
 */

import React, { useEffect, useState, useRef } from "react"
import {
  Button,
  Select,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Table,
  Popconfirm,
  Tooltip,
  Cascader
} from "antd"
import "./style/index.scss"
import OperateButton from "@/components/OperateButton"
import ConnectPersonnel from "./components/ConnectPersonnel"
import {
  handoverPersonnelList,
  handoverPersonnelProjectPage
} from "@/apis/connectPersonnel"

const { Option } = Select

function PersonnelManagement() {
  const channelOptions = [
    {
      value: 0,
      label: "UUAM"
    },
    {
      value: 1,
      label: "合作渠道"
    }
  ] //渠道下拉
  const [personOptions, setPersonOptions] = useState([]) //人员下拉
  const [channel, setChannel] = useState(null) //选择渠道
  const [personnel, setPersonnel] = useState(null) //选择交接人员
  const [selectionType, setSelectionType] = useState("checkbox")
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [dataSource, setDataSource] = useState([]) //表单数据
  const [dialogData, setDialogData] = useState({
    title: "项目及项目内任务交接",
    data: {},
    type: "alone",
    source: null,
    personnel: null
  }) //弹窗数据
  const [isModalOpen, setIsModalOpen] = useState(false) //弹窗展示
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)

  const columns = [
    {
      title: "项目",
      dataIndex: "projectName",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>
    },
    {
      title: "项目状态",
      dataIndex: "status",
      ellipsis: true,
      render: (text, record) => {
        switch (text) {
          case 0:
            return <span className="node-status teal">未开始</span>
          case 1:
            return <span className="node-status blue">进行中</span>
          case 2:
            return <span className="node-status purple">已结项</span>
          case 3:
            return <span className="node-status gray">已关闭</span>
        }
      }
    },
    {
      title: "项目角色",
      dataIndex: "systemRoleName",
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            <div>
              {record.systemRoleName ? (
                <Tooltip
                  placement="topLeft"
                  title={record.systemRoleName
                    .map((i) => {
                      return i
                    })
                    .join("、")}
                >
                  {record.systemRoleName
                    .map((i) => {
                      return i
                    })
                    .join("、")}
                </Tooltip>
              ) : (
                "--"
              )}
            </div>
          </>
        )
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      render: (text, record) => (
        <>
          <OperateButton
            text="交接"
            icon="icon-pencil"
            data={record}
            click={() => handleConnect(record)}
          />
        </>
      )
    }
  ]

  /**
   * @description: 切换渠道
   * @return {*}
   * @author: LINA.QI
   */
  const selectChannel = (value) => {
    setPersonnel(null)
    if (value !== undefined) {
      dialogData.source = value
      setChannel(value)
      getHandoverPersonnelList(value)
    } else {
      setDataSource([])
      setChannel(null)
    }
  }

  /**
   * @description: 获取人员下拉框
   * @return {*}
   * @author: LINA.QI
   */
  const getHandoverPersonnelList = (type) => {
    handoverPersonnelList({source:type,companyFlag:1})
      .then((res) => {
        setPersonOptions(res)
      })
      .catch((e) => message.error(e))
  }

  useEffect(() => {
    getHandoverPersonnelProjectPage()
    // eslint-disable-next-line
  }, [page, size, personnel])

  /**
   * @description: 切换交接人员
   * @param {*} value
   * @return {*}
   * @author: LINA.QI
   */
  const changeCascader = (value, selectedOptions) => {
    if (value && selectedOptions[value.length - 1].type === 'user') {
      setPersonnel(selectedOptions[value.length - 1].id)
      dialogData.personnel = selectedOptions[value.length - 1].id
    } else {
      setPersonnel(null)
    }
  }

  const selectPersonnel = (value) => {
    setPage(1)
    setPersonnel(value)
    dialogData.personnel = value
  }

  /**
   * @description: 获取列表内容
   * @return {*}
   * @author: LINA.QI
   */
  const getHandoverPersonnelProjectPage = () => {
    setSelectedRowKeys([])
    const params = {
      data: {
        userId: personnel,
        type: channel
      },
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    handoverPersonnelProjectPage(params)
      .then((res) => {
        setDataSource(res.list)
        setTotal(res.total)
        console.log(selectedRowKeys)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  /**
   * @description: 多选
   * @return {*}
   * @author: LINA.QI
   */
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      )
      setSelectedRowKeys(selectedRowKeys)
    }
  }
  /**
   * @description: 多选交接
   * @return {*}
   * @author: LINA.QI
   */
  const handleCheckbox = () => {
    if (selectedRowKeys.length === 0) {
      message.warning("请至少选择一个项目！")
    } else {
      dialogData.data = { projectIds: selectedRowKeys, oldUserId: personnel }
      dialogData.type = "alone"
      setIsModalOpen(true)
    }
  }

  /**
   * @description: 单个交接
   * @return {*}
   * @author: LINA.QI
   */
  const handleConnect = (record) => {
    console.log(record)
    dialogData.data = { projectIds: [record.projectId], oldUserId: personnel }
    dialogData.type = "alone"
    setIsModalOpen(true)
  }

  /**
   * @description: 全部交接
   * @return {*}
   * @author: LINA.QI
   */
  const confirmConnect = () => {
    dialogData.data = { oldUserId: personnel }
    dialogData.type = "all"
    setIsModalOpen(true)
  }
  const filter = (input, path) =>
    path.some((option) => {
      if (input.charCodeAt() >= 32 && input.charCodeAt() <= 126) {
        return (
          option.name
            .spell("low", "poly")
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        )
      } else {
        return option.name.toLowerCase().indexOf(input.toLowerCase()) > -1
      }
    })

  /**
   * @description:关闭弹窗
   * @return {*}
   * @author: LINA.QI
   */
  const handleCloseModal = () => {
    setIsModalOpen(false)
    setPage(1)
    getHandoverPersonnelProjectPage()
  }

  return (
    <>
      <div className="PersonnelManagement">
        <div className="personnel-top">
          <div className="personnel-left">
            <Button
              type="primary"
              className="role_add"
              onClick={handleCheckbox}
            >
              交接选中
              <i className="add_icon iconfont icon-jiaojiexuanzhong"></i>
            </Button>
            <Popconfirm
              placement="bottomLeft"
              title={"确定要全部交接吗？"}
              okText="确认"
              cancelText="取消"
              icon={
                <i
                  className="iconfont icon-shanchu"
                  style={{
                    color: "#F05252",
                    fontSize: "16px",
                    marginRight: "4px"
                  }}
                ></i>
              }
              disabled={!personnel}
              onConfirm={() => confirmConnect()}
            >
              <Button className="role_add">
                全部交接
                <i className="add_icon iconfont icon-quanbujiaojie"></i>
              </Button>
            </Popconfirm>
          </div>
          <div className="personnel-right">
            请选择待交接人员：
            <Select
              placeholder="请选择"
              style={{
                width: 100,
                marginRight: "10px"
              }}
              allowClear
              options={channelOptions}
              onChange={selectChannel}
            />
            {channel == 0 && (
              <Cascader
                options={personOptions.uuamUser}
                onChange={changeCascader}
                placeholder="请选择"
                showSearch={{
                  filter
                }}
                fieldNames={{ label: "name", value: "id" }}
              />
            )}
            {channel == 1 && (
              <Select
                placeholder="请选择"
                style={{
                  width: 200
                }}
                allowClear
                value={personnel}
                options={personOptions.channelUser}
                fieldNames={{ label: "userName", value: "userId" }}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={channel === null}
                onChange={selectPersonnel}
              />
            )}
          </div>
        </div>
        <Table
          rowSelection={{
            type: selectionType,
            selectedRowKeys,
            ...rowSelection
          }}
          className="custom-table"
          rowKey={(record) => record.projectId}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />
        <Modal
          title={dialogData.title}
          wrapClassName="custom-modal"
          open={isModalOpen}
          centered={true}
          maskClosable={false}
          footer={null}
          onCancel={() => setIsModalOpen(false)}
          width={430}
          destroyOnClose={true}
        >
          <ConnectPersonnel
            data={dialogData}
            isOpen={isModalOpen}
            confirmClick={handleCloseModal}
          />
        </Modal>
      </div>
    </>
  )
}

export default PersonnelManagement
