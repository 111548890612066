/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-13 13:42:54
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-18 10:41:10
 * @FilePath: /pms-web/src/containers/CapacityEfficiency/components/memberStatistic.js
 * @Description: 产能人效-详情-按人员统计
 */
import {
  Button, DatePicker, Form, Input, message, Modal, Pagination,
  Progress, Table
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  efficiencyLoginDetail,
  efficiencyStatisticsByPersonnel
} from "../../../apis/efficiency";
import OperateButton from "../../../components/OperateButton";
import { exportFile } from "../../../utils/commonFunc/exportFile";
import "./index.scss";
function StaffStatistic({ productCode, productName }) {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loginData, setLoginData] = useState([]);
  const [personName, setPersonName] = useState("");
  let { containerHeight } = useSelector((state) => state.appReducer);

  //
  const [isModalOpen, setIsModalOpen] = useState(false);
  // 登录明细列表- 用户id和产品code参数
  const [loginUserId, setLoginUserId] = useState("");
  const [loginProductCode, setLoginProductCode] = useState("");
  const { Search } = Input;
  const columns = [
    {
      title: "人员",
      dataIndex: "userName",
      width: 117,
      ellipsis: true,
      fixed: "left",
    },
    {
      title: "产品名",
      dataIndex: "productName",
      width: 187,
      ellipsis: true,
      render: (text) => {
        return (
          <>
            <div>{text ? text : "--"}</div>
          </>
        );
      },
    },
    {
      title: "协同模型",
      dataIndex: "proposeMoney",
      width: 113,
      ellipsis: true,
      render: (text) => {
        return (
          <>
            <div>{text ? text : "--"}</div>
          </>
        );
      },
    },
    {
      title: "总投入",
      dataIndex: "totalNum",
      width: 97,
      ellipsis: true,
    },
    {
      title: "良品总数（良率）",
      dataIndex: "goodProductNum",
      width: 167,
      ellipsis: true,
      render: (_, record) => {
        const percent = record.goodProductNumPor.slice(0, -1);
        const percentSrc = "(" + record.goodProductNumPor + ")";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <div className="column-percent-container">
            <div>{record.goodProductNum}</div>
            {percentSrc}
            <Progress
              type="circle"
              percent={percent}
              size={16}
              strokeColor={color}
              className="custom-progress"
            />
          </div>
        );
      },
    },
    {
      title: "缺陷数量（不良率）",
      dataIndex: "defectNumPro",
      width: 183,
      ellipsis: true,
      render: (_, record) => {
        const percent = record.defectNumPro.slice(0, -1);
        const percentSrc = "(" + record.defectNumPro + ")";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <div className="column-percent-container">
            <div>{record.defectNum}</div>
            {percentSrc}
            <Progress
              type="circle"
              percent={percent}
              size={16}
              strokeColor={color}
              className="custom-progress"
            />
          </div>
        );
      },
    },
    {
      title: "人检总数",
      dataIndex: "personTotalNum",
      width: 113,
      ellipsis: true,
    },
    {
      title: "人检良品数（占比）",
      dataIndex: "personGoodProductNumPro",
      width: 183,
      ellipsis: true,
      render: (_, record) => {
        const percent = record.personGoodProductNumPro.slice(0, -1);
        const percentSrc = "(" + record.personGoodProductNumPro + ")";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <div className="column-percent-container">
            <div>{record.personGoodProductNum}</div>
            {percentSrc}
            <Progress
              type="circle"
              percent={percent}
              size={16}
              strokeColor={color}
              className="custom-progress"
            />
          </div>
        );
      },
    },
    {
      title: "人检缺陷数（占比）",
      dataIndex: "personDefectNum",
      width: 183,
      ellipsis: true,
      render: (_, record) => {
        const percent = record.personDefectNumPro.slice(0, -1);
        const percentSrc = "(" + record.personDefectNumPro + ")";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <div className="column-percent-container">
            <div>{record.personDefectNum}</div>
            {percentSrc}
            <Progress
              type="circle"
              percent={percent}
              size={16}
              strokeColor={color}
              className="custom-progress"
            />
          </div>
        );
      },
    },
    {
      title: "登录时长",
      dataIndex: "loginTimeDesc",
      width: 133,
      ellipsis: true,
    },
    {
      title: "操作",
      width: 104,
      dataIndex: "operation",
      fixed: "right",
      render: (text, record) => (
        <div className="operation">
          <OperateButton
            text="登录时长"
            icon="icon-document-search"
            click={() => checkModal(record)}
            data={record}
          />
        </div>
      ),
    },
  ];
  // 登录明细
  const loginColumns = [
    {
      title: "日期",
      dataIndex: "date",
      width: 111,
      ellipsis: true,
      fixed: "left",
    },
    {
      title: "登录时长",
      dataIndex: "loginTime",
      width: 157,
      ellipsis: true,
    },
    {
      title: "最早登录时间",
      dataIndex: "earliestLoginTime",
      width: 189,
      ellipsis: true,
    },
    {
      title: "最后登出时间",
      dataIndex: "lastLoginTime",
      width: 200,
      ellipsis: true,
    },
  ];

  // 按员工统计列表查询参数
  const getQueryParams = () => {
    const { projectCodeOrName, time } = form.getFieldsValue();
    const queryParams = {
      data: {
        productName: "aa", //随便填写
        productCode, // 产能人效首页传过来的code
        userName: projectCodeOrName, // 名称或者手机号
      },
      page: {
        pageIndex: page,
        pageSize: size,
      },
    };
    if (time && time.length) {
      queryParams.data.startTime = dayjs(time[0])
        .startOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      queryParams.data.endTime = dayjs(time[1])
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
    }
    return queryParams;
  };

  // 按人员统计/登录明细-导出
  const exportList = (exportType) => {
    const params = {};
    const { time, projectCodeOrName } = form.getFieldsValue();
    params.userName = projectCodeOrName;
    let searchTime = "";
    if (time && time.length) {
      params.startTime = dayjs(time[0])
        .startOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      params.endTime = dayjs(time[1])
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      searchTime =
        "(" +
        dayjs(time[0]).startOf("date").format("YYYY-MM-DD") +
        "——" +
        dayjs(time[1]).startOf("date").format("YYYY-MM-DD") +
        ")";
    }
    if (exportType === "login") {
      params.productCode = loginProductCode;
      params.userId = loginUserId;
      exportFile(
        `/capacityEfficiency/loginDetailExport`,
        "post",
        params,
        `${personName}-${productName}${searchTime}.xlsx`
      );
    } else {
      params.productCode = productCode;
      params.productName = " ";
      exportFile(
        `/capacityEfficiency/statisticsByPersonnelExport`,
        "post",
        params,
        `${productName}${searchTime}.xlsx`
      );
    }
  };

  /**
   *
   * @description 查询列表方法
   */
  const queryList = () => {
    const queryParams = getQueryParams();
    efficiencyStatisticsByPersonnel(queryParams)
      .then((res) => {
        setDataSource(res.list);
        setTotal(res.total);
      })
      .catch((e) => message.error(e));
  };

  /**
   * @description 查询登录明细列表
   */
  const queryLoginList = () => {
    const params = {
      productCode: loginProductCode,
      userId: loginUserId,
    };

    efficiencyLoginDetail(params)
      .then((list) => {
        setLoginData(list);
      })
      .catch((e) => message.error(e));
  };

  useEffect(() => {
    queryList();
    // eslint-disable-next-line
  }, [page, size]);

  useEffect(() => {
    if (isModalOpen) {
      queryLoginList();
    }
  }, [isModalOpen]);

  // 输入搜索e
  const onSearch = (value) => {
    setPage(1);
    queryList();
  };

  // 登录明细弹框
  const checkModal = (record) => {
    setLoginUserId(record.userId);
    setPersonName(record.userName);
    setLoginProductCode(record.productCode);
    setIsModalOpen(true);
  };

  // 翻页
  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };

  // 日期选择
  const onFinish = (params) => {
    setPage(1);
    queryList();
  };

  return (
    <>
      <div className="staff-statistic-container">
        <div className="header-container">
          <Button
            type="primary"
            className="button-export"
            onClick={() => exportList("staff")}
          >
            导出
            <i className="iconfont icon-external-link"></i>
          </Button>
          <Form form={form} layout="inline" colon={false}>
            <Form.Item name="time" label="选择日期:">
              <RangePicker onChange={onFinish} className="staff-range-picker" />
            </Form.Item>
            <Form.Item name="projectCodeOrName">
              {/* <Input
                style={{
                  width: 354,
                }}
                className="staff-input"
                autoComplete="off"
                placeholder="请输入人员姓名或手机号"
                onChange={(e) => {
                  if (invalidInput(e.nativeEvent.data)) {
                    form.setFieldsValue({
                      projectCodeOrName: e.target.value.slice(0, -1),
                    });
                  }
                }}
                suffix={<SearchOutlined onClick={() => onSearch()} />}
              ></Input> */}
              <Search
                placeholder="请输入人员姓名或手机号"
                onSearch={onSearch}
                allowClear
                className="custom-staff-search"
              />
            </Form.Item>
          </Form>
        </div>

        <div className="table-container">
          <Table
            size="middle"
            className="custom-table staff-table"
            rowKey={(record) => record.id}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={{
              // y: containerHeight - 56 - 24 * 2 - 56 * 2 - 32 - 56 - 24,
              x: 1300,
            }}
          />
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "40", "50"]}
            showSizeChanger
            showQuickJumper
            className="custom-pagination"
            showTotal={(total) => `共 ${total} 条`}
            onChange={handleChangePagination}
          />
        </div>
        <Modal
          className="login-detail-modal"
          title="登录明细"
          open={isModalOpen}
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          maskClosable={false}
          cancelText={<i className="iconfont icon-reply">返回</i>}
          confirmLoading={false}
          okButtonProps={{ style: { display: "none" } }}
          okText={
            <span>
              <i className="iconfont icon-baocun"></i>保存
            </span>
          }
        >
          <Button
            type="primary"
            className="button-export"
            onClick={() => exportList("login")}
          >
            导出
            <i className="iconfont icon-external-link"></i>
          </Button>
          <div className="table-list-container" style={{ height: 350 }}>
            <Table
              size="middle"
              className="custom-table login-details-table"
              rowKey={(record) => record.id}
              dataSource={loginData}
              columns={loginColumns}
              pagination={false}
              scroll={{
                y: containerHeight - 56 - 24 * 2 - 56 * 2 - 32 - 56 - 24,
                x: 1300,
              }}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}
export default StaffStatistic;
