import { axiosInstance } from "../config";

// 渠道分页
export const getChannelPage = (params) => {
  return axiosInstance.post(`/channel/page`, params);
};

//通过渠道id查询详情
export const queryDetailById = (id) => {
  return axiosInstance.post(`/channel/${id}`);
};

// 更新/编辑详情保存
export const updateChannelDetail = (params) => {
  return axiosInstance.post(`/channel/update`, params);
};

// 更新/编辑详情保存
export const deleteChannelById = (id) => {
  return axiosInstance.post(`/channel/delete/${id}`);
};

// 渠道-新增
export const addChannel = (params) => {
  return axiosInstance.post(`/channel/add`, params);
};

/*----------------销售工具管理----------------------------*/
// 新增销售工具
export const addSaleTool = (params) => {
  return axiosInstance.post(`/channelsalestool/add`, params);
};

// 通过id删除销售工具
export const deleteToolById = (id) => {
  return axiosInstance.post(`/channelsalestool/delete/${id}`);
};

// 停用
export const disableTool = (id) => {
  return axiosInstance.post(`/channelsalestool/disable/${id}`);
};

// 下载token
// export const downLoadToken = (id) => {
//   return axiosInstance.post(`/channelsalestool/downloadToken/${id}`);
// };

// 启用
export const enableTool = (id) => {
  return axiosInstance.post(`/channelsalestool/enable/${id}`);
};

// 分页查询
export const getToolsPage = (params) => {
  return axiosInstance.post(`/channelsalestool/page`, params);
};

// 修改销售工具
export const updateSaleTool = (params) => {
  return axiosInstance.post(`/channelsalestool/update`, params);
};

// 通过id查询tool
export const queryToolById = (params) => {
  return axiosInstance.post(`/systemdict/list`, params);
};

// 演示项目传
export const queryDemoProjects = (params) => {
  return axiosInstance.post(`/project/page`, params);
};

/*-----------------------渠道-人员管理----------------------------*/

// {
//   "channelId": 324342432,
//   "personnelName": "张三",
//   "personnelPhone": "13681666666"
// }
// 新增渠道人员
export const addPersonnel = (params) => {
  return axiosInstance.post(`/channelpersonnel/add`, params);
};

// 删除渠道人员
export const deletePersonnel = (id) => {
  return axiosInstance.post(`/channelpersonnel/delete/${id}`);
};

// 启用人员账号
export const personnalEnable = (id) => {
  return axiosInstance.post(`/channelpersonnel/enable/${id}`);
};

// 禁用人员账号
export const personnalForbidden = (id) => {
  return axiosInstance.post(`/channelpersonnel/forbidden/${id}`);
};

// {
//   "password": "h453hu",
//   "phone": "13681666666"
// }
// 渠道人员登录
export const personnalLogin = (params) => {
  return axiosInstance.post(`/channelpersonnel/login`, params);
};

// {
//   "data": {
//     "channelId": 324342432,
//     "searchName": "张三"
//   },
//   "page": {
//     "pageIndex": 0,
//     "pageSize": 10
//   }
// }
// 分页查询
export const personnalPage = (params) => {
  return axiosInstance.post(`/channelpersonnel/page`, params);
};

// 重置密码
export const resetPassword = (id) => {
  return axiosInstance.post(`/channelpersonnel/resetPasswords/${id}`);
};

// 下拉列表
export const querySelectList = () => {
  return axiosInstance.post(`/channelpersonnel/selectList`);
};

// {
//   "id": 0,
//   "personnelPhone": "13681666666"
// }
// 修改渠道人员
export const personnalUpdate = (params) => {
  return axiosInstance.post(`/channelpersonnel/update`, params);
};

// 通过id查询
export const queryPersonnalById = (id) => {
  return axiosInstance.post(`/channelpersonnel/${id}`);
};
