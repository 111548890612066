/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-08-03 16:24:55
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-08-05 09:10:25
 */
import store from "../../store";
import { axiosInstance } from "../config";

const uuamURL = process.env.REACT_APP_UUAM_URL;

// 获取用户是否具有当前系统的权限
export function getUserAuthBySystemId() {
  const url = [
    uuamURL,
    "user/authz/system",
    "/",
    store.getState().userReducer.systemId,
  ].join("");
  return axiosInstance.get(url);
}
//获取用户有权限的菜单
export function getPermissionMenu() {
  return axiosInstance.get(
    uuamURL + "user/authz/menus/" + store.getState().userReducer.systemId
  );
}
//获取用户信息
export function getUserInfo() {
  return axiosInstance.get(uuamURL + "user/info");
}
