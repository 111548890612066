/*
 * @Author: gn
 * @Date: 2023-06-09 14:38:22
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-10-23 14:12:00
 * @Description: file content
 */
import readSession from "@/utils/commonFunc/readSession";
import { Form, InputNumber, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./index.scss";

const { isWorkday, isHoliday } = require('chinese-workday')

const EditableContext = React.createContext(null)
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  data,
  isDisabled,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing && record.allowModified && record.maxWorkHour > 0) {
      inputRef.current.focus()
    }
  }, [editing])
  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    })
  }
  const save = async () => {
    try {
      const values = await form.validateFields()
      // toggleEdit()
      handleSave({
        ...record,
        ...values
      })
    } catch (errInfo) {
      console.log("Save failed:", errInfo)
    }
  }
  const disabledValue = () => {
    if(isDisabled) {
      return true
    } else {
      return !record.allowModified || record.approvel
    }
  }
  let childNode = children
  if (editable) {
    form.setFieldsValue(record)
    childNode = (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title}必填`
          }
        ]}
      >
        <InputNumber
          ref={inputRef}
          min={0}
          max={record.maxWorkHour}
          precision={1}
          step={0.1}
          onChange={save}
          disabled={disabledValue()}
        />
      </Form.Item>
    )
  }
  return <td {...restProps}>{childNode}</td>
}

const WorkStatistics = (props) => {
  const { rowNum = 11 } = props
  const [dataSource, setDataSource] = useState([])
  const isDisabled = useRef(false)
  const currentUser = useSelector((state) => state.userReducer);
  useEffect(() => {
    setDataSource(props.dataSource)
    const taskUserId = (readSession("detailTaskData") && readSession("detailTaskData").taskUserId) || (readSession("taskProgressList") && readSession("taskProgressList").userId)
    const isMyLiable = readSession("detailTaskData") && readSession("detailTaskData").isMyLiable
    if(readSession('taskPass')) {
      isDisabled.current = false
    } else {
      if(taskUserId != currentUser.id && !isMyLiable) {
        isDisabled.current = true
      } else {
        isDisabled.current = false
      }
    }
    
  }, [props])
  const rowClassName = (record) => {
    if (isHoliday(record.date) || !isWorkday(record.date)) {
      return 'holiday';
    } else {
      return '';
    }
  };
  const defaultColumns = [
    {
      title: "日期",
      dataIndex: "date",
      ellipsis: true,
      width: "40%",
      render: (text, record) => {
        return `${isHoliday(text) || !isWorkday(text) ? "休-" : "班-"}${text} ${
          record.allowModified
            ? record.approvel === 1
              ? "(已审批)"
              : ""
            : "(已锁定)"
        }`
      }
    },
    {
      title: "工时(天)",
      width: "30%",
      dataIndex: "workHour",
      ellipsis: true,
      editable: true
    },
    {
      title: "剩余工时(天)",
      dataIndex: "lastHour",
      ellipsis: true
    }
  ]
  const handleSave = (row) => {
    const newData = [...dataSource]
    const index = newData.findIndex((item) => row.date === item.date)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row
    })
    setDataSource(newData)
    props.getData(newData)
  }
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        data: props.dataSource,
        handleSave,
        isDisabled: isDisabled.current
      })
    }
  })
  return (
    <Table
      style={{ margin: "10px 0" }}
      components={components}
      size="middle"
      className="custom-table work-hour-table"
      rowKey={(record) => record.id}
      rowClassName={rowClassName}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      id="work-hour-table"
      scroll={{
        y: rowNum * 49
      }}
    />
  )
}

export default WorkStatistics
