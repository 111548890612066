/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-06 13:22:19
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-07 10:00:45
 */
import {
  addNode,
  addPlanBatch,
  addStageVersion,
  changeProjectNodeInfo,
  deleteNode,
  deleteStageVersion,
  fileList,
  projectNode,
  projectNodeUser,
  projectRole,
  systemdict,
  updateNode,
  updateStageVersion,
  userBySystem
} from "@/apis/projectCenter/projectManagement"
import { queryNodeTaskWorkHourExist } from "@/apis/projectOverview"
import {
  FalseLengthRange,
  LengthRange,
  LengthRangeName
} from "@/utils/commonFunc/validate"
import writeSession from "@/utils/commonFunc/writeSession"
import { DndContext } from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Progress,
  Select,
  Table,
  message
} from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import OperateButton from "../../../components/OperateButton"
import "../index.scss"

const { Option } = Select
const { TextArea } = Input
const { RangePicker } = DatePicker

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  })
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 999
        }
      : {})
  }
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <i
                className="iconfont icon-menu-alt-2"
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move"
                }}
                {...listeners}
              />
            )
          })
        }
        return child
      })}
    </tr>
  )
}

function ProjectPlan(props) {
  const { projectData } = props
  const [officerList, setOfficerList] = useState([]) //责任人列表
  const [roleList, setRoleList] = useState([]) //责任角色列表
  const [inList, setInList] = useState([])
  const [outList, setOutList] = useState([])
  const [busList, setBusList] = useState([])
  const [data, setData] = useState([]) // 表格数据
  const [modalOpen, setModalOpen] = useState(false)
  const [modalStatus, setModalStatus] = useState("add")
  const [nodeObj, setNodeObj] = useState({})
  const deleteNodeIds = [] //被删除的节点
  const [versionTitle, setVersionTitle] = useState("新增版本号")
  const [isModalOpen, setIsModalOpen] = useState(false) //弹窗展示
  const [form] = Form.useForm()
  const [versionForm] = Form.useForm()

  const dateFormat = "YYYY-MM-DD"

  const columns = [
    {
      title: "阶段名称",
      dataIndex: "stageName",
      key: "stageName",
      width: 220,
      render: (text, record) => {
        return (
          <div>
            <span>{text}</span>
            {record.sameVersion && <span>{record.version}</span>}
            {record.sameVersion && (
              <i
                className="icon-pencil iconfont mouse"
                style={{
                  color: "#3F83F8",
                  marginLeft: "8px"
                }}
                onClick={() => handleAddVersion(record, "edit")}
              ></i>
            )}
          </div>
        )
      }
    },
    {
      title: "阶段描述",
      dataIndex: "stageDesc",
      key: "stageDesc"
    },
    {
      title: "",
      dataIndex: "operation",
      align: "center",
      width: 160,
      fixed: "right",
      render: (text, record) => (
        <div className="operation-box">
          <span onClick={() => handleAddChildren(record)}>添加任务</span>
          {record.isInitVersion ? (
            <span onClick={() => handleAddVersion(record, "add")}>
              新增版本
            </span>
          ) : (
            <OperateButton
              text="删除版本"
              popType={true}
              title={`确定要删除该版本吗？`}
              icon="icon-close"
              data={record}
              confirm={() => handleDeletVersion(record.stageVersionId)}
            />
          )}
        </div>
      )
    }
  ] //父节点描述
  const childrenColumns = [
    {
      key: "sort",
      width: 50,
      sortIcon: () => {
        return "nihao"
      }
    },
    {
      title: "编号",
      dataIndex: "nodeCode",
      key: "nodeCode",
      width: 100,
      render: (text) => {
        return <span className="light-text">{text}</span>
      }
    },
    {
      title: "任务名称",
      dataIndex: "nodeName",
      key: "nodeName",
      render: (text) => {
        return <span className="light-text">{text}</span>
      }
    },
    {
      title: "节点输入",
      dataIndex: "nodeInput",
      key: "nodeInput",
      render: (text) => {
        return <span className="wrap-text light-text">{handleInput(text)}</span>
      }
    },
    {
      title: <p>责任人</p>,
      dataIndex: "user",
      key: "user",
      width: 150,
      render: (text, record) => {
        return projectData.status === 0 ? (
          <Select
            placeholder="请选择责任人"
            value={record.userName ? record.userName : ""}
            onSelect={(value, option) =>
              handleChangeUser(value, option, record)
            }
            onFocus={() => handleFocus(record)}
          >
            {officerList.map((item, index) => (
              <Option key={item.projectUserId} value={item.projectUserId}>
                {item.name}
              </Option>
            ))}
          </Select>
        ) : (
          <span className="light-text">{text ? text.userName : "--"}</span>
        )
      }
    },
    {
      title: "计划开始时间-计划结束时间",
      dataIndex: "planStartTime",
      key: "planStartTime",
      width: 300,
      render: (text, record, index) => {
        return projectData.status === 0 ? (
          <RangePicker
            value={
              text
                ? [
                    dayjs(text, dateFormat),
                    dayjs(record.planEndTime, dateFormat)
                  ]
                : []
            }
            allowClear={false}
            onChange={(date, dateString) =>
              handleChange(date, dateString, record, index)
            }
          />
        ) : (
          <span className="light-text">
            {text
              ? dayjs(text).format("YYYY-MM-DD") +
                " ~ " +
                dayjs(record.planEndTime).format("YYYY-MM-DD")
              : "--"}
          </span>
        )
      }
    },
    {
      title: "任务状态",
      dataIndex: "nodeStatus",
      key: "nodeStatus",
      width: 130,
      render: (text, record) => {
        switch (text) {
          case 0:
            return (
              <span
                className={
                  "node-status " + (record.isDelay ? "yellow" : "teal")
                }
              >
                {record.isDelay ? "未开始(已延期)" : "未开始"}
              </span>
            )
          case 1:
            return (
              <span
                className={"node-status " + (record.isDelay ? "red" : "green")}
              >
                {record.isDelay ? "进行中(已延期)" : "进行中"}
              </span>
            )
          default:
            return <span className="node-status blue">已完成</span>
        }
      }
    },
    {
      title: "任务进度",
      dataIndex: "nodeProgress",
      key: "nodeProgress",
      width: 100,
      render: (text, record) => {
        return (
          <div>
            {(text ? text : 0) + "%"}
            <Progress
              type="circle"
              percent={record.nodeProgress ? record.nodeProgress : 0}
              size={15}
              strokeWidth={10}
              style={{ marginLeft: "7px" }}
              strokeColor={"#3F83F8"}
            />
          </div>
        )
      }
    },
    {
      title: "任务工时(天)",
      dataIndex: "workHours",
      key: "workHours",
      width: 115,
      render: (text, record, index) => {
        return <span className="light-text">{text ? text : "--"}</span>
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 160,
      fixed: "right",
      render: (text, record) => (
        // eslint-disable-next-line
        <div className="operation">
          {projectData.status === 1 && (
            <OperateButton
              text="编辑"
              icon="icon-pencil"
              data={record}
              click={() => {
                handleEdit(record, "bainji")
              }}
            />
          )}
          <OperateButton
            text="删除"
            popType={false}
            click={() => handleDelete(record)}
          />
        </div>
      )
    }
  ] // 子节点描述

  const nullData = [] //没有值的table

  useEffect(() => {
    getProjectNode() //获取节点下内容
    getProjectRole()
    getFileList(0)
    getFileList(1)
    getBus()
  }, []) // eslint-disable-line

  /**
   * @description: 获取责任角色
   * @return {*}
   * @Author: gn
   */
  const getProjectRole = () => {
    projectRole(projectData.id)
      .then((res) => {
        setRoleList(res)
      })
      .catch((e) => message.error(e))
  }
  /**
   * @description: 切换角色
   * @return {*}
   * @Author: gn
   */
  const changeRole = (val) => {
    getUserBySystem(val.join(","))
  }
  /**
   * @description: 获取责任人
   * @return {*}
   * @Author: gn
   */
  const getUserBySystem = (systemRoleId) => {
    const params = {
      projectId: projectData.id,
      systemRoleId: systemRoleId
    }
    userBySystem(params)
      .then((res) => {
        setOfficerList(res)
      })
      .catch((e) => message.error(e))
  }
  /**
   * @description: 获取节点输入输出
   * @return {*}
   * @Author: gn
   */
  const getFileList = (val) => {
    const params = {
      projectId: projectData.id,
      inputOrOutput: val
    }
    fileList(params)
      .then((res) => {
        if (val === 0) {
          setInList(res)
        } else if (val === 1) {
          setOutList(res)
        }
      })
      .catch((err) => {
        message.error(err)
      })
  }
  /**
   * @description: 业务系统
   * @return {*}
   * @Author: gn
   */
  const getBus = () => {
    const params = {
      categoryCode: "business_system"
    }
    systemdict(params)
      .then((res) => {
        setBusList(res)
      })
      .catch((err) => {
        message.error(err)
      })
  }
  /**
   * @description: 获取节点下内容
   * @return {*}
   * @author: LINA.QI
   */
  const getProjectNode = () => {
    projectNode(projectData.id)
      .then((res) => {
        res.forEach((item) => {
          const sameId =
            res.filter((otherItem) => otherItem.stageId === item.stageId)
              .length > 1
          item.sameVersion = sameId
          if (item.nodeList) {
            item.nodeList.forEach((resp) => {
              // resp.projectUserId = undefined;
              resp.userName = resp.user ? resp.user.userName : undefined
            })
          }
        })
        setData(res)
        console.log(data)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 处理节点展示
   * @return {*}
   * @author: LINA.QI
   */
  const handleInput = (data) => {
    if (data) {
      let newText = []
      data.forEach((item) => {
        newText.push(item)
      })
      return newText.toString()
    }
  }
  /**
   * @description: 按下获取责任人
   * @return {*}
   * @author: LINA.QI
   */
  const handleFocus = (record) => {
    setOfficerList([])
    projectNodeUser(record.nodeId)
      .then((res) => {
        setOfficerList(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 切换责任人
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeUser = (value, option, record) => {
    let newData = [...data]
    newData.forEach((item) => {
      console.log(newData)
      if (!item.nodeList) {
        return
      }
      item.nodeList = item.nodeList.map((res) => {
        if (res.nodeId === record.nodeId) {
          return {
            ...res,
            projectUserId: value,
            userName: option.children,
            isChange: true
          }
        } else {
          return res
        }
      })
    })
    setData(newData)
    writeSession("sessionSave", true)
  }
  /**
   * @description: 新增任务
   * @return {*}
   * @Author: gn
   */
  const handleAddChildren = (record) => {
    setModalOpen(true)
    setModalStatus("add")
    setNodeObj({
      stageId: record.stageId,
      stageVersionId: record.stageVersionId
    })
    const json = {
      nodeId: null,
      nodeName: null,
      nodeCode: null,
      nodeDesc: null,
      businessSystems: null,
      nodeSort: "",
      projectUserId: null,
      stageId: record.stageId,
      systemRoleId: undefined,
      nodeInputIds: [],
      nodeOutputIds: [],
      planTime: [],
      stageVersionId: record.stageVersionId
    }
    form.setFieldsValue({ ...json })
  }

  /**
   * @description: 新增版本
   * @return {*}
   * @author: LINA.QI
   */
  const handleAddVersion = (record, type) => {
    versionForm.setFieldsValue({
      type: type === "add" ? "add" : "edit",
      stageVersionId: record.stageVersionId,
      version: type === "add" ? null : record.version,
      stageId: type === "add" ? record.stageId : null
    })
    setVersionTitle(type === "add" ? "新增版本" : "修改版本")
    setIsModalOpen(true)
  }
  /**
   * @description: 删除版本
   * @return {*}
   * @author: LINA.QI
   */
  const handleDeletVersion = (id) => {
    deleteStageVersion({ stageVersionId: id, projectId: projectData.id })
      .then((res) => {
        if (res) {
          message.success("删除成功！")
          getProjectNode()
        }
      })
      .catch((err) => {
        message.error(err)
      })
  }

  /**
   * @description: 切换时间
   * @return {*}
   * @author: LINA.QI
   */
  const handleChange = (date, dateString, record, index) => {
    let newData = [...data]
    newData.forEach((item) => {
      item.nodeList = item.nodeList.map((res) => {
        if (res.nodeId === record.nodeId) {
          return {
            ...res,
            planStartTime: dateString[0],
            planEndTime: dateString[1],
            isChange: true
          }
        } else {
          return res
        }
      })
    })
    setData(newData)
    writeSession("sessionSave", true)
  }

  /**
   * @description: 编辑
   * @return {*}
   * @author: LINA.QI
   */
  const handleEdit = (record, type) => {
    const params = {
      ...record,
      systemRoleId: record.systemRoleId.split(",")
    }
    setModalOpen(true)
    // if(projectData.status === 0){
    //   setModalStatus('add');
    // }else{
    //   setModalStatus('edit');
    // }
    setModalStatus("edit")
    setNodeObj(params)
    let planTime = []
    if (record.planStartTime) {
      planTime = [
        dayjs(record.planStartTime, dateFormat),
        dayjs(record.planEndTime, dateFormat)
      ]
    }
    let nodePut = {
      nodeInputIds: record.nodeInputIds ? record.nodeInputIds : [],
      nodeOutputIds: record.nodeOutputIds ? record.nodeOutputIds : []
    }
    form.setFieldsValue({
      ...params,
      ...nodePut,
      planTime,
      projectUserId: record.user?.projectUserId
    })
    getUserBySystem(record.systemRoleId)
  }

  /**
   * @description: 删除
   * @return {*}
   * @Author: gn
   */
  const handleDelete = (val) => {
    let params = {
      projectId: projectData.id,
      nodeId: val.nodeId
    }
    queryNodeTaskWorkHourExist(params).then((res) => {
      Modal.confirm({
        title:
          res === true
            ? "该节点已产生工时，删除后将会清除相应工时数据，请谨慎操作，是否确认删除？"
            : "确定要删除该节点吗？",
        icon: (
          <i
            className="iconfont icon-shanchu"
            style={{ color: "#F05252", fontSize: "16px", marginRight: "4px" }}
          ></i>
        ),
        wrapClassName: "confirmation",
        centered: true,
        okText: "确认",
        okButtonProps: {
          icon: (
            <i
              className="iconfont icon-check"
              style={{ color: "#fff", fontSize: "16px" }}
            />
          )
        },
        cancelText: "取消",
        cancelButtonProps: {
          icon: <i className="iconfont icon-x" style={{ fontSize: "16px" }} />
        },
        onOk: () => confirmDel(val.nodeId)
      })
    })
  }

  const confirmDel = (nodeId) => {
    const params = {
      nodeId: nodeId,
      projectId: projectData.id
    }
    deleteNode(params)
      .then((res) => {
        message.success("删除成功")
        getProjectNode()
      })
      .catch((err) => {
        message.error(err)
      })
  }
  /**
   * @description: 批量保存
   * @return {*}
   * @author: LINA.QI
   */
  const handleConfirm = (val) => {
    if (val) {
      let projectNodeList = []
      val.forEach((e) => {
        e.nodeList.forEach((v, i) => {
          let json = {
            ...v,
            nodeSort: i
          }
          projectNodeList.push(json)
        })
      })

      let params = {
        deleteNodeIds: deleteNodeIds,
        projectId: projectData.id,
        projectNodeList: projectNodeList
      }
      addPlanBatch(params)
        .then((res) => {
          // message.success('保存成功');
          getProjectNode()
        })
        .catch((e) => message.error(e))
    } else {
      let params = []
      data.forEach((item) => {
        if (item.nodeList) {
          item.nodeList.forEach((resp) => {
            if (resp.isChange) {
              resp.planStartTime = resp.planStartTime
                ? dayjs(resp.planStartTime).format("YYYY-MM-DD HH:mm:ss")
                : null
              resp.planEndTime = resp.planEndTime
                ? dayjs(resp.planEndTime).format("YYYY-MM-DD 23:59:59")
                : null
              params.push(resp)
            }
          })
        }
      })
      if (params.length === 0) {
        message.error("暂无数据可保存！")
        return
      }
      changeProjectNodeInfo(params)
        .then((res) => {
          message.success("保存成功")
          writeSession("sessionSave", false)
          getProjectNode()
        })
        .catch((e) => message.error(e))
    }
  }

  /**
   * @description: 单个保存
   * @return {*}
   * @Author: gn
   */
  const [taskLoading, setTaskLoading] = useState(false)
  const handleAdd = (val) => {
    let params = {
      ...nodeObj,
      ...val,
      projectId: projectData.id,
      planStartTime:
        Array.isArray(val.planTime) && val.planTime.length > 0
          ? dayjs(val.planTime[0]).format("YYYY-MM-DD HH:mm:ss")
          : "",
      planEndTime:
        Array.isArray(val.planTime) && val.planTime.length > 0
          ? dayjs(val.planTime[1]).format("YYYY-MM-DD 23:59:59")
          : ""
    }
    setTaskLoading(true)
    addNode(params)
      .then((res) => {
        message.success("保存成功")
        getProjectNode()
        setModalOpen(false)
        setTaskLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setTaskLoading(false)
      })
  }
  /**
   * @description: 单个编辑
   * @return {*}
   * @Author: gn
   */
  const handleUpdate = (val) => {
    const params = {
      nodeId: nodeObj.nodeId,
      projectUserId: val.projectUserId,
      planStartTime:
        Array.isArray(val.planTime) && val.planTime.length > 0
          ? dayjs(val.planTime[0]).format("YYYY-MM-DD HH:mm:ss")
          : "",
      planEndTime:
        Array.isArray(val.planTime) && val.planTime.length > 0
          ? dayjs(val.planTime[1]).format("YYYY-MM-DD 23:59:59")
          : ""
    }
    setTaskLoading(true)
    updateNode(params)
      .then((res) => {
        message.success("编辑成功")
        getProjectNode()
        setModalOpen(false)
        setTaskLoading(false)
      })
      .catch((e) => {
        message.error(e)
        setTaskLoading(false)
      })
  }

  /**
   * @description: 取消
   * @return {*}
   * @author: LINA.QI
   */
  const handleCancel = () => {
    writeSession("sessionSave", false)
    getProjectNode()
  }

  /**
   * @description: 拖拽
   * @param {*} active
   * @param {*} over
   * @return {*}
   * @Author: gn
   */
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      let arrData = [...data]
      for (let n = 0; n < arrData.length; n++) {
        let nodeList = arrData[n].nodeList
        if (nodeList.findIndex((i) => i.nodeId === active.id) !== -1) {
          const activeIndex = nodeList.findIndex((i) => i.nodeId === active.id)
          const overIndex = nodeList.findIndex((i) => i.nodeId === over?.id)
          arrData[n].nodeList = arrayMove(nodeList, activeIndex, overIndex)
        }
      }
      setData(arrData)
      handleConfirm(arrData)
    }
  }
  /**
   * @description: 关闭弹窗
   * @return {*}
   * @Author: gn
   */
  function hanleCloseModel() {
    setModalOpen(false)
  }

  useEffect(() => {
    if (!isModalOpen) {
      versionForm.resetFields()
    }
  }, [isModalOpen])
  /**
   * @description: 版本弹窗关闭
   * @return {*}
   * @author: LINA.QI
   */
  function handleCloseVersions() {
    setIsModalOpen(false)
  }
  /**
   * @description: 表单校验成功
   * @return {*}
   * @Author: gn
   */
  function onFinish(values) {
    const params = {
      ...values,
      systemRoleId: values.systemRoleId.join(",")
    }
    if (modalStatus === "add") {
      handleAdd(params)
    } else if (modalStatus === "edit") {
      handleUpdate(params)
    }
  }
  /**
   * @description: 表单校验失败
   * @return {*}
   * @Author: gn
   */
  function onFinishFailed(error) {}

  /**
   * @description: 版本号校验成功
   * @return {*}
   * @author: LINA.QI
   */
  function confirmFinish(values) {
    if (versionForm.getFieldValue().type === "add") {
      addStageVersion({
        ...versionForm.getFieldValue(),
        projectId: projectData.id
      })
        .then((res) => {
          if (res) {
            message.success("新增成功")
            getProjectNode()
            setIsModalOpen(false)
          }
        })
        .catch((e) => message.error(e))
    } else {
      updateStageVersion({
        ...versionForm.getFieldValue(),
        projectId: projectData.id
      })
        .then((res) => {
          if (res) {
            message.success("修改成功")
            getProjectNode()
            setIsModalOpen(false)
          }
        })
        .catch((e) => message.error(e))
    }
  }

  return (
    <>
      <div className="project-plan">
        {data && data.length > 0 && (
          <Table
            columns={
              projectData.status === 3 || projectData.status === 2
                ? columns.filter((ea) => ea.dataIndex !== "operation")
                : columns
            }
            className="up-table"
            rowKey={(record) => record.stageVersionId}
            expandable={{
              expandedRowRender: (record) => (
                <DndContext
                  modifiers={[restrictToVerticalAxis]}
                  onDragEnd={onDragEnd}
                >
                  <SortableContext
                    // rowKey array
                    items={
                      record.nodeList
                        ? record.nodeList.map((i) => i.nodeId)
                        : []
                    }
                    strategy={verticalListSortingStrategy}
                  >
                    <Table
                      components={{ body: { row: Row } }}
                      columns={
                        projectData.status === 3 || projectData.status === 2
                          ? childrenColumns.filter(
                              (ea) => ea.dataIndex !== "operation"
                            )
                          : childrenColumns
                      }
                      dataSource={
                        record.nodeList && record.nodeList.length > 0
                          ? record.nodeList
                          : []
                      }
                      scroll={{
                        x: 1300
                      }}
                      pagination={false}
                      // className="child-table"
                      rowKey={(record) => record.nodeId}
                    />
                  </SortableContext>
                </DndContext>
              ),
              defaultExpandAllRows: true,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <i
                    className="iconfont icon-chevron-up"
                    onClick={(e) => onExpand(record, e)}
                  />
                ) : (
                  <i
                    className="iconfont icon-chevron-down"
                    onClick={(e) => onExpand(record, e)}
                  />
                )
            }}
            dataSource={data}
            scroll={{
              x: 1000
            }}
            pagination={false}
          />
        )}
        {data.length === 0 && (
          <Table
            columns={columns}
            className="up-table"
            dataSource={nullData}
            pagination={false}
          />
        )}
      </div>
      {projectData.status === 0 && (
        <div className="confirm-box">
          <Button onClick={handleCancel}>
            <i className="iconfont icon-x" />
            取消
          </Button>
          <Button type="primary" onClick={() => handleConfirm(null)}>
            <i className="iconfont icon-baocun" />
            保存
          </Button>
        </div>
      )}
      <Modal
        width="520px"
        destroyOnClose={true}
        className="custom-modal"
        maskClosable={false}
        title="添加/编辑任务"
        open={modalOpen}
        footer={null}
        onCancel={hanleCloseModel}
      >
        <Form
          name="second"
          initialValues={{
            remember: true
          }}
          labelCol={{
            span: 4
          }}
          // wrapperCol={{
          //     span: 16,
          // }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="nodeCode"
            rules={[...LengthRangeName("节点编号", 1, 15)]}
            label="节点编号"
          >
            <Input
              disabled={modalStatus === "edit"}
              autoComplete="off"
              placeholder="请输入节点编号"
            ></Input>
          </Form.Item>
          <Form.Item
            name="nodeName"
            rules={[...LengthRangeName("节点名称", 1, 50)]}
            label="节点名称"
          >
            <Input
              disabled={modalStatus === "edit"}
              autoComplete="off"
              placeholder="请输入节点名称"
            ></Input>
          </Form.Item>
          <Form.Item
            name="nodeDesc"
            rules={[...FalseLengthRange(1, 600)]}
            label="责任描述"
          >
            <TextArea
              disabled={modalStatus === "edit"}
              rows={4}
              placeholder="请输入责任描述"
            />
          </Form.Item>
          <Form.Item
            name="systemRoleId"
            rules={[{ required: true }]}
            label="责任角色"
          >
            <Select
              placeholder="请选择责任角色"
              disabled={modalStatus === "edit"}
              mode="multiple"
              onChange={changeRole}
            >
              {roleList.map((item) => {
                return (
                  <Option
                    key={`${item.id}-${item.roleName}`}
                    value={item.id}
                    disabled={item.status === "1"}
                  >
                    {item.roleName}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item name="projectUserId" label="责任人">
            <Select
              placeholder="请选择责任人"
              disabled={"secondStatus" === "edit"}
            >
              {officerList.map((item) => {
                return (
                  <Option
                    key={`${item.id}-${item.projectUserId}`}
                    value={item.projectUserId}
                    disabled={item.status === "1"}
                  >
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="planTime"
            rules={
              [
                // {required : true}
              ]
            }
            label="计划时间"
          >
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="nodeInputIds" label="节点输入">
            <Select
              placeholder="请选择节点输入"
              disabled={modalStatus === "edit"}
              optionFilterProp="label"
              mode="multiple"
              showSearch
              options={inList.map((d) => ({ label: d.fileName, value: d.id }))}
            />
          </Form.Item>
          <Form.Item name="nodeOutputIds" label="节点输出">
            <Select
              placeholder="请选择节点输出"
              disabled={modalStatus === "edit"}
              mode="multiple"
              optionFilterProp="label"
              showSearch
              options={outList.map((d) => ({ label: d.fileName, value: d.id }))}
            />
          </Form.Item>
          <Form.Item name="businessSystems" label="业务系统">
            <Select
              placeholder="请选择业务系统"
              disabled={modalStatus === "edit"}
              fieldNames={{ label: "dictDesc", value: "dictCode" }}
              options={busList}
            />
          </Form.Item>
          <Form.Item
            style={{
              textAlign: "right",
              marginTop: "25px",
              marginBottom: "0px"
            }}
          >
            <Button style={{ marginRight: "8px" }} onClick={hanleCloseModel}>
              <i
                className="iconfont icon-x"
                style={{ fontSize: "14px", marginRight: "2px" }}
              ></i>
              取消
            </Button>
            <Button type="primary" htmlType="submit" loading={taskLoading}>
              <i
                className="iconfont icon-baocun"
                style={{ fontSize: "14px", marginRight: "2px" }}
              ></i>
              确认
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={versionTitle}
        wrapClassName="custom-modal"
        open={isModalOpen}
        centered={true}
        maskClosable={false}
        footer={null}
        onCancel={handleCloseVersions}
        width={500}
        destroyOnClose={true}
      >
        <Form
          name="second"
          labelCol={{
            span: 6
          }}
          form={versionForm}
          onFinish={confirmFinish}
          autoComplete="off"
        >
          <Form.Item
            name="version"
            rules={[...LengthRange(1, 30)]}
            label="输入版本号"
          >
            <Input
              autoComplete="off"
              placeholder="请输入版本号,长度<=30"
            ></Input>
          </Form.Item>
          <Form.Item
            style={{
              textAlign: "right",
              marginTop: "25px",
              marginBottom: "0px"
            }}
          >
            <Button
              style={{ marginRight: "8px" }}
              onClick={handleCloseVersions}
            >
              <i
                className="iconfont icon-x"
                style={{ fontSize: "14px", marginRight: "2px" }}
              ></i>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              <i
                className="iconfont icon-baocun"
                style={{ fontSize: "14px", marginRight: "2px" }}
              ></i>
              确认
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default ProjectPlan
