import { Button, Form, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { NameAddressSpecialChar, NameNotSpecialChar } from "../../../utils/commonFunc/validate";
import ".././index.scss";


const TypeHearder = (props) => {
  const { typeSource, headerType, deleteItem, getComponentTypeId, api, refresh } = props
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeId, setActiveId] = useState('')
  const [overBoolean, setOverBoolean] = useState(false)
  const [form] = Form.useForm()
  const dom = document.getElementById("types")

  useEffect(() => {
    activeId === '' && typeSource.length > 0 && setActiveId(typeSource[0].id)
    typeSource.length > 0 && getComponentTypeId(activeId)
  }, [activeId,typeSource,getComponentTypeId]);
  
  useEffect(()=> {
    dom && showShallow()
  },[dom,typeSource])
  const showShallow = () => {
    if(dom.scrollWidth > dom.clientWidth + 2) {
      setOverBoolean(true)
      dom.style.overflowX = "scroll"
    } else {
      setOverBoolean(false)
      dom.style.overflowX = "hidden"
    }
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.validateFields().then(values => {
      let params = {}
      if(headerType === 'equipment') {
        params = {
          categoryName: values.name,
          categoryNo: values.code
        }
      } else {
        params = {
          componentType: values.name,
          componentNo: values.code
        }
      }
      api(params).then(res => {
        if(res) {
          message.success("添加成功");
          form.resetFields()
          setIsModalOpen(false);
          refresh()
          showShallow()
        }
      })
      .catch((e) => message.error(e));
    })
    .catch((e) => message.error(e));
  };
  const deleteOption = (id) => {
    deleteItem(id)
    showShallow()
  }
  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false);
  };
  const changeActive = (item) => {
    if(!item) return
    setActiveId(item.id)
    getComponentTypeId(item.id)
  }
  
  const deviceIcon = require("@/assets/img/devicemanage/device.png")
  const componentIcon = require("@/assets/img/devicemanage/component.png")
  return (
    <>
      <div className="type-header">
        <p className="title">
        <span>
          <img style={{marginRight: "10px"}} src={headerType === 'equipment' ? deviceIcon : componentIcon} alt=""/>{headerType === 'equipment' ? '设备类型及类型下型号统计' : '组件类型'}
        </span>
        </p>
        <div className="types-content">
          <div className="types" id="types">
            {typeSource.map((d, idx)=> {
              return (
                <div className="type" key={idx}>
                  <div
                    className={`type-item ${activeId === d.id && 'active'}`}
                    onClick={() => changeActive(d)}
                  >
                    <p className="name" title={d.name}>{d.name}</p>
                    <p className="number">{d.number}</p>
                  </div>
                  {!d.number && <i className="iconfont icon-x-circle close" onClick={() => deleteOption(d.id)}></i>}
                  <div className={`${activeId === d.id && 'triangle'}`}></div>
                </div>
              )
            })}
          </div>
          <div className="add-part">
            <Button className="add-button" style={overBoolean ? {boxShadow: '-4px 0px 15px 0px rgba(136, 136, 136, 0.2)'} : {}}  onClick={showModal}>
              <i className="iconfont icon-plus"></i>
              添加类型
            </Button>
          </div>
        </div>
        
      </div>
      <Modal className="custom-modal" title="添加类型" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} maskClosable={false}>
        <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
          <Form.Item
            name="code"
            rules={NameAddressSpecialChar(headerType === 'equipment' ? "类型代码" : "类型编码",15)}
            label={headerType === 'equipment' ? "类型代码" : "类型编码"}
          >
            <Input
              style={{width: 300}}
              autoComplete="off"
              placeholder="支持输入英文、数字、_、-，长度<=15"
            ></Input>
          </Form.Item>
          <Form.Item
            name="name"
            rules={NameNotSpecialChar(headerType === 'equipment' ? "类型名称" : "组件类型",15,true)}
            label={headerType === 'equipment' ? "类型名称" : "组件类型"}
          >
            <Input 
              style={{width: 300}}
              autoComplete="off"
              placeholder="支持输入中英文、数字、_、-，长度<=15"
            ></Input>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default TypeHearder