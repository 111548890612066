/*
 * @Author: gn
 * @Date: 2023-06-09 14:38:22
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-04-18 10:08:00
 * @Description: file content
 */
import { Popconfirm } from "antd"
import React from "react"
import "./index.scss"

const OperateButton = (props) => {
  const {
    icon,
    text,
    data,
    popType,
    title,
    click,
    confirm,
    okText,
    cancelText,
    description
  } = props
  return (
    <>
      {!popType ? (
        <span onClick={() => click(data)} className="operate-button">
          {/* <i className={`iconfont ${icon} outIcon`}></i>
        <div className="extend">
          <i className={`iconfont ${icon}`}></i>
          <span className="text">{text}</span>
        </div> */}
          <span className="text">{text}</span>
        </span>
      ) : (
        <Popconfirm
          title={title}
          icon={
            <i
              className="iconfont icon-shanchu"
              style={{ color: "#F05252", fontSize: "16px", marginRight: "4px" }}
            ></i>
          }
          description={description}
          onConfirm={() => confirm(data.id)}
          okText={okText || "确定"}
          cancelText={cancelText || "取消"}
          placement="topRight"
        >
          <span className="operate-button" onClick={() => click && click(data)}>
            {/* <i className={`iconfont ${icon} outIcon`}></i>
          <div className="extend">
            <i className={`iconfont ${icon}`}></i>
            <span className="text">{text}</span>
          </div> */}
            <span className="text">{text}</span>
          </span>
        </Popconfirm>
      )}
    </>
  )
}

export default OperateButton
