/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-07-26 09:07:39
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-09-12 10:37:45
 */
export const CHANGE_MENU = "CHANGE_MENU"
export const GET_CONTAINER_HEIGHT = "GET_CONTAINER_HEIGHT"
export const UPDATE_EDITABLE_DOM = "UPDATE_EDITABLE_DOM"

export const GET_SYSTEM_AUTH = "GET_SYSTEM_AUTH"
export const SET_TOKEN = "SET_TOKEN"
export const GET_INFO = "GET_INFO"
export const GET_MENU = "GET_MENU"
export const SET_USER_INFO = "SET_USER_INFO"

export const UPDATE_PROJECT_NODE = "UPDATE_PROJECT_NODE"
export const ADD_PROJECT_NODE = "ADD_PROJECT_NODE"
export const DELETE_PROJECT_NODE = "DELETE_PROJECT_NODE"
export const DISPLAY_PROJECT_NODE = "DISPLAY_PROJECT_NODE"
// 项目面板-项目列表-筛选条件
export const BOARD_PROJECT_LIST_FILTER = "BOARD_PROJECT_LIST_FILTER"
