/*
 * @Author: pan.aiyan
 * @Date: 2023-06-20 18:48:28
 * @LastEditors: pan.aiyan
 * @LastEditTime: 2023-06-20 18:48:52
 * @Description: file content
 */
import { EventEmitter } from "events";

// 导入事件总线,利用这个对象发射和监听事件,这个对象是全局的
const eventBus = new EventEmitter();

export default eventBus;
