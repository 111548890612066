/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-13 13:42:54
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-18 09:53:17
 * @Description: 产能人效-详情-按人员统计
 */
import {
  Button, DatePicker, Form, Input, message,
  Pagination,
  Progress, Table
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { efficiencyStatisticsByDevice } from "../../../apis/efficiency";
import { exportFile } from "../../../utils/commonFunc/exportFile";
import "./index.scss";
function DeviceStatistic({ productCode, productName }) {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  let { containerHeight } = useSelector((state) => state.appReducer);
  const { Search } = Input;
  const columns = [
    {
      title: "设备序列号",
      dataIndex: "deviceNo",
      width: 117,
      ellipsis: true,
      fixed: "left",
    },
    {
      title: "产品名",
      dataIndex: "productName",
      width: 187,
      ellipsis: true,
      render: (text) => {
        return (
          <>
            <div>{text ? text : "--"}</div>
          </>
        );
      },
    },
    {
      title: "协同模型",
      dataIndex: "model",
      width: 113,
      ellipsis: true,
      render: (text) => {
        return (
          <>
            <div>{text ? text : "--"}</div>
          </>
        );
      },
    },
    {
      title: "总投入",
      dataIndex: "totalNum",
      width: 97,
      ellipsis: true,
    },
    {
      title: "良品总数（良率）",
      dataIndex: "goodProductPor",
      width: 167,
      ellipsis: true,
      render: (_, record) => {
        const percent = record.goodProductPor.slice(0, -1);
        const percentSrc = "(" + record.goodProductPor + ")";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <div className="column-percent-container">
            <div>{record.goodProductNum}</div>
            {percentSrc}
            <Progress
              type="circle"
              percent={percent}
              size={16}
              strokeColor={color}
              className="custom-progress"
            />
          </div>
        );
      },
    },
    {
      title: "缺陷数量（不良率）",
      dataIndex: "defectNumPor",
      width: 183,
      ellipsis: true,
      render: (_, record) => {
        const percent = record.defectNumPor.slice(0, -1);
        const percentSrc = "(" + record.defectNumPor + ")";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <div className="column-percent-container">
            <div>{record.defectNum}</div>
            {percentSrc}
            <Progress
              type="circle"
              percent={percent}
              size={16}
              strokeColor={color}
              className="custom-progress"
            />
          </div>
        );
      },
    },
    {
      title: "人检总数",
      dataIndex: "personTotalNum",
      width: 113,
      ellipsis: true,
    },
    {
      title: "人检良品数（占比）",
      dataIndex: "personGoodProductNumPor",
      width: 183,
      ellipsis: true,
      render: (_, record) => {
        const percent = record.personGoodProductNumPor.slice(0, -1);
        const percentSrc = "(" + record.personGoodProductNumPor + ")";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <div className="column-percent-container">
            <div>{record.personGoodProductNum}</div>
            {percentSrc}
            <Progress
              type="circle"
              percent={percent}
              size={16}
              strokeColor={color}
              className="custom-progress"
            />
          </div>
        );
      },
    },
    {
      title: "人检缺陷数（占比）",
      dataIndex: "personDefectNumPor",
      width: 183,
      ellipsis: true,
      render: (_, record) => {
        const percent = record.personDefectNumPor.slice(0, -1);
        const percentSrc = "(" + record.personDefectNumPor + ")";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <div className="column-percent-container">
            <div>{record.personDefectNum}</div>
            {percentSrc}
            <Progress
              type="circle"
              percent={percent}
              size={16}
              strokeColor={color}
              className="custom-progress"
            />
          </div>
        );
      },
    },
    {
      title: "机检总数",
      dataIndex: "machineTotalNum",
      width: 113,
      ellipsis: true,
    },
    {
      title: "机检良品数（占比）",
      dataIndex: "machineGoodProductNumPor",
      width: 183,
      ellipsis: true,
      render: (_, record) => {
        const percent = record.machineGoodProductNumPor.slice(0, -1);
        const percentSrc = "(" + record.machineGoodProductNumPor + ")";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <div className="column-percent-container">
            <div>{record.machineGoodProductNum}</div>
            {percentSrc}
            <Progress
              type="circle"
              percent={percent}
              size={16}
              strokeColor={color}
              className="custom-progress"
            />
          </div>
        );
      },
    },
    {
      title: "机检缺陷数（占比）",
      dataIndex: "machineDefectNumPor",
      width: 183,
      ellipsis: true,
      render: (_, record) => {
        const percent = record.machineDefectNumPor.slice(0, -1);
        const percentSrc = "(" + record.machineDefectNumPor + ")";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <div className="column-percent-container">
            <div>{record.machineDefectNum}</div>
            {percentSrc}
            <Progress
              type="circle"
              percent={percent}
              size={16}
              strokeColor={color}
              className="custom-progress"
            />
          </div>
        );
      },
    },
  ];
  // 登录明细
  const loginColumns = [
    {
      title: "日期",
      dataIndex: "date",
      width: 111,
      ellipsis: true,
      fixed: "left",
    },
    {
      title: "登录时长",
      dataIndex: "loginTime",
      width: 157,
      ellipsis: true,
    },
    {
      title: "最早登录时间",
      dataIndex: "earliestLoginTime",
      width: 189,
      ellipsis: true,
    },
    {
      title: "最后登出时间",
      dataIndex: "lastLoginTime",
      width: 200,
      ellipsis: true,
    },
  ];

  // 按员工统计列表查询参数
  const getQueryParams = () => {
    const { projectCodeOrName, time } = form.getFieldsValue();
    console.log("productCode-aa", productCode);
    const queryParams = {
      data: {
        productName: "aa", //随便填写
        productCode, // 产能人效首页传过来的code
        userName: projectCodeOrName, // 名称或者手机号
      },
      page: {
        pageIndex: page,
        pageSize: size,
      },
    };
    if (time && time.length) {
      queryParams.data.startTime = dayjs(time[0])
        .startOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      queryParams.data.endTime = dayjs(time[1])
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
    }
    return queryParams;
  };

  /**
   *
   * @description 查询列表方法
   */
  const queryList = () => {
    const queryParams = getQueryParams();
    efficiencyStatisticsByDevice(queryParams)
      .then((res) => {
        setDataSource(res.list);
        setTotal(res.total);
      })
      .catch((e) => message.error(e));
  };

  useEffect(() => {
    queryList();
    // eslint-disable-next-line
  }, [page, size]);

  // 输入搜索e
  const onSearch = (value) => {
    setTimeout(() => {
      setPage(1);
      queryList();
    }, 100);
  };

  // 翻页
  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };

  // 日期选择
  const onFinish = (params) => {
    setPage(1);
    queryList();
  };

  // 设备管理-导出明细
  const exportList = (exportType) => {
    const params = {};
    const { time, projectCodeOrName } = form.getFieldsValue();
    params.userName = projectCodeOrName;
    let searchTime = "";
    if (time && time.length) {
      params.startTime = dayjs(time[0])
        .startOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      params.endTime = dayjs(time[1])
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      searchTime =
        "(" +
        dayjs(time[0]).startOf("date").format("YYYY-MM-DD") +
        "——" +
        dayjs(time[1]).startOf("date").format("YYYY-MM-DD") +
        ")";
    }
    params.productCode = productCode;
    params.productName = " ";
    exportFile(
      `/capacityEfficiency/statisticsByDeviceExport`,
      "post",
      params,
      `${productName}${searchTime}.xlsx`
    );
  };

  return (
    <>
      <div className="device-statistic-container">
        <div className="header-container">
          <Button
            type="primary"
            className="button-export"
            onClick={() => exportList("staff")}
          >
            导出
            <i className="iconfont icon-external-link"></i>
          </Button>
          <Form form={form} layout="inline" colon={false}>
            <Form.Item name="time" label="选择日期:">
              <RangePicker onChange={onFinish} className="staff-range-picker" />
            </Form.Item>
            <Form.Item name="projectCodeOrName">
              <Search
                placeholder="请输入编号或项目名称"
                onSearch={onSearch}
                allowClear
                className="custom-device-search"
              />
            </Form.Item>
          </Form>
        </div>

        <div className="table-container">
          <Table
            size="middle"
            className="custom-table staff-table"
            rowKey={(record) => record.id}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={{
              y: containerHeight - 56 - 24 * 2 - 56 * 2 - 32 - 56 - 24,
              x: 1300,
            }}
          />
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "40", "50"]}
            showSizeChanger
            showQuickJumper
            className="custom-pagination"
            showTotal={(total) => `共 ${total} 条`}
            onChange={handleChangePagination}
            style={{ marginTop: 20 }}
          />
        </div>
      </div>
    </>
  );
}
export default DeviceStatistic;
