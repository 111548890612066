/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-08 11:24:58
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-04 10:17:44
 * @FilePath: /pms-web/src/containers/ProjectBoard/components/projectStatusGrid.js
 * @Description: 项目状态统计-统计格子项
 */
import eventBus from "@/utils/commonFunc/eventBus";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import writeSession from '@/utils/commonFunc/writeSession'
function ProjectStatusGrid({ name, status, image, num, backgroundColor }) {
  const navigate = useNavigate();
  // 面包屑点击传来的值以及方法
  const breadcrumbItemClickEventsFn = useCallback((item) => {
    console.log("item", item);
    switch (item) {
      case "项目看板":
        eventBus.emit("setBreadcrumbData", ["运营分析", "项目看板"]);
        break;
      default:
        return;
    }
  }, []);

  useEffect(() => {
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    );
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      );
    };
  }, [breadcrumbItemClickEventsFn]);
  const goProjectList = () => {
    setTimeout(() => {
      eventBus.emit("setBreadcrumbData", ["运营分析", "项目看板", "项目列表"]);
    }, 100);
    navigate("/operations-analysis/board/project-list", {
      state: { status },
    });
    writeSession("boardParams", {status})
  };
  return (
    <>
      <div className="grid-container" onClick={() => goProjectList()}>
        <div className="rect-block" style={{ backgroundColor }}></div>
        <div className="project-num">{num}</div>
        <div className="project-status-type">{name}</div>
        <img src={image} alt="" />
      </div>
    </>
  );
}
export default ProjectStatusGrid;
