/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-06 13:22:19
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-05-07 17:48:09
 */
import { roleList } from "@/apis/projectCenter/common"
import {
  memberList,
  removeUser,
  projectUserLevel
} from "@/apis/projectCenter/projectManagement"
import {
  Button,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
  Tooltip
} from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import OperateButton from "../../../components/OperateButton"
import "../index.scss"
import AddMember from "./AddMember"
import Transfer from "./Transfer"
const { Search } = Input
const { Option } = Select

function ProjectMember(props) {
  const { projectData } = props
  const [sourceOptions, setSourceOptions] = useState([
    {
      code: "0",
      name: "UUAM"
    },
    {
      code: "1",
      name: "合作渠道"
    },
    {
      code: "2",
      name: "外部人员"
    }
  ]) // eslint-disable-line
  const [industryOptions, setIndustryOptions] = useState([]) //角色下拉框数据
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState([]) //表格数据
  const [isModalOpen, setIsModalOpen] = useState(false) //弹窗展示
  const [component, setComponent] = useState("AddMember") //弹窗切换组件
  const [roleName, setRoleName] = useState(null)

  const [dialogData, setDialogData] = useState({
    title: "添加成员",
    data: {},
    type: "ADD",
    projectId: undefined
  }) //弹窗数据
  const [filterParams, setFilterParams] = useState({
    sourceCode: null, //用户来源
    roleId: null, //角色ID
    projectId: projectData.id, //项目ID
    name: null //	姓名
  }) // eslint-disable-line
  const columns = [
    {
      title: "姓名",
      dataIndex: "userName",
      ellipsis: {
        showTitle: false
      },
      render: (text, record) => {
        return text ? (
          <div>
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>
            <span
              className={
                "roleShow " +
                (record.isLeader ? "blue" : record.isManager ? "yellow" : "")
              }
            >
              {record.isLeader ? "责任人" : record.isManager ? "管理" : ""}
            </span>
          </div>
        ) : (
          "--"
        )
      }
    },
    {
      title: "来源",
      dataIndex: "userSource",
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return String(text) ? (
          <Tooltip
            placement="topLeft"
            title={text === 0 ? "UUAM" : text === 1 ? "合作渠道" : "外部人员"}
          >
            {text === 0 ? "UUAM" : text === 1 ? "合作渠道" : "外部人员"}
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "组织架构",
      dataIndex: "userDept",
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "角色",
      dataIndex: "roles",
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return text ? (
          <Tooltip placement="topLeft" title={handleText(text)}>
            {handleText(text)}
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "添加时间",
      dataIndex: "joinTime",
      sorter: {
        compare: (a, b) => a.planStartTime - b.planStartTime,
        multiple: 10
      },
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--"
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 100,
      fixed: "right",
      render: (text, record) => (
        // eslint-disable-next-line
        <div className="operation">
          {(roleStatus === 2 || (!record.isLeader && roleStatus === 1)) && (
            <OperateButton
              text="编辑"
              icon="icon-pencil"
              data={record}
              click={handleEdit}
            />
          )}
          {record.isLeader && roleStatus === 2 && (
            <OperateButton
              text="转移"
              data={record}
              icon="icon-switch-horizontal"
              click={() => handleTransfer(record)}
            />
          )}
          {!record.isLeader && (
            <OperateButton
              text="移出"
              icon="icon-user-remove"
              popType={true}
              title={`你确定要将该成员从项目移出吗？*移出成员可能导致部分任务计划失效！`}
              data={record}
              confirm={handleRemove}
            />
          )}
        </div>
      )
    }
  ]

  useEffect(() => {
    queryList() //列表
    getProjectUserLevel() //获取用户在项目中角色级别
  }, [page, size]) // eslint-disable-line

  /**
   * @description: 获取用户在项目中角色级别
   * @return {*}
   * @author: LINA.QI
   */
  const [roleStatus, setRoleStatus] = useState(null)
  const getProjectUserLevel = () => {
    projectUserLevel(projectData.id)
      .then((res) => {
        setRoleStatus(res)
      })
      .catch((e) => message.error(e))
  }
  /**
   * @description:
   * @return {*}
   * @author: LINA.QI
   */
  const handleText = (data) => {
    if (data) {
      let newText = []
      data.forEach((item) => {
        newText.push(item.roleName)
      })
      return newText.toString()
    }
  }

  /**
   * @description:切换来源
   * @param {*} params
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeSource = (params) => {
    filterParams.sourceCode = params
    filterParams.roleId = null
    setRoleName(null)
    getRoleList(params) //角色下拉
    setPage(1)
    queryList()
  }

  /**
   * @description: 角色下拉
   * @return {*}
   * @author: LINA.QI
   */
  const getRoleList = (data) => {
    if (filterParams.sourceCode) {
      roleList({ source: data, projectId: projectData.id })
        .then((res) => {
          setIndustryOptions(res)
        })
        .catch((e) => message.error(e))
    }
  }

  /**
   * @description: 切换角色
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeRole = (value, option) => {
    filterParams.roleId = value ? value : null
    setRoleName(option ? option.children : null)
    setPage(1)
    queryList()
  }

  /**
   * @description 输入编号查询
   */
  const onSearch = (params) => {
    filterParams.name = params
    setPage(1)
    queryList()
  }

  /**
   * @description: 添加成员
   * @return {*}
   * @author: LINA.QI
   */
  const openDialog = () => {
    setDialogData({
      title: "添加成员",
      data: {},
      type: "ADD",
      projectId: projectData.id
    })
    setIsModalOpen(true)
    setComponent("AddMember")
  }

  /**
   * @description: 编辑成员
   * @return {*}
   * @author: LINA.QI
   */
  const handleEdit = (record) => {
    setDialogData({
      title: "编辑成员",
      data: [{ ...record }],
      type: "EDIT",
      projectId: projectData.id
    })
    setIsModalOpen(true)
    setComponent("AddMember")
  }

  /**
   * @description:关闭弹窗
   * @return {*}
   * @author: LINA.QI
   */
  const handleCloseModal = () => {
    setIsModalOpen(false)
    setPage(1)
    queryList()
  }

  /**
   * @description: 移出
   * @return {*}
   * @author: LINA.QI
   */
  const handleRemove = (record) => {
    removeUser(record)
      .then((res) => {
        message.success("移出成功")
        setPage(1)
        queryList()
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 转移
   * @return {*}
   * @author: LINA.QI
   */
  const handleTransfer = (record) => {
    setDialogData({
      title: "项目经理转移",
      data: record,
      type: "ADD",
      projectId: projectData.id
    })
    setIsModalOpen(true)
    setComponent("Transfer")
  }

  /**
   *
   * @description 查询列表方法
   */
  const queryList = () => {
    const queryParams = {
      data: filterParams,
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    memberList(queryParams)
      .then((res) => {
        setDataSource(res.list)
        setTotal(res.total)
      })
      .catch((e) => {
       props.goBack("项目管理")
      })
  }

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  return (
    <>
      <div className="project-member">
        <div className="form-container">
          <Button type="primary" className="creation" onClick={openDialog}>
            添加成员<i className="iconfont icon-plus-sm"></i>
          </Button>
          <div className="form-left">
            <span>来源:</span>
            <Select
              showSearch
              allowClear
              placeholder="请选择"
              optionFilterProp="children"
              onChange={handleChangeSource}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {sourceOptions.map((item, index) => (
                <Option key={item.code} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <span>角色:</span>
            <Select
              showSearch
              allowClear
              placeholder="请选择"
              optionFilterProp="children"
              onChange={handleChangeRole}
              value={roleName}
              disabled={!filterParams.sourceCode}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {industryOptions.map((item, index) => (
                <Option key={item.id} value={item.id}>
                  {item.roleName}
                </Option>
              ))}
            </Select>
            <Space>
              <Search placeholder="请输入姓名" onSearch={onSearch} allowClear />
            </Space>
          </div>
        </div>
        <Table
          style={{ margin: "15px 0 10px 0" }}
          size="middle"
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />
        <Modal
          title={dialogData.title}
          wrapClassName="custom-modal"
          open={isModalOpen}
          centered={true}
          maskClosable={false}
          footer={null}
          onCancel={handleCloseModal}
          width={430}
          destroyOnClose={true}
        >
          {component === "AddMember" ? (
            <AddMember
              data={dialogData}
              isOpen={isModalOpen}
              confirmClick={handleCloseModal}
            />
          ) : (
            <Transfer
              data={dialogData}
              confirmClick={handleCloseModal}
              goBack={() => props.goBack("项目管理")}
            />
          )}
        </Modal>
      </div>
    </>
  )
}
export default ProjectMember
