import { Button } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { myExam } from "../../apis/projectCenter/myExam/index";

function MobileExam() {
  const [examList, setExamList] = useState([])
  const navigate = useNavigate();
  useEffect(() => {
    const params = {
      data: {isPhone: true},
      page: {
        pageIndex: 1,
        pageSize: 999
      }
    }
    myExam(params).then(res => {
      setExamList(res.list)
    })
  },[])

  const goExam = (item) => {
    navigate(`/mobile-exam/page?pageId=${item.id}&subjectId=${item.subjectId}`, {
      state: {
        pageId: item.id,
        subjectId: item.subjectId
      }
    })
  }

  return (
    <div className="exam-list">
      <p className="title">考试记录</p>
      {examList.length > 0 ? <div className="list">
        {examList.map(d => (
          <div className="exam-item">
            <div className="item-top">
              <div className="top-left">
                <img src={require("@/assets/img/mobile-exam.png")} alt="" className="exam-image"/>
                
                { d.isQualified === false && <span className="exam-status">不合格</span>}
              </div>
              <div className="top-right">
                <p className="project-name">{d.projectName}{}</p>
                <p style={{margin: "8px 0 4px"}}><span className="label">科目：</span><span className="wrapper">{d.subjectName}</span></p>
                <p><span className="label">发卷时间：</span><span className="wrapper">{dayjs(d.createTime).format("YYYY-MM-DD HH:mm:ss")}</span></p>
              </div>
            </div>
            <div className="exam-content">
              <div>
                <p className="label">最终得分</p>
                <p className={Number.isFinite(d.finalScore) ? "wrapper" : ""}>{Number.isFinite(d.finalScore) ? d.finalScore : "-"}</p>
              </div>
              <Button type="primary" onClick={() => goExam(d)}>{Number.isFinite(d.finalScore) ? "重考" : "去考试"}</Button>
            </div>
          </div>
        ))}
      </div>
      :
      <div className="empty-list">
        <img src={require("@/assets/img/mobile-empty.png")} alt="" className="empty-image"/>
      </div>}
    </div>
  )
}

export default MobileExam