/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-08 10:48:52
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-07-07 11:38:56
 * @FilePath: /pms-web/src/containers/ProjectBoard/components/projectType.js
 * @Description: 项目类型统计
 */
import ProjectTypeGrid from "./projectTypeGrid";
function ProjectType({ dataList }) {
  return (
    <>
      <div className="project-type-container">
        {dataList.map((grid, index) => (
          <ProjectTypeGrid
            key={index}
            chartOptions={grid.options}
            projectName={grid.projectName}
            projectType={grid.dataType}
            statusCode={grid.statusCode}
            // status={grid.status}
            // image={grid.image}
            // num={grid.num}
            // backgroundColor={grid.backgroundColor}
            // key={index}
          />
        ))}
      </div>
    </>
  );
}
export default ProjectType;
