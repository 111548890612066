/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-10-10 11:21:16
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-10-17 17:26:17
 * @FilePath: /pms-web/src/components/TabDate/index.js
 * @Description: tab标签（date形式）
 */
import { message } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import "./index.scss"
const { isWorkday, isHoliday } = require('chinese-workday')
function TabDate({ tabData, updateTable, tabIndex, lastHour }) {
  // tab选中的index
  const [activeIndex, setActiveIndex] = useState(0) // 任务信息列表
  useEffect(() => {
    setActiveIndex(tabIndex)
  }, [tabIndex])
  /**
   * @description: 切换tab
   * @return {*}
   */
  const handleChangeTab = (activeIndex) => {
    if (lastHour && lastHour > 1) {
      message.warning("填报工时已经超出上限，请调整！")
    } else {
      setActiveIndex(activeIndex)
    }
    updateTable(tabData[activeIndex], activeIndex)
  }
  return (
    <div className="tab-approve-box-list">
      {tabData.map((tabItem, index) => (
        <div
          key={index}
          className={[
            "tab-block",
            activeIndex === index ? "tab-block-active" : null
          ].join(" ")}
          onClick={() => handleChangeTab(index)}
        >
          {activeIndex === index && <div className="active-bg"></div>}
          <div className="date-item">
            <div className="item-month">
              {dayjs(tabItem.date).month() + 1}月
            </div>
            <div className="item-date">{dayjs(tabItem.date).date()}</div>
            <div className="item-status">
            {Number(tabItem.approvel) || Number(tabItem.approvel) === 0 ? tabItem.approvel ? "已审批" : ""
              : isHoliday(tabItem.date) || !isWorkday(tabItem.date) ? "休" : "班"}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TabDate
