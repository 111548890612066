/*
 * @Description: 
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-08-09 10:34:29
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-16 13:24:56
 */
import { axiosInstance } from "../../config"

//我的考试
export const myExam = (param) => {
  return axiosInstance.post("/projectexamsubjectuser/myExam",param)
}

//考试明细
export const examDetail = (id) => {
 return axiosInstance.post(`/projectexamsubjectuserscore/examDetail/${id}`)
}
//请求试卷
export const getExamPage = (id) => {
  return axiosInstance.post(`/projectexamsubjectuserrecord/requestExam?projectExamUserId=${id}`)
}

//提交试卷
export const submitExamPage = (params) => {
  return axiosInstance.post(`/projectexamsubjectuserrecord/computeScore`, params)
}

//我的考试项目下拉
export const myExamProjectList = (params) => {
 return axiosInstance.post(`/projectexamsubjectuser/myExamProjectList`, params)
}

