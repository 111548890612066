/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-08-03 14:09:22
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-08-04 13:49:53
 */
import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
  onLoad: process.env.REACT_APP_KEYCLOAK_ONLOAD,
});

export default keycloak;
