/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-08-03 16:02:22
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-08-05 09:09:57
 */
import readSession from "@/utils/commonFunc/readSession";
import { GET_SYSTEM_AUTH, SET_TOKEN, SET_USER_INFO } from "../constant";


const key = "kc-token";

const initialState = {
  token: readSession(key),
  id: "",
  name: "",
  avatar: "",
  systemId: "1668161912320102401",
  role: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SYSTEM_AUTH:
      return {
        ...state,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.username,
      };
    default:
      return state;
  }
};

export default userReducer;
