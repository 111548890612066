/*
 * @Author: gn
 * @Date: 2023-06-15 20:25:34
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-18 11:50:45
 * @Description: file content
 */
import OperateButton from "@/components/OperateButton";
import { Button, Form, Input, message, Modal, Pagination, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  addPersonnel,
  deletePersonnel,
  personnalEnable,
  personnalForbidden,
  personnalPage,
  personnalUpdate,
  resetPassword,
} from "../../../apis/channelManage";
import { LengthRange, RulesTelphone } from "../../../utils/commonFunc/validate";

function PersonnelTabs({ channelId }) {
  const [dataSource, setDataSource] = useState([]);
  const { containerHeight } = useSelector((state) => state.appReducer);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  // 0-关闭 1-新增 2-修改
  const [modalMode, setModalMode] = useState(0);
  const [editPersonnalData, setEditPersonnalData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [personnalName, setPersonnalName] = useState("");
  const { Search } = Input;

  const columns = [
    {
      title: "姓名",
      dataIndex: "personnelName",
      ellipsis: true,
    },
    {
      title: "手机号",
      dataIndex: "personnelPhone",
      ellipsis: true,
    },

    {
      title: "创建时间",
      dataIndex: "createTime",
      ellipsis: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--";
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      ellipsis: true,
      render: (_, record) => {
        return (
          <div className={record.status === 1 ? "status1" : "status2"}>
            {record.status === 1 ? "正常" : "已禁用"}
          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "220px",
      render: (text, record) => (
        <div className="operation">
          <OperateButton
            text="编辑"
            icon="icon-pencil"
            data={record}
            click={() => handleEdit(record)}
          />
          {record.status === 1 && (
            <OperateButton
              text="禁用"
              icon="icon-ban"
              popType={true}
              title={`确定禁用工具？`}
              data={record}
              confirm={() => handleForbidden(record.id)}
            />
          )}
          {record.status === 4 && (
            <OperateButton
              text="启用"
              icon="icon-play"
              popType={true}
              title={`确定启用工具？`}
              data={record}
              confirm={() => handleEnable(record.id)}
            />
          )}
          <OperateButton
            text="重置密码"
            icon="icon-key"
            click={() => handleResetPassword(record.id)}
          />
          <OperateButton
            text="删除"
            icon="icon-trash"
            popType={true}
            title={`确定删除渠道? *注意删除后渠道销售工具和渠道人员将无法正常使用`}
            data={record}
            confirm={() => handleDelete(record.id)}
          />
        </div>
      ),
    },
  ];

  // 渠道人员分页列表
  const queryList = () => {
    const params = {
      data: {
        channelId,
        searchName: personnalName,
      },
      page: {
        pageIndex: page,
        pageSize: size,
      },
    };
    personnalPage(params)
      .then((result) => {
        setTotal(result?.total);
        setDataSource(result?.list);
      })
      .catch((e) => message.error(e));
  };

  useEffect(() => {
    queryList();
  }, [page, size, personnalName]);

  useEffect(() => {
    if (modalMode === 2) {
      form.setFieldsValue({
        name: editPersonnalData.personnelName,
        phone: editPersonnalData.personnelPhone,
      });
    }
  }, [modalMode, editPersonnalData, form]);

  // 修改
  const handleEdit = (record) => {
    setEditPersonnalData(record);
    setModalMode(2);
  };

  // 停用
  const handleForbidden = (id) => {
    personnalForbidden(id)
      .then((res) => {
        if (res) {
          message.success("禁用成功");
          queryList();
        }
      })
      .catch((e) => message.error(e));
  };

  // 启用
  const handleEnable = (id) => {
    personnalEnable(id)
      .then((res) => {
        if (res) {
          message.success("启用成功");
          queryList();
        }
      })
      .catch((e) => message.error(e));
  };

  // 重置密码
  const handleResetPassword = (id) => {
    resetPassword(id)
      .then((res) => {
        if (res) {
          message.success("重置密码成功！");
        }
      })
      .catch((e) => message.error(e));
  };

  // 删除渠道
  const handleDelete = (id) => {
    deletePersonnel(id)
      .then((res) => {
        message.success("删除成功");
        queryList();
      })
      .catch((e) => message.error(e));
  };

  // 新增人员点击事件
  const handleAddClick = () => {
    setModalMode(1);
  };

  const handleOk = () => {
    const values = form.getFieldsValue();
    const { name, phone } = values;

    if (isSaving) {
      return;
    }
    form
      .validateFields()
      .then((values) => {
        setModalMode(0);
        setIsSaving(true);
        if (modalMode === 1) {
          addPersonnel({
            channelId,
            personnelName: name,
            personnelPhone: phone,
          })
            .then((res) => {
              queryList();
              setIsSaving(false);
            })
            .catch((e) => message.error(e));
        } else {
          personnalUpdate({
            personnelPhone: phone,
            id: editPersonnalData.id,
          })
            .then((res) => {
              queryList();
              setIsSaving(false);
            })
            .catch((e) => message.error(e));
        }
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    setModalMode(0);
  };

  const handleModalClose = () => {
    setIsSaving(false);
    form.resetFields();
  };
  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };

  /**
   * @description: 搜索
   * @return {*}
   * @Author: gn
   */
  function onSearch(value) {
    setPage(1);
    setPersonnalName(value);
  }
  return (
    <div className="Personnel-Tabs">
      <div className="channel_head">
        <Button type="primary" onClick={() => handleAddClick()}>
          新增人员<i className="add_icon iconfont icon-plus-sm"></i>
        </Button>
        <Search
          placeholder="请输入姓名或者手机号"
          onSearch={onSearch}
          allowClear
          className="custom-channel-personel-search"
        />
      </div>
      <Table
        size="middle"
        className="custom-table personal-table"
        rowKey={(record) => record.id}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={
          {
            // y: containerHeight - 56 - 24 * 2 - 32 - 56 * 2 - 24 * 2,
          }
        }
      />
      <Pagination
        total={total}
        pageSize={size}
        current={page}
        pageSizeOptions={["10", "20", "30", "40", "50"]}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `共 ${total} 条`}
        className="custom-pagination"
        onChange={handleChangePagination}
      />
      <Modal
        width="413px"
        className="custom-modal add-person-modal"
        title={modalMode === 1 ? "新增人员" : "修改手机号码"}
        maskClosable={false}
        open={modalMode}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={handleModalClose}
        okText={
          <span>
            <i className="iconfont icon-baocun"></i>保存
          </span>
        }
        cancelText={
          <span>
            <i className="iconfont icon-x"></i>取消
          </span>
        }
      >
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 19 }}>
          <Form.Item
            name="name"
            label="人员姓名"
            rules={[...LengthRange(1, 15)]}
          >
            <Input
              style={{ width: "280px" }}
              placeholder="请输入人员姓名"
              disabled={modalMode === 2}
            ></Input>
          </Form.Item>
          <Form.Item name="phone" label="手机号码：" rules={RulesTelphone}>
            <Input
              style={{ width: "280px" }}
              placeholder="请输入手机号"
            ></Input>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default PersonnelTabs;
