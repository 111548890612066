/*
 * @Author: gn
 * @Date: 2023-06-21 14:59:15
 * @LastEditors: gn
 * @LastEditTime: 2023-09-25 09:42:59
 * @Description: file content
 */
import { addFileList, dictionariesList, fileMessage, isTheFile, selectRoleList } from "@/apis/systemMgmt/SOPManagement";
import { CheckboxRequired, LengthRangeName, NameAddressSpecialChar, StanderUrl } from "@/utils/commonFunc/validate";
import {
  Button,
  Form, message, Popconfirm, Table
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "../index.scss";
import { EditableCell, EditableRow } from "./editableCell";




function FileTabs(props) {
  //mesStatus当前数据的状态，2草稿，1已停用
  const { SOPId, activeKey , tabsStatus, mesStatus, handleEditStatus, } = props;
  const { containerHeight } = useSelector((state) => state.appReducer);
  const dataRef = useRef();
  const [dataSource, setDataSource] = useState([]);
  const [fileTypeList, setFileTypeList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [loading , setLoading] = useState(false);
  const [autoStatus , setAutoStatus] = useState(false);
  


  /**
   * @description: 获取文件详情
   * @return {*}
   * @Author: gn
   */
  function getFileMessage(field=undefined,order='default') {
    let params= {
      id: SOPId,
      field,
      order
    }
    fileMessage(params).then(res => {
      let arr = [];
      res.forEach((e, i) => {
        let json = {
          ...e,
          key: i,
          proOpen : false,
        };
        arr.push(json);
      });
      setDataSource(arr);
    }).catch(err => message.error(err))
  };
  useEffect(() => {
    getFileMessage();
  }, [SOPId])// eslint-disable-line

  useEffect(()=>{
    dataRef.current = dataSource;
  } , [dataSource]);

  useEffect(()=>{
    if(activeKey === '2'){
      setAutoStatus(true);
    }
    if(activeKey !== '2' && tabsStatus !== 'view' && autoStatus){
      autoSub();
      setAutoStatus(false);
    }
  },[activeKey]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const defaultColumns = tabsStatus === 'view' ? [{
    title: "文件编号",
    dataIndex: "fileCode",
    editable: tabsStatus !== 'view',
    sorter: true,
    render: (text) => <span>{text}</span>,
  },
  {
    title: "文件类型",
    dataIndex: "fileType",
    editable: tabsStatus !== 'view',
    render: (text) => <span>{fileTypeList.filter((e) => e.value === text).map((v) => {
      return <span>{v.label}</span>
    })}</span>,
  },
  {
    title: "文件权限",
    dataIndex: "fileRight",
    editable: tabsStatus !== 'view',
    render: (text) => <span>{roleList.map((e) => {
      if (text.indexOf(e.value) !== -1) {
        return <div key={e.value}>{e.label}</div>
      }
    })}</span>,
    // render : (text , record)=><span>{text}</span>
  },
  {
    title: "文件名",
    dataIndex: "fileName",
    sorter: true,
    editable: tabsStatus !== 'view',
    render: (text) => <span>{text}</span>,
  },
  {
    title: "文件模版（飞书）",
    dataIndex: "fileTemplate",
    editable: tabsStatus !== 'view',
    render: (text) => <span style={{ color: '#1C64F2' }}>{text}</span>,
  },] : [
    {
      title: "文件编号",
      dataIndex: "fileCode",
      sorter: true,
      editable: tabsStatus !== 'view',
      render: (text) => <span>{text}</span>,
    },
    {
      title: "文件类型",
      dataIndex: "fileType",
      editable: tabsStatus !== 'view',
      render: (text) => <span>{fileTypeList.filter((e) => e.value === text).map((v) => {
        return <>{v.label}</>
      })}</span>,
    },
    {
      title: "文件权限",
      dataIndex: "fileRight",
      editable: tabsStatus !== 'view',
      render: (text) => <span>{roleList.map((e) => {
        if (text.indexOf(e.value) !== -1) {
          return <div key={e.value}>{e.label}</div>
        }
      })}</span>,
      // render : (text , record)=><span>{text}</span>
    },
    {
      title: "文件名",
      dataIndex: "fileName",
      sorter: true,
      editable: tabsStatus !== 'view',
      render: (text) => <span>{text}</span>,
    },
    {
      title: "文件模版（飞书）",
      dataIndex: "fileTemplate",
      editable: tabsStatus !== 'view',
      render: (text) => <span style={{ color: '#1C64F2' }}>{text}</span>,
    },
    {
      title: (
        <i
          className="iconfont icon-plus-circle mouse"
          onClick={() => onAddTableCell()}
        ></i>
      ),
      dataIndex: "operation",
      width : '50px',
      align: 'center',
      render: (_, record , index) => (
        <>  
          <Popconfirm
            title="该文件在节点中使用，将直接从节点引用中删除？"
            icon={<i className="iconfont icon-shanchu" style={{color: "#F05252",fontSize: "16px",marginRight: "4px"}}></i>}
            open={record.proOpen}
            onConfirm={()=>proConfirm(record , index)}
            onCancel={()=>proCancel(record , index)}
            okText="确定"
            cancelText="取消"
          >
            <i
              className="iconfont icon-minus-circle mouse"
              onClick={() => onDeleteTableCell(record , index)}
              style={{ color: "#3F83F8" }}
            ></i>
          </Popconfirm>
        </>
        
      ),
    },
  ];
  /**
   * @description: 气泡确认框的确认
   * @return {*}
   * @Author: gn
   */  
  function proConfirm(record , index){
    const newDataSource = dataSource.filter(
      (item) => item.key !== record.key
    );
    setDataSource(newDataSource);
  };
  function proCancel(record , index){
    let arr = [ ...dataSource];
    arr[index].proOpen = false;
    setDataSource(arr);
  };

  //渲染虚拟节点
  const currentEditableDomList = [
    {
      title: "文件编号",
      name: "fileCode",
      type: 'input',
      placeholder: '请输入文件编号',
      rules: [
        {
          required: true,
          message: `文件编号不能为空`,
        },
        ...NameAddressSpecialChar('文件编号',15)
      ],
    },
    {
      title: "文件类型",
      name: "fileType",
      options: fileTypeList,
      type: 'select',
      placeholder: '请选择文件类型',
      rules: [
        {
          required: true,
          message: `文件类型不能为空`,
        },
      ],
    },
    {
      title: "文件权限",
      name: "fileRight",
      options: roleList,
      type: 'select',
      mode: 'multiple',
      placeholder: '请选择文件权限',
      rules: [
        // {
        //   required: true,
        //   message: `文件权限不能为空`,
        // },
        ...CheckboxRequired('文件权限')
      ],
    },
    {
      title: "文件名",
      name: "fileName",
      type: 'input',
      placeholder: '请输入文件名',
      rules: [
        ...LengthRangeName('文件名',1,30)
      ],
    },
    {
      title: "文件模版（飞书）",
      name: "fileTemplate",
      type: 'input',
      placeholder: '请输入文件模版（飞书）',
      rules: [
        ...StanderUrl()
      ],
    },
  ]
  const columns = defaultColumns.map((col) => {
    // console.log(col.options)
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        domObj: [...currentEditableDomList],
        handleSave,
      }),
    };
  });
  /**
   * @description: 获取文件类型
   * @return {*}
   * @Author: gn
   */
  function getFileType() {
    const params = {
      categoryCode: 'file_type',
    };
    dictionariesList(params).then(res => {
      let arr = [];
      res.forEach(e => {
        let json = {
          label: e.dictDesc,
          value: e.dictCode,
        };
        arr.push(json);
      })
      setFileTypeList(arr);
    }).catch(err => { message.error(err) });
  };
  /**
   * @description: 获取文件权限
   * @return {*}
   * @Author: gn
   */
  function getSelecRoleList() {
    selectRoleList().then(res => {
      let arr = [{ value: 'all', label: '全部' },];
      res.forEach(e => {
        let json = {
          label: e.roleName,
          value: e.id,
          disableStatus : e.status === '1' ? true : false,
        };
        arr.push(json);
      })
      setRoleList(arr);
    }).catch(err => { message.error(err) });
  };
  useEffect(() => {
    getFileType();
    getSelecRoleList();
  }, [])
  /**
   * @description: 新增一行
   * @return {*}
   * @Author: gn
   */
  const onAddTableCell = () => {
    const tableData = [
      {
        key: dataSource.length,
        proOpen : false,
        fileCode: "",
        fileName: "",
        fileRight: ['all'],
        fileTemplate: '',
        fileType: '1',
        sopBasicInfoId: SOPId,
        updateOrAdd: tabsStatus === 'add' ? 1 : 0,
      },
      ...dataSource,
    ];
    setDataSource(tableData);
  };
  /**
   * @description: 删除一行
   * @param {*} record
   * @return {*}
   * @Author: gn
   */
  const onDeleteTableCell = (record , index) => {
    const params = {
      id : record.id,
    };
    isTheFile(params).then(res=>{
      if(res.data === false){
        const newDataSource = dataSource.filter(
          (item) => item.key !== record.key
        );
        setDataSource(newDataSource);
      }else{
        let arr = [ ...dataSource];
        arr[index].proOpen = true;
        setDataSource(arr);
      }
    }).catch(err=>message.error(err));
    
  };
  /**
   * @description: 编辑信息
   * @return {*}
   * @Author: gn
   */
  const handleSave = (row) => {
    // console.log(row)
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    // console.log(newData)
    setDataSource(newData);
  };
  /**
   * @description: 筛选
   * @return {*}
   * @Author: gn
   */
  function changeTable(pagination, filters, sorter, extra) {
    // return
    // console.log(sorter.order);
    console.log(pagination, filters, sorter, extra);
    switch (sorter.order) {
      case "ascend":
        return getFileMessage(sorter.field, 'ASC');
      case "descend":
        return getFileMessage(sorter.field,'DESC');
      default:
        return getFileMessage(sorter.field,'default')
    }
  };
  /**
   * @description: 提交数据
   * @return {*}
   * @Author: gn
   */
  function handleSub() {
    if(dataSource.length === 0){
      message.warning('数据不能为空');
      return
    };
    let params = [...dataSource];
    params.forEach(e => {
      e.updateOrAdd = tabsStatus === 'add' ? 1 : 0;
    });
    setLoading(true);
    addFileList(params).then(res => {
      message.success('保存成功');
      setLoading(false);
      getFileMessage();
    }).catch(err => { setLoading(false); message.error(err) });
  };
  /**
   * @description: 自动保存
   * @return {*}
   * @Author: gn
   */  
  function autoSub(){
    if(dataSource.length === 0){
      return
    };
    let params = [...dataRef.current];
    params.forEach(e => {
      e.updateOrAdd = tabsStatus === 'add' ? 1 : 0;
    });
    addFileList(params).then(res => {
      getFileMessage();
    }).catch(err => {});
  };
  return (
    <>
      <Table
        size="middle"
        tableLayout='fixed'
        style={{ marginBottom: '10px' }}
        rowKey={(record) => record.key}
        pagination={false}
        className="custom-table"
        components={components}
        rowClassName={() => "editable-row"}
        dataSource={dataSource}
        columns={columns}
        onChange={changeTable}
        // scroll={{
        //   y: containerHeight - 56 - 24 * 2 - 32 - 56 * 2 - 24 * 2,
        // }}
      />
      {
        tabsStatus === 'view' ?
          <Form.Item style={{marginTop : '25px',marginBottom:'0px'}}>
            <Button style={{ marginRight: '8px' }} onClick={handleEditStatus}>
              <i className="iconfont icon-reply" style={{ fontSize: '14px', marginRight: '2px' }}></i>返回
            </Button>
          </Form.Item>
          :
          <Form.Item style={{marginTop : '25px',marginBottom:'0px'}}>
            <Button style={{ marginRight: '8px' }} onClick={handleEditStatus}>
              <i className="iconfont icon-x" style={{ fontSize: '14px', marginRight: '2px' }}></i>取消
            </Button>
            <Button type="primary" loading={loading} onClick={handleSub}>
              <i className="iconfont icon-baocun" style={{ fontSize: '14px', marginRight: '2px' }}></i>保存
            </Button>
          </Form.Item>
      }
    </>
  )
};

export default FileTabs;