/*
 * @Author: gn
 * @Date: 2023-06-25 18:58:01
 * @LastEditors: gn
 * @LastEditTime: 2023-07-20 13:56:04
 * @Description: file content
 */
import { stageId } from "@/apis/systemMgmt/SOPManagement";
import { FalseLengthRange, LengthRangeName } from "@/utils/commonFunc/validate";
import {
  Button,
  Form, Input, message
} from "antd";
import React, { useEffect, useState } from "react";



function SopFirstChildren(props){
    const {  modelObj, handlePushFirst , handleReplaceFirst , hanleCloseModel, } = props;
    const [ keyId , setKeyId] = useState(null);

    const [form] = Form.useForm();
    
    const { TextArea } = Input;
    /**
     * @description: 获取随机id
     * @return {*}
     * @Author: gn
     */    
    function getStageId(){
        stageId().then(res=>{
            setKeyId(res);
        }).catch(err=>message.error(err))
    };
    useEffect(()=>{
        form.setFieldsValue({...modelObj});
        if(!modelObj.id){
            getStageId();
        }  
    },[])// eslint-disable-line
    /**
     * @description: 表单校验成功
     * @return {*}
     * @Author: gn
     */    
    function onFinish(values){
        console.log(values);
        if(keyId){
            //新增阶段
            const json = {
                ...modelObj,
                ...values,
                id : keyId,
            };
            handlePushFirst(json);
        }else{
            //编辑阶段
            const json = {
                ...modelObj,
                ...values,
            };
            handleReplaceFirst(json);
        }
        hanleCloseModel();
    };
    /**
     * @description: 表单校验失败
     * @return {*}
     * @Author: gn
     */    
    function onFinishFailed(error){

    };
    return(
        <Form
            name="first"
            initialValues={{
                remember: true,
            }}
            labelCol={{
                span: 4,
                }}
            // wrapperCol={{
            //     span: 16,
            // }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                name="stageName"
                rules={[
                ...LengthRangeName('阶段名称', 1, 15)
                ]}
                label="阶段名称"
            >
                <Input
                autoComplete="off"
                placeholder="请输入阶段名称"
                ></Input>
            </Form.Item>
            <Form.Item
                name="stageDesc"
                rules={[
                    ...FalseLengthRange(1,30)
                ]}
                label="阶段描述"
            >
                <TextArea rows={4} placeholder="请输入阶段描述" />
            </Form.Item>
            <Form.Item style={{textAlign : 'right',marginTop : '25px',marginBottom:'0px'}}>
                <Button style={{marginRight : '8px'}} onClick={hanleCloseModel}>
                    <i className="iconfont icon-x" style={{fontSize : '14px',marginRight:'2px'}}></i>取消
                </Button>
                <Button type="primary" htmlType="submit">
                    <i className="iconfont icon-baocun" style={{fontSize : '14px',marginRight:'2px'}}></i>确认
                </Button>
            </Form.Item>
        </Form>
    )
};
export default SopFirstChildren