/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-13 13:42:54
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-05 11:13:02
 * @Description: 运营分析-工时看板
 */
import {
  fillWorkhourApi,
  getWorkhourApi,
  userDepListApi,
  workHourPageByProject,
  workHourPageByStaff
} from "@/apis/workHour"
import ProjectCascader from "@/components/ProjectCascader"
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Pagination,
  Select,
  Switch,
  Table,
  message
} from "antd"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import isBetween from "dayjs/plugin/isBetween"
import { useEffect, useRef, useState } from "react"
import OperateButton from "../../components/OperateButton"
import { exportFile } from "../../utils/commonFunc/exportFile"
import { hasPermission } from "../../utils/commonFunc/hasPermission"
import WorkHourDetail from "./components/workHourDetail"
import "./index.scss"
let isSameOrBefore = require("dayjs/plugin/isSameOrBefore")
dayjs.extend(isSameOrBefore)

function WorkHour() {
  const columnsByStaff = [
    {
      title: "月份",
      dataIndex: "date",
      width: 150,
      ellipsis: true
    },
    {
      title: "员工",
      dataIndex: "userName",
      width: 150,
      ellipsis: true,
      render: (text) => {
        return (
          <>
            <div>{text ? text : "--"}</div>
          </>
        )
      }
    },
    {
      title: "部门",
      dataIndex: "deptName",
      width: 150,
      ellipsis: true,
      render: (text) => {
        return (
          <>
            <div>{text ? text : "--"}</div>
          </>
        )
      }
    },
    {
      title: "公司",
      dataIndex: "companyName",
      width: 150,
      ellipsis: true,
      render: (text) => {
        return (
          <>
            <div>{text ? text : "--"}</div>
          </>
        )
      }
    },
    {
      title: "工时统计（天）",
      dataIndex: "count",
      width: 150,
      ellipsis: true
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 100,
      // fixed: "auto",
      render: (text, record) => {
        return (
          <div className="operation">
            <OperateButton
              text="明细"
              icon="icon-clipboard-list"
              click={(record) => openModal(record)}
              data={record}
            ></OperateButton>
          </div>
        )
      }
    }
  ]
  const columnsByProject = [
    {
      title: "月份",
      dataIndex: "date",
      width: 117,
      ellipsis: true
    },
    {
      title: "ERP编号",
      dataIndex: "erpNo",
      width: 117,
      ellipsis: true
    },
    {
      title: "项目编号",
      dataIndex: "projectCode",
      width: 117,
      ellipsis: true
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      width: 117,
      ellipsis: true
    },
    {
      title: "工时统计（天）",
      dataIndex: "count",
      width: 117,
      ellipsis: true
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 100,
      fixed: "right",
      render: (text, record) => {
        return (
          <div className="operation">
            <OperateButton
              text="明细"
              icon="icon-clipboard-list"
              click={(record) => openModal(record)}
              data={record}
            ></OperateButton>
          </div>
        )
      }
    }
  ]
  const { RangePicker } = DatePicker
  const [form] = Form.useForm()
  const [page, setPage] = useState(1)
  const pageRef = useRef(1)
  const [size, setSize] = useState(10)
  const sizeRef = useRef(10)
  const [total, setTotal] = useState(0)
  const [dates, setDates] = useState(null)
  const [timeValue, setTimeValue] = useState([
    dayjs().subtract(1, "years"),
    dayjs()
  ]) //默认日期
  dayjs.extend(isBetween)
  dayjs.extend(duration)
  // 按照部门统计表格数据
  const [dataSource, setDataSource] = useState([])
  // 列表项
  const [columns, setColumns] = useState(columnsByStaff)
  // 弹窗是否打开
  const [isOpen, setIsOpen] = useState(false)
  // 当前选中的明细条目
  const [recordItem, setRecordItem] = useState({})
  // 统计类别选项列表
  const typeList = [
    { value: 1, label: "按人员统计" },
    { value: 2, label: "按项目统计" }
  ]
  // 工时开关
  const [switchOpen, setSwitchOpen] = useState(false)
  // 公司选项列表
  const [userDepList, setUserDepList] = useState([])
  // 当前类型
  const [curType, setCurType] = useState(1)
  const { Option } = Select
  const [staffItem, setStaffItem] = useState({})
  const getUserId = (data, list) => {
    if (data.children && data.children.length > 0) {
      data.children.forEach((item) => {
        if (item.children && item.children.length > 0) {
          getUserId(item, list)
        } else {
          if (item.type === "user") {
            list.push(item.id)
          }
        }
      })
    } else {
      if (data.id && data.type === "user") {
        list.push(data.id)
      }
    }
    return list
  }

  //设置默认日期显示
  const setDefaultDate = () => {
    // 设置时间初始值显示
    setTimeValue([dayjs().subtract(1, "year"), dayjs()])
  }

  //只能选择12个月内日期
  const disabledDate = (current) => {
    if (!dates) {
      return false
    }
   const tooLate = dates[0] && current.diff(dates[0], "years") >= 1
    const tooEarly = dates[1] && dates[1].diff(current, "years") >= 1
    return !!tooEarly || !!tooLate
  }

  useEffect(() => {
    onQueryClick()
  }, [timeValue, staffItem])

  //打卡日期弹窗
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null])
    } else {
      setDates(null)
    }
  }

  // 按员工统计列表查询参数
  const getQueryParams = () => {
    const { statisticType, project, approvel } = form.getFieldsValue()
    const queryParams = {
      data: {
        projectId: project, // 产品id
        type: statisticType, // 统计类型,
        userIdList: getUserId(staffItem, []), // 用户id
        approvel,
        startDate: timeValue[0].format("YYYY-MM-DD"),
        endDate: timeValue[1].format("YYYY-MM-DD")
      },
      page: {
        pageIndex: pageRef.current,
        pageSize: sizeRef.current
      }
    }
    // 当前日期的上周
    const firstDayBefore = dayjs()
      .subtract(1, "week")
      .endOf("isoWeek")
      .format("YYYY-MM-DD")
    // 当前日期
    const lastDayAfter = dayjs().format("YYYY-MM-DD")
    //开始时间是否在近一周内
    const startDateIsInCurMonth = dayjs(firstDayBefore).isBetween(
      queryParams.data.startDate,
      queryParams.data.endDate,
      "day",
      "[]"
    )
    //结束时间是否在近一周内
    const endDateIsInCurMonth = dayjs(lastDayAfter).isBetween(
      queryParams.data.startDate,
      queryParams.data.endDate,
      "day",
      "[]"
    )
    if (startDateIsInCurMonth || endDateIsInCurMonth) {
      message.config({
        top: 45,
        rtl: false
      })
      message.warning("注意本月工时未锁定，查询结果可能不准确!")
    }
    return queryParams
  }

  /**
   * 翻页
   * @param {*} page
   * @param {*} pageSize
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
    pageRef.current = page
    sizeRef.current = pageSize
    queryList()
  }

  /**
   *
   * @description 查询列表方法
   */
  const queryList = () => {
    const queryParams = getQueryParams()
    const { statisticType } = form.getFieldsValue()
    if (statisticType === 1) {
      workHourPageByStaff(queryParams)
        .then((res) => {
          setDataSource(res.list)
          setTotal(res.total)
        })
        .catch((e) => message.error(e))
    } else {
      workHourPageByProject(queryParams)
        .then((res) => {
          setDataSource(res.list)
          setTotal(res.total)
        })
        .catch((e) => message.error(e))
    }
  }

  useEffect(() => {
    getUserDepList()
    // eslint-disable-next-line
  }, [page, size])

  // 获取公司列表下拉
  const getUserDepList = () => {
    userDepListApi().then((res) => {
      setUserDepList(res)
    })
  }

  const onChange = (value, selectedOptions) => {
    if (value && value.length > 0) {
      setStaffItem(selectedOptions[value.length - 1])
    } else {
      setStaffItem({})
    }
  }
  const filter = (input, path) =>
    path.some((option) => {
      if (input.charCodeAt() >= 32 && input.charCodeAt() <= 126) {
        return (
          option.name
            .spell("low", "poly")
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        )
      } else {
        return option.name.toLowerCase().indexOf(input.toLowerCase()) > -1
      }
    })

  // 点击查询
  const onQueryClick = () => {
    setPage(1)
    pageRef.current = 1
    queryList()
  }

  // 统计类型改变
  const handleTypeChange = (value) => {
    setCurType(value)
    setDefaultDate()
    if (value === 1) {
      setColumns(columnsByStaff)
      form.setFieldsValue({
        project: undefined,
        approvel: 3
      })
    } else {
      setColumns(columnsByProject)
      form.setFieldsValue({
        staff: undefined,
        approvel: 3
      })
      setStaffItem({})
    }
  }

  //选中项目
  const projectChange = (data) => {
    form.setFieldsValue({
      project: data.projectId
    })
    onQueryClick()
  }

  // 导出
  const exportList = () => {
    const params = getQueryParams()
    exportFile(`/projectworkhours/export`, "post", params.data, "")
  }

  // 打开弹窗
  const openModal = (record) => {
    setRecordItem(record)
    setIsOpen(true)
  }

  // 关闭弹窗
  const closeModal = () => {
    setIsOpen(false)
  }

  const [fillWorkhour, setFillWorkhour] = useState({
    startTime: null,
    endTime: null
  })
  useEffect(() => {
    getWorkhourApi()
      .then((res) => {
        setSwitchOpen(res.isOpen)
        setFillWorkhour({
          startTime: res.startDate,
          endTime: res.endDate
        })
      })
      .catch((err) => console.log)
  }, [])

  //工时填报开关
  const switchChange = (checked) => {
    setSwitchOpen(checked)
    if (!checked) {
      const params = {
        isOpen: false,
        startDate: null,
        endDate: null
      }
      fillWorkhourApi(params)
        .then((res) => {
          if (res.code === 200) {
            setFillWorkhour({
              startTime: null,
              endTime: null
            })
            message.success("补填工时关闭成功！")
          }
        })
        .catch((err) => message.warning("暂无操作权限"))
    }
  }

  const rangeChange = (dates) => {
    if (dates && dayjs(dates[0]).isSameOrBefore(dayjs(dates[1]))) {
      const params = {
        isOpen: true,
        startDate: dayjs(dates[0]).format("YYYY-MM-DD"),
        endDate: dayjs(dates[1]).format("YYYY-MM-DD")
      }
      fillWorkhourApi(params)
        .then((res) => {
          if (res.code === 200) {
            message.success("补填工时开启成功！")
          }
        })
        .catch((err) => message.warning("暂无操作权限"))
    }
  }

  return (
    <>
      <div className="work-hour-container">
        {hasPermission([
          "projectworkhours:setWorkHourReplenishConfig",
          "projectworkhours:getWorkHourReplenishConfig"
        ]) && (
          <div className="workhour-switch">
            <span className="label">填报工时开关：</span>
            <Switch checked={switchOpen} onChange={switchChange} />
            {switchOpen && (
              <RangePicker
                className="rangeTime"
                onChange={rangeChange}
                allowClear
                defaultValue={[
                  fillWorkhour.startTime && dayjs(fillWorkhour.startTime),
                  fillWorkhour.endTime && dayjs(fillWorkhour.endTime)
                ]}
              />
            )}
          </div>
        )}
        <div className="header-container">
          <Button className="button-export" onClick={() => exportList()}>
            导出
            <i className="iconfont icon-external-link"></i>
          </Button>
          <Form form={form} layout="inline" colon={true}>
            <Form.Item name="statisticType" label="类别" initialValue={1}>
              <Select onChange={(value) => handleTypeChange(value)}>
                {typeList.map((item, index) => (
                  <Option value={item.value} key={index}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {curType === 1 && (
              <Form.Item name="staff">
                <Cascader
                  options={userDepList}
                  onChange={onChange}
                  placeholder="请选择"
                  changeOnSelect
                  showSearch={{
                    filter
                  }}
                  fieldNames={{ label: "name", value: "id" }}
                />
              </Form.Item>
            )}
            {curType === 2 && (
              <Form.Item name="project" label="项目">
                <ProjectCascader
                  width={250}
                  onChange={projectChange}
                  selectAll={true}
                />
              </Form.Item>
            )}
            <Form.Item name="approvel" initialValue={3}>
              <Select
                placeholder="请选择"
                allowClear
                style={{ width: 108 }}
                onChange={onQueryClick}
                options={[
                  {
                    value: 3,
                    label: "全部"
                  },
                  {
                    value: 1,
                    label: "已审批"
                  },
                  {
                    value: 2,
                    label: "已锁定"
                  },
                  {
                    value: 0,
                    label: "未审批"
                  }
                ]}
              ></Select>
            </Form.Item>
            <Form.Item label="选择日期:">
              <RangePicker
                allowClear={false}
                value={dates || timeValue}
                format="YYYY-MM-DD"
                className="staff-range-picker"
                onChange={(dates) => setTimeValue(dates)}
                disabledDate={disabledDate}
                onOpenChange={onOpenChange}
                onCalendarChange={(dates) => {
                  setDates(dates)
                }}
                changeOnBlur
                style={{ width: 282 }}
                defaultValue={[dayjs().subtract(1, "years"), dayjs()]}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="table-container">
          <Table
            size="middle"
            className="custom-table staff-table"
            rowKey={(record, index) => index}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "40", "50"]}
            showSizeChanger
            showQuickJumper
            className="custom-pagination"
            showTotal={(total) => `共 ${total} 条`}
            onChange={handleChangePagination}
            style={{ marginTop: 20 }}
          />
        </div>
      </div>
      <WorkHourDetail
        isOpen={isOpen}
        closeModal={closeModal}
        form={form}
        record={recordItem}
      />
    </>
  )
}
export default WorkHour
