/*
 * @Author: gn
 * @Date: 2023-06-27 15:51:06
 * @LastEditors: gn
 * @LastEditTime: 2023-07-04 10:52:56
 * @Description: file content
 */
import {
    Button,
    Form,
    Select,
    Table,
    message,
    Pagination,
    DatePicker,
    Space,
    Input,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import dayjs from "dayjs";
import { historyList } from "@/apis/systemMgmt/SOPManagement";
import eventBus from "@/utils/commonFunc/eventBus";


  function HistoryChildren(props){
    const { SOPId, SOPName , handleEditStatus} = props;
    const [ arrList , setArrList ] = useState([]);
    /**
     * @description: 获取操作历史
     * @return {*}
     * @Author: gn
     */    
    function getHistoryList(){
      const params = {
        id : SOPId,
      };
      historyList(params).then(res=>{
        setArrList(res);
      })
    };
    useEffect(()=>{
      getHistoryList();
      eventBus.emit("setBreadcrumbData", ["系统管理", "SOP管理","操作历史"]);
    },[])// eslint-disable-line

    function breadcrumbItemClickEventsFn(item){
      if(item === 'SOP管理'){
        handleEditStatus()
      }
    };
  
    useEffect(() => {
      eventBus.addListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      );
      return () => {
        eventBus.removeListener(
          "breadcrumbItemClickEvents",
          breadcrumbItemClickEventsFn
        );
      };
    }, [breadcrumbItemClickEventsFn]);
    
    return (
      <>
        <div className="history_list">
          <div className="list_head">
            <i className="iconfont icon-clock" style={{color : '#3F83F8'}}></i>
            <span className="list_name">{SOPName}</span>
          </div>
          <div >
            { arrList.length > 1 ? arrList.map((e , i)=>{
              return ( 
                <div key={i} className="list_content">
                  <div className="content_time">{dayjs(e.createTime).format("YYYY-MM-DD HH:mm:ss")}</div>
                  {
                    i===0 && <img className="content_img" src={require('@/assets/img/sop/top.png')} />
                  }
                  {
                    i > 0 && i < arrList.length -1 && <img className="content_img" src={require('@/assets/img/sop/middle.png')} />
                  }
                  {
                    i === arrList.length - 1 && <img className="content_img" src={require('@/assets/img/sop/bottom.png')} />
                  }
                  <div className="content_name">{e.operatorName}</div>
                  <div className="content_op">{e.operationAction}</div>
                  <div>{e.module ? ( e.module === 1 ? '文件列表' : 'SOP节点' ) : e.sopName + 'v' + e.version}</div>
                </div>
              )
            })
            :
           (
            arrList.map((e , i)=>{
              return ( 
                <div key={i} className="list_content">
                  <div className="content_time">{dayjs(e.createTime).format("YYYY-MM-DD HH:mm:ss")}</div>
                  <img className="content_img" src={require('@/assets/img/sop/one.png')} />
                  <div className="content_name">{e.operatorName}</div>
                  <div className="content_op">{e.operationAction}</div>
                  <div>{e.module ? ( e.module === 1 ? '文件列表' : 'SOP节点' ) : e.sopName + 'v' + e.version}</div>
                </div>
              )
            })
           )
          }
          </div>
          <Button style={{marginRight : '8px'}} onClick={handleEditStatus}>
              <i className="iconfont icon-reply" style={{fontSize : '14px',marginRight:'2px'}}></i>返回
          </Button>
        </div>

      </>
    )
  };
  export default HistoryChildren;