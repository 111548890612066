/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-16 16:03:26
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-05-11 17:11:27
 */
import { roleList } from "@/apis/projectCenter/common"
import {
  addMemberSave,
  editMemberSave,
  noCancelRole,
  userBySource
} from "@/apis/projectCenter/projectManagement"
import { Button, Form, Input, message, Radio, Select, Switch } from "antd"
import { useEffect, useState } from "react"
import {
  LengthRange,
  NameNotSpecialChar,
  RulesTelphone
} from "../../../utils/commonFunc/validate"
import "../index.scss"
import cnchar from "cnchar"

const { Option } = Select

function AddMember(props) {
  const { data, isOpen, confirmClick } = props
  console.log(data.data)
  const [form] = Form.useForm() //表单
  const [radioValue, setRadioValue] = useState(0)
  const layout = {
    labelCol: {
      span: 7
    }
  }
  const [memberList, setMemberList] = useState([]) //成员列表
  const [rolesList, setRolesList] = useState([]) //成员角色列表
  const [userInfo, setUserInfo] = useState({}) //用户信息
  const [isLoading, setIsLoading] = useState(false) //保存loading
  const [switchValue, setSwitchValue] = useState(false)

  useEffect(() => {
    getRoleList(radioValue) //角色下拉
    if (data.type === "ADD") {
      getUserBySource(radioValue) //成员下拉
    } else {
      setRadioValue(data.data[0].userSource)
      setSwitchValue(data.data[0].isManager)
    }
  }, [isOpen]) // eslint-disable-line

  /**
   * @description: 获取角色下拉
   * @return {*}
   * @author: LINA.QI
   */
  const getRoleList = (param) => {
    if (data.type === "EDIT") {
      Promise.all([
        noCancelRole(data.data[0].id),
        roleList({
          projectId: data.projectId,
          source: data.data[0].userSource === 2 ? 2 : 9
        })
      ])
        .then((res) => {
          let roleList = []
          let roleData = []
          data.data[0].roles.forEach((item) => {
            roleData.push(item.roleId.toString())
          })
          roleList = res[1].map((item) => {
            item.disabled =
              res[0].includes(item.id) || item.roleType === 1 ? true : false
            return item
          })
          if (data.data[0].userSource === 0 || data.data[0].userSource === 1) {
            roleList = roleList.filter((item) => item.roleType !== 2)
          } else {
            roleList = roleList.filter((item) => item.roleType === 2)
          }
          form.setFieldValue("roleIds", roleData)
          console.log(data.data[0])
          form.setFieldsValue(data.data[0])
          setRolesList(
            roleList.filter((d) =>
              data.data[0].isLeader ? true : d.roleType != 1
            )
          )
        })
        .catch((e) => message.error(e))
    } else {
      roleList({ projectId: data.projectId, source: param })
        .then((res) => {
          setRolesList(res)
        })
        .catch((e) => message.error(e))
    }
  }

  /**
   * @description: 获取选择成员下拉
   * @return {*}
   * @author: LINA.QI
   */
  const getUserBySource = (params) => {
    userBySource({ source: params, projectId: data.projectId, username: "" })
      .then((res) => {
        setMemberList(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 模糊搜索赚拼音
   * @return {*}
   * @author: LINA.QI
   */
  const selectPinYin = (input, option) => {
    if (input.charCodeAt() >= 32 && input.charCodeAt() <= 126) {
      return (
        option.label
          .spell("low", "poly")
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    } else {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }

  /**
   * @description: 切换成员
   * @return {*}
   * @author: LINA.QI
   */
  const [switchShow, setSwitchShow] = useState(true) //是否展示协同按钮
  const handleType = (data) => {
    let filterData = []
    data.forEach((item) => {
      filterData.push(memberList.find((res) => res.userId === item))
    })
    setUserInfo(filterData)
    form.setFieldValue("userName", data)
    setSwitchShow(data.length > 1 ? false : true)
  }

  /**
   * @description: 切换radio
   * @return {*}
   * @author: LINA.QI
   */
  const handChangeRadio = (data) => {
    setRadioValue(data.target.value)
    form.resetFields()
    getRoleList(data.target.value)
    getUserBySource(data.target.value)
  }

  /**
   * @description: 切换协同权限
   * @return {*}
   * @author: LINA.QI
   */
  const switchChange = (checked) => {
    setSwitchValue(checked)
  }

  /**
   * @description: 新增
   * @return {*}
   * @author: LINA.QI
   */
  const addMember = (params) => {
    const addParams =
      radioValue !== 2
        ? {
            users: userInfo,
            userSource: radioValue,
            projectId: data.projectId,
            roleIds: params.roleIds,
            isManager: params.isManager
          }
        : {
            users: [
              {
                userPhone: params.userPhone,
                userName: params.userName
              }
            ],

            projectId: data.projectId,
            userSource: radioValue,
            isManager: false,
            roleIds: params.roleIds
          }
    if (!addParams.isManager || addParams.users.length > 1) {
      delete addParams.isManager
    }
    addMemberSave(addParams)
      .then((res) => {
        confirmClick()
        setIsLoading(false)
        message.success("新增成功")
      })
      .catch((e) => {
        setIsLoading(false)
        message.error(e)
      })
  }

  /**
   * @description: 修改
   * @return {*}
   * @author: LINA.QI
   */
  const editMember = (params) => {
    const editParams = {
      id: data.data[0].id,
      roleIds: params.roleIds,
      isManager: params.isManager
    }
    editMemberSave(editParams)
      .then((res) => {
        confirmClick()
        setIsLoading(false)
        message.success("修改成功")
      })
      .catch((e) => {
        setIsLoading(false)
        message.error(e)
      })
  }

  /**
   * @description: 保存
   * @return {*}
   * @author: LINA.QI
   */
  const handleSubmit = () => {
    form
      .validateFields()
      .then((res) => {
        setIsLoading(true)
        if (data.type === "ADD") {
          addMember(res)
        } else {
          editMember(res)
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo)
      })
  }

  return (
    <>
      <div className="addProject">
        <Form form={form} {...layout}>
          <div
            hidden={data.type === "EDIT"}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <span style={{ marginLeft: "32px", marginBottom: "15px" }}>
              <i
                style={{
                  color: "#ff4d4f",
                  marginRight: "5px",
                  fontSize: "14px"
                }}
              >
                *
              </i>
              选择来源：
            </span>
            <Radio.Group
              onChange={handChangeRadio}
              defaultValue={0}
              value={radioValue}
            >
              <Radio value={0}>UUAM</Radio>
              <Radio value={1}>合作渠道</Radio>
              <Radio value={2}>外部人员</Radio>
            </Radio.Group>
          </div>
          <Form.Item
            name="userId"
            label="选择成员"
            rules={
              radioValue === 2
                ? []
                : [{ required: true, message: `请选择成员` }]
            }
            hidden={radioValue === 2 || data.type === "EDIT"}
          >
            <Select
              mode="multiple"
              style={{ width: "260px" }}
              placeholder="请选择成员"
              onChange={handleType}
              showSearch
              filterOption={selectPinYin}
              options={memberList.map((d) => ({
                label:
                  d.userName +
                  (d.userDept ? `(${d.userDept})` : "") +
                  (d.isExist ? "(已存在)" : ""),
                value: d.userId,
                disabled: d.disabled || d.isExist
              }))}
            ></Select>
          </Form.Item>
          <Form.Item
            name="userName"
            label="成员"
            rules={[{ required: true, message: `请选择成员` }]}
            hidden={data.type !== "EDIT"}
          >
            <Input
              disabled={data.type === "EDIT"}
              style={{ width: "260px" }}
              placeholder="请选择成员"
            ></Input>
          </Form.Item>
          <Form.Item
            name="userName"
            label="成员姓名："
            rules={
              radioValue !== 2
                ? []
                : [...NameNotSpecialChar("成员姓名"), ...LengthRange(1, 15)]
            }
            hidden={radioValue !== 2 || data.type === "EDIT"}
          >
            <Input
              style={{ width: "260px" }}
              placeholder="请输入成员姓名"
            ></Input>
          </Form.Item>
          <Form.Item
            name="userPhone"
            label="成员手机号："
            rules={
              radioValue !== 2 || data.type === "EDIT" ? [] : RulesTelphone
            }
            hidden={radioValue !== 2 || data.type === "EDIT"}
          >
            <Input
              style={{ width: "260px" }}
              placeholder="请输入成员手机号"
            ></Input>
          </Form.Item>
          <Form.Item
            name="roleIds"
            label="成员角色："
            rules={[{ required: true, message: `请选择成员角色` }]}
          >
            <Select
              style={{ width: "260px" }}
              mode="multiple"
              placeholder="请选择成员角色"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={rolesList.map((d) => ({
                label: d.roleName,
                value: d.id,
                disabled: d.disabled
              }))}
            ></Select>
          </Form.Item>
          <Form.Item
            name="isManager"
            label="协同管理权限："
            hidden={radioValue !== 0 || !switchShow}
          >
            <Switch checked={switchValue} onChange={switchChange} />
          </Form.Item>
        </Form>
      </div>
      <div className="dialog-footer">
        <Button
          className="cancel"
          disabled={isLoading}
          onClick={() => confirmClick()}
        >
          <i className="iconfont icon-x"></i>取消
        </Button>
        <Button
          className="confirm"
          loading={isLoading}
          type="primary"
          onClick={handleSubmit}
        >
          <i className="iconfont icon-baocun"></i>确认
        </Button>
      </div>
    </>
  )
}
export default AddMember
