import { Form, Input, message, Pagination, Select, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { backWarehouse, getDeviceData, getOnLineList, updateOnlineDevice } from "../../apis/deviceManage/onLine/index";
import { getDeviceModelTree, getDeviceTypeTree } from "../../apis/deviceManage/type/index";
import OperateButton from "../../components/OperateButton";
import ProjectCascader from "../../components/ProjectCascader";
import EquipmentDetail from "./components/EquipmentDetail";
import "./index.scss";

const { Search } = Input;

function OnLine() {
  const [dataSource, setDataSource] = useState([]);
  const [deviceTypeSource, setDeviceTypeSource] = useState([]) //设备类型
  const [deviceModelSource, setDeviceModelSource] = useState([]) //设备型号
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const { containerHeight } = useSelector((state) => state.appReducer);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({})
  const [isShowCAD, setIsShowCAD] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [searchData, setSearchData] = useState({
    deviceCategoryId: undefined,
    typeCode: undefined,
    stockTimeSort: undefined,
    projectId:undefined,
    deviceNo:undefined
  })

  useEffect(() => {
    queryDeviceTypeList()
    queryDeviceModelList()
  },[])
  const onFinish = () => {
    form.validateFields().then(values => {
      setSearchData({
        ...searchData,
        ...values,
        projectId: values.projectId ? values.projectId.projectId : undefined
      })
      setPage(1);
    })
  };

  const queryList = useCallback((data = {}) => {
    const params = {
      data,
      page: {
        pageIndex: page,
        pageSize: size,
      },
    };
    getOnLineList(params)
      .then((res) => {
        setDataSource(res.list);
        setTotal(res.total);
      })
      .catch((e) => message.error(e));
  },[page,size]);

  /**
   * @description 点击查询按钮
   */
  useEffect(() => {
    queryList(searchData)
  },[searchData,queryList])

  useEffect(() => {
    queryDeviceModelList(searchData.deviceCategoryId) 
    setSearchData({
      ...searchData,
      typeCode: undefined
    })
    form.setFieldValue("typeCode",undefined)
  },[searchData.deviceCategoryId])

  const tableChange = (selectedRowKeys, selectedRows, info) => {
    setSearchData({
      ...searchData,
      stockTimeSort: info.order 
    })
  }
  

  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };

  /**
   *
   * @description 获取设备类型
   */
   const queryDeviceTypeList = () => {
    getDeviceTypeTree()
      .then((res) => {
        setDeviceTypeSource(res);
      })
      .catch((e) => message.error(e));
  };

  /**
   *
   * @description 获取设备型号
   */
   const queryDeviceModelList = (id) => {
    getDeviceModelTree({deviceCategoryId: id})
      .then((res) => {
        setDeviceModelSource(res);
      })
      .catch((e) => message.error(e));
  };

  const columns = [
    {
      title: "云端设备号",
      dataIndex: "cloudDeviceNo",
      width: 180,
      ellipsis: true,
    },
    {
      title: "设备序列号",
      dataIndex: "deviceNo",
      ellipsis: true,
    },
    {
      title: "出库备注",
      dataIndex: "remark",
      ellipsis: true,
    },
    {
      title: "出库时间",
      dataIndex: "inOutTime",
      sorter: true,
      ellipsis: true,
      width: 170
    },
    {
      title: "设备类型",
      dataIndex: "categoryName",
      ellipsis: true,
    },
    {
      title: "设备型号",
      dataIndex: "typeName",
      ellipsis: true,
    },
    {
      title: "使用项目",
      dataIndex: "projectName",
      ellipsis: true,
    },
    {
      title: "状态",
      width: 100,
      dataIndex: "inOutStatus",
      ellipsis: true,
      render: (text) => <span className={`status ${text === 0 ? 'statusNomal' : 'statusBack' }`}>{text === 0 ? '正常' : '已返库'}</span>,
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 150,
      render: (text, record) => (
        <div className="operation">
          <OperateButton icon="icon-eye" text="查看" click={() => checkModal(record, "check")}/>
          {record.inOutStatus === 0 && <OperateButton icon="icon-pencil" text="修改配置" click={() => updateModal(record, "update")}/>}
          {record.inOutStatus === 0 && <OperateButton icon="icon-icon-currency-dollar" text="返库" popType={true} title="确认对该设备进行返库操作？" confirm={returnBack} data={record}/>}
        </div>
      ),
    },
  ];
  const checkModal = (data,type) => {
    getDeviceData(data.id).then(res => {
      setModalTitle('设备详情')
      setModalType(type)
      setIsShowCAD(true)
      setModalData({
        ...res.deviceStockVo,
        data: res.deviceStockVo.components,
        id: data.id,
      })
      setModalOpen(true)
    })
    .catch((e) => message.error(e));
  }
  const updateModal = (data,type) => {
    getDeviceData(data.id).then(res => {
      setModalTitle('修改配置')
      setModalType(type)
      setIsShowCAD(false)
      setModalData({
        ...res.deviceStockVo,
        data: res.deviceStockVo.components,
        id: data.id,
      })
      setModalOpen(true)
    })
    .catch((e) => message.error(e));
  }
  const saveModalData = (data,callback) => {
    updateOnlineDevice(data).then(res => {
      if(res) {
        message.success("修改成功")
        callback()
        onFinish()
      }
    })
    .catch((e) => message.error(e));
  }
  const closeModal = () => {
    setModalOpen(false)
  }
  const returnBack =(id) => {
    backWarehouse(id).then(res => {
      if(res) {
        message.success("返库成功")
        onFinish() 
      }
    })
    .catch((e) => message.error(e));
  }
  return (
    <div className="online-content">
      <div className="form-container">
        <Form form={form} layout="inline" colon={false}>
          <Form.Item name="deviceCategoryId" label="设备类型">
            <Select
              style={{
                width: 160,
              }}
              showSearch
              allowClear
              placeholder="选择类型"
              options={deviceTypeSource && deviceTypeSource.map(d => ({label: d.categoryName, value: d.id}))}
              onChange={onFinish}
            />
          </Form.Item>
          <Form.Item name="typeCode" label="设备型号" style={{marginLeft: "20px"}}>
            <Select
              style={{
                width: 160,
              }}
              showSearch
              allowClear
              placeholder="选择型号"
              options={deviceModelSource && deviceModelSource.map(d => ({label: d.typeName, value: d.typeCode}))}
              onChange={onFinish}
            />
          </Form.Item>
          <Form.Item name="projectId" label="项目名称" style={{marginLeft: "20px"}}>
            <ProjectCascader width={250} onChange={onFinish} selectAll={true}/>
          </Form.Item>
          <Form.Item name="deviceNo" style={{marginLeft: "20px"}}>
            <Search
              style={{
                width: 270,
              }}
              autoComplete="off"
              allowClear
              onSearch={onFinish}
              onKeyDown={(event) => {
                console.log(event.code);
                if(event.code === "Enter"){
                  onFinish()
                }
                
              }}
              placeholder="请输入云端设备号或设备序列号"
            />
          </Form.Item>
        </Form>
      </div>
      <div className="table-container" style={{ marginTop: 16 }}>
        <Table
          size="middle"
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          onChange={tableChange}
          pagination={false}
        />
        {total === 0 ? undefined : <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "40", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />}
      </div>
      <EquipmentDetail
        modalTitle={modalTitle}
        isVersion={modalType === "check" ? true : false}
        modalType={modalType}
        modalData={modalData}
        modalOpen={modalOpen}
        isShowCAD={isShowCAD}
        saveModal={saveModalData}
        cancelModal={closeModal}
      />
    </div>
  );
}

export default OnLine;
