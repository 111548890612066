import { Button, Form, Input, message, Modal, Pagination, Select, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { addDeviceComponentsList, addDeviceComponentsType, deleteDeviceComponentsList, deleteDeviceComponentsType, getDeviceComponentsList, getDeviceComponentsTypeList, updateDeviceComponentsList } from "../../apis/deviceManage/assembly";
import OperateButton from "../../components/OperateButton";
import { NameNotSpecialChar } from "../../utils/commonFunc/validate";
import ArgTable from "./components/ArgTable";
import TypeHearder from "./components/TypeHeader";
import "./index.scss";

function EquipmentAssembly() {
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const [typeSource, setTypeSource] = useState([])
  const [typeId, setTypeId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [parameter, setParameter] = useState([])
  const [modalType, setModalType] = useState('')
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [updateId, setUpdateId] = useState('')
   /**
   *
   * @description 查询列表方法
   */
    const queryList = useCallback(() => {
      let params = {
        data: {
          deviceComponentTypeId: typeId
        },
        page:{
          pageIndex: page,
          pageSize: size
        }
      }
      getDeviceComponentsList(params)
        .then((res) => {
          setTotal(res.total);
          setDataSource(res.list);
        })
        .catch((e) => message.error(e));
    },[typeId, page, size]);

  /**
   * @description 加载组件时先进行一次查询
   */
  // eslint-disable-next-line
  useEffect(() => {   
    queryDeviceComponentsTypeList();
  }, []);
  
  useEffect(() => {   
    typeId && queryList();
  }, [typeId, page, size, queryList]);

 

  /**
   *
   * @description 查询组件类型
   */
  const queryDeviceComponentsTypeList = () => {
    getDeviceComponentsTypeList()
      .then((res) => {
        let source = res.map(d => {
          return {
            id: d.id,
            code: d.componentNo,
            name: d.componentType,
            number: d.number
          }
        })
        if(res[0]) {
          setTypeId(res[0].id)
        }
        setTypeSource(source);
      })
      .catch((e) => message.error(e));
  };

  const columns = [
    {
      title: "组件类型",
      dataIndex: "componentType",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "组件品牌",
      dataIndex: "componentBrand",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "组件型号",
      dataIndex: "componentModel",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "操作",
      width: 100,
      dataIndex: "operation",
      render: (text, record) => (
        <div className="operation">
          <OperateButton text="编辑" icon="icon-pencil" click={updateModal} data={record}/>
          <OperateButton text="删除" popType={true} title="确定删除该组件型号？" icon="icon-trash" confirm={confirm} data={record}/>
        </div>
       
      ),
    },
  ];

  /**
 * @description 添加设备型号
 */
  const handleAdd = () => {
    setModalType("add")
    form.setFieldsValue({deviceComponentTypeId: typeId})
    setIsModalOpen(true)
  };
 /**
 * @description 编辑设备型号
 */
  const updateModal = (data) => {
    setUpdateId(data.id)
    setModalType("update")
    form.setFieldsValue(data)
    let parameterlist = JSON.parse(data.componentParameter)
    setParameter(parameterlist.map((d,idx) => {
      return {
        ...d,
        key: idx
      }
    }))
    setIsModalOpen(true)
  }
 /**
 * @description 提交设备型号
 */
  const handleOk = () => {
    form.validateFields(["deviceComponentTypeId","componentBrand","componentModel"]).then(values => {
      for (let i = 0; i < parameter.length; i++) {
        const item = parameter[i];
        if(!item.argName || !item.argValue || !item.argType || !item.argKey) {
          message.warning(`请完善第${i + 1}行信息`);
          return
        }
      }
      let params = {}
      if(modalType === "add") {
        params = {
          ...values,
          componentParameter: JSON.stringify(parameter)
        }
        addDeviceComponentsList(params).then(res => {
          if(res) {
            message.success("添加成功");
            form.resetFields()
            setParameter([])
            setIsModalOpen(false);
            queryList()
            queryDeviceComponentsTypeList();
          }
        })
        .catch((e) => message.error(e));
      } else{
        params = {
          ...values,
          id: updateId,
          componentParameter: JSON.stringify(parameter)
        }
        updateDeviceComponentsList(params).then(res => {
          if(res) {
            message.success("更新成功");
            form.resetFields()
            setParameter([])
            setIsModalOpen(false);
            queryList()
          }
        })
        .catch((e) => message.error(e));
      }
    })
  };

  const handleCancel = () => {
    form.resetFields()
    setParameter([])
    setIsModalOpen(false);
  };

  const confirm = (id) => {
    deleteDeviceComponentsList(id).then(res => {
      if(res) {
        message.success("删除成功");
        queryList()
        queryDeviceComponentsTypeList();
      }
    })
    .catch((e) => message.error(e));
  }
  const deleteType = (id) => {
    deleteDeviceComponentsType(id).then(res => {
      if(res) {
        message.success("删除成功");
        queryDeviceComponentsTypeList()
      }
    })
    .catch((e) => message.error(e));
  }
  const getComponentTypeId = (id) => {
    setTypeId(id)
    if(typeId !== id) {
      setPage(1)
    } 
  }
  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };
  const getParameter = (data) => {
    setParameter(data)
  }
  return (
    <div className="assembly-content">
      <TypeHearder
        typeSource={typeSource}
        deleteItem={deleteType}
        getComponentTypeId={getComponentTypeId}
        api={addDeviceComponentsType}
        refresh={queryDeviceComponentsTypeList}
      />
      <div className="table-container">
        <Button
          onClick={handleAdd}
          type="primary"
          style={{
            display: "flex",
            marginBottom: "15px",
          }}
        >
          添加组件型号<i className="iconfont icon-plus"></i>
        </Button>
        <Table
          size="middle"
          rowClassName={() => "editable-row"}
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        { total === 0 ? undefined : <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "40", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />}
      </div>
      <Modal
        width="690px"
        className='custom-modal'
        title={modalType === "add" ? '添加组件型号' : '编辑组件型号'}
        destroyOnClose={true}
        maskClosable={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={<span><i className="iconfont icon-baocun"></i>保存</span>}
        cancelText={<span><i className="iconfont icon-x"></i>取消</span>}
      >
        <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
          <Form.Item
            name="deviceComponentTypeId"
            rules={[{required:true}]}
            label="组件类型"
          >
            <Select
              style={{
                width: 300,
              }}
              disabled={modalType !== "add"}
              placeholder="选择组件类型"
              options={typeSource.map(d => ({label: d.name,value:d.id}))}
            >
            </Select>
          </Form.Item>
          <Form.Item
            name="componentBrand"
            rules={NameNotSpecialChar("组件品牌",15,true)}
            label="组件品牌"
          >
            <Input
              style={{width: 300}}
              autoComplete="off"
              placeholder="支持输入英文、数字、_、-，最多15字符"
            ></Input>
          </Form.Item>
          <Form.Item
            name="componentModel"
            rules={NameNotSpecialChar("组件型号", 15,true)}
            label="组件型号"
          >
            <Input
              style={{width: 300}}
              autoComplete="off"
              placeholder="支持输入英文、数字、_、-，最多15字符"
            ></Input>
          </Form.Item>
          <Form.Item
            name="componentParameter"
            label="组件参数"
          >
            <ArgTable dataSource={parameter} getParameter={getParameter} type={"update"} columnType={false}/>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default EquipmentAssembly;
