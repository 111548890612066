/*
 * @Author: gn
 * @Date: 2023-06-25 18:58:01
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-04-26 14:40:06
 * @Description: file content
 */
import { fileSelectList, stageId } from "@/apis/systemMgmt/SOPManagement";
import { FalseLengthRange, LengthRangeName } from "@/utils/commonFunc/validate";
import {
  Button,
  Form, Input, Select
} from "antd";
import React, { useEffect, useState } from "react";


const { Option } = Select;

function SopSecondChildren(props){
    const { SOPId , secondStatus , modelObj, handlePushSecond , handleReplaceSecond , hanleCloseModel, roleList , busList } = props;
    
    const [ keyId , setKeyId] = useState(null);
    const [inList, setInList] = useState([]);
    const [outList, setOutList] = useState([]);

    const [form] = Form.useForm();
    
    const { TextArea } = Input;
    
    /**
     * @description: 获取随机id
     * @return {*}
     * @Author: gn
     */    
    function getStageId(){
        stageId().then(res=>{
            setKeyId(res);
        }).catch(err=>message.error(err))
    };
    useEffect(()=>{
        form.setFieldsValue({...modelObj});
        if(!modelObj.id){
            getStageId();
        }  
    },[])// eslint-disable-line

    /**
     * @description: 获取节点输入-输出的下拉框
     * @return {*} type = 0输入，type=1输出
     * @Author: gn
     */
    function getFileSelectList(type) {
        const params = {
            id: SOPId,    //sop的id
            inputOrOutput: type,
            nodeId: modelObj.id,   //节点id
        };
        fileSelectList(params).then(res => {
            if (type === 0) {
                setInList(res);
            } else if (type === 1) {
                setOutList(res);
            }
        }).catch(err => message.error(err));
    };

    
    useEffect(()=>{
        form.setFieldsValue({...modelObj});
        getFileSelectList(0);
        getFileSelectList(1);
    },[])// eslint-disable-line
    /**
     * @description: 表单校验成功
     * @return {*}
     * @Author: gn
     */    
    function onFinish(values){
      console.log(values);
        // console.log(values);
        if(secondStatus === 'add'){
            const json = {
                ...modelObj,
                ...values,
                id : keyId,
                systemRoleId: values.systemRoleId.join(",")
            };
            handlePushSecond(json);
        }else{
            const json = {
                ...modelObj,
                ...values,
                systemRoleId: values.systemRoleId.join(",")
            };
            handleReplaceSecond(json);
        }
        hanleCloseModel();
    };
    /**
     * @description: 表单校验失败
     * @return {*}
     * @Author: gn
     */    
    function onFinishFailed(error){

    };
    return(
        <Form
            name="second"
            initialValues={{
                remember: true,
            }}
            labelCol={{
                span: 4,
                }}
            // wrapperCol={{
            //     span: 16,
            // }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                name="nodeCode"
                rules={[
                ...LengthRangeName('节点编号' , 1, 15)
                ]}
                label="节点编号"
            >
                <Input
                disabled={secondStatus=== 'view'}
                autoComplete="off"
                placeholder="请输入节点编号"
                ></Input>
            </Form.Item>
            <Form.Item
                name="nodeName"
                rules={[
                    ...LengthRangeName('节点名称', 1, 50)
                ]}
                label="节点名称"
            >
                <Input
                disabled={secondStatus=== 'view'}
                autoComplete="off"
                placeholder="请输入节点名称"
                ></Input>
            </Form.Item>
            <Form.Item
                name="nodeDesc"
                rules={[
                    ...FalseLengthRange(1,600)
                ]}
                label="责任描述"
            >
                <TextArea disabled={secondStatus=== 'view'} rows={4} placeholder="请输入责任描述" />
            </Form.Item>
            <Form.Item
                name="systemRoleId"
                rules={[
                    {required : true}
                ]}
                label="责任角色"
            >
                <Select
                    placeholder="请选择责任角色"
                    mode="multiple"
                    disabled={secondStatus=== 'view'}
                    >
                        {roleList.map((item) => {
                            //console.log("item", options);
                            return (
                            <Option
                                key={`${item.id}-${item.roleName}`}
                                value={item.id}
                                disabled={ item.status === '1'}
                            >
                                {item.roleName}
                            </Option>
                            );
                        })}
                </Select>
            </Form.Item>
            <Form.Item
                name="nodeInput"
                label="节点输入"
            >
                <Select
                    placeholder="请选择节点输入"
                    disabled={secondStatus=== 'view'}
                    optionFilterProp="label"
                    mode="multiple"
                    showSearch
                    options={inList.map(d => ({label: d.fileName,value: d.id}))}
                    />
            </Form.Item>
            <Form.Item
                name="nodeOutput"
                label="节点输出"
            >
                <Select
                    placeholder="请选择节点输出"
                    disabled={secondStatus=== 'view'}
                    optionFilterProp="label"
                    mode="multiple"
                    showSearch
                    options={outList.map(d => ({label: d.fileName,value: d.id}))}
                    />
            </Form.Item>
            <Form.Item
                name="businessSystems"
                label="业务系统"
            >
                <Select
                    placeholder="请选择业务系统"
                    disabled={secondStatus=== 'view'}
                    fieldNames={
                        { label: 'dictDesc', value: 'dictCode', }
                    }
                    options={busList}
                    />
            </Form.Item>
            { secondStatus === 'view' ? 
                <Form.Item style={{textAlign : 'right',marginTop : '25px',marginBottom:'0px'}}>
                    <Button style={{marginRight : '8px'}} onClick={hanleCloseModel}>
                        <i className="iconfont icon-reply" style={{fontSize : '14px',marginRight:'2px'}}></i>返回
                    </Button>
                </Form.Item>
                :
                <Form.Item style={{textAlign : 'right',marginTop : '25px',marginBottom:'0px'}}>
                    <Button style={{marginRight : '8px'}} onClick={hanleCloseModel}>
                        <i className="iconfont icon-x" style={{fontSize : '14px',marginRight:'2px'}}></i>取消
                    </Button>
                    <Button type="primary" htmlType="submit">
                        <i className="iconfont icon-baocun" style={{fontSize : '14px',marginRight:'2px'}}></i>确认
                    </Button>
                </Form.Item>
            }
        </Form>
    )
};
export default SopSecondChildren
