import { Button, Form, Input, message, Modal, Pagination, Select, Table } from "antd";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { addAndUpdateSubject, deleteQuestion, getQuestion, getQuestionList } from "../../../apis/examBank";
import CustomIcon from "../../../components/CustomIcon/customIcon";
import OperateButton from "../../../components/OperateButton";
import eventBus from "../../../utils/commonFunc/eventBus";

const { Search, TextArea } = Input

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `请填写${title}`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} maxLength={100} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingInlineEnd: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

function ExercisesTable ({pageChange, subjectName, subjectId}) {
  const [dataSource, setDataSource] = useState([])
  const [optionData, setOptionData] = useState([
    {
      key: 0,
      optionDesc: ""
    },
    {
      key: 1,
      optionDesc: ""
    }
  ])
  const [count, setCount] = useState(2);
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState({
    pageSize: 10,
    pageIndex: 1
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [subjectForm] = Form.useForm()
  const [searchData, setSearchData] = useState({
    subjectId,
    questionType: undefined,
    questionName: undefined
  })
  const [modalType, setModalType] = useState("")
  const [questionId, setQuestionId] = useState("")
  const [type, setType] = useState(0)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const columns = [
    {
      title: "题型",
      dataIndex: "questionType",
      width: 200,
      render: (text) => {
        return (
          <>{text === 0 ? "单选题" : "多选题"}</>
        )
      }
    },
    {
      title: "题目",
      dataIndex: "questionName",
      ellipsis: true
    },
    {
      title: "答案",
      dataIndex: "questionAnswer",
      render: (text) => {
        return (
          <span>{(text && text != "") && JSON.parse(text).join("; ")}</span>
        )
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 160,
      render: (text, record) => (
        <>
          <OperateButton
            text="编辑"
            data={record}
            click={() => openDialog('edit',record)}
          />
          <OperateButton
            text="删除"
            data={record}
            popType={true}
            title={`确定删除该题目吗? `}
            confirm={() => deleteQue(record.id)}
          />
        </>
      )
    }
  ]
  const defaultColumns = [
    {
      title: "选项描述",
      dataIndex: "optionDesc",
      editable: true,
      ellipsis: true,
      render: (text) =>(
        <div style={{width: "100%",whiteSpace: "pre-wrap"}}>
          {text}
        </div>
      )
    },
    {
      dataIndex: 'operation',
      width: 60,
      className: "special-operation",
      filterDropdown: true,
      filterIcon: () => {return (<i onClick={addrow} className='iconfont icon-plus-circle iconplus'></i>)},
      render: (_, record) =>
        optionData.length >= 1 ? (
          <i className='iconfont icon-minus-circle iconminus' onClick={() =>  handleDelete(record.key)}></i>
        ) : null,
    },
  ]
  const modalColumns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    queryList()
  },[page,searchData])
  //筛选更新
  const selectChange = (val) => {
    setSearchData({
      ...searchData,
      questionType: val
    })
    setPage({
      ...page,
      pageIndex: 1
    })
  }
  const onFinish = (val) => {
    setSearchData({
      ...searchData,
      questionName: val
    })
    setPage({
      ...page,
      pageIndex: 1
    })
  }
  //获取列表信息
  const queryList = () => {
    const params = {
      data: searchData,
      page: page
    }
    getQuestionList(params).then(res => {
      setDataSource(res.list)
      setTotal(res.total)
    })
    .catch(err => console.log(err))
  }
  //保存题目选项
  const handleSave = (row) => {
    const rowItem = optionData.filter(d => d.optionDesc === row.optionDesc)
    if (rowItem.length > 0 && rowItem[0].key != row.key) {
      message.warning("选项描述不可重复")
      return
    }
    const newData = [...optionData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setOptionData(newData);

     // 修改选项清除修改前的已选项
     setAnswer(row.key)
  };
  //增加题目选项
  const addrow = () => {
    const newData = {
      key: count,
      optionDesc: ""
    }
    setOptionData([...optionData,newData])
    setCount(count + 1)
  }
  //删除题目选项
  const handleDelete = (key) => {
    // 删除答案中已选项
    setAnswer(key)
    //保存删除后的选项
    const newData = optionData.filter((item) => item.key !== key);
    setOptionData(newData);
  }

  const setAnswer = (key) => {
    const newOption = [...optionData]
    const index = newOption.findIndex((item) => key === item.key);
    const item = newOption[index];
    const ansower = subjectForm.getFieldsValue(["questionAnswer"]).questionAnswer
    const ansowers = ansower && Array.isArray(ansower) ? ansower : ansower ? [ansower] : [] //确保选项是个数组
    if(ansowers.filter(d => d != item.optionDesc).length > 0) {
      subjectForm.setFieldValue("questionAnswer", ansowers.filter(d => d != item.optionDesc))
    } else {
      subjectForm.resetFields(['questionAnswer'])
    }
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  //切换分页信息
  const handleChangePagination = (pageIndex, pageSize) => {
    setPage({
      pageIndex,
      pageSize
    })
  }
  //打开弹框并获取题目信息
  const openDialog = (type, record) => {
    setModalType(type)
    if (type === "edit") {
      getQuestion(record.id).then(res => {
        setQuestionId(record.id)
        setType(res.questionType)
        subjectForm.setFieldsValue({
          ...res,
          questionAnswer: JSON.parse(res.questionAnswer)
        })
        setOptionData(JSON.parse(res.questionChoose).map((d,index) => {return {key: index, optionDesc: d}}))
        setCount((JSON.parse(res.questionChoose) && JSON.parse(res.questionChoose).length) || 0)
      })
    }
    setIsModalOpen(true)
  }
  //关闭弹框，清空弹框数据
  const cancelDialog = () => {
    setIsModalOpen(false)
    setType(0)
    setOptionData([
      {
        key: 0,
        optionDesc: ""
      },
      {
        key: 1,
        optionDesc: ""
      }
    ])
    subjectForm.resetFields()
  }
  //切换题目类型，清空答案
  const typeChange = (e) => {
    setType(e)
    subjectForm.resetFields(['questionAnswer'])
  }
  //新增题目
  const addQuestion = () => {
    subjectForm.validateFields().then((values) => {
      if(optionData.length < 2) {
        message.warning("选项不得低于2个")
        return
      }
      if(optionData.filter(d => !d.optionDesc || d.optionDesc === "").length > 0) {
        message.warning("请完善选项内容！")
        return
      }
      if(type === 1 && values.questionAnswer.length < 2) {
        message.warning("多选题答案必须大于2个")
        return
      }

      const params = {
        ...values,
        questionChoose: JSON.stringify(optionData.map(d => {return d.optionDesc})),
        questionAnswer: JSON.stringify(Array.isArray(values.questionAnswer) ? values.questionAnswer : [values.questionAnswer]),
        subjectId,
        id: modalType === "edit" ? questionId : undefined
      }
      setConfirmLoading(true)
      addAndUpdateSubject(params).then(() => {
        message.success(modalType === "edit" ? "修改成功" : "新增成功")
        cancelDialog()
        queryList()
        setConfirmLoading(false)
      })
      .catch(err => {
        message.warning(err)
        setConfirmLoading(false)
      })
    })
    .catch(err => message.warning(err))
  }
  //删除题目
  const deleteQue = (id) => {
    deleteQuestion(id).then(() => {
      message.success("删除成功！")
      queryList()
    })
    .catch(err => message.warning(err))
  }

  // 面包屑点击传来的值以及方法
  const breadcrumbItemClickEventsFn = useCallback(
    (item) => {
      switch (item) {
        case "考试题库":
          eventBus.emit("setBreadcrumbData", ["系统管理", "考试题库"])
          pageChange("subject")
          break
        default:
          return
      }
    },
    []
  )

  useEffect(() => {
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    )
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      )
    }
  }, [breadcrumbItemClickEventsFn])

  return (
    <>
      <div className="sub-header">
        <div>
          <CustomIcon name={"icon-kaoshitiku"} width={"15px"} height={"16px"}/>
          <span className="sub-name">{subjectName}</span>
        </div>
        <Button onClick={() => pageChange("subject")}><i className="iconfont icon-reply"/>返回</Button>
      </div>
      <div className="search-line">
        <Button type="primary" className="add-subject" onClick={() => openDialog('add')}>
          添加题目
          <i className="add_icon iconfont icon-plus"></i>
        </Button>
        <div>
          <Select
            style={{ width: 120 }}
            placeholder="全部题型"
            allowClear
            options={[
              { value: 0, label: '单选题' },
              { value: 1, label: '多选题' },
            ]}
            onChange={selectChange}
          />
          <Search
            style={{
              width: 270,
              marginLeft: 12
            }}
            autoComplete="off"
            allowClear
            onSearch={onFinish}
            onKeyDown={(event) => {
              if(event.code === "Enter"){
                onFinish(event.target.value)
              }
            }}
            placeholder="请输入题目"
          />
        </div>
      </div>
      <Table
        className="custom-table"
        rowKey={(record) => record.key}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      {total != 0 && (
        <Pagination
          total={total}
          pageSize={page.pageSize}
          current={page.pageIndex}
          pageSizeOptions={["10", "20", "30", "40", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />
      )}
      <Modal
        title={modalType === "add" ? "添加题目" : "修改题目"}
        wrapClassName="custom-modal"
        open={isModalOpen}
        centered={true}
        maskClosable={false}
        onCancel={cancelDialog}
        width={500}
        footer={null}
        destroyOnClose={true}
      >
        <Form form={subjectForm} labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} onFinish={addQuestion}>
          <Form.Item label="题型" name="questionType" rules={[{required: true, message: "请选择题型"}]} initialValue={0}>
            <Select
              placeholder="请选择"
              onChange={typeChange}
              options={[
                { value: 0, label: '单选题' },
                { value: 1, label: '多选题' },
              ]}
            />
          </Form.Item>
          <Form.Item label="题目" name="questionName" rules={[{required: true, message: "请填写"}]}>
            <TextArea
              showCount
              maxLength={500}
              placeholder="请输入"
              style={{
                height: 110,
                resize: 'none',
              }}
            />
          </Form.Item>
          <Form.Item label="选项" required>
            <Table
              components={components}
              className="custom-table modal-table"
              rowKey={(record) => record.key}
              dataSource={optionData}
              columns={modalColumns}
              pagination={false}
            />
          </Form.Item>
          <Form.Item label="答案" name="questionAnswer" rules={[{required: true, message: "请选择答案"}]}>
            {type === 0 ? 
             <Select
             placeholder="请选择"
             options={optionData.filter(d => d.optionDesc && d.optionDesc != "").map(d => {return {value: d.optionDesc, label: d.optionDesc}})}
           />
           :
            <Select
              placeholder="请选择"
              mode='multiple'
              options={optionData.filter(d => d.optionDesc && d.optionDesc != "").map(d => {return {value: d.optionDesc, label: d.optionDesc}})}
            />}
          </Form.Item>
          <div className="modal-footer">
            <Button onClick={cancelDialog} style={{marginRight: "12px"}}>取消</Button>
            <Button type="primary" htmlType="submit" loading={confirmLoading}>保存</Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}
export default ExercisesTable