/*
 * @Author: gn
 * @Date: 2023-06-05 18:07:59
 * @LastEditors: gn
 * @LastEditTime: 2023-07-20 15:12:41
 * @Description: file content
 */
import OperateButton from "@/components/OperateButton";
import { Button, Input, message, Pagination, Table, Space } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { deleteChannelById, getChannelPage } from "../../apis/channelManage";
import ChannelEdit from "./components/ChannelEdit";
import eventBus from "@/utils/commonFunc/eventBus";
import "./index.scss";

function ProjectOverview() {
  const [editStatus, setEditStatus] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [channelName, setChannelName] = useState("");
  // const { containerHeight } = useSelector((state) => state.appReducer);
  const [curChannelId, setCurChannelId] = useState("");
  const { Search } = Input;

  const queryList = () => {
    const params = {
      data: {
        channelName: channelName,
        createTimeSort: 1,
      },
      page: {
        pageIndex: page,
        pageSize: size,
      },
    };
    // 获取渠道分页
    getChannelPage(params)
      .then((result) => {
        setDataSource(result.list);
        setTotal(result.total);
      })
      .catch((e) => message.error(e));
  };

  useEffect(() => {
    queryList();
  }, [page, size, channelName]);

  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };

  /**
   * @description: 搜索
   * @return {*}
   * @Author: gn
   */
  const onSearch = (value) => {
    setPage(1);
    setChannelName(value);
  };

  /**
   * @description: 新增
   * @return {*}
   * @Author: gn
   */
  function changeEdit() {
    setCurChannelId("");
    setChannelName("");
    setEditStatus(true);
  }
  /**
   * @description: 编辑-根据id获取详情页信息
   * @return {*}
   */
  function handleEdit(id) {
    eventBus.emit("setBreadcrumbData", ["合作中心", "渠道管理", "渠道详情"]);
    setCurChannelId(id);
    setChannelName("");
    setEditStatus(true);
  }
  /**
   * @description: 通过id删除渠道
   * @param
   */
  function handleDelete(record) {
    if (record.channelPersonnelSum > 0) {
      message.warning("渠道已有人员，不允许删除！");
      return;
    }
    deleteChannelById(record.id)
      .then((result) => {
        message.success("删除渠道成功");
        if (dataSource.length > 1) {
          queryList();
        } else {
          setPage(1);
        }
      })
      .catch((e) => message.error(e));
  }
  /**
   * @description: 取消编辑
   * @return {*}
   * @Author: gn
   */
  function handelCancel() {
    setEditStatus(false);
    setPage(1);
    queryList();
  }

  const columns = [
    {
      title: "渠道名称",
      dataIndex: "channelName",
      ellipsis: true,
      width: "150px",
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "人员",
      dataIndex: "channelPersonnelSum",
      ellipsis: true,
      width: "150px",
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      ellipsis: true,
      width: "150px",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <span>{dayjs(text).format("YYYY-MM-DD HH:mm:ss")}</span>
      ),
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "150px",
      render: (text, record) => (
        <div className="operation">
          <OperateButton
            text="进入"
            icon="icon-a-IconsSolid"
            data={record}
            click={() => handleEdit(record.id)}
          />
          <OperateButton
            text="删除"
            icon="icon-trash"
            popType={true}
            title={
              "确定删除渠道? *注意删除后渠道销售工具和渠道人员将无法正常使用"
            }
            data={record}
            confirm={() => handleDelete(record)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="channelManagement">
      {!editStatus ? (
        <>
          <div className="channel_head">
            <Button type="primary" onClick={() => changeEdit()}>
              新增渠道<i className="add_icon iconfont icon-plus-sm"></i>
            </Button>
            <Space>
              <Search
                placeholder="请输入渠道名称"
                onSearch={onSearch}
                allowClear
                className="custom-channel-search"
              />
            </Space>
          </div>
          <Table
            size="middle"
            className="custom-table channel_table"
            rowKey={(record) => record.id}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={
              {
                // y: containerHeight - 56 - 24 * 2 - 32 - 56 * 2 - 24 * 2,
              }
            }
          />
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "40", "50"]}
            showSizeChanger
            showQuickJumper
            showTotal={(total) => `共 ${total} 条`}
            onChange={handleChangePagination}
            className="custom-pagination"
            style={{ marginTop: 10, paddingLeft: 15, paddingRight: 15 }}
          />
        </>
      ) : (
        <>
          <ChannelEdit channelId={curChannelId} handelCancel={handelCancel} />
        </>
      )}
    </div>
  );
}

export default ProjectOverview;
