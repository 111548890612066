/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-08-03 15:49:36
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-08-04 11:49:50
 */
const readSession = (key) => {
  let value = sessionStorage.getItem(key);
  if (value && value !== "undefined" && value !== "null") {
    return JSON.parse(value);
  }
  return null;
};

export default readSession;
