/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-20 10:12:29
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-07-17 14:20:58
 */
import { axiosInstance } from '../../config';

//我的项目-分页查询
export const projectPage = (params) => {
  return axiosInstance.post('/project/own/page',params);
};


//我的项目-项目状态
export const statusCount = (params) => {
 return axiosInstance.post('/project/own/statusCount',params);
};

//我的项目-星标
export const addOrCancelStar = (projectId) => {
 return axiosInstance.post(`/project/own/addOrCancelStar?projectId=${projectId}`);
};

