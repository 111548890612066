/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-06 13:22:19
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-07-17 16:29:28
 */
import { workHourMemberList } from "@/apis/projectCenter/projectManagement"
import { sumWorkhourApi, workHourDetail } from "@/apis/workHour"
import CustomIcon from "@/components/CustomIcon/customIcon"
import { exportFile } from "@/utils/commonFunc/exportFile"
import {
  Button,
  DatePicker,
  Modal,
  Pagination,
  Select,
  Table,
  message
} from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import "../index.scss"
const { Option } = Select
const { RangePicker } = DatePicker

function ProjectMember(props) {
  const { projectData } = props
  const [personOptions, setPersonOptions] = useState([]) // eslint-disable-line
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState([]) //表格数据
  const [modalPage, setModalPage] = useState(1)
  const [modalSize, setModalSize] = useState(10)
  const [modalTotal, setModalTotal] = useState(0)
  const [modalSource, setModalSource] = useState([])
  const [modalUserId, setModalUserId] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false) //弹窗展示
  const [members, setMembers] = useState([])
  const [totalHour, setTotalHour] = useState(0)
  const [filterParams, setFilterParams] = useState({
    approvel: 3,
    startDate: null, //用户来源
    endDate: null, //角色ID
    projectId: projectData.id, //项目ID
    userId: null, //	姓名
    type: 1
  }) // eslint-disable-line
  const columns = [
    {
      title: "日期",
      dataIndex: "date",
      ellipsis: {
        showTitle: false
      }
    },
    {
      title: "ERP编号",
      dataIndex: "erpNo",
      ellipsis: {
        showTitle: false
      }
    },
    {
      title: "项目编号",
      dataIndex: "projectCode",
      ellipsis: {
        showTitle: false
      }
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      ellipsis: {
        showTitle: false
      }
    },
    {
      title: "员工",
      dataIndex: "userName",
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return (
          <>
            <div>{text ? text : "--"}</div>
          </>
        )
      }
    },
    {
      title: "部门",
      dataIndex: "deptName",
      ellipsis: {
        showTitle: false
      }
    },
    {
      title: "工时统计(天)",
      dataIndex: "count",
      ellipsis: {
        showTitle: false
      },
      render: (text, record) => {
        return (
          <>
            <div onClick={() => openDialog(record.userId)} className="count">
              {text ? text : "--"}
            </div>
          </>
        )
      }
    }
  ]

  useEffect(() => {
    queryList() //列表
  }, [page, size])

  useEffect(() => {
    modalQueryList() //列表
  }, [modalPage, modalSize, modalUserId])

  useEffect(() => {
    getMemberList()
  }, [])

  /**
   * @description:切换来源
   * @param {*} params
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeSource = (id) => {
    setPage(1)
    setFilterParams({
      ...filterParams,
      userId: id
    })
  }
  /**
   * @description: 模糊搜索赚拼音
   * @return {*}
   * @author: LINA.QI
   */
  const selectPinYin = (input, option) => {
    if (input.charCodeAt() >= 32 && input.charCodeAt() <= 126) {
      return (
        option.label
          .spell("low", "poly")
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    } else {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }

  /**
   * @description 输入编号查询
   */
  const rangeChange = (date) => {
    setPage(1)
    setFilterParams({
      ...filterParams,
      startDate: date && date[0] && dayjs(date[0]).format("YYYY-MM-DD"),
      endDate: date && date[1] && dayjs(date[1]).format("YYYY-MM-DD")
    })
  }

  /**
   * @description: 添加成员
   * @return {*}
   * @author: LINA.QI
   */
  const openDialog = (userId) => {
    setIsModalOpen(true)
    setModalUserId(userId)
  }

  /**
   *
   * @description 弹框查询列表方法
   */
  const modalQueryList = () => {
    const queryParams = {
      data: {
        projectId: projectData.id, //项目ID
        userId: modalUserId, //	姓名
        type: 2
      },
      page: {
        pageIndex: 1,
        pageSize: 99999
      }
    }
    workHourDetail(queryParams)
      .then((res) => {
        setModalSource(res.list)
        setModalTotal(res.total)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description:关闭弹窗
   * @return {*}
   * @author: LINA.QI
   */
  const handleCloseModal = () => {
    setIsModalOpen(false)
    setPage(1)
    queryList()
  }
  /**
   *
   * @description 查询列表方法
   */
  const getMemberList = () => {
    workHourMemberList(projectData.id)
      .then((res) => {
        setMembers(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   *
   * @description 查询列表方法
   */
  const queryList = () => {
    const queryParams = {
      data: filterParams,
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    workHourDetail(queryParams)
      .then((res) => {
        setDataSource(res.list)
        setTotal(res.total)
      })
      .catch((e) => message.error(e))
    sumWorkhourApi(filterParams)
      .then((res) => {
        if (res.code) {
          setTotalHour(res.data)
        } else {
          setTotalHour(res)
        }
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  /**
   * @description: 弹框切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleModalChangePagination = (page, pageSize) => {
    setModalPage(page)
    setModalSize(pageSize)
  }

  //导出
  const explain = (type) => {
    exportFile(
      `/projectworkhours/exportDetail`,
      "post",
      type === "statistics"
        ? filterParams
        : {
            approvel: 3,
            projectId: projectData.id,
            userId: modalUserId,
            type: 2
          }
    )
  }

  return (
    <>
      <div className="project-workhour">
        <div className="form-container">
          <Button
            type="primary"
            className="creation"
            onClick={() => explain("statistics")}
          >
            导出明细
            <i className="iconfont icon-external-link" />
          </Button>
          <div className="form-left">
            <span>员工：</span>
            <Select
              showSearch
              allowClear
              placeholder="选择人员"
              className="select"
              filterOption={selectPinYin}
              onChange={handleChangeSource}
              options={
                members &&
                members.map((d) => ({
                  label: d.userName,
                  value: d.userId
                }))
              }
            />
            <span>选择日期：</span>
            <RangePicker className="range" onChange={rangeChange} />
            <Button className="search" onClick={queryList}>
              查询
            </Button>
          </div>
        </div>
        <div className="totle-hour">
          <CustomIcon name={"icon-shijian"} />
          <span className="text">工时合计：{totalHour}（天）</span>
        </div>
        <Table
          style={{ margin: "15px 0 10px 0" }}
          size="middle"
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />
        <Modal
          title="工时明细"
          wrapClassName="custom-modal statistics-modal"
          open={isModalOpen}
          centered={true}
          maskClosable={false}
          footer={null}
          onCancel={handleCloseModal}
          width={"65%"}
          destroyOnClose={true}
        >
          <div style={{ marginBottom: "20px" }}>
            <Button
              type="primary"
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => explain("detail")}
            >
              导出明细
              <i
                className="iconfont icon-external-link"
                style={{ marginLeft: "2px" }}
              />
            </Button>
          </div>
          <Table
            style={{ margin: "15px 0 10px 0" }}
            size="middle"
            className="custom-table"
            rowKey={(record) => record.id}
            dataSource={modalSource}
            columns={columns}
            pagination={false}
          />
          {/* <Pagination
            total={modalTotal}
            pageSize={modalSize}
            current={modalPage}
            pageSizeOptions={["10", "20", "30", "50"]}
            showSizeChanger
            className="custom-pagination"
            onChange={handleModalChangePagination}
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px"
            }}
          >
            <Button className="search" onClick={handleCloseModal}>
              <i
                className="iconfont icon-reply"
                style={{ marginRight: "5px" }}
              />
              返回
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}
export default ProjectMember
