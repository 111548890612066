/*
 * @Author: gn
 * @Date: 2023-06-15 20:11:57
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-04 15:11:51
 * @Description: file content
 */
import { Tabs } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import "../index.scss";
import BasicTabs from "./BasicTabs";
import MarketTabs from "./MarketTabs";
import PersonnelTabs from "./PersonnelTabs";
import eventBus from "@/utils/commonFunc/eventBus";

function ChannelEdit(props) {
  const { handelCancel } = props;
  const [channelId, setChannelId] = useState(props.channelId);
  function handleEditStatus(val) {
    setChannelId(val);
  }
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: `基本信息`,
      children: (
        <BasicTabs
          channelId={channelId}
          handleEditStatus={handleEditStatus}
          handelCancel={handelCancel}
        />
      ),
    },
    {
      key: "2",
      label: `销售工具`,
      children: (
        <MarketTabs channelId={channelId} handelCancel={handelCancel} />
      ),
      disabled: !channelId,
    },
    {
      key: "3",
      label: `人员管理`,
      children: (
        <PersonnelTabs channelId={channelId} handelCancel={handelCancel} />
      ),
      disabled: !channelId,
    },
  ];

  // 面包屑点击传来的值以及方法
  const breadcrumbItemClickEventsFn = useCallback((item) => {
    console.log("item", item);
    switch (item) {
      case "渠道管理":
        eventBus.emit("setBreadcrumbData", ["合作中心", "渠道管理"]);
        handelCancel();
        break;
      default:
        return;
    }
  }, []);

  useEffect(() => {
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    );
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      );
    };
  }, [breadcrumbItemClickEventsFn]);
  return (
    <>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </>
  );
}

export default ChannelEdit;
