/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2024-07-31 13:28:00
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-16 14:11:40
 */
import {
  Button,
  Input,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
  Tooltip,
  message
} from "antd"
import { useEffect, useState } from "react"
import {
  subjectListApi,
  subjectPage,
  addSubjectUser,
  noticeApi
} from "@/apis/projectCenter/projectManagement"
import dayjs from "dayjs"
import OperateButton from "../../../components/OperateButton"
import { useNavigate } from "react-router-dom"
import SendOutExam from "./SendOutExam"
import AnswerDetails from "./AnswerDetails"

const { Search } = Input
const { Option } = Select

function ProjectExam(props) {
  const { projectData } = props
  const [filterParams, setFilterParams] = useState({
    subjectId: null, //科目
    projectId: projectData.id, //项目ID
    userName: null, //	考试人姓名
    sortField: null,
    sortType: null
  }) // eslint-disable-line
  const [subjectList, setSubjectList] = useState([]) //科目下拉框数据
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState([]) //表格数据
  const [dialogData, setDialogData] = useState({
    title: "选择考试人员",
    data: {},
    type: "ADD",
    projectId: projectData.id
  }) //弹窗数据
  const [isModalOpen, setIsModalOpen] = useState(false) //弹窗展示
  const [component, setComponent] = useState("SendOutExam") //弹窗切换组件

  const columns = [
    {
      title: "科目",
      dataIndex: "subjectName",
      ellipsis: {
        showTitle: false
      },
      render: (text) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          "--"
        )
      }
    },
    {
      title: "发卷时间",
      dataIndex: "createTime",
      width: "200px",
      sorter: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--"
      }
    },
    {
      title: "答卷人",
      dataIndex: "userName",
      ellipsis: {
        showTitle: false
      },
      render: (text, record) => {
        return (
          <span style={{ color: record.isDelete ? "rgba(0, 0, 0, 0.25)" : "" }}>
            {text}
          </span>
        )
      }
    },
    {
      title: "答题次数",
      dataIndex: "answersNumber",
      ellipsis: {
        showTitle: false
      },
      render: (text, record) => {
        return text > 0 ? (
          <span
            style={{ color: "#3F83F8", cursor: "pointer" }}
            onClick={() => handlerView(record)}
          >
            {text}
          </span>
        ) : (
          text
        )
      }
    },
    {
      title: "最后交卷时间",
      dataIndex: "finalSubmissionTime",
      width: "200px",
      sorter: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--"
      }
    },
    {
      title: "最终得分",
      dataIndex: "finalScore",
      ellipsis: {
        showTitle: false
      },
      render: (text, record) => {
        return text !== null && text >= 0 ? (
          <span
            style={{
              color:
                record.finalScore >= record.passLine ? "#27AE60" : "#F5222D"
            }}
          >
            {record.finalScore >= record.passLine
              ? text + " (合格)"
              : text + " (不合格)"}
          </span>
        ) : (
          "--"
        )
      }
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 100,
      render: (text, record) => (
        // eslint-disable-next-line
        <div className="operation">
          {record.answersNumber === 0 && (
            <OperateButton
              text="提醒"
              icon="icon-pencil"
              data={record}
              click={handleRemind}
            />
          )}
          {record.finalScore < record.passLine &&
            record.answersNumber > 0 &&
            record.isRestart && (
              <OperateButton
                text="重考提醒"
                data={record}
                icon="icon-switch-horizontal"
                click={() => handleRemind(record)}
              />
            )}
          {record.finalScore < record.passLine &&
            record.answersNumber > 0 &&
            !record.isRestart && (
              <OperateButton
                text="重新发卷"
                data={record}
                icon="icon-switch-horizontal"
                click={() => handleAgain(record)}
              />
            )}
        </div>
      )
    }
  ]

  useEffect(() => {
    getSubjectList()
    queryList()
  }, [page, size])

  /**
   * @description: 获取科目下拉
   * @return {*}
   * @author: LINA.QI
   */
  const getSubjectList = () => {
    subjectListApi(projectData.id,false)
      .then((res) => {
        setSubjectList(res)
      })
      .catch((e) => message.error(e))
  }

  /**
   * @description: 查询列表
   * @return {*}
   * @author: LINA.QI
   */

  const queryList = () => {
    const queryParams = {
      data: filterParams,
      page: {
        pageIndex: page,
        pageSize: size
      }
    }
    subjectPage(queryParams).then((res) => {
      setDataSource(res.list)
      setTotal(res.total)
    })
  }

  /**
   * @description: 排序
   * @return {*}
   * @author: LINA.QI
   */
  const handleSort = (electedRowKeys, selectedRows, info) => {
    console.log(info)
    filterParams.sortType =
      info.order === "ascend" ? "asc" : info.order === "descend" ? "desc" : null
    filterParams.sortField =
      info.field === "createTime"
        ? "create_time"
        : !info.order
          ? null
          : "final_submission_time"
    setPage(1)
    queryList()
  }
  /**
   * @description:切换科目
   * @param {*} params
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangeSubject = (params) => {
    filterParams.subjectId = params
    setPage(1)
    queryList()
  }

  /**
   * @description 输入考试人姓名查询
   */
  const onSearch = (params) => {
    filterParams.userName = params
    setPage(1)
    queryList()
  }

  /**
   * @description:发卷子
   * @return {*}
   * @author: LINA.QI
   */
  const handlerSendOutExam = () => {
    setDialogData({
      title: "选择考试人员",
      data: { subjectList },
      type: "ADD",
      projectId: projectData.id
    })
    setIsModalOpen(true)
    setComponent("SendOutExam")
  }

  /**
   * @description: 查看答题明细
   * @param {*} record
   * @return {*}
   * @author: LINA.QI
   */
  const handlerView = (record) => {
    setDialogData({
      title: "答题明细",
      data: record,
      type: "ADD",
      projectId: projectData.id
    })
    setIsModalOpen(true)
    setComponent("AnswerDetails")
  }
  /**
   * @description:关闭弹窗
   * @return {*}
   * @author: LINA.QI
   */
  const handleCloseModal = () => {
    setIsModalOpen(false)
    setPage(1)
    queryList()
  }

  /**
   * @description: 返回
   * @return {*}
   * @author: LINA.QI
   */
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  /**
   * @description: 切换条数/页数
   * @param {*} page
   * @param {*} pageSize
   * @return {*}
   * @author: LINA.QI
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page)
    setSize(pageSize)
  }

  /**
   * @description: 提醒
   * @return {*}
   * @author: LINA.QI
   */

  const handleRemind = (data) => {
    if (data.isDelete) {
      message.error("该成员已不在此项目中！")
      return
    }
    const params = {
      notice: data.notice,
      projectId: data.projectId,
      subjectId: data.subjectId,
      subjectName: data.subjectName,
      userList: [
        {
          projectUserId: data.projectUserId,
          roleId: data.roleId,
          userId: data.userId,
          userName: data.userName
        }
      ]
    }
    noticeApi(params).then((res) => {
      if (res) {
        message.success("提醒发送成功")
        queryList()
      }
    })
  }

  /**
   * @description: 重新发卷子
   * @return {*}
   * @author: LINA.QI
   */
 const handleAgain = (data) => {
    if (data.isDelete) {
      message.error("该成员已不在此项目中！")
      return
    }
    const params = {
      notice: data.notice,
      projectId: data.projectId,
      subjectId: data.subjectId,
      subjectName: data.subjectName,
      userList: [
        {
          projectUserId: data.projectUserId,
          roleId: data.roleId,
          userId: data.userId,
          userName: data.userName
        }
      ]
    }
    addSubjectUser(params).then((res) => {
      if (res) {
        message.success("发卷子成功")
        queryList()
      }
    })
  }

  return (
    <>
      <div className="project-exam">
        <div className="form-container">
          <Button
            type="primary"
            className="creation"
            onClick={handlerSendOutExam}
          >
            发卷子<i className="iconfont icon-fajuanzi1"></i>
          </Button>
          <div className="form-left">
            <Select
              showSearch
              allowClear
              placeholder="请选择科目"
              optionFilterProp="children"
              onChange={handleChangeSubject}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {subjectList.map((item, index) => (
                <Option key={item.id} value={item.id}>
                  {item.subjectName}
                </Option>
              ))}
            </Select>
            <Space>
              <Search
                placeholder="请输入考试人姓名"
                onSearch={onSearch}
                allowClear
              />
            </Space>
          </div>
        </div>
        <Table
          style={{ margin: "15px 0 10px 0" }}
          size="middle"
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          onChange={handleSort}
        />
        <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />
        <Modal
          title={dialogData.title}
          wrapClassName="custom-modal"
          open={isModalOpen}
          maskClosable={false}
          footer={null}
          onCancel={handleCancel}
          width={700}
          destroyOnClose={true}
        >
          {component === "SendOutExam" ? (
            <SendOutExam
              data={dialogData}
              isOpen={isModalOpen}
              confirmClick={handleCloseModal}
              cancelClick={handleCancel}
            />
          ) : (
            <AnswerDetails
              data={dialogData}
              isOpen={isModalOpen}
              cancelClick={handleCancel}
            />
          )}
        </Modal>
      </div>
    </>
  )
}
export default ProjectExam
