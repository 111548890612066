/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-07-26 09:07:39
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-08-04 09:39:47
 */
import { combineReducers } from "redux";
import appReducer from "./reducer/appReducer";
import projectTypeReducer from "./reducer/projectTypeReducer";
import userReducer from "./reducer/userReduce";

const reducer = combineReducers({
  appReducer,
  projectTypeReducer,
  userReducer,
});

export default reducer;
