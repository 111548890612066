import { axiosInstance } from "../../config";

//查询组件类型
export const getDeviceComponentsTypeList = () => {
  return axiosInstance.post("/devicecomponentstype/list")
};

//新增组件类型
export const addDeviceComponentsType = (params) => {
  return axiosInstance.post("/devicecomponentstype/add", params)
};

//删除组件类型
export const deleteDeviceComponentsType = (id) => {
  return axiosInstance.post(`/devicecomponentstype/delete/${id}`)
};

//设备组件分页查询
export const getDeviceComponentsList = (params) => {
  return axiosInstance.post("/devicecomponents/page", params)
};

//新增设备组件
export const addDeviceComponentsList = (params) => {
  return axiosInstance.post("/devicecomponents/add", params)
};

//修改设备组件
export const updateDeviceComponentsList = (params) => {
  return axiosInstance.post("/devicecomponents/update", params)
};

//删除设备组件
export const deleteDeviceComponentsList = (id) => {
  return axiosInstance.post(`/devicecomponents/delete/${id}`)
};

//查看组件参数
export const checkComponentParameter = (params) => {
  return axiosInstance.post(`/devicecomponents/searchParameter`, params)
};

//更新组件参数
export const updateComponentParameter = (params) => {
  return axiosInstance.post(`/devicecomponents/updateParameter`, params)
};