import { Cascader } from 'antd';
import { useEffect, useState } from 'react';
import { getSopProject } from "../../apis/deviceManage/common";
import "./index.scss";

const ProjectCascader = ({ width = 300 , onChange, selectAll, type = true }) => {
  const [options, setOptions] = useState([]);

  const triggerChange = (changedValue) => {
    onChange?.({
      ...changedValue,
    });
  };
  
  useEffect(() => {
    getSopProject().then(res => {
      setOptions(res.map(d => {
        return {
          label: d.sopName,
          value: d.rCode,
          isLeaf: false,
          children: d.projects.map(p => ({label: p.name, value: p.id}))
        }
      }))
    })
    .catch(() => {})
  },[])

  const onValueChange = (value) => {
    if(value && value.length > 1) {
      triggerChange({projectId:value[1]})
    } else {
      triggerChange({projectId:undefined})
    }
  };
  const filter = (inputValue, path) =>
  path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  // const loadData = (selectedOptions) => {
  //   const targetOption = selectedOptions[selectedOptions.length - 1];
  //   let params = {
  //     data: {
  //       rcode: targetOption.value,
  //       status: selectAll ?  undefined : 1
  //     },
  //     page:{
  //       pageIndex: 1,
  //       pageSize: 9999
  //     }
  //   }
  //   getProject(params).then(res => {
  //     targetOption.children = res.list.map(d => ({label: d.projectName, value: d.id}))
  //     setOptions([...options]);
  //   })
  // };
  const style = {
    width: width,
  }
  return <Cascader style={{width: width}} dropdownMenuColumnStyle={style} showSearch={{ filter }} options={options} onChange={onValueChange} placeholder="选择项目" />;
};
export default ProjectCascader;