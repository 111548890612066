import readSession from "@/utils/commonFunc/readSession";

export const hasPermission = (permissionCode) => {
  const permissionCodeList = Array.isArray(permissionCode) ? permissionCode : [permissionCode]
  // 获取用户所有菜单和权限
  const userMenu = readSession("user-menu") || [];
  // 获取当前所在父页面权限
  const topHash = "/" + window.location.hash.split("/")[1];
  let currentParentMenu = userMenu.find((menu) => menu.path === topHash);
  // 首页的path比较特殊不能和其他一起进行通用匹配 单独处理一下
  if (typeof currentParentMenu === "undefined") {
    currentParentMenu = userMenu.find((menu) => menu.path === "/");
  }
  // 父菜单下有子菜单
  const currentChildMenu = currentParentMenu.children.find(
    (item) => item.route === window.location.hash.slice(1)
  );
  const permissionList = currentChildMenu.meta.permission ? currentChildMenu.meta.permission.map(d => {return d.permission}) : [];
  return permissionCodeList.every(element => permissionList.includes(element));
};
