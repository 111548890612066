/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-07-22 06:20:33
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-08-13 11:42:30
 */
import store from "@/store";
import axios from "axios";
import {  message } from "antd"

export const baseUrl = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

// resquest拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().userReducer.token;
    config.headers = {
      authorization: token && `Bearer ${token}`,
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// response拦截器
axiosInstance.interceptors.response.use(
  (res) => {
    if (
      (res.data.code >= 200 && res.data.code <= 300) ||
      res.request.responseType === "blob"
    ) {
      return res.data.data || res.data;
    } else {
     message.error(res.data.msg)
      return Promise.reject(res.data.msg);
    }
  },
  (err) => {
    return Promise.reject(err.message || "Unknown Error");
  }
);

// response 拦截器
// axiosInstance.interceptors.response.use(
//   (response) => {
//     console.log("response.config", response);
//     // if (response.config.responseType === "blob") {
//     //   // 如果是文件流，直接过
//     //   return response;
//     // } else if (response.data.code >= 200 && response.data.code <= 300) {
//     //   return response.data;
//     // } else {
//     //   return Promise.reject(response.data.msg);
//     //   // ElMessage.error(response.data.message)
//     //   return response.data;
//     // }
//   }
//   // (error) => {
//   //   console.log("err" + error); // for debug
//   //   // ElMessage.error(error.message)
//   //   return Promise.reject(error);
//   // }
// );

export { axiosInstance };
