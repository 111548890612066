import { Button, Form, Input, message, Modal, Pagination, Select, Table } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { addDeviceInventory, deleteDeviceInventory, getDeviceInventoryData, getInventoryList, outInventory, updateDeviceInventory } from "../../apis/deviceManage/inventory/index";
import { getDeviceModelTree, getDeviceTypeTree } from "../../apis/deviceManage/type/index";
import OperateButton from "../../components/OperateButton";
import ProjectCascader from "../../components/ProjectCascader";
import EquipmentDetail from "../OnLine/components/EquipmentDetail";
import "./index.scss";


function SalaryLevelMgmt() {
  const [dataSource, setDataSource] = useState([]);
  const [deviceTypeSource, setDeviceTypeSource] = useState([]) //设备类型
  const [deviceModelSource, setDeviceModelSource] = useState([]) //设备型号
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [modalType, setModalType] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [searchData, setSearchData] = useState({
    deviceCategoryId: undefined,
    typeCode: undefined,
    stockTimeSort: undefined
  })
  const [deviceId, setDeviceId] = useState('')

  /**
   *
   * @description 查询列表方法
   */
   const queryList = useCallback((data = {}) => {
    let params = {
      data,
      page:{
        pageIndex: page,
        pageSize: size
      }
    }
    getInventoryList(params)
      .then((res) => {
        setTotal(res.total);
        setDataSource(res.list);
      })
      .catch((e) => message.error(e));
  },[page,size]);

  /**
   * @description 加载组件时先进行一次查询
   */
  // eslint-disable-next-line
  useEffect(() => {
    queryDeviceTypeList()
    queryDeviceModelList()
  },[])
  useEffect(() => queryList(searchData), [searchData,queryList]);
  useEffect(() => {
    queryDeviceModelList(searchData.deviceCategoryId) 
    setSearchData({
      ...searchData,
      typeCode: undefined
    })
    form.setFieldValue("typeCode",undefined)
  },[searchData.deviceCategoryId])

  /**
   * @description 点击查询按钮
   */
  const onFinish = () => {
    form.validateFields().then(values => {
      setSearchData({
        ...searchData,
        ...values,
      })
      setPage(1);
    })
  };

  /**
   *
   * @description 获取设备类型
   */
   const queryDeviceTypeList = () => {
    getDeviceTypeTree()
      .then((res) => {
        setDeviceTypeSource(res);
      })
      .catch((e) => message.error(e));
  };

  /**
   *
   * @description 获取设备型号
   */
   const queryDeviceModelList = (id) => {
    getDeviceModelTree({deviceCategoryId: id})
      .then((res) => {
        setDeviceModelSource(res);
      })
      .catch((e) => message.error(e));
  };

  /**
   * @description 创建设备
   */
  const handleAdd = () => {
    setModalTitle('创建设备(入库)')
    setModalType('create')
    setModalOpen(true)
  };

  /**
   * @description 删除二次弹框确认逻辑
   */
  const confirm = (id) => {
    deleteDeviceInventory(id)
      .then((res) => {
        if(res) {
          message.success("删除成功");
          onFinish()
        }
      })
      .catch((e) => message.error(e));
  };

  const columns = [
    {
      title: "云端设备号",
      dataIndex: "cloudDeviceNo",
      ellipsis: true,
    },
    {
      title: "设备序列号",
      dataIndex: "deviceNo",
      ellipsis: true,
    },
    {
      title: "设备类型",
      dataIndex: "categoryName",
      ellipsis: true,
    },
    {
      title: "设备型号",
      dataIndex: "typeName",
      ellipsis: true,
    },
    {
      title: "入库时间",
      dataIndex: "inOutTime",
      sorter: true,
      ellipsis: true,
      render: (text) => <span>{dayjs(text).format("YYYY-MM-DD HH:mm:ss")}</span> ,
    },
    {
      title: "操作",
      width: 190,
      dataIndex: "operation",
      render: (text, record) => (
        <div className="operation">
          <OperateButton icon="icon-eye" text="查看" click={() => checkModal(record, "check")}/>
          <OperateButton icon="icon-pencil" text="修改配置" click={() => updateModal(record, "update")}/>
          <OperateButton icon="icon-chuku" text="出库" click={() => outbound(record.id)}/>
          <OperateButton icon="icon-trash" text="删除" popType={true} title="确定删除该设备型号？" confirm={confirm} data={record}/>
        </div>
       
      ),
    },
  ];

  const saveModalData = (data,callback) => {
    let params = {}
    if(modalType === "create") {
      params = {
        ...data,
        deviceTypeId: data.deviceTypeId[1]
      }
      addDeviceInventory(params).then(res => {
        if(res) {
          message.success("创建成功")
          callback()
          onFinish()
        }
      })
      .catch((e) => message.error(e));
    } else {
      let params = {
        id: data.id,
        components: data.components
      }
      updateDeviceInventory(params).then(res => {
        if(res) {
          message.success("配置成功")
          callback()
          onFinish()
        }
      })
      .catch((e) => message.error(e));
    }
    
  }
  const checkModal = (data,type) => {
    getDeviceInventoryData(data.id).then(res => {
      setModalTitle('设备详情')
      setModalType(type)
      setModalData({
        ...res.deviceStockVo,
        data: res.deviceStockVo.components
      })
      setModalOpen(true)
    })
    .catch((e) => message.error(e));
  }
  const updateModal = (data,type) => {
    getDeviceInventoryData(data.id).then(res => {
      setModalTitle('修改配置')
      setModalType(type)
      setModalData({
        ...res,
        data:res.deviceStockVo.components
      })
      setModalOpen(true)
    })
    .catch((e) => message.error(e));
  }
  const closeModal = () => {
    outboundForm.resetFields()
    setModalOpen(false)
    setIsModalOpen(false)
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [outboundForm] = Form.useForm();
  const outbound = (id) => {
    setDeviceId(id)
    setIsModalOpen(true)
  }
  const handleOk = () => {
    outboundForm.validateFields().then(values => {
      let params = {
        ...values.projectId,
        remark: values.remark,
        deviceStockId: deviceId
      }
      outInventory(params).then(res => {
        if(res) {
          message.success("出库成功")
          closeModal()
          onFinish()
        }
      })
      .catch((e) => message.error(e));
    })
    .catch((e) => message.error(e));
  }

  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };
  const tableChange = (selectedRowKeys, selectedRows, info) => {
    setSearchData({
      ...searchData,
      stockTimeSort: info.order
    })
  }

  return (
    <div className="inventory-content">
      <div className="form-container">
        <Button
          onClick={handleAdd}
          type="primary"
          style={{
            background: '#1C64F2',
            color: "#fff",
            display: "flex"
          }}
        >
          创建设备(入库)<i className="iconfont icon-plus"></i>
        </Button>
        <Form form={form} layout="inline" onFinish={onFinish} colon={false}>
          <Form.Item name="deviceCategoryId" label="设备类型">
            <Select
              style={{
                width: 160,
              }}
              allowClear
              placeholder="选择类型"
              options={deviceTypeSource && deviceTypeSource.map(d => ({label: d.categoryName, value: d.id}))}
              onChange={onFinish}
            >
            </Select>
          </Form.Item>
          <Form.Item name="typeCode" label="设备型号" style={{marginLeft: "20px"}}>
            <Select
              style={{
                width: 160,
              }}
              allowClear
              placeholder="选择型号"
              options={deviceModelSource && deviceModelSource.map(d => ({label: d.typeName, value: d.typeCode}))}
              onChange={onFinish}
            >
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="table-container">
        <Table
          size="middle"
          rowClassName={() => "editable-row"}
          className="custom-table"
          rowKey={(record) => record.id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          onChange={tableChange}
        />
        {total === 0 ? undefined : <Pagination
          total={total}
          pageSize={size}
          current={page}
          pageSizeOptions={["10", "20", "30", "40", "50"]}
          showSizeChanger
          className="custom-pagination"
          onChange={handleChangePagination}
        />}
      </div>
      <EquipmentDetail
        modalTitle={modalTitle}
        isVersion={modalType === "check" ? true : false}
        modalType={modalType}
        modalData={modalData}
        modalOpen={modalOpen}
        saveModal={saveModalData}
        cancelModal={closeModal}
      />
      <Modal
        className="custom-modal"
        title="出库"
        width={413}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={closeModal}
        maskClosable={false}
        destroyOnClose={true}
        okText={<span><i className="iconfont icon-baocun"></i>保存</span>}
        cancelText={<span><i className="iconfont icon-x"></i>取消</span>}
      >
        <Form form={outboundForm} layout="inline"  colon={false}>
          <Form.Item
            name="projectId"
            rules={[
              {
                required: true,
              },
            ]}
            label="使用项目"
          >
              <ProjectCascader/>
          </Form.Item>
          <Form.Item
            name="remark"
            required
            label="出库备注"
          >
            <Input
              style={{width: 300}}
              maxLength={30}
              autoComplete="off"
              placeholder="可输入出库地址等信息"
            ></Input>
          </Form.Item>
        </Form>
      </Modal>

    </div>
  );
}

export default SalaryLevelMgmt;
