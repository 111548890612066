/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-20 10:12:29
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-09-19 15:52:43
 */
import { axiosInstance } from "../../config"

//查询任务状态
export const nodeStatusCount = () => {
  return axiosInstance.get("/projectNode/queryNodeStatusCount")
}

//任务分页列表
export const nodePage = (params) => {
  return axiosInstance.post("/projectNode/queryNodePageByLabelCode", params)
}

//获取节点下可选用户列表
export const userByNodeId = (nodeId) => {
  return axiosInstance.get(`/projectNode/getProjectUserByNodeId/${nodeId}`)
}

//转移任务
export const transferNode = (params) => {
  return axiosInstance.post("/projectNode/transferNode", params)
}

// 获取项目下我的所有任务列表
export const myTasksByProject = (params) => {
  return axiosInstance.post(`/projectworkhours/tasksByProject`, params)
}

// 获取我的任务填报时间
export const myTasksDate = () => {
  return axiosInstance.post(`/projectworkhours/fillInDate`)
}

// 获取我的任务内容
export const myTasksList = (params) => {
  return axiosInstance.post(`/projectworkhours/fillInPage`,params)
}

// 获取我的任务填报确认
export const myTasksComfirm= (params) => {
  return axiosInstance.post(`/projectworkhours/fillIn`, params)
}

// 获取项目版本下拉
export const getProjectVersion= (params) => {
  return axiosInstance.post(`project/versionSelect`, params)
}
