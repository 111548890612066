/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-08-11 13:11:38
 * @LastEditors: LINA.QI
 * @LastEditTime: 2024-07-31 17:44:42
 */
import NotFoundPage from "@/containers/NotFoundPage";
import { flatedMenu } from "@/menu";
import { flatMenu } from "@/utils/commonFunc/handleRoute";
import readSession from "@/utils/commonFunc/readSession";
import { Navigate, Route, useLocation } from "react-router-dom";

const key = "kc-token";

function GuardRouter() {
  let location = useLocation();
  let index = window.location.hash.indexOf("?");
  if (index < 0) {
    index = window.location.hash.length;
  }
  const pathName = window.location.hash.slice(1, index);
  const targetRouter = flatedMenu.find((item) => item.path === pathName);
  const userMenus = readSession("user-menu");
  const flatedSourceMenu = flatMenu(userMenus);
  let hasPermission = flatedSourceMenu.map((r) => r.route).filter((e) => e);
  hasPermission.push(
    "/operations-analysis/board/project-list",
    "/operations-analysis/board/project-list/project-detail",
    "/operations-analysis/efficiency/detail",
    "/mobile-workhour",
    "/mobile-exam",
    "/mobile-exam/page",
    "/examination"
  );
  if (targetRouter && targetRouter.redirect) {
    return (
      <Route
        exact
        path={location.pathname}
        element={<Navigate to={targetRouter.redirect} />}
      />
    );
  }

  if (!targetRouter) {
    return <Route exact path={location.pathname} element={<NotFoundPage />} />;
  }

  const hasToken = readSession(key);
  if (hasToken) {
    const auth = hasPermission.includes(pathName);
    if (auth) {
      return (
        <Route
          exact
          path={location.pathname}
          element={targetRouter.component}
        />
      );
    }
  }
}

export default GuardRouter;
