/*
 * @Author: gn
 * @Date: 2023-06-19 16:22:37
 * @LastEditors: gn
 * @LastEditTime: 2023-09-14 20:13:50
 * @Description: file content
 */
import { axiosInstance } from "../config";

//字典表
export function dictionariesList(data){
    return axiosInstance.post('/systemdict/list' , data);
}

//sop的table表格
export function sopPage(data){
    return axiosInstance.post('/sopbasicinfo/page' , data);
}

//复制
export function copeSop(data){
    return axiosInstance.post('/sopbasicinfo/copySop' , data)
}

//发布
export function releaseSop(data){
    return axiosInstance.post('/sopbasicinfo/releaseSop' , data);
}

//删除
export function deleteSop(data){
    return axiosInstance.post('/sopbasicinfo/delete' , data);
}

//禁用
export function disableSop(data){
    return axiosInstance.post('/sopbasicinfo/disableSop' , data);
}

//新增/修改基本信息
export function addSopBase(data){
    return axiosInstance.post('/sopbasicinfo/addBasicInfo' , data);
}

export function updataBase(data){
    return axiosInstance.post('/sopbasicinfo/updateBasicInfo' , data);
}

//查看基本信息
export function baseMessage(data){
    return axiosInstance.post('/sopbasicinfo/getBasicInfoById/'+data);
}

//文件权限
export function selectRoleList(data){
    return axiosInstance.post('/systemrole/selectSystemRoleList' , data);
}

//新增/修改sop文件列表
export function addFileList(data){
    return axiosInstance.post('/sopbasicinfo/addFileList',data);
}

//获取sop文件列表详情
export function fileMessage(params){
    return axiosInstance.post('/sopbasicinfo/getFileListById',params);
}

//获取随机id
export function stageId(){
    return axiosInstance.post('/sopbasicinfo/getId');
}

//新增sop节点
export function addStage(data){
    return axiosInstance.post('/sopbasicinfo/addStageAndNode' , data);
}

//获取sop节点详情
export function stageNode(data){
    return axiosInstance.post('/sopbasicinfo/getNodeById/' + data);
}

//查询节点输入-输出下拉框
export function fileSelectList(data){
    return axiosInstance.post('/sopbasicinfo/fileListSelect' , data);
}

//操作历史记录
export function historyList(data){
    return axiosInstance.post('/sopoperationshistory/sopOperationsHistoryList' , data);
}

//判断文件列表是否被sop节点引用
export function isTheFile(data){
    return axiosInstance.post('/sopbasicinfo/isTheFileReferenced' , data);
}

//所有节点输入/输出的下拉框
export function fileAllSelect(data){
    return axiosInstance.post('/sopbasicinfo/fileListAllSelect' , data);
}