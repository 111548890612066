/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-15 10:45:48
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-08-30 11:50:52
 * @FilePath: /pms-web/src/containers/CapacityEfficiency/components/Icon.js
 * @Description: svg-icon
 * params: {name: iconfont名称, color:颜色,width:宽,height:高, onClick点击事件}
 */
import { createFromIconfontCN } from "@ant-design/icons";
import React from "react";
function CustomIcon({ name, color = '', width = '', height = '', onClick = () => {}, cursor = '' }) {
  const MyIcon = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/c/font_4108972_2tz11in4ctt.js",
  });
  return (
    <MyIcon
      type={name}
      className="custom-icon"
      style={{ color, width, height, cursor }}
      onClick={onClick}
    />
  );
}
export default CustomIcon;
