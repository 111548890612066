/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-13 13:25:08
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-17 15:46:13
 * @FilePath: /pms-web/src/containers/CapacityEfficiency/efficiencyDetail.js
 * @Description: 产能人效-详情页
 */
import DeviceStatistic from "./components/deviceStatistic";
import EfficiencyAnalysis from "./components/efficiencyAnalysis";
import StaffStatistic from "./components/staffStatistic";
import { useSearchParams } from "react-router-dom";
import eventBus from "@/utils/commonFunc/eventBus";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { productByCode } from "../../apis/efficiency";
function EfficiencyDetail() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const productCode = searchParams.get("productCode");
  const tabList = ["产能分析", "按设备统计", "按人员统计"]; // tab列表
  const [activeIndex, setActiveIndex] = useState(0); // 点击索引
  const [productName, setProductName] = useState("");
  // 面包屑点击传来的值以及方法
  const breadcrumbItemClickEventsFn = useCallback(
    (item) => {
      console.log("items-analyzed", item);
      switch (item) {
        case "产能人效":
          eventBus.emit("setBreadcrumbData", ["运营分析", "产能人效"]);
          navigate("/operations-analysis/efficiency");
          break;
        default:
          return;
      }
    },
    [navigate]
  );

  // 根据产品code获取详情信息
  const getProductByCode = () => {
    productByCode(productCode).then((result) => {
      setProductName(result.productName);
      // setModeName(result.collaborativeModeDesc);
    });
  };

  useEffect(() => {
    getProductByCode();
    setTimeout(() => {
      eventBus.emit("setBreadcrumbData", ["运营分析", "产能人效", "详情"]);
    }, 200);
  }, []);

  useEffect(() => {
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    );
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      );
    };
  }, [breadcrumbItemClickEventsFn]);
  const handleChangeTab = (index) => {
    setActiveIndex(index);
  };
  return (
    <>
      <div className="efficiency-detail-container">
        <div className="tab-box">
          {tabList.map((item, index) => (
            <span
              key={index}
              className={[
                "tab-list",
                activeIndex === index ? "tab-active" : null,
              ].join(" ")}
              onClick={() => handleChangeTab(index)}
            >
              {item}
            </span>
          ))}
        </div>
        {activeIndex === 0 ? (
          <EfficiencyAnalysis
            productCode={productCode}
            productName={productName}
          />
        ) : activeIndex === 1 ? (
          <DeviceStatistic
            productCode={productCode}
            productName={productName}
          />
        ) : (
          <StaffStatistic productCode={productCode} productName={productName} />
        )}
      </div>
    </>
  );
}
export default EfficiencyDetail;
