/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-08-03 17:53:07
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-08-29 11:31:59
 */
import { getPermissionMenu, getUserAuthBySystemId, getUserInfo } from "@/apis/user";
import { routerFilter } from "@/utils/commonFunc/handleRoute";
import removeSession from "@/utils/commonFunc/removeSession";
import writeSession from "@/utils/commonFunc/writeSession";
import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SET_TOKEN, SET_USER_INFO } from "./store/constant";

const key = "kc-token";

async function getSystemAuth() {
  const res = await getUserAuthBySystemId();
  return res;
}

const AuthConfirm = ({ children }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const kcLogout = () => {
    return new Promise((resolve, reject) => {
      keycloak
        .logout()
        .then(() => {
          removeSession(key);
          removeSession("user-menu")
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getMenu = async () => {
    const res = await getPermissionMenu();
    const userMenus = routerFilter(res);
    writeSession("user-menu", userMenus);
  };

  //获取用户信息
  const getInfo = async () => {
    const { uuamUser } = await getUserInfo();
    writeSession("UserId", uuamUser.id)
    dispatch({ type: SET_USER_INFO, payload: uuamUser });
  };

  const isLoggedIn = keycloak.authenticated;
  if (isLoggedIn) {
    writeSession(key, keycloak.token);
    dispatch({ type: SET_TOKEN, payload: keycloak.token });

    getSystemAuth().then((auth) => {
      if (!auth) {
        const confirm = window.confirm("您不具有该系统访问权限！");
        if (confirm) {
          kcLogout();
        }
      } else {
        setInterval(() => {
          keycloak
            .updateToken(30)
            .then((refreshed) => {
              if (refreshed) {
                writeSession(key, keycloak.token);
                dispatch({ type: SET_TOKEN, payload: keycloak.token });
              }
            })
            .catch((error) => {
              console.log("Failed to refresh token", error);
            });
        }, 60000);
        window.location.hash = window.location.hash || "/";
        setShouldRender(true);
      }
    });
    getMenu();
    getInfo();
    return shouldRender ? children : null;
  } else {
    if (isLoggedIn !== undefined) {
      keycloak.clearToken();
      keycloak.login();
      //kcLogout();
    }
    removeSession(key);
    return;
  }
};

export default AuthConfirm;
