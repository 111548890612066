import eventBus from "@/utils/commonFunc/eventBus";
import {
  DatePicker, Form, Input, message,
  Pagination, Progress, Select,
  Table
} from "antd";
import dayjs from "dayjs";
import EChartsReact from "echarts-for-react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { querySelectList } from "../../apis/channelManage";
import { efficiencyList, efficiencyListPie } from "../../apis/efficiency";
import { getAllSop } from "../../apis/operationAnalysis";
import OperateButton from "../../components/OperateButton";
import "./index.scss";
const { Option } = Select;
const { RangePicker } = DatePicker;

function CostMgmt() {
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const { containerHeight } = useSelector((state) => state.appReducer);
  const [channelList, setChannelList] = useState([]);
  // 项目类型选择列表
  const [projectTypeList, setProjectTypeList] = useState([]);
  // 饼图数据
  const [pieDataList, setPieDataList] = useState([]);
  const navigate = useNavigate();
  const { Search } = Input;
  useEffect(() => {
    // 查询渠道选择列表
    querySelectList().then((res) => {
      const list = res?.map((item, index) => {
        const { id, personnelName } = item;
        return {
          name: personnelName,
          value: id,
          key: index,
        };
      });
      setChannelList(list);
    });
    // 查询所有sop项目类型
    getAllSop().then((res) => {
      const typeList = res?.map((item, index) => {
        const { rCode, sopName } = item;
        return {
          name: sopName,
          value: rCode,
        };
      });
      setProjectTypeList(typeList);
    });
  }, []);

  const getQueryParams = (isList = false) => {
    const { selectedChannel, selectProjectType, projectCodeOrName, time } =
      form.getFieldsValue();
    const queryParams = {};
    if (isList) {
      queryParams.data = {
        channel: selectedChannel,
        endTime: null,
        // productCode: "",
        productName: projectCodeOrName,
        projectId: selectProjectType,
        startTime: null,
      };
    } else {
      queryParams.channel = selectedChannel;
      queryParams.startTime = null;
      queryParams.endTime = null;
      queryParams.productName = projectCodeOrName;
      queryParams.projectId = selectProjectType;
    }
    queryParams.page = {
      pageIndex: page,
      pageSize: size,
    };
    if (!time) {
      return queryParams;
    }
    if (isList) {
      queryParams.data.startTime = dayjs(time && time[0])
        .startOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      queryParams.data.endTime = dayjs(time && time[1])
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
    } else {
      queryParams.startTime = dayjs(time && time[0])
        .startOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
      queryParams.endTime = dayjs(time && time[1])
        .endOf("date")
        .format("YYYY-MM-DD HH:mm:ss");
    }
    // eslint-disable-next-line
    return queryParams;
  };

  /**
   * @description 查询列表饼图
   */
  const queryListPie = useCallback(() => {
    const queryParams = getQueryParams();
    efficiencyListPie(queryParams).then((res) => {
      setPieData(res);
    });
  }, []);

  /**
   *
   * @description 查询列表方法
   */
  const queryList = useCallback(() => {
    const queryParams = getQueryParams(true);
    efficiencyList(queryParams)
      .then((res) => {
        setDataSource(res.list);
        setTotal(res.total);
      })
      .catch((e) => message.error(e));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    queryList();
  }, [page, size, queryList]);

  useEffect(() => {
    queryListPie();
  }, [queryListPie]);

  /**
   * @description 设置饼图数据
   */
  const setPieData = (result) => {
    let dataList = [];
    if (result) {
      dataList = result?.map((item, index) => {
        const { title, subTitle, dimensions, source, dataType } = item;
        return {
          projectName: title,
          dataType,
          options: {
            title: {
              text: subTitle,
              subtext: title,
              x: "center",
              y: "center",
              top: "65",
              textStyle: {
                fontWeight: 500,
                fontSize: 18,
                fontFamily: "Roboto",
                color: "#374151",
              },
              subtextStyle: {
                color: "#6B7280",
                fontFamily: "思源黑体 CN",
                fontSize: "12px",
              },
            },
            dataset: {
              source,
              dimensions,
            },
            series: [
              {
                labelLine: {
                  show: true,
                  length: 10,
                },
                type: item.chartType.toLowerCase(),
                color: ["#3884F5", "#FFAE4E"],
                label: {
                  show: true,
                  formatter(param) {
                    return param.value.project + "：" + param.value.number;
                  },
                  fontFamily: "思源黑体 CN",
                  fontSize: "12px",
                  color: "#6B7280",
                },
                radius: ["60%", "80%"],
              },
            ],
          },
        };
      });
    }
    setPieDataList(dataList);
  };

  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };

  useEffect(() => {
    queryList();
    // eslint-disable-next-line
  }, [page, size]);

  // 输入搜索e
  const onSearch = (value) => {
    setTimeout(() => {
      setPage(1);
      queryListPie();
      queryList();
    }, 100);
  };

  // 跳转详情页
  const goDetail = (record) => {
    setTimeout(() => {
      eventBus.emit("setBreadcrumbData", ["运营分析", "产能人效", "详情"]);
    }, 100);
    navigate(
      `/operations-analysis/efficiency/detail?productCode=${record.productCode}`
    );
  };

  const columns = [
    {
      title: "产品名称",
      dataIndex: "productName",
      ellipsis: true,
      render: (text) => {
        return (
          <>
            <div>{text ? text : "--"}</div>
          </>
        );
      },
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      ellipsis: true,
      render: (text) => {
        return (
          <>
            <div>{text ? text : "--"}</div>
          </>
        );
      },
    },
    {
      title: "协同人员",
      dataIndex: "collaborator",
      ellipsis: true,
    },
    {
      title: "设备数量",
      dataIndex: "deviceQuantity",
      ellipsis: true,
    },
    {
      title: "总投入",
      dataIndex: "total",
      ellipsis: true,
    },
    {
      title: "良品总数（良率）",
      dataIndex: "totalGoodProduct",
      ellipsis: true,
      render: (_, record) => {
        const percent = record.totalGoodProductDesc.slice(0, -1) || 0;
        const percentSrc = "（" + record.totalGoodProductDesc + "）";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {record.totalGoodProduct}
              {percentSrc}
              <Progress
                type="circle"
                percent={percent}
                size={16}
                strokeColor={color}
                className="custom-progress"
              />
            </div>
          </>
        );
      },
    },
    {
      title: "缺陷数量（不良率）",
      dataIndex: "totalBadProduct",
      ellipsis: true,
      render: (_, record) => {
        const percent = record.totalBadProductDesc.slice(0, -1) || 0;
        const percentSrc = "（" + record.totalBadProductDesc + "）";
        const color = percent >= 60 ? "#31C48D" : "#F3911A";
        return (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {record.totalBadProduct}
              {percentSrc}
              <Progress
                type="circle"
                percent={percent}
                size={16}
                strokeColor={color}
                className="custom-progress"
              />
            </div>
          </>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "operation",
      ellipsis: true,
      render: (text, record) => (
        <div className="operation">
          <OperateButton
            text="详情"
            icon="icon-clipboard-list"
            click={(record) => goDetail(record)}
            data={record}
          ></OperateButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="efficiency-page-container">
        <div className="form-chart-container">
          <div className="form-container">
            <Form form={form} layout="inline" colon={false}>
              <Form.Item
                name="selectedChannel"
                style={{ marginRight: 0 }}
                label="渠道:"
              >
                <Select
                  onChange={onSearch}
                  style={{ width: 108 }}
                  placeholder="全部渠道"
                  allowClear
                >
                  {channelList.map((item, index) => (
                    <Option value={item.value} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="selectProjectType"
                rules={[
                  {
                    max: 30,
                  },
                ]}
                label="项目类型:"
              >
                <Select
                  style={{
                    width: 108,
                  }}
                  showSearch
                  allowClear
                  onChange={onSearch}
                  placeholder="全部类型"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {projectTypeList.map((item, index) => (
                    <Option value={item.value} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="time" label="选择日期">
                <RangePicker
                  style={{ width: 254 }}
                  onChange={onSearch}
                  className="staff-range-picker"
                />
              </Form.Item>
              <Form.Item name="projectCodeOrName">
                <Search
                  placeholder="请输入编号或项目名称"
                  onSearch={onSearch}
                  allowClear
                  className="custom-capacity-search"
                />
              </Form.Item>
            </Form>
          </div>
          <div className="chart-container">
            {pieDataList.map((data, index) => (
              <>
                <div className="project-type-circle-container" key={index}>
                  <EChartsReact
                    style={{
                      width: "100%",
                      height: "100%",
                      // borderRight: index !== 2 ? "1px solid #e3e3e3" : "none",
                    }}
                    option={data.options}
                  />
                  {/* {data.projectName && ( */}
                  {/* <div className="project-name">{data.projectName}</div> */}
                  {/* )} */}
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="table-container">
          <Table
            size="middle"
            className="custom-table efficiency-table"
            rowKey={(record) => record.id}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={
              {
                // y: containerHeight - 56 - 24 * 2 - 56 * 2 - 32 - 56 - 24,
              }
            }
          />
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "40", "50"]}
            showSizeChanger
            showQuickJumper
            className="custom-pagination"
            style={{ marginTop: 20, paddingRight: 15 }}
            showTotal={(total) => `共 ${total} 条`}
            onChange={handleChangePagination}
          />
        </div>
      </div>
    </>
  );
}

export default CostMgmt;

