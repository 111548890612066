/*
 * @Author: huangjian 495797221@qq.com
 * @Date: 2023-06-08 10:50:43
 * @LastEditors: huangjian 495797221@qq.com
 * @LastEditTime: 2023-07-05 13:55:29
 * @FilePath: /pms-web/src/containers/ProjectBoard/components/projectTrend.js
 * @Description: 近12个月开展趋势折线图
 */
import * as echart from "echarts";
import EChartsReact from "echarts-for-react";
import { useEffect, useState } from "react";
function ProjectTrend({ dataList }) {
  const [trendOptions, setTrendOptions] = useState({});
  useEffect(() => {
    setTimeout(() => {
      const chart = document.getElementsByClassName("custom-trend-chart")[0];
      echart.init(chart).resize();
    }, 1);
    const legendData = [];
    const series = [];
    let xAxisData = [];
    dataList.forEach((data, index) => {
      const { title, source } = data;
      legendData[legendData.length] = title;
      series[series.length] = {
        name: title,
        type: "line",
        // stack: "Total",
        smooth: true,
        data: source.map((sourceItem, i) => {
          return sourceItem.number;
        }),
      };
      xAxisData = source.map((sourceItem) => {
        return sourceItem.day;
      });
    });
    setTrendOptions({
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: legendData,
        bottom: 20,
        icon: "roundRect",
        itemWidth: 12,
        itemHeight: 2,
      },
      color: ["#1C64F2", "#0694A2", "#9CA3AF"],
      grid: {
        left: "3%",
        right: "4%",
        bottom: "18%",
        top: "5%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: true,
        data: xAxisData,
      },
      yAxis: {
        type: "value",
      },
      series,
    });
  }, [dataList]);
  return (
    <>
      <div className="project-trend-container">
        <EChartsReact
          option={trendOptions}
          className="custom-trend-chart"
        ></EChartsReact>
      </div>
    </>
  );
}
export default ProjectTrend;
