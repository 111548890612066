import React from 'react'

const BaseInfo  = (props) => {
  return(
    <div className='base-info'>
      <div className='row'>
        <div className='col-name'><span className='iconfont icon-xingxing'></span>产品代码</div>
        <div className='col-value'>{props.baseData.productCode}</div>
      </div>
      <div className='row'>
        <div className='col-name'><span className='iconfont icon-xingxing'></span>产品名称</div>
        <div className='col-value'>{props.baseData.productName}</div>
      </div>
      <div className='row'>
        <div className='col-name'><span className='iconfont icon-xingxing'></span>协同模式</div>
        <div className='col-value'>{props.baseData.collaborativeModeDesc}</div>
      </div>
    </div>
  )
}

export default BaseInfo