/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-07-26 14:50:00
 * @LastEditors: pan.aiyan
 * @LastEditTime: 2023-07-19 17:32:27
 */
import { projectUserLevel } from "@/apis/projectCenter/projectManagement";
import {
  addProjectProduct,
  allSelsetData,
  productDevicePage,
  productModelPage,
  productOpticsSolutionPage,
  projectProductPage
} from "@/apis/projectOverview";
import eventBus from "@/utils/commonFunc/eventBus";
import readSession from "@/utils/commonFunc/readSession";
import {
  Button,
  Form,
  Input,
  Modal,
  Pagination,
  Select,
  Table,
  message
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { invalidInput } from "../../utils/commonFunc/invalidInput";
import {
  CheckSingleRequired,
  ENNumLimit
} from "../../utils/commonFunc/validate";
import ProductDetail from "./component/productDetail.js";
import "./index.scss";

function ProjectTypeMgmt() {
  const [form] = Form.useForm();
  const [formAdd] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceDetail, setDataSourceDetail] = useState([]);
  const [columnsDetail, setColumnsDetail] = useState([]);
  const [collaborativeModeSource, setCollaborativeModeSource] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sizeNum, setSizeNum] = useState(10);
  const [pageNum, setPageNum] = useState(1);
  const [totalNum, setTotalNum] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [isShow, setIsShow] = useState(true)
  const [detailData, setDetailData] = useState({})
  const { containerHeight } = useSelector((state) => state.appReducer);
  const [isMyLiable, setIsMyLiable] = useState(false)

  useEffect(() => {
    queryList(filterParams);
    projectUserLevel(readSession("sessionProjectId"))
      .then((res) => {
        setIsMyLiable([1,2].includes(res))
      })
      .catch((e) => console.log(e))
  }, [page, size]);
  useEffect(() => {
    fillterQueryNum(currentRow, modalTitle);
  }, [pageNum, sizeNum]);

  /**
   *
   * @description 查询列表方法
   */
  const queryList = () => {
    form.validateFields().then((values) => {
      const queryParams = {
        data: {
          projectId: readSession("sessionProjectId"),
        },
        page: {
          pageIndex: page,
          pageSize: size,
        },
      };
      queryParams.data.keyword = values.keyword;
      projectProductPage(queryParams)
        .then((res) => {
          setDataSource(res.list);
          setTotal(res.total);
        })
        .catch((e) => message.error(e));
    });
  };

  /**
   * @description 分页查询
   */
  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };
  /**
   * @description 分页查询各项数量数据
   */
  const handleChangePaginationNum = (page, pageSize) => {
    setPageNum(page);
    setSizeNum(pageSize);
  };

  /**
   * @description 点击查询按钮
   */
  const onFinish = (params) => {
    setFilterParams(params);
    setPage(1);
    queryList(params);
  };

  /**
   * @description 新增项目类型
   */
  const handleAdd = () => {
    allSelsetData({ categoryCode: "collaborative_mode_type" })
      .then((res) => {
        setCollaborativeModeSource(res);
        setModalTitle("新建产品");
        setIsAddVisible(true);
      })
      .catch((e) => message.error(e));
  };
  // 点击取消
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsAddVisible(false);
  };
  // 设备详情表格表头数据
  const deviceDetailsData = [
    {
      title: "设备序列号",
      dataIndex: "deviceNo",
      ellipsis: true,
    },
    {
      title: "产品编号",
      dataIndex: "productCode",
      ellipsis: true,
    },
    {
      title: "模型版本",
      dataIndex: "modelVersion",
      ellipsis: true,
    },
    {
      title: "模型更新时间",
      dataIndex: "completeTime",
      ellipsis: true,
      // sorter: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--";
      },
    },
  ];
  // 光学方案表格表头数据
  const OpticalSolutionData = [
    {
      title: "方案ID",
      dataIndex: "id",
      width: 85,
      ellipsis: true,
    },
    {
      title: "设备序列号",
      dataIndex: "deviceNo",
      width: 85,
      ellipsis: true,
    },
    {
      title: "产品编号",
      dataIndex: "productCode",
      width: 85,
      ellipsis: true,
    },
    {
      title: "位姿",
      dataIndex: "pos",
      width: 85,
      ellipsis: true,
    },
    {
      title: "光学面数",
      dataIndex: "opticalNum",
      width: 85,
      ellipsis: true,
    },
    {
      title: "更新时间",
      dataIndex: "commitTime",
      width: 85,
      ellipsis: true,
      // sorter: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--";
      },
    },
    {
      title: "调试人",
      dataIndex: "operator",
      width: 85,
      ellipsis: true,
    },
  ];

  // 模型详情表格表头数据ßß
  const ModelDetailsData = [
    {
      title: "模型版本",
      dataIndex: "modelVersion",
      width: 85,
      ellipsis: true,
    },
    {
      title: "产品编号",
      dataIndex: "productCode",
      width: 85,
      ellipsis: true,
    },
    {
      title: "部署机台",
      dataIndex: "deviceNos",
      width: 85,
      ellipsis: true,
    },
    {
      title: "模型完成时间",
      dataIndex: "completeTime",
      width: 85,
      ellipsis: true,
      // sorter: true,
      render: (text) => {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "--";
      },
    },
  ];

  // 点击数量
  const toCountModel = (row, name) => {
    setModalTitle(name);
    setCurrentRow(row);
    fillterQueryNum(row, name);
    setIsModalVisible(true);
  };
  const fillterQueryNum = (row, name) => {
    let params = {
      data: {
        projectId: row.projectId,
        projectProductId: row.id,
      },
      page: {
        pageSize: sizeNum,
        pageIndex: pageNum,
      },
    };

    switch (name) {
      case "设备数量":
        productDevicePageFn(params);
        setColumnsDetail(deviceDetailsData);
        break;
      case "光学数量":
        productOpticsSolutionPageFn(params);
        setColumnsDetail(OpticalSolutionData);
        break;
      case "模型数量":
        productModelPageFn(params);
        setColumnsDetail(ModelDetailsData);
        break;
      default:
        return;
    }
  };
  const productDevicePageFn = (params) => {
    productDevicePage(params)
      .then((res) => {
        setDataSourceDetail(res.list);
        setTotalNum(res.total);
      })
      .catch((e) => message.error(e));
  };

  const productOpticsSolutionPageFn = (params) => {
    productOpticsSolutionPage(params)
      .then((res) => {
        setDataSourceDetail(res.list);
        setTotalNum(res.total);
      })
      .catch((e) => message.error(e));
  };
  const productModelPageFn = (params) => {
    productModelPage(params)
      .then((res) => {
        setDataSourceDetail(res.list);
        setTotalNum(res.total);
      })
      .catch((e) => message.error(e));
  };
  const handleSave = () => {
    formAdd
      .validateFields()
      .then((values) => {
        let params = {
          ...values,
          projectId: readSession("sessionProjectId"),
        };
        addProjectProduct(params)
          .then((res) => {
            setPage(1);
            setIsAddVisible(false);
            queryList();
          })
          .catch((e) => message.error(e));
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };
  // const tableChange = (selectedRowKeys, selectedRows, info) => {
  //   setSortType(info.order === "ascend" ? "asc" : "desc");
  // };
  // 面包雪点击传来的值以及方法
  const breadcrumbItemClickEventsFn = (item) => {
    switch (item) {
      case "项目产品":
        setIsShow(true)
        eventBus.emit("setBreadcrumbData", ["项目空间", "项目产品"]);
        break;
      default:
        return;
    }
  };
  const goDetail = (data) => {
    setDetailData(data);
    eventBus.emit("setBreadcrumbData", ["项目空间", "项目产品", "产品详情"]);
    setIsShow(false)
  }
  
  const goBack = () => {
    setIsShow(true)
  }

  useEffect(() => {
    eventBus.addListener(
      "breadcrumbItemClickEvents",
      breadcrumbItemClickEventsFn
    );
    return () => {
      eventBus.removeListener(
        "breadcrumbItemClickEvents",
        breadcrumbItemClickEventsFn
      );
    };
  }, [breadcrumbItemClickEventsFn]);

  const columns = [
    {
      title: "产品代码",
      dataIndex: "productCode",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "协同模式",
      dataIndex: "collaborativeModeDesc",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "样本数量",
      dataIndex: "projectNum",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "OK样本数量",
      dataIndex: "okSampleNum",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "NG样本数量",
      dataIndex: "ngSampleNum",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "限度样数量",
      dataIndex: "limitationSampleNum",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>,
    },

    {
      title: "设备数量",
      dataIndex: "deviceNum",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div
            className="blue-text"
            onClick={() => toCountModel(record, "设备数量")}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "光学数量",
      dataIndex: "opticsSolutionNum",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div
            className="blue-text"
            onClick={() => toCountModel(record, "光学数量")}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "模型数量",
      dataIndex: "modelNum",
      ellipsis: true,
      render: (text, record) => {
        return (
          <div
            className="blue-text"
            onClick={() => toCountModel(record, "模型数量")}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "operate",
      render: (_, record) => {
        return (
          <span className="operate" onClick={() => goDetail(record)}>详情</span>
        )
      }
    }
  ];
  return (
    <>
      {isShow ? <div className="product-page">
        <div>
          <Form
            layout="inline"
            form={form}
            onFinish={onFinish}
            colon={false}
            className="form-container"
          >
            <Form.Item label="" name="name">
              {isMyLiable && <Button
                type="primary"
                className="creation"
                onClick={() => {
                  handleAdd();
                }}
              >
                新建产品
                <i className="iconfont icon-plus-sm"></i>
              </Button>}
            </Form.Item>
            <Form.Item label="" name="keyword">
              <Input
                autoComplete="off"
                placeholder="请输入产品代码"
                style={{ width: 354, height: 32 }}
                onChange={(e) => {
                  if (invalidInput(e.nativeEvent.data)) {
                    form.setFieldsValue({
                      keyword: e.target.value.slice(0, -1),
                    });
                  }
                }}
                suffix={
                  <i
                    className="iconfont icon-search"
                    onClick={() => {
                      queryList();
                    }}
                  ></i>
                }
              />
            </Form.Item>
          </Form>
        </div>
        <div className="table-container">
          <Table
            size="middle"
            className="custom-table"
            rowKey={(record) => record.index}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            pageSizeOptions={["10", "20", "30", "40", "50"]}
            showSizeChanger
            // showQuickJumper
            className="custom-pagination"
            // showTotal={(total) => `共 ${total} 条`}
            onChange={handleChangePagination}
          />
        </div>

        <Modal
          title={modalTitle}
          centered
          width={700}
          wrapClassName="custom-modal"
          visible={isModalVisible}
          maskClosable={false}
          onCancel={() => {
            handleCancel();
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                handleCancel();
              }}
            >
              <i className="iconfont icon-reply"></i>
              返回
            </Button>,
          ]}
        >
          <Table
            style={{ margin: "20px 0" }}
            className="custom-table"
            rowKey={(record) => record.id}
            size="middle"
            // onChange={tableChange}
            dataSource={dataSourceDetail}
            columns={columnsDetail}
            pagination={false}
            scroll={{
              y: containerHeight - 56 - 24 * 2 - 32 - 24 - 32 - 32 - 60,
            }}
          />
          {dataSourceDetail && dataSourceDetail.length > 0 && (
            <Pagination
              total={totalNum}
              pageSize={sizeNum}
              current={pageNum}
              pageSizeOptions={["10", "20", "30", "40", "50"]}
              showSizeChanger
              showQuickJumper
              className="custom-pagination"
              showTotal={(totalNum) => `共 ${totalNum} 条`}
              onChange={handleChangePaginationNum}
            />
          )}
        </Modal>
        <Modal
          title={modalTitle}
          centered
          width={400}
          wrapClassName="custom-modal"
          visible={isAddVisible}
          maskClosable={false}
          onOk={() => handleSave()}
          onCancel={() => handleCancel()}
          okText={
            <span>
              <i className="iconfont icon-save2"></i>保存
            </span>
          }
          cancelText={
            <span>
              <i className="iconfont icon-x"></i>返回
            </span>
          }
        >
          <Form
            layout="inline"
            form={formAdd}
            onFinish={() => {
              onFinish();
            }}
            colon={false}
          >
            <Form.Item
              label="产品代码："
              name="productCode"
              rules={[...ENNumLimit("产品代码", 30)]}
            >
              <Input
                autoComplete="off"
                placeholder="请输入产品代码"
                style={{ width: 265 }}
                onChange={(e) => {
                  if (invalidInput(e.nativeEvent.data)) {
                    form.setFieldsValue({
                      productCode: e.target.value.slice(0, -1),
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="产品名称："
              name="productName"
              rules={[...ENNumLimit("产品名称", 30)]}
            >
              <Input
                autoComplete="off"
                placeholder="请输入产品名称"
                style={{ width: 265 }}
                onChange={(e) => {
                  if (invalidInput(e.nativeEvent.data)) {
                    form.setFieldsValue({
                      productName: e.target.value.slice(0, -1),
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="协同模式："
              name="collaborativeMode"
              rules={[...CheckSingleRequired("协同模式")]}
            >
              <Select
                style={{ width: 265 }}
                allowClear
                placeholder="选择协同模式"
                options={collaborativeModeSource.map((d) => ({
                  label: d.dictDesc,
                  value: d.dictCode,
                }))}
              ></Select>
            </Form.Item>
          </Form>
        </Modal>
      </div> : <ProductDetail detailData={detailData} goBack={goBack}/>}
    </>
  );
}

export default ProjectTypeMgmt;
