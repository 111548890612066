import { addProductComponent, getProductComponent } from "@/apis/projectOverview";
import ParameterTable from '@/containers/OnLine/components/ParameterTable';
import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { projectUserLevel } from "@/apis/projectCenter/projectManagement";
import readSession from "@/utils/commonFunc/readSession";

const ProductComponent  = (props) => {
  const { baseData } = props
  const [parameterSource, setParameterSource] = useState([])
  const [isMyLiable, setIsMyLiable] = useState(false)
  useEffect(() => {
    getProductComponent(baseData.id).then(res => {
      setParameterSource(res.components ? res.components.map((d,idx) => {
        return {
          ...d,
          key: idx
        }
      }) : [])
    })
    projectUserLevel(readSession("sessionProjectId"))
      .then((res) => {
        setIsMyLiable([1,2].includes(res))
      })
      .catch((e) => console.log(e))
  },[props])
  const getSource = (data) => {
    setParameterSource(data)
  }

  const confirm = () => {
    let params = {
      projectProductId: baseData.id,
      components: parameterSource.map(d => ({id: d.id, componentParameter: d.componentParameter}))
    }
    addProductComponent(params).then(res => {
      if(res) {
        message.success("保存成功")
      }
    })
    .catch(() => {})
  }

  const goBack = () => {
    props.goBack()
  }
  return(
    <>
      <ParameterTable parameterSource={parameterSource} modalType={''} getSource={getSource} width={700}/>
      {isMyLiable && <div className="foot">
        <Button onClick={goBack}><i className="iconfont icon-x"></i>取消</Button>
        <Button type="primary" onClick={confirm}><i className="iconfont icon-baocun"></i>保存</Button>
      </div>}
    </>
  )
}

export default ProductComponent