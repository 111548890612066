/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-09 14:43:41
 * @LastEditors: LINA.QI
 * @LastEditTime: 2023-06-28 13:40:03
 */
import React from 'react';
import './index.scss';

function StateTab(props) {
  const { tabList, title, checkStatus, changeClick } = props;
  const colorObj = {
    999: {
      background: require('@/assets/img/projectcenter/blue-bg.png'),
      activeBg: require('@/assets/img/projectcenter/blue-bg.png'),
      color: '#A4CAFE',
    },
    0: {
      background: require('@/assets/img/projectcenter/tiffany.png'),
      activeBg: require('@/assets/img/projectcenter/tiffany-active.png'),
      color: '#AFECEF',
    },
    1: {
      background: require('@/assets/img/projectcenter/green.png'),
      activeBg: require('@/assets/img/projectcenter/green-active.png'),
      color: '#BCF0DA',
    },
    2: {
      background: require('@/assets/img/projectcenter/purple.png'),
      activeBg: require('@/assets/img/projectcenter/purple-active.png'),
      color: '#E2CDFF',
    },
    3: {
      background: require('@/assets/img/projectcenter/gray.png'),
      activeBg: require('@/assets/img/projectcenter/gray-active.png'),
      color: '#E5E7EB',
    },
    4: {
      background: require('@/assets/img/projectcenter/red.png'),
      activeBg: require('@/assets/img/projectcenter/red-active.png'),
      color: '#FBD5D5',
    },
    5: {
      background: require('@/assets/img/projectcenter/finish.png'),
      activeBg: require('@/assets/img/projectcenter/finish-active.png'),
      color: '#A4CAFE',
    },
  };

  return (
    <>
      <div className="state-tab">
        <p className="title-box">
          <img
            alt=""
            className="title-icon"
            src={require('@/assets/img/iconFill/tabIcon.png')}
          />
          {title}
        </p>
        <div className="state-box">
          {tabList.map((item, index) => (
            <div
              className={`single-box ${checkStatus === item.status ? 'active-box' : ""}`}
              style={{
                width:
                  tabList.length === 7
                    ? Math.ceil(100 / tabList.length) - 1.5 + '%'
                    : Math.ceil(100 / tabList.length) - 1 + '%',
              }}
              key={item.status}
              onClick={() => changeClick(item.status)}
            >
              <div
                className="below-bg"
                style={{
                  backgroundImage:
                    checkStatus === item.status
                      ? `url(${colorObj[item.status].activeBg})`
                      : `url(${colorObj[item.status].background})`,
                  backgroundSize: '100% 100%',
                }}
              ></div>
              <div
                className={checkStatus === item.status ? 'activeBg' : 'up-bg'}
              >
                <p className="up-num">{item.num}</p>
                <p className="up-ch">{item.name}</p>
              </div>
              <div
                className="blur"
                style={{ background: colorObj[item.status].color }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default StateTab;
