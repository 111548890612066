// 必填项
export const NameRequired = (name = "") => {
  return [
    {
      required: true,
      message: `${name}不能为空`,
      trigger: ["change", "blur"]
    }
  ]
}
// 必填项
export const NameRequiredFill = (name = "") => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    }
  ]
}
// 非必填长度限制
export const FalseLengthRange = (min, max) => {
  return [
    {
      min,
      max,
      required: false,
      message: `字符长度${min}-${max}之间`,
      trigger: ["change", "blur"]
    }
  ]
}
// 长度限制
export const LengthRange = (min, max) => {
  return [
    {
      min,
      max,
      required: true,
      message: `字符长度${min}-${max}之间`,
      trigger: ["change", "blur"]
    }
  ]
}
// 长度限制
export const LengthRangeName = (name, min, max) => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },
    {
      min,
      max,
      message: `字符长度${min}-${max}之间`,
      trigger: ["change", "blur"]
    }
  ]
}

// 小数
export const RulesOnlyPencel = [
  {
    pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/g,
    message: "只能填写正数",
    required: true,
    trigger: "change"
  }
]
export const RulesOnlyPencel2 = [
  {
    // pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{2})$/g,
    pattern: /^(([0-9]\d*))(\.\d{1,2})?$/g,
    message: "最多保留两位小数",
    required: false,
    trigger: "change"
  }
]
// 正整数
export const RulesOnlyNumber = [
  {
    pattern: /^[0-9]\d*$/g,
    message: "只能填写整数",
    required: false,
    trigger: "change"
  }
]

// 数字框检验
export const NameAndRulesOnlyNumber = (name) => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },
    {
      pattern: /^[0-9]\d*$/g,
      message: "只能填写整数",
      required: true,
      trigger: "change"
    }
  ]
}
// 校验字符长度
export const NameAndRulesOnlyNumberLength = (name) => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },
    {
      // pattern: /^(?:[0-9]|[1-9][0-9]|[1-9][0-9][0-9]|3600)$/,
      message: "只能填写整数，且取值范围1-3600",
      required: true,
      trigger: ["change", "blur"]
    }
  ]
}
// 校验字符长度
export const NameAndRulesOnlyNumberTime = (name) => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },

    {
      pattern: /^\d{1,}$/g,
      message: "只能为正整数",
      trigger: ["blur", "change"]
    }
    // {
    //   validator: validateArea,
    //   trigger: ["blur", "change"],
    // },
  ]
}
// 校验字符长度
export const CTtime = (name) => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },

    {
      pattern: /^\d{1,}$/g,
      message: `${name}必须大于0`,
      trigger: ["blur", "change"]
    }
  ]
}
// 校验字符长度
export const CTtimeBash = (name) => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },

    {
      pattern: /^\d{0,}$/g,
      message: `${name}必须大于0`,
      trigger: ["blur", "change"]
    }
  ]
}
// 校验字符长度
export const CTtime100 = (name) => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },

    {
      validator: validateArea100,
      trigger: ["blur", "change"]
    }
  ]
}
// 进度长度
export const percent100 = (name) => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },

    {
      validator: validateArealimit100,
      trigger: ["blur", "change"]
    },
    {
      pattern: /^[0-9]\d*$/g,
      message: "只能填写整数",
      trigger: "change"
    }
  ]
}

// 今日工时
export const todayLeftWorkHours = (leftHours) => {
  return [
    {
      required: true,
      validator: (_, value) => {
        if (!value && value !== 0) {
          return Promise.reject(new Error(`请输入今日工时`))
        }
        return Promise.resolve()
      },
      trigger: ["change", "blur"]
    }
  ]
}

let validateArea100 = (rule, value, callback) => {
  if (value < 100) {
    callback(new Error("只能填写整数，且取值范围必须大于100"))
  } else {
    callback()
  }
}
let validateArealimit100 = (rule, value, callback) => {
  if (value > 100) {
    callback(new Error("只能填写整数，且取值范围必须小于100"))
  } else {
    callback()
  }
}
// Mac地址校验
export const NameAndRulesOnlyMAC = (name) => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },
    {
      pattern:
        /[A-F\d]{2}:[A-F\d]{2}:[A-F\d]{2}:[A-F\d]{2}:[A-F\d]{2}:[A-F\d]{2}/,
      message: "请输入正确MAC地址",
      required: true,
      trigger: ["change", "blur"]
    }
  ]
}

// 校验字符长度
export const NameAndRulesOnlyNumTimes = (name) => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },
    {
      pattern: /^(?:1|2|3)$/,
      message: "只能填写整数，且取值范围1-3",
      required: true,
      trigger: ["change", "blur"]
    }
  ]
}

// 仅支持中英文
export const CNEN = (name = "") => {
  return [
    {
      message: `${name}仅支持中英文`,
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z\-\u4e00-\u9fa5]+$/
    }
  ]
}

// 仅支持中文
export const CN = (name = "") => {
  return [
    {
      required: true,
      message: `${name}仅支持中文`,
      trigger: ["blur", "change"],
      pattern: /^[\u4e00-\u9fa5]+$/
    }
  ]
}
// 仅支持英文
export const EN = (name = "") => {
  return [
    {
      required: true,
      message: `${name}仅支持英文`,
      trigger: ["change"],
      pattern: /^[a-zA-Z]+$/
    }
  ]
}
// 仅支持中英文数字下划线
export const CNENNum_ = (name = "") => {
  return [
    {
      message: `${name}仅支持中英文数字下划线`,
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/
    }
  ]
}
// 仅支持中英文数字下划线
export const ENNum = (name = "") => {
  return [
    {
      message: `${name}仅支持英文，数字`,
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z0-9]+$/
    }
  ]
}
// 仅支持英文数字下划线
export const ENNumLimit = (name = "", num) => {
  return [
    {
      required: true,
      message: `${name}仅支持英文，数字`,
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z0-9]+$/
    },
    num && {
      min: 1,
      max: num,
      message: `${name}仅支持中英文数字，下划线，横线，长度<=${num}`,
      trigger: "change"
    }
  ]
}
// 仅支持中英文数字下划线横线
export const NameNotSpecialChar = (name = "", num, required = false) => {
  return [
    {
      required: required,
      message: `请输入${name}`,
      trigger: ["blur", "change"]
    },
    {
      message: `仅支持输入中英文、数字、-、_，长度<=${num}`,
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z0-9_\-\u4e00-\u9fa5]+$/
    },
    num && {
      min: 1,
      max: num,
      message: `${name}仅支持中英文数字，下划线，横线，长度<=${num}`,
      trigger: "change"
    }
  ]
}

// 仅支持中英文数字
export const NameNormalSpecialChar = (name = "") => {
  return [
    {
      message: `${name}仅支持中英文数字`,
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z0-9\u4e00-\u9fa5]+$/
    }
  ]
}
// 仅支持中英文数字
export const NameNormalSpecialCharMac = (name = "") => {
  return [
    {
      message: `${name}仅支持英文，数字，：`,
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z0-9:]+$/
    }
  ]
}
// 仅支持英文数字下划线横线
export const NameAddressSpecialChar = (name = "", num) => {
  return [
    {
      message: `${name}仅支持英文数字，下划线，横线`,
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z0-9_\-://.]+$/
    },
    num && {
      min: 1,
      max: num,
      required: true,
      message: `${name}仅支持英文数字，下划线，横线，长度<=${num}`,
      trigger: "change"
    }
  ]
}
// 仅支持英文数字下划线横线
export const NameAddressSpecialChar1 = (num) => {
  return [
    {
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z0-9_\-://.]+$/
    },
    num && {
      min: 1,
      max: num,
      required: true,
      message: `仅支持英文数字，下划线，横线，长度<=${num}`,
      trigger: "change"
    }
  ]
}
// 仅支持英文数字下划线横线小数点
export const NameAddressSpecialPoint = (name = "", num) => {
  return [
    {
      message: `${name}仅支持英文数字，下划线，横线和小数点`,
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z0-9_\-.]+$/
    },
    num && {
      min: 1,
      max: num,
      required: true,
      message: `${name}仅支持英文数字，下划线，横线，长度<=${num}`,
      trigger: "change"
    }
  ]
}
// 仅支持英文数字下划线横线,长度<=32
export const NameAddressSpecialCharLength = (name = "") => {
  return [
    {
      message: `${name}仅支持英文数字，下划线，横线，长度<=32`,
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z0-9_\-://.]+$/
    },
    {
      min: 1,
      max: 32,
      required: true,
      message: `${name}仅支持英文数字，下划线，横线，长度<=32`,
      trigger: "change"
    }
  ]
}

// 仅支持英文数字下划线横线,长度<=120
export const LongNameSpecialCharLength = (name = "") => {
  return [
    {
      message: `${name}仅支持英文数字，下划线，横线，长度<=120`,
      trigger: ["blur", "change"],
      pattern: /^[a-zA-Z0-9_\-://.]+$/
    },
    {
      min: 1,
      max: 120,
      required: true,
      message: `${name}仅支持英文数字，下划线，横线，长度<=120`,
      trigger: "change"
    }
  ]
}

// export const NotSpecialChar = (name = "") => {
//   return [
//     {
//       message: `${name}不支持特殊字符`,
//       trigger: ["blur", "change"],
//       pattern:
//         /^[^`~!@#$%^&*+=<>?:"{}|,\/;'\\[\]·~！@#￥%……&*——+={}|《》？：“”【】、；‘'，。、]+$/,
//     },
//   ];
// };
// checkbox必选
export const CheckboxRequired = (name = "") => {
  return [
    {
      type: "array",
      required: true,
      message: `请至少选择一个${name}`,
      trigger: "change"
    }
  ]
}
// 单选
export const CheckSingleRequired = (name = "") => {
  return [
    {
      required: true,
      message: `请选择${name}`,
      trigger: ["blur", "change"]
    }
  ]
}
// 单选
export const selectRequired = (name = "") => {
  return [
    {
      required: true,
      message: `请选择${name}`,
      trigger: ["blur", "change"]
    }
  ]
}

//上传文件
export const RuleFile = [
  {
    message: "请上传文件",
    required: true,
    trigger: ["blur", "change"]
  }
]
//校验手机号
export const RulesTelphone = [
  {
    pattern: /^1[3456789]\d{9}$/,
    message: "请填写正确手机号",
    required: true,
    trigger: ["blur", "change"]
  }
]

// 邮箱校验
export const RuleEmail = [
  {
    pattern:
      /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
    message: "邮箱格式不正确"
  },
  {
    max: 50,
    message: "邮箱不得超过50字符"
  }
]

//校验英文数字
export const RuleVersion = (name, max) => {
  return [
    {
      pattern: /^[a-zA-Z0-9]+$/,
      message: `${name}仅支持英文数字，长度<=${max}`,
      required: true,
      trigger: ["change"]
    },
    {
      min: 1,
      max,
      required: true,
      message: `${name}仅支持英文数字，长度<=${max}`,
      trigger: "change"
    }
  ]
}

//校验身份证号
export const RuleIDNumber = [
  {
    pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
    message: `请输入正确身份证号`,
    required: true,
    trigger: ["blur", "change"]
  }
]

// 检验IP地址
export const RuleIPAddress = [
  {
    pattern:
      /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
    message: `请输入正确IP地址`,
    trigger: ["blur", "change"]
  }
]

// 校验端口
export const RulePort = [
  {
    pattern:
      /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/,
    message: `请输入正确端口号`,
    required: true,
    trigger: ["blur", "change"]
  }
]

// 请输入英文字母，数字，符号
// export const RulesOnlyNumberCharacter = (name) => {
//   return [
//     {
//       required: true,
//       message: `请输入${name}`,
//       trigger: ["change", "blur"],
//     },

//     {
//       pattern:
//         /^(?![0-9]+$)(?![` ~!@#$%^&*()\+=<>?:"、{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】；‘'，。]+$)/,
//       message: "请输入英文字母，数字，符号",
//       required: true,
//       trigger: "change",
//     },
//     { min: 1, max: 32, message: "长度小于32", trigger: "change" },
//   ];
// };

export const RulesPassword = [
  {
    pattern: /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/,
    message: "密码为字母、数字、字符的两种组合",
    required: true,
    trigger: "change"
  },
  {
    min: 6,
    max: 16,
    message: "长度不少于6个字符不多于16个字符",
    trigger: "change"
  }
]
// 设备sn
export const RulesOnlySN = [
  {
    pattern: /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/,
    message: "SN码为字母、数字、字符组合",
    required: true,
    trigger: "change"
  },
  {
    min: 1,
    max: 32,
    message: "长度<=32",
    trigger: "change"
  }
]

//校验版本号
export const RuleVersionLength = (name = "") => {
  return [
    {
      pattern: /^\d+(\.\d+)*$/,
      message: `${name}仅支持数字、.，长度<=8`,
      required: true,
      trigger: ["change"]
    },
    {
      min: 1,
      max: 8,
      required: true,
      message: `${name}仅支持数字、.，长度<=8`,
      trigger: "change"
    }
  ]
}

//判读是否为外链
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
// 必填项
export const NameRequiredFillPointPosName = (name = "") => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },
    {
      pattern: /^[a-zA-Z0-9]+$/,
      message: `${name}仅支持英文数字`,
      trigger: ["change", "blur"]
    }
  ]
}
// 必填项
export const NameRequiredFillValue = (name = "") => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },
    {
      pattern: /^[0-9]\d*$/g,
      message: "只能填写整数",
      trigger: ["change", "blur"]
    }
  ]
}
// 必填项
export const messageTemplate = (name = "") => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },
    {
      min: 1,
      max: 120,
      required: true,
      message: `${name}仅支持英文数字,长度<=120`,
      trigger: "change"
    }
  ]
}

//小于10的正整数
export const RulesNumberTen = [
  {
    pattern: /^(?:[1-9]|10)$/,
    message: "只能填写整数，且取值范围1-10",
    trigger: ["change", "blur"]
  }
]
//小于100的正整数
export const RulesNumberHundred = [
  {
    pattern: /^(?:[1-9]|[1-9][0-9]|100)$/,
    message: "只能填写整数，且取值范围1-100",
    trigger: ["change", "blur"]
  }
]
//小于1000的正整数
export const RulesNumberThousand = [
  {
    pattern: /^(?:[1-9]|[1-9][0-9]|[1-9][0-9][0-9]|1000)$/,
    message: "只能填写整数，且取值范围1-1000",
    trigger: ["change", "blur"]
  }
]
// let validateArea = (rule, value, callback) => {
//   if (value > 10001 || value < 1) {
//     callback(new Error("只能填写整数，且取值范围1-10000"));
//   } else {
//     callback();
//   }
// };

export const StanderRequired = () => {
  return [
    {
      validator: validateNumber,
      trigger: ["blur", "change"]
    }
  ]
}
let validateNumber = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入检测标准"))
  } else {
    callback()
  }
}
// 必填项
export const StanderDesc = (name = "") => {
  return [
    {
      required: true,
      message: `请输入${name}`,
      trigger: ["change", "blur"]
    },
    {
      min: 1,
      max: 16,
      required: true,
      message: `${name}输入中英文字符、符号、数字，长度<=16`,
      trigger: "change"
    }
  ]
}
//验证网址
export const StanderUrl = () => {
  return [
    {
      pattern:
        /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/g,
      message: "请填写正确的url",
      trigger: ["change", "blur"]
    }
  ]
}
