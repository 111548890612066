/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-08-03 15:47:18
 * @LastEditors: Wang Yulin
 * @LastEditTime: 2022-08-03 18:06:01
 */
const writeSession = (key, value) => {
  if (value) {
    value = JSON.stringify(value);
  }
  sessionStorage.setItem(key, value);
};

export default writeSession;
