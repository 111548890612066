import { axiosInstance } from "../config";

//项目类型
export const getProjectType = () => {
  return axiosInstance.post("/sopbasicinfo/selectPublishedAndDisableSop")
};

//项目类型
export const getOwnProjectType = () => {
  return axiosInstance.post("/sopbasicinfo/selectPublishedSopProjectUse")
};

//项目类型下的项目
export const getProject = (params) => {
  return axiosInstance.post("/project/page", params)
};

//sop和项目树结构
export const getSopProject = () => {
  return axiosInstance.post("/sopbasicinfo/sopTreeProject")
};

//下载文件
export const download = (url) => {
  return axiosInstance.get(`/file/minio/download${url}`)
};