/*
 * @Author: gn
 * @Date: 2023-06-05 18:07:59
 * @LastEditors: gn
 * @LastEditTime: 2023-07-19 17:31:47
 * @Description: file content
 */
import { copeSop, deleteSop, dictionariesList, disableSop, releaseSop, sopPage } from "@/apis/systemMgmt/SOPManagement";
import OperateButton from '@/components/OperateButton';
import {
  Button, message,
  Pagination, Select,
  Table
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HistoryChildren from "./components/HistoryChildren";
import TabsChildren from "./components/TabsChildren";
import "./index.scss";
import eventBus from "@/utils/commonFunc/eventBus";




function SOPManagement() {
  const [editStatus, setEditStatus] = useState('list');
  const [sopStatus, setSopStatus] = useState(null);
  const [sopSort, setSopSort] = useState(2);
  const [sopStatusList, setSopStatusList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [SOPId, setSOPId] = useState(null);
  const [SOPName , setSOPName] = useState(null);
  //add新增，edit编辑，view新增
  const [tabsStatus, setTabsStatus] = useState('add');
  //2草稿状态，可以编辑基本信息。1已禁用，不可编辑基本信息
  const [mesStatus, setMesStatus] = useState('2');


  const { containerHeight } = useSelector((state) => state.appReducer);

  useEffect(() => {
    getDictionariesList();
  }, [])// eslint-disable-line
  
  useEffect(() => {
    getSopPage();
  }, [page, size, sopStatus, sopSort])// eslint-disable-line
  /**
   * @description: 请求字典表，查询状态
   * @return {*}
   * @Author: gn
   */
  function getDictionariesList() {
    const params = {
      categoryCode: 'sop_and_role_status'
    }
    dictionariesList(params).then(res => {
      setSopStatusList(res);
    }).catch(err => message.error(err));
  };
  /**
   * @description: sop的分页请求
   * @return {*}
   * @Author: gn
   */
  function getSopPage() {
    const params = {
      data: {
        status: sopStatus,
        sort: sopSort,
      },
      page: {
        pageIndex: page,
        pageSize: size,
      },
    };
    sopPage(params).then(res => {
      setDataSource(res.list);
      setTotal(res.total);
    }).catch(err => { message.error(err) })
  };

  const handleChangePagination = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };
  /**
   * @description: 搜索
   * @return {*}
   * @Author: gn
   */
  function handleChangeSelect(value) {
    setSopStatus(value);
    setPage(1);
  };
  /**
   * @description: 新增
   * @return {*}
   * @Author: gn
   */
  function changeEdit() {
    setEditStatus('tabs');
    setSOPId(null);
    setMesStatus(null);
    setTabsStatus('add');
  }
  /**
   * @description: 编辑
   * @return {*}
   * @Author: gn
   */
  function handleEdit(record) {
    setEditStatus('tabs');
    setSOPId(record.id);
    setTabsStatus('edit');
    setMesStatus(record.status);
  };
  /**
   * @description: 查看
   * @return {*}
   * @Author: gn
   */
  function handleView(record) {
    setEditStatus('tabs');
    setSOPId(record.id);
    setTabsStatus('view');
    setMesStatus(record.status);
  };
  /**
   * @description: 复制
   * @return {*}
   * @Author: gn
   */
  function handleCope({ id }) {
    const params = {
      id: id,
    };
    copeSop(params).then(res => {
      getSopPage();
      message.success('复制成功');
    }).catch(err => { message.error(err) })
  };
  /**
   * @description: 发布
   * @return {*}
   * @Author: gn
   */
  function handleRelease({ id }) {
    const params = {
      id: id,
    };
    releaseSop(params).then(res => {
      getSopPage();
      message.success('发布成功');
    }).catch(err => { message.error(err) })
  };
  /**
   * @description: 删除
   * @return {*}
   * @Author: gn
   */
  function handleDelete(id) {
    const params = {
      id: id,
    };
    deleteSop(params).then(res => {
      getSopPage();
      message.success('删除成功');
    }).catch(err => { message.error(err) });

  };
  /**
   * @description: 禁用
   * @return {*}
   * @Author: gn
   */
  function handleDisable(id) {
    const params = {
      id: id,
    };
    disableSop(params).then(res => {
      getSopPage();
      message.success('禁用成功');
    }).catch(err => { message.error(err) });
  };
  /**
   * @description: 操作历史
   * @return {*}
   * @Author: gn
   */  
  function handleHistory(record){
    setSOPId(record.rCode);
    setSOPName(record.sopName);
    setEditStatus('history');
  };
  /**
   * @description: 筛选
   * @return {*}
   * @Author: gn
   */
  function changeTable(pagination, filters, sorter, extra) {
    // return
    console.log(sorter.order);
    switch (sorter.order) {
      case "ascend":
        return setSopSort(1);
      case "descend":
        return setSopSort(0);
      default:
        return setSopSort(2)
    }
  };
  /**
   * @description: 关闭弹窗
   * @return {*}
   * @Author: gn
   */
  function handleEditStatus() {
    eventBus.emit("setBreadcrumbData", ["系统管理", "SOP管理",]);
    setEditStatus('list');
    getSopPage();
  };

  const columns = [
    {
      title: "SOP名称",
      dataIndex: "sopName",
      ellipsis: true,
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "状态",
      dataIndex: "status",
      ellipsis: true,
      render: (text, record) => {
        switch (text) {
          case '0':
            return (
              <span
                className='node-status teal'>
                已发布
              </span>
            );
          case '1':
            return (
              <span
                className='node-status red'>
                已禁用
              </span>
            );
          case '2':
            return <span className="node-status gray">草稿</span>;
        }
      },
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      ellipsis: true,
      // sorter: (a, b) => new Date(a.createTime) - new Date(b.createTime),
      sorter: true,
      render: (text) => <span title={dayjs(text).format("YYYY-MM-DD HH:mm:ss")}>{dayjs(text).format("YYYY-MM-DD HH:mm:ss")}</span>,
    },
    {
      title: "操作",
      dataIndex: "operation",
      render: (text, record) => (
        <div className="operation">
          {
            record.status === '0' &&
            <>
              <OperateButton
                text="查看"
                icon="icon-eye"
                data={record}
                click={() => handleView(record)}
              />
              <OperateButton
                text="复制"
                icon="icon-cash"
                data={record}
                click={() => handleCope(record)}
              />
              <OperateButton
                text="禁用"
                icon="icon-ban"
                popType={true}
                title={`确定禁用${record.sopName}? 禁用后SOP将无法使用`}
                data={record}
                confirm={handleDisable}
              />
              <OperateButton
                text="操作历史"
                icon="icon-clock"
                data={record}
                click={() => handleHistory(record)}
              />
            </>
          }

          {record.status === '1' &&
            <>
              <OperateButton
                text="编辑"
                icon="icon-pencil"
                data={record}
                popType={true}
                title={`编辑后的内容仅对新创建项目有效。`}
                confirm={() => handleEdit(record)}
              />
              <OperateButton
                text="复制"
                icon="icon-cash"
                data={record}
                click={() => handleCope(record)}
              />
              <OperateButton
                text="发布"
                icon="icon-paper-airplane"
                popType={true}
                title={`确定已完成${record.sopName}的SOP设置并进行发布?`}
                data={record}
                confirm={() => handleRelease(record)}
              />
              <OperateButton
                text="操作历史"
                icon="icon-clock"
                data={record}
                click={() => handleHistory(record)}
              />
            </>
          }
          {record.status === '2' &&
            <>
              <OperateButton
                text="编辑"
                icon="icon-pencil"
                data={record}
                click={() => handleEdit(record)}
              />
              <OperateButton
                text="复制"
                icon="icon-cash"
                data={record}
                click={() => handleCope(record)}
              />
              <OperateButton
                text="发布"
                icon="icon-paper-airplane"
                popType={true}
                title={`确定已完成${record.sopName}的SOP设置并进行发布?`}
                data={record}
                confirm={() => handleRelease(record)}
              />
              <OperateButton
                text="删除"
                icon="icon-trash"
                popType={true}
                title={`确定删除${record.sopName}?`}
                data={record}
                confirm={handleDelete}
              />
            </>
          }
        </div>



      ),
    },
  ];
  return (
    <div className="SOPManagement">
      {
        editStatus === 'list' && (
          <>
            <div className="sop_head">
              <Button type="primary" onClick={() => changeEdit()}>
                创建SOP<i className="iconfont icon-plus-sm" style={{ fontSize: '14px' }}></i>
              </Button>
              
              <div>
                <span>状态：</span>
                <Select
                  value={sopStatus}
                  style={{ width: 110 }}
                  fieldNames={
                    { label: 'dictDesc', value: 'dictCode', }
                  }
                  onChange={handleChangeSelect}
                  options={[
                    { dictCode: null, dictDesc: '全部状态' },
                    ...sopStatusList,
                  ]}
                />
              </div>
            </div>
            <Table
              size="middle"
              className="custom-table"
              rowKey={(record) => record.id}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              // scroll={{
              //   y: containerHeight - 56 - 24 * 2 - 32 - 56 * 2 - 24 * 2,
              // }}
              onChange={changeTable}
            />
            {total !== 0 && <div style={{ textAlign: 'right', }}>
              <Pagination
                className="custom-pagination"
                total={total}
                pageSize={size}
                current={page}
                pageSizeOptions={["10", "20", "30", "40", "50"]}
                showSizeChanger
                // showQuickJumper
                // showTotal={(total) => `共 ${total} 条`}
                onChange={handleChangePagination}
              />
            </div>}
          </>
        )
      }
      {
        editStatus === 'tabs' && (
          <>
            <TabsChildren SOPId={SOPId} tabsStatus={tabsStatus} mesStatus={mesStatus} handleEditStatus={handleEditStatus} />
          </>
        )
      }
      {
        editStatus === 'history' && (
          <>
            <HistoryChildren SOPId={SOPId} SOPName={SOPName} handleEditStatus={handleEditStatus} />
          </>
        )
      }
    </div>
  )
}

export default SOPManagement;
