/*
 * @Description:
 * @Version: 1.0
 * @Autor: LINA.QI
 * @Date: 2023-06-05 13:17:05
 * @LastEditors: LINA.QI
 * @LastEditTime: 2023-07-14 11:05:37
 */

import { useEffect, useState } from 'react';
import Management from '../Management/index';
import CreatProject from './components/CreatProject';
import writeSession from '@/utils/commonFunc/writeSession';
import './index.scss';

function ProjectManagement() {
  const [components, setComponents] = useState('CreatProject');
  const [projectData, setProjectData] = useState(null); //项目ID

  useEffect(() => {
    if (components === 'CreatProject') {
      writeSession('sessionSave', false);
    }
  }, [components]);

  /**
   * @description: 切换组件
   * @return {*}
   * @author: LINA.QI
   */
  const handleChange = (data) => {
    setProjectData(data);
    setComponents('Management');
  };

  const changeBack = () => {
    setComponents('CreatProject');
  };

  return (
    <>
      {components === 'CreatProject' ? (
        <CreatProject changeComponents={handleChange}></CreatProject>
      ) : (
        <Management
          changeComponents={changeBack}
          projectData={projectData}
        ></Management>
      )}
    </>
  );
}

export default ProjectManagement;
