/*
 * @Description:
 * @Version: 1.0
 * @Autor: Wang Yulin
 * @Date: 2022-07-22 06:20:33
 * @LastEditors: pan.aiyan
 * @LastEditTime: 2023-06-16 10:40:06
 */
import { axiosInstance } from "../config";

export const test = () => {
  return axiosInstance.get("/test");
};

export const queryProjectFilePage = (params) => {
  return axiosInstance.post("/projectFile/queryProjectFilePage", params);
};
